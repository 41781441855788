import React, { useEffect, useState } from "react";
import "antd/dist/antd.min.css";
import axios from "axios";
import swal from "sweetalert";
import { createGlobalStyle } from "styled-components";

import {
  Layout,
  Row,
  Col,
  PageHeader,
  Divider,
  Space,
  Button,
  Table,
  Typography,
  Select,
  Spin
} from "antd";

import { FileExcelOutlined } from "@ant-design/icons";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import thTH from "antd/lib/locale/th_TH";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

import * as xlsx from "xlsx";

const { Content } = Layout;
const { Text } = Typography;
const { Option } = Select;

const StyledForm = createGlobalStyle`.ant-form-item {
    margin-bottom: 5px;
  }
  `;

const configuredLocale = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    dateFormat: "DD MM BBBB", // DD MM BBBB
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker.lang,
      dateFormat: "DD MM BBBB", // DD MM BBBB
      dateTimeFormat: "DD MM BBBB HH:mm:ss",
      yearFormat: "BBBB"
    }
  }
};

dayjs.locale("th");
dayjs.extend(buddhistEra);

const Research_citation_count = () => {
  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [levelU, _levelU] = useState("");
  const [r_id, _r_id] = useState("");

  const [dataSource1, _dataSource1] = useState();
  const [dataSourceEx, _dataSourceEx] = useState();
  const [columns1, _column1] = useState([]);

  const [dataYear, _dataYear] = useState([]);
  const [xValues, _xValues] = useState(5);

  const [loading, _loading] = useState(true);

  const [pageTable, _pageTable] = useState(1);
  const [pageSizeTable, _pageSizeTable] = useState(200);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const xresearch_id = localStorage.getItem("research_id");

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          axios
            .post(xURL + "findlevel", { research_id: xresearch_id })
            .then(result => {
              // console.log(result.data.length);
              if (result.data.length > 0) {
                if (result.data[0].user_level_id == 4) {
                  _r_id(xresearch_id);
                  _levelU("research");
                } else {
                  _levelU("");
                }
              } else {
                _levelU("");
              }

              GetTable();
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const GetTable = () => {
    getcolumn(5);
  };

  const getcolumn = num => {
    axios
      .post(xURL + "find_scopus_cyear2", {
        ldata: num
      })
      .then(result => {
        let cl = [],
          syear = [];
        let xyear = "";

        cl.push({
          title: "ที่",
          dataIndex: "num",
          key: "num",
          align: "center",
          width: 80,
          render: (text, record, index) => {
            return {
              props: {
                style: {
                  background: record.title == "รวมทั้งหมด" ? "#b5f5ec" : ""
                }
              },
              children: (
                <div>
                  {record.title == "รวมทั้งหมด"
                    ? <div>
                        {""}
                      </div>
                    : <div>
                        {index + 1}
                      </div>}
                </div>
              )
            };
          }
        });

        cl.push({
          title: "ชื่อเรื่อง",
          dataIndex: "title",
          key: "title",
          width: 500,
          render: (text, record) => {
            return {
              props: {
                style: {
                  background: record.title == "รวมทั้งหมด" ? "#b5f5ec" : ""
                }
              },
              children: (
                <div>
                  {text}
                </div>
              )
            };
          }
        });

        cl.push({
          title: "ปีที่ตีพิมพ์",
          dataIndex: "yearofsc",
          key: "yearofsc",
          align: "center",
          width: 100,
          sorter: (a, b) => a.yearofsc - b.yearofsc,
          render: (text, record) => {
            return {
              props: {
                style: {
                  background: record.title == "รวมทั้งหมด" ? "#b5f5ec" : ""
                }
              },
              children: (
                <div>
                  {text}
                </div>
              )
            };
          }
        });

        cl.push({
          title: "รวม",
          dataIndex: "sum",
          key: "sum",
          align: "center",
          render: (text, record) => {
            return {
              props: {
                style: {
                  background: record.title == "รวมทั้งหมด" ? "#b5f5ec" : ""
                }
              },
              children: (
                <a onClick={e => {}} style={{ fontWeight: "bold" }}>
                  {text}
                </a>
              )
            };
          }
        });

        result.data.forEach((i, idx) => {
          xyear = dayjs("01/01/" + i.year).format("BBBB");
          // console.log(result.data);

          cl.push({
            title: xyear,
            dataIndex: "year" + idx,
            key: "year" + idx,
            align: "center",
            render: (text, record) => {
              return {
                props: {
                  style: {
                    background: record.title == "รวมทั้งหมด" ? "#b5f5ec" : ""
                  }
                },
                children: (
                  <a onClick={e => {}}>
                    {text}
                  </a>
                )
              };
            }
          });

          syear.push({
            year: i.year
          });

          _dataYear(syear);
        });

        _column1(cl);

        /////////////////////////////////////////////////
        axios
          .post(xURL + "find_citation_count", {
            year: syear
          })
          .then(result => {
            //console.log(result.data);
            Show_CC(result.data, num);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Show_CC = (zdata, num) => {
    const Tb1 = [],
      Tb2 = [];

    let xsum1 = 0,
      xsum2 = 0,
      xsum3 = 0,
      xsum4 = 0,
      xsum5 = 0,
      xsum6 = 0,
      xsum7 = 0,
      xsum8 = 0,
      xsum9 = 0,
      xsum10 = 0,
      xsum11 = 0,
      xsum12 = 0,
      xsum13 = 0,
      xsum14 = 0,
      xsum15 = 0,
      xsum16 = 0,
      xsum17 = 0,
      xsum18 = 0,
      xsum19 = 0,
      xsum20 = 0,
      xsum21 = 0,
      xsum22 = 0,
      xsum23 = 0,
      xsum24 = 0,
      xsum25 = 0,
      xsum26 = 0,
      xsum27 = 0,
      xsum28 = 0,
      xsum29 = 0,
      xsum30 = 0,
      xsumall = 0;

    if (num == 5) {
      zdata.forEach((i, idx) => {
        Tb1.push({
          key: idx,
          num: parseInt(idx + 1),
          title: i.title,
          yearofsc: i.year,
          id: i.id,
          sum: i.yearsum,
          year0: i.year0,
          year1: i.year1,
          year2: i.year2,
          year3: i.year3,
          year4: i.year4
        });

        Tb2.push({
          title: i.title,
          yearofsc: i.year,
          sum: i.yearsum,
          year0: i.year0,
          year1: i.year1,
          year2: i.year2,
          year3: i.year3,
          year4: i.year4
        });

        xsum1 = xsum1 + i.year0;
        xsum2 = xsum2 + i.year1;
        xsum3 = xsum3 + i.year2;
        xsum4 = xsum4 + i.year3;
        xsum5 = xsum5 + i.year4;
        xsumall = xsumall + i.yearsum;
      });

      Tb1.push({
        key: 10,
        num: "",
        title: "รวมทั้งหมด",
        id: 0,
        sum: xsumall,
        year0: xsum1,
        year1: xsum2,
        year2: xsum3,
        year3: xsum4,
        year4: xsum5
      });

      Tb2.push({
        title: "รวมทั้งหมด",
        yearofsc: "",
        sum: xsumall,
        year0: xsum1,
        year1: xsum2,
        year2: xsum3,
        year3: xsum4,
        year4: xsum5
      });
    } else if (num == 10) {
      zdata.forEach((i, idx) => {
        Tb1.push({
          key: idx,
          num: parseInt(idx + 1),
          title: i.title,
          yearofsc: i.year,
          id: i.id,
          sum: i.yearsum,
          year0: i.year0,
          year1: i.year1,
          year2: i.year2,
          year3: i.year3,
          year4: i.year4,
          year5: i.year5,
          year6: i.year6,
          year7: i.year7,
          year8: i.year8,
          year9: i.year9
        });

        Tb2.push({
          title: i.title,
          yearofsc: i.year,
          sum: i.yearsum,
          year0: i.year0,
          year1: i.year1,
          year2: i.year2,
          year3: i.year3,
          year4: i.year4,
          year5: i.year5,
          year6: i.year6,
          year7: i.year7,
          year8: i.year8,
          year9: i.year9
        });

        xsum1 = xsum1 + i.year0;
        xsum2 = xsum2 + i.year1;
        xsum3 = xsum3 + i.year2;
        xsum4 = xsum4 + i.year3;
        xsum5 = xsum5 + i.year4;
        xsum6 = xsum6 + i.year5;
        xsum7 = xsum7 + i.year6;
        xsum8 = xsum8 + i.year7;
        xsum9 = xsum9 + i.year8;
        xsum10 = xsum10 + i.year9;
        xsumall = xsumall + i.yearsum;
      });

      Tb1.push({
        key: 10,
        num: "",
        title: "รวมทั้งหมด",
        id: 0,
        sum: xsumall,
        year0: xsum1,
        year1: xsum2,
        year2: xsum3,
        year3: xsum4,
        year4: xsum5,
        year5: xsum6,
        year6: xsum7,
        year7: xsum8,
        year8: xsum9,
        year9: xsum10
      });

      Tb2.push({
        title: "รวมทั้งหมด",
        yearofsc: "",
        sum: xsumall,
        year0: xsum1,
        year1: xsum2,
        year2: xsum3,
        year3: xsum4,
        year4: xsum5,
        year5: xsum6,
        year6: xsum7,
        year7: xsum8,
        year8: xsum9,
        year9: xsum10
      });
    } else if (num == 20) {
      zdata.forEach((i, idx) => {
        Tb1.push({
          key: idx,
          num: parseInt(idx + 1),
          title: i.title,
          yearofsc: i.year,
          id: i.id,
          sum: i.yearsum,
          year0: i.year0,
          year1: i.year1,
          year2: i.year2,
          year3: i.year3,
          year4: i.year4,
          year5: i.year5,
          year6: i.year6,
          year7: i.year7,
          year8: i.year8,
          year9: i.year9,
          year10: i.year10,
          year11: i.year11,
          year12: i.year12,
          year13: i.year13,
          year14: i.year14,
          year15: i.year15,
          year16: i.year16,
          year17: i.year17,
          year18: i.year18,
          year19: i.year19
        });

        Tb2.push({
          title: i.title,
          yearofsc: i.year,
          sum: i.yearsum,
          year0: i.year0,
          year1: i.year1,
          year2: i.year2,
          year3: i.year3,
          year4: i.year4,
          year5: i.year5,
          year6: i.year6,
          year7: i.year7,
          year8: i.year8,
          year9: i.year9,
          year10: i.year10,
          year11: i.year11,
          year12: i.year12,
          year13: i.year13,
          year14: i.year14,
          year15: i.year15,
          year16: i.year16,
          year17: i.year17,
          year18: i.year18,
          year19: i.year19
        });

        xsum1 = xsum1 + i.year0;
        xsum2 = xsum2 + i.year1;
        xsum3 = xsum3 + i.year2;
        xsum4 = xsum4 + i.year3;
        xsum5 = xsum5 + i.year4;
        xsum6 = xsum6 + i.year5;
        xsum7 = xsum7 + i.year6;
        xsum8 = xsum8 + i.year7;
        xsum9 = xsum9 + i.year8;
        xsum10 = xsum10 + i.year9;
        xsum11 = xsum11 + i.year10;
        xsum12 = xsum12 + i.year11;
        xsum13 = xsum13 + i.year12;
        xsum14 = xsum14 + i.year13;
        xsum15 = xsum15 + i.year14;
        xsum16 = xsum16 + i.year15;
        xsum17 = xsum17 + i.year16;
        xsum18 = xsum18 + i.year17;
        xsum19 = xsum19 + i.year18;
        xsum20 = xsum20 + i.year19;
        xsumall = xsumall + i.yearsum;
      });

      Tb1.push({
        key: 10,
        num: "",
        title: "รวมทั้งหมด",
        id: 0,
        sum: xsumall,
        year0: xsum1,
        year1: xsum2,
        year2: xsum3,
        year3: xsum4,
        year4: xsum5,
        year5: xsum6,
        year6: xsum7,
        year7: xsum8,
        year8: xsum9,
        year9: xsum10,
        year10: xsum11,
        year11: xsum12,
        year12: xsum13,
        year13: xsum14,
        year14: xsum15,
        year15: xsum16,
        year16: xsum17,
        year17: xsum18,
        year18: xsum19,
        year19: xsum20
      });

      Tb2.push({
        title: "รวมทั้งหมด",
        yearofsc: "",
        sum: xsumall,
        year0: xsum1,
        year1: xsum2,
        year2: xsum3,
        year3: xsum4,
        year4: xsum5,
        year5: xsum6,
        year6: xsum7,
        year7: xsum8,
        year8: xsum9,
        year9: xsum10,
        year10: xsum11,
        year11: xsum12,
        year12: xsum13,
        year13: xsum14,
        year14: xsum15,
        year15: xsum16,
        year16: xsum17,
        year17: xsum18,
        year18: xsum19,
        year19: xsum20
      });
    } else if (num == 30) {
      zdata.forEach((i, idx) => {
        Tb1.push({
          key: idx,
          num: parseInt(idx + 1),
          title: i.title,
          yearofsc: i.year,
          id: i.id,
          sum: i.yearsum,
          year0: i.year0,
          year1: i.year1,
          year2: i.year2,
          year3: i.year3,
          year4: i.year4,
          year5: i.year5,
          year6: i.year6,
          year7: i.year7,
          year8: i.year8,
          year9: i.year9,
          year10: i.year10,
          year11: i.year11,
          year12: i.year12,
          year13: i.year13,
          year14: i.year14,
          year15: i.year15,
          year16: i.year16,
          year17: i.year17,
          year18: i.year18,
          year19: i.year19,
          year20: i.year20,
          year21: i.year21,
          year22: i.year22,
          year23: i.year23,
          year24: i.year24,
          year25: i.year25,
          year26: i.year26,
          year27: i.year27,
          year28: i.year28,
          year29: i.year29
        });

        Tb2.push({
          title: i.title,
          yearofsc: i.year,
          sum: i.yearsum,
          year0: i.year0,
          year1: i.year1,
          year2: i.year2,
          year3: i.year3,
          year4: i.year4,
          year5: i.year5,
          year6: i.year6,
          year7: i.year7,
          year8: i.year8,
          year9: i.year9,
          year10: i.year10,
          year11: i.year11,
          year12: i.year12,
          year13: i.year13,
          year14: i.year14,
          year15: i.year15,
          year16: i.year16,
          year17: i.year17,
          year18: i.year18,
          year19: i.year19,
          year20: i.year20,
          year21: i.year21,
          year22: i.year22,
          year23: i.year23,
          year24: i.year24,
          year25: i.year25,
          year26: i.year26,
          year27: i.year27,
          year28: i.year28,
          year29: i.year29
        });

        xsum1 = xsum1 + i.year0;
        xsum2 = xsum2 + i.year1;
        xsum3 = xsum3 + i.year2;
        xsum4 = xsum4 + i.year3;
        xsum5 = xsum5 + i.year4;
        xsum6 = xsum6 + i.year5;
        xsum7 = xsum7 + i.year6;
        xsum8 = xsum8 + i.year7;
        xsum9 = xsum9 + i.year8;
        xsum10 = xsum10 + i.year9;
        xsum11 = xsum11 + i.year10;
        xsum12 = xsum12 + i.year11;
        xsum13 = xsum13 + i.year12;
        xsum14 = xsum14 + i.year13;
        xsum15 = xsum15 + i.year14;
        xsum16 = xsum16 + i.year15;
        xsum17 = xsum17 + i.year16;
        xsum18 = xsum18 + i.year17;
        xsum19 = xsum19 + i.year18;
        xsum20 = xsum20 + i.year19;
        xsum21 = xsum21 + i.year20;
        xsum22 = xsum22 + i.year21;
        xsum23 = xsum23 + i.year22;
        xsum24 = xsum24 + i.year23;
        xsum25 = xsum25 + i.year24;
        xsum26 = xsum26 + i.year25;
        xsum27 = xsum27 + i.year26;
        xsum28 = xsum28 + i.year27;
        xsum29 = xsum29 + i.year28;
        xsum30 = xsum30 + i.year29;
        xsumall = xsumall + i.yearsum;
      });

      Tb1.push({
        key: 10,
        num: "",
        title: "รวมทั้งหมด",
        id: 0,
        sum: xsumall,
        year0: xsum1,
        year1: xsum2,
        year2: xsum3,
        year3: xsum4,
        year4: xsum5,
        year5: xsum6,
        year6: xsum7,
        year7: xsum8,
        year8: xsum9,
        year9: xsum10,
        year10: xsum11,
        year11: xsum12,
        year12: xsum13,
        year13: xsum14,
        year14: xsum15,
        year15: xsum16,
        year16: xsum17,
        year17: xsum18,
        year18: xsum19,
        year19: xsum20,
        year20: xsum21,
        year21: xsum22,
        year22: xsum23,
        year23: xsum24,
        year24: xsum25,
        year25: xsum26,
        year26: xsum27,
        year27: xsum28,
        year28: xsum29,
        year29: xsum30
      });

      Tb2.push({
        title: "รวมทั้งหมด",
        yearofsc: "",
        sum: xsumall,
        year0: xsum1,
        year1: xsum2,
        year2: xsum3,
        year3: xsum4,
        year4: xsum5,
        year5: xsum6,
        year6: xsum7,
        year7: xsum8,
        year8: xsum9,
        year9: xsum10,
        year10: xsum11,
        year11: xsum12,
        year12: xsum13,
        year13: xsum14,
        year14: xsum15,
        year15: xsum16,
        year16: xsum17,
        year17: xsum18,
        year18: xsum19,
        year19: xsum20,
        year20: xsum21,
        year21: xsum22,
        year22: xsum23,
        year23: xsum24,
        year24: xsum25,
        year25: xsum26,
        year26: xsum27,
        year27: xsum28,
        year28: xsum29,
        year29: xsum30
      });
    }

    _loading(false);
    _dataSource1(Tb1);
    _dataSourceEx(Tb2);
  };

  const Showlist = (zyear, zhead, zcount) => {};

  const bExcelClick = () => {
    let xdata = [];

    let tb = [],
      xyear = "";

    tb.push("ชื่อเรื่อง");
    tb.push("ปีที่พิมพ์");
    tb.push("รวม");
    dataYear.forEach((i, idx) => {
      xyear = dayjs("01/01/" + i.year).format("BBBB");

      tb.push(xyear);
    });

    xdata.push(["Citation Count"]);
    xdata.push([""]);
    xdata.push(["แสดงข้อมูล " + xValues + " ปี"]);
    xdata.push(tb);

    // console.log(dataSourceEx);
    for (var row in dataSourceEx) {
      const xx = [];

      for (var col in dataSourceEx[row]) {
        xx.push(dataSourceEx[row][col]);
      }

      xdata.push(xx);
    }

    var wb = xlsx.utils.book_new(),
      ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, "Citation Count.xlsx");
  };

  const handleChange = value => {
    //console.log(`selected ${value}`);
    _loading(true);

    if (value == 0) {
      getcolumn(5);
      _xValues(5);
    } else if (value == 1) {
      getcolumn(10);
      _xValues(10);
    } else if (value == 2) {
      getcolumn(20);
      _xValues(20);
    } else if (value == 3) {
      getcolumn(30);
      _xValues(30);
    }
  };

  const pageOnChange = (zpage, zpageSize) => {
    _pageTable(zpage);
    _pageSizeTable(zpageSize);
  };

  return (
    <React.Fragment>
      <StyledForm />
      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={23}>
            <PageHeader className="site-page-header" title="Citation Count" />
            <Divider />
          </Col>
        </Row>

        <Row>
          <Col span={23}>
            <Space style={{ float: "right", marginBottom: "20px" }}>
              <Button ghost type="success" onClick={bExcelClick}>
                <FileExcelOutlined /> Excel
              </Button>
            </Space>
          </Col>
        </Row>

        <Row justify="center" style={{ marginBottom: 10 }}>
          <Col span={23}>
            <Spin tip="Loading..." spinning={loading} size="large">
              <Table
                bordered
                scroll={{ x: "max-content" }}
                dataSource={dataSource1}
                columns={columns1}
                size="small"
                pagination={{
                  onChange: (zpage, zpageSize) => {
                    pageOnChange(zpage, zpageSize);
                  },
                  current: pageTable,
                  pageSize: pageSizeTable
                }}
              />
            </Spin>

            <Divider />

            <Row style={{ float: "right" }}>
              <Space>
                <p>แสดงรายการ :</p>
                <Select
                  defaultValue="0"
                  style={{ width: 120 }}
                  onChange={handleChange}
                >
                  <Option value="0">5 ปี</Option>
                  <Option value="1">10 ปี</Option>
                  <Option value="2">20 ปี</Option>
                  <Option value="3">30 ปี</Option>
                </Select>
              </Space>
            </Row>
          </Col>
        </Row>
      </Content>
      <div style={{ height: "250px" }} />

      <Footer_last />
    </React.Fragment>
  );
};

export default Research_citation_count;
