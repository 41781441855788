import React, { useEffect, useState } from "react";
import axios from "axios";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import { createGlobalStyle } from "styled-components";

import {
  SearchOutlined,
  ClearOutlined,
  FileExcelOutlined
} from "@ant-design/icons";

import {
  Layout,
  Row,
  Col,
  PageHeader,
  Divider,
  Card,
  Form,
  Space,
  Button,
  Table,
  Typography,
  Select
} from "antd";

const { Content } = Layout;
const { Text } = Typography;
const { Option } = Select;

const StyledForm = createGlobalStyle`.ant-form-item {
    margin-bottom: 5px;
  }
  `;

const Research_report4 = () => {
  const [form1] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [Xvalue1, _Xvalue1] = useState("value1");
  const [Xvalue2, _Xvalue2] = useState("v1");

  const [dataSource1, _dataSource1] = useState();

  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const bClear = () => {
    window.location.reload(false);
  };

  const bSearch = (v1, v2) => {
    // console.log(v1, v2);
  };

  const [slcomfig, _slconfig] = useState([
    {
      value: "v1",
      label: "จำนวนเงินทุนสนับสนุนการวิจัย จำแนกตามแหล่งทุน"
    },
    {
      value: "v2",
      label: "จำนวนเงินทุนสนับสนุนการวิจัย จำแนกตามทิศทางวิจัย"
    }
  ]);

  const columns1 = [
    {
      title: "รายการข้อมูล",
      dataIndex: "htb_list",
      key: "htb_list",
      width: 400,
      align: "center"
    },
    {
      title: "61",
      dataIndex: "y61",
      key: "y61",
      width: 100,
      align: "center"
    },
    {
      title: "62",
      dataIndex: "y62",
      key: "y62",
      width: 100,
      align: "center"
    },
    {
      title: "63",
      dataIndex: "y63",
      key: "y63",
      width: 100,
      align: "center"
    },
    {
      title: "64",
      dataIndex: "y64",
      key: "y64",
      width: 100,
      align: "center"
    },
    {
      title: "65",
      dataIndex: "y65",
      key: "y65",
      width: 100,
      align: "center"
    },
    {
      title: "66",
      dataIndex: "y66",
      key: "y66",
      width: 100,
      align: "center"
    }
  ];

  const handleChange = value => {
    // console.log(`selected ${value}`);

    _Xvalue1(value);

    if (value == "value1") {
      _slconfig([
        {
          value: "v1",
          label: "จำนวนเงินทุนสนับสนุนการวิจัย จำแนกตามแหล่งทุน"
        },
        {
          value: "v2",
          label: "จำนวนเงินทุนสนับสนุนการวิจัย จำแนกตามทิศทางวิจัย"
        }
      ]);
    } else if (value == "value2") {
      _slconfig([
        {
          value: "v1",
          label: "จำนวนแหล่งทุนภาครัฐในประเทศ (ไม่รวม มช.)"
        },
        {
          value: "v2",
          label: "จำนวนแหล่งทุนภาครัฐจากต่างประเทศ"
        },
        {
          value: "v3",
          label: "จำนวนแหล่งทุนภาคเอกชนในประเทศ"
        },
        {
          value: "v4",
          label: "จำนวนแหล่งทุนภาคเอกชนจากต่างประเทศ"
        },
        {
          value: "v5",
          label:
            "จำนวนแหล่งทุนที่เป็นองค์กร บริษัท หรือผู้ประกอบการเอกชนที่ร่วมทุนวิจัยกับภาครัฐ"
        },
        {
          value: "v6",
          label: "จำนวนเงินทุนสนับสนุนการวิจัยจาก NIH"
        },
        {
          value: "v7",
          label:
            "จำนวนเงินทุนสนับสนุนการวิจัยจากองค์กร บริษัท หรือผู้ประกอบการเอกชน"
        },
        {
          value: "v8",
          label: "จำนวนแหล่งทุนใหม่"
        }
      ]);
    }
  };

  const handleChange2 = value => {
    // console.log(`selected ${value}`);
    _Xvalue2(value);
  };

  return (
    <React.Fragment>
      <StyledForm />

      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={23}>
            <PageHeader
              className="site-page-header"
              title="ผลลัพธ์ด้านการเงิน ตลาด และกลยุทธ์"
            />
            <Divider />

            <Row justify="center">
              <Col span={23}>
                <Card
                  style={{
                    borderRadius: "15px",
                    overflow: "hidden"
                  }}
                >
                  <Form form={form1}>
                    <Space wrap>
                      <Form.Item label="" name="sl1">
                        <Select
                          defaultValue="value1"
                          style={{ width: 320 }}
                          onChange={handleChange}
                          options={[
                            {
                              value: "value1",
                              label: "ผลการดำเนินการด้านการเงิน เพิ่มเติมในเล่ม"
                            },
                            {
                              value: "value2",
                              label: "การตลาด"
                            }
                          ]}
                        />
                      </Form.Item>

                      <Form.Item label="" name="sl2">
                        <Select
                          defaultValue="v1"
                          style={{ width: 450 }}
                          onChange={handleChange2}
                          options={slcomfig}
                        />
                      </Form.Item>
                    </Space>
                  </Form>

                  <br />
                  <Row>
                    <Space>
                      <Button
                        ghost
                        type="primary"
                        onClick={bSearch(Xvalue1, Xvalue2)}
                        hidden
                      >
                        <SearchOutlined /> แสดงข้อมูล
                      </Button>

                      <Button ghost type="danger" onClick={bClear}>
                        <ClearOutlined /> เคลียร์
                      </Button>

                      <Button ghost type="success">
                        <FileExcelOutlined /> Excel
                      </Button>
                    </Space>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        <br />
        <br />

        <Row justify="center" style={{ marginBottom: 10 }}>
          <Col span={23}>
            <Table
              bordered
              scroll={{ x: "max-content" }}
              dataSource={dataSource1}
              columns={columns1}
              size="small"
              pagination={false}
            />
          </Col>
        </Row>
      </Content>

      <Footer_last />
    </React.Fragment>
  );
};

export default Research_report4;
