import React, { useEffect, useState } from "react";
import "antd/dist/antd.min.css";
import axios from "axios";

import { createGlobalStyle } from "styled-components";

import {
  ClearOutlined,
  SaveOutlined,
  PaperClipOutlined,
  EditOutlined,
  DeleteOutlined
} from "@ant-design/icons";

import {
  Layout,
  Row,
  Col,
  PageHeader,
  Divider,
  Card,
  Form,
  Input,
  Select,
  ConfigProvider,
  Space,
  Table,
  Typography,
  Button,
  Modal
} from "antd";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import swal from "sweetalert";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import thTH from "antd/lib/locale/th_TH";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

const { Content } = Layout;
const { Option } = Select;
const { confirm } = Modal;

const StyledForm = createGlobalStyle`.ant-form-item {
  margin-bottom: 5px;
}
`;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 }
};

const configuredLocale = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    dateFormat: "DD MM BBBB", // DD MM BBBB
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker.lang,
      dateFormat: "DD MM BBBB", // DD MM BBBB
      dateTimeFormat: "DD MM BBBB HH:mm:ss",
      yearFormat: "BBBB"
    }
  }
};

dayjs.locale("th");
dayjs.extend(buddhistEra);

const Research_input_patent = () => {
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [xPatent_type, _xPatent_type] = useState("");
  const [xPatent_typeValue, _xPatent_typeValue] = useState("0");

  const [xResearch, _xResearch] = useState("");
  const [xResearchValue, _xResearchValue] = useState("0");

  const [xPatent_role, _xPatent_role] = useState("");
  const [xPatent_roleValue, _xPatent_roleValue] = useState("0");

  const [xPatent_scope, _xPatent_scope] = useState("");
  const [xPatent_scopeValue, _xPatent_scopeValue] = useState("0");

  const DatePicker = generatePicker(dayjsGenerateConfig);

  const [ListFile, _ListFile] = useState([]);
  const [ListFile2, _ListFile2] = useState([]);
  const [ListFile3, _ListFile3] = useState([]);
  const [ListFile4, _ListFile4] = useState([]);

  const [Maxpartent1, _Maxpartent1] = useState();
  const [Maxpartent2, _Maxpartent2] = useState();

  const [dataSource1, _dataSource1] = useState();

  const [paginationInfo] = useState({
    current: 1,
    pageSize: 10
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [id_patent, _id_patent] = useState();
  const [id_patentResearch, _id_patentResearch] = useState();

  const [chkFile1, _chkFile1] = useState();
  const [chkFile2, _chkFile2] = useState();

  useEffect(() => {
    const token = localStorage.getItem("token");
    //console.log(token);

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");

          //ประเภท
          axios
            .get(xURL + "Patent_type")
            .then(result => {
              //console.log(result.data);

              _xPatent_type(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหานักวิจัย
          axios
            .get(xURL + "research_pms")
            .then(result => {
              //console.log(result.data);

              _xResearch(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหาสถานะผู้ประดิษฐ์
          axios
            .get(xURL + "Patent_role")
            .then(result => {
              //console.log(result.data);

              _xPatent_role(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหาระดับการเผยแพร่
          axios
            .get(xURL + "Patent_scope")
            .then(result => {
              //console.log(result.data);

              _xPatent_scope(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          dataOrder();
          Patent_Data();
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const dataOrder = () => {
    //ค้นหาลำดับ1
    axios
      .get(xURL + "Patent_order1")
      .then(result => {
        //console.log(result.data);

        _Maxpartent1(result.data[0].patent_id);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    //ค้นหาลำดับ12
    axios
      .get(xURL + "Patent_order2")
      .then(result => {
        //console.log(result.data);

        _Maxpartent2(result.data[0].id);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bClearclick = () => {
    form1.resetFields();
    form2.resetFields();

    _xPatent_typeValue("0");
    _xResearchValue("0");
    _xPatent_roleValue("0");
    _xPatent_scopeValue("0");

    _ListFile([]);
    _ListFile2([]);
    _ListFile3([]);
    _ListFile4([]);

    dataOrder();
    Patent_Data();

    _chkFile1();
    _chkFile2();
  };

  const bSaveclick = () => {
    const f = form1.getFieldsValue();

    if (!f.edpatent_name) {
      swal(
        "เพิ่ม ทรัพย์สินทางปัญญา ไม่ได้!",
        "ยังไม่ได้ คีย์ ชื่อที่แสดง",
        "warning"
      );
    } else if (xPatent_typeValue == "0") {
      swal(
        "เพิ่ม ทรัพย์สินทางปัญญา ไม่ได้!",
        "ยังไม่ได้ เลือก ประเภท",
        "warning"
      );
    } else if (xResearchValue == "0") {
      swal(
        "เพิ่ม ทรัพย์สินทางปัญญา ไม่ได้!",
        "ยังไม่ได้ เลือก ผู้ประดิษฐ์",
        "warning"
      );
    } else if (xPatent_roleValue == "0") {
      swal(
        "เพิ่ม ทรัพย์สินทางปัญญา ไม่ได้!",
        "ยังไม่ได้ เลือก สถานะผู้ประดิษฐ์",
        "warning"
      );
    } else if (!f.patent_request_number) {
      swal(
        "เพิ่ม ทรัพย์สินทางปัญญา ไม่ได้!",
        "ยังไม่ได้ คีย์ เลขที่คำขอ",
        "warning"
      );
    } else if (xPatent_scopeValue == "0") {
      swal(
        "เพิ่ม ทรัพย์สินทางปัญญา ไม่ได้!",
        "ยังไม่ได้ เลือก ระดับการเผยแพร่",
        "warning"
      );
    } else {
      swal({
        title: "ยืนยันเพิ่มทรัพย์สินทางปัญญา",
        text: "",
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          swal({
            title: "แจ้งเตือน!",
            text: "เพิ่มทรัพย์สินทางปัญญา สำเร็จ",
            icon: "success",
            buttons: false,
            timer: 2000
          }).then(() => {
            insertData();
          });
        }
      });
    }
  };

  const insertData = () => {
    const f = form1.getFieldsValue();

    let xdate_input = "",
      xdate_publish = "",
      xdate_expire = "";

    xdate_input = dayjs(f.date_input).format("YYYY-MM-DD");
    xdate_publish = dayjs(f.date_publish).format("YYYY-MM-DD");
    xdate_expire = dayjs(f.date_expire).format("YYYY-MM-DD");

    // console.log(
    //   f.edpatent_name,
    //   xPatent_typeValue,
    //   xResearchValue,
    //   xPatent_roleValue,
    //   xdate_input,
    //   f.patent_request_number,
    //   f.patent_number,
    //   xdate_publish,
    //   xdate_expire,
    //   xPatent_scopeValue,
    //   ListFile,
    //   ListFile2
    // );

    const fd = new FormData();
    fd.append("file", ListFile);
    const fd2 = new FormData();
    fd2.append("file", ListFile2);

    let xfile = "",
      xfile2 = "";

    axios.post(xURL + "uploadfile6", fd).then(res => {
      axios.post(xURL + "uploadfile7", fd2).then(res2 => {
        if (ListFile == "" || typeof ListFile == "undefined") {
          xfile = "";
        } else {
          xfile = res.data.filename;
        }

        if (ListFile2 == "" || typeof ListFile2 == "undefined") {
          xfile2 = "";
        } else {
          xfile2 = res2.data.filename;
        }

        axios
          .post(xURL + "insertPatent", {
            patent_name: f.edpatent_name,
            patent_type: xPatent_typeValue,
            patent_researchers: xResearchValue,
            patent_role: xPatent_roleValue,
            date_input: xdate_input,
            patent_request_number: f.patent_request_number,
            patent_number: f.patent_number,
            date_publish: xdate_publish,
            date_expire: xdate_expire,
            patent_scope: xPatent_scopeValue,
            patent_submit_file: xfile,
            patent_certificate_file: xfile2,
            order1: Maxpartent1 + 1,
            order2: Maxpartent2 + 1
          })
          .then(result => {
            bClearclick();
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      });
    });
  };

  const Patent_Data = () => {
    axios
      .get(xURL + "data_patent")
      .then(result => {
        const Tb1 = [];

        let xnum = 0,
          xpatent_name = "";

        result.data.forEach((i, idx) => {
          let xtype = "",
            xrole = "",
            xscope = "";

          if (i.patent_type == 1) {
            xtype = "อนุสิทธิบัตร";
          } else if (i.patent_type == 2) {
            xtype = "สิทธิบัตร";
          }

          if (i.patent_role == 1) {
            xrole = "ผู้ประดิษฐ์หลัก";
          } else if (i.patent_role == 2) {
            xrole = "ผู้ประดิษฐ์ร่วม";
          }

          if (i.patent_scope == 1) {
            xscope = "ระดับชาติ";
          } else if (i.patent_scope == 2) {
            xscope = "ระดับนานาชาติ";
          }

          let xdate1 = "",
            xdate2 = "",
            xdate3 = "";

          if (!i.date_input) {
            xdate1 = "";
          } else {
            xdate1 = dayjs(i.date_input).format("DD/MM/BBBB");
          }
          if (!i.date_publish) {
            xdate2 = "";
          } else {
            xdate2 = dayjs(i.date_publish).format("DD/MM/BBBB");
          }
          if (!i.date_expire) {
            xdate3 = "";
          } else {
            xdate3 = dayjs(i.date_expire).format("DD/MM/BBBB");
          }

          let url_file = "",
            url_file2 = "";

          url_file = `${xURL}file6/${i.patent_submit_file}`;
          url_file2 = `${xURL}file7/${i.patent_certificate_file}`;

          if (i.patent_name !== xpatent_name) {
            xnum = xnum + 1;
          }

          Tb1.push({
            key: idx,
            num: xnum,
            patent_name: i.patent_name,
            patent_type: xtype,
            patent_researchers: i.patent_researchers,
            patent_role: xrole,
            date_input: xdate1,
            patent_request_number: i.patent_request_number,
            patent_number: i.patent_number,
            date_publish: xdate2,
            date_expire: xdate3,
            patent_scope: xscope,
            linkfile: url_file,
            linkfile2: url_file2,
            chkfile1: i.patent_submit_file,
            chkfile2: i.patent_certificate_file,
            patent_researchers_id: i.research_id
          });

          xpatent_name = i.patent_name;
        });

        _dataSource1(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bEdit = zdata => {
    setIsModalVisible(true);

    // console.log(zdata);

    if (zdata.patent_type == "อนุสิทธิบัตร") {
      _xPatent_typeValue(1);
    } else if (zdata.patent_type == "สิทธิบัตร") {
      _xPatent_typeValue(2);
    }

    // console.log(zdata.patent_researchers_id);
    _xResearchValue(zdata.patent_researchers_id);

    if (zdata.patent_role == "ผู้ประดิษฐ์หลัก") {
      _xPatent_roleValue(1);
    } else if (zdata.patent_role == "ผู้ประดิษฐ์ร่วม") {
      _xPatent_roleValue(2);
    }

    if (zdata.patent_role == "ผู้ประดิษฐ์หลัก") {
      _xPatent_scopeValue(1);
    } else if (zdata.patent_role == "ผู้ประดิษฐ์ร่วม") {
      _xPatent_scopeValue(2);
    }

    if (zdata.patent_scope == "ระดับชาติ") {
      _xPatent_scopeValue(1);
    } else if (zdata.patent_scope == "ระดับนานาชาติ ") {
      _xPatent_scopeValue(2);
    }

    _chkFile1(zdata.chkfile1);
    _chkFile2(zdata.chkfile2);

    axios
      .post(xURL + "getDate_patent", {
        patent_request_number: zdata.patent_request_number,
        patent_researchers_id: zdata.patent_researchers_id
      })
      .then(result => {
        let xdate_input = "",
          xdate_publish = "",
          xdate_expire = "";

        if (!result.data[0].date_input) {
          xdate_input = "";
        } else {
          xdate_input = dayjs(result.data[0].date_input);
        }

        if (!result.data[0].date_publish) {
          xdate_publish = "";
        } else {
          xdate_publish = dayjs(result.data[0].date_publish);
        }

        if (!result.data[0].date_expire) {
          xdate_expire = "";
        } else {
          xdate_expire = dayjs(result.data[0].date_expire);
        }

        _id_patent(result.data[0].patent_id);
        _id_patentResearch(result.data[0].patent_researchers_id);

        form2.setFieldsValue({
          edpatent_name: zdata.patent_name,
          patent_type: zdata.patent_type,
          slpatent_researchers: zdata.patent_researchers,
          patent_role: zdata.patent_role,
          date_input: xdate_input,
          patent_request_number: zdata.patent_request_number,
          patent_number: zdata.patent_number,
          date_publish: xdate_publish,
          date_expire: xdate_expire,
          patent_scope: zdata.patent_scope
        });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bEditClick = () => {
    const f = form2.getFieldsValue();

    if (!f.edpatent_name) {
      swal(
        "เพิ่ม ทรัพย์สินทางปัญญา ไม่ได้!",
        "ยังไม่ได้ คีย์ ชื่อที่แสดง",
        "warning"
      );
    } else if (xPatent_typeValue == "0") {
      swal(
        "เพิ่ม ทรัพย์สินทางปัญญา ไม่ได้!",
        "ยังไม่ได้ เลือก ประเภท",
        "warning"
      );
    } else if (xResearchValue == "0") {
      swal(
        "เพิ่ม ทรัพย์สินทางปัญญา ไม่ได้!",
        "ยังไม่ได้ เลือก ผู้ประดิษฐ์",
        "warning"
      );
    } else if (xPatent_roleValue == "0") {
      swal(
        "เพิ่ม ทรัพย์สินทางปัญญา ไม่ได้!",
        "ยังไม่ได้ เลือก สถานะผู้ประดิษฐ์",
        "warning"
      );
    } else if (!f.patent_request_number) {
      swal(
        "เพิ่ม ทรัพย์สินทางปัญญา ไม่ได้!",
        "ยังไม่ได้ คีย์ เลขที่คำขอ",
        "warning"
      );
    } else if (xPatent_scopeValue == "0") {
      swal(
        "เพิ่ม ทรัพย์สินทางปัญญา ไม่ได้!",
        "ยังไม่ได้ เลือก ระดับการเผยแพร่",
        "warning"
      );
    } else {
      swal({
        title: "ยืนยันแก้ไขทรัพย์สินทางปัญญา",
        text: "",
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          swal({
            title: "แจ้งเตือน!",
            text: "แก้ไขทรัพย์สินทางปัญญา สำเร็จ",
            icon: "success",
            buttons: false,
            timer: 2000
          }).then(() => {
            editData();
          });
        }
      });
    }
  };

  const editData = () => {
    const f = form2.getFieldsValue();

    let xdate_input = "",
      xdate_publish = "",
      xdate_expire = "";

    xdate_input = dayjs(f.date_input).format("YYYY-MM-DD");
    xdate_publish = dayjs(f.date_publish).format("YYYY-MM-DD");
    xdate_expire = dayjs(f.date_expire).format("YYYY-MM-DD");

    // console.log(id_patent, id_patentResearch);

    const fd = new FormData();
    fd.append("file", ListFile3);
    const fd2 = new FormData();
    fd2.append("file", ListFile4);

    let xfile = "",
      xfile2 = "";

    axios.post(xURL + "uploadfile6", fd).then(res => {
      axios.post(xURL + "uploadfile7", fd2).then(res2 => {
        if (ListFile3 == "" || typeof ListFile3 == "undefined") {
          if (chkFile1 !== "") {
            xfile = chkFile1;
          } else {
            xfile = "";
          }
        } else {
          xfile = res.data.filename;
        }

        if (ListFile4 == "" || typeof ListFile4 == "undefined") {
          if (chkFile2 !== "") {
            xfile2 = chkFile2;
          } else {
            xfile2 = "";
          }
        } else {
          xfile2 = res2.data.filename;
        }

        axios
          .post(xURL + "editPatent", {
            patent_name: f.edpatent_name,
            patent_type: xPatent_typeValue,
            patent_researchers: xResearchValue,
            patent_role: xPatent_roleValue,
            date_input: xdate_input,
            patent_request_number: f.patent_request_number,
            patent_number: f.patent_number,
            date_publish: xdate_publish,
            date_expire: xdate_expire,
            patent_scope: xPatent_scopeValue,
            id_patent: id_patent,
            id_patentResearch: id_patentResearch,
            patent_submit_file: xfile,
            patent_certificate_file: xfile2
          })
          .then(result => {
            handleCancel();
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      });
    });
  };

  const bDelete = zdata => {
    // console.log(zdata.chkfile1, zdata.chkfile2);

    axios
      .post(xURL + "getDate_patent", {
        patent_request_number: zdata.patent_request_number,
        patent_researchers_id: zdata.patent_researchers_id
      })
      .then(result => {
        deletedata(
          zdata,
          result.data[0].patent_id,
          result.data[0].patent_researchers_id
        );
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const deletedata = (zdata, patent_id, patent_researchers_id) => {
    swal({
      title: "ยืนยัน ลบ ทรัพย์สินทางปัญญา",
      text: zdata.patent_name,
      icon: "warning",
      dangerMode: true,
      buttons: ["ยกเลิก", "ยืนยัน"]
    }).then(willDelete => {
      if (willDelete) {
        if (chkFile1 !== "") {
          axios
            .post(xURL + "delete_file", {
              linkfile: zdata.chkfile1,
              order: 5
            })
            .then(result => {})
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
        }
        if (chkFile2 !== "") {
          axios
            .post(xURL + "delete_file", {
              linkfile: zdata.chkfile2,
              order: 6
            })
            .then(result => {})
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
        }

        axios
          .post(xURL + "deletePatent", {
            id_patent: patent_id,
            id_patentResearch: patent_researchers_id
          })
          .then(result => {
            //getInvoice(xgrant, xpo);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        swal("แจ้งเตือน!", "ทรัพย์สินทางปัญญา สำเร็จ!", "success").then(() => {
          handleCancel();
        });
      }
    });
  };

  const columns1 = [
    {
      title: "ลำดับที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 60,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.patent_name === dataSource1[trueIndex - 1].patent_name
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.patent_name === dataSource1[trueIndex + i].patent_name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ชื่อที่แสดงถึงการประดิษฐ์/การออกแบบผลิตภัณฑ์",
      dataIndex: "patent_name",
      key: "patent_name",
      width: 300,
      // sorter: (a, b) => {
      //   return a.patent_name.localeCompare(b.patent_name);
      // },
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: (
            <p style={{ fontWeight: "bold" }}>
              {text}
            </p>
          ),
          props: {}
        };

        if (
          index >= 1 &&
          record.patent_name === dataSource1[trueIndex - 1].patent_name
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.patent_name === dataSource1[trueIndex + i].patent_name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ประเภท",
      dataIndex: "patent_type",
      key: "patent_type",
      align: "center",
      width: 100,
      // sorter: (a, b) => {
      //   return a.patent_type.localeCompare(b.patent_type);
      // }
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.patent_name === dataSource1[trueIndex - 1].patent_name
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.patent_name === dataSource1[trueIndex + i].patent_name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ผู้ประดิษฐ์",
      dataIndex: "patent_researchers",
      key: "patent_researchers",
      width: 200
      // sorter: (a, b) => {
      //   return a.patent_researchers.localeCompare(b.patent_researchers);
      // }
    },
    {
      title: "สถานะผู้ประดิษฐ์",
      dataIndex: "patent_role",
      key: "patent_role",
      align: "center",
      width: 100
      // sorter: (a, b) => {
      //   return a.patent_role.localeCompare(b.patent_role);
      // }
    },
    {
      title: "วันที่ยื่นจด",
      dataIndex: "date_input",
      key: "date_input",
      align: "center",
      width: 100,
      // sorter: (a, b) => {
      //   return a.date_input.localeCompare(b.date_input);
      // }
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.patent_name === dataSource1[trueIndex - 1].patent_name
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.patent_name === dataSource1[trueIndex + i].patent_name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "เลขที่คำขอ",
      dataIndex: "patent_request_number",
      key: "patent_request_number",
      align: "center",
      width: 100,
      // sorter: (a, b) => a.patent_request_number - b.patent_request_number
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.patent_name === dataSource1[trueIndex - 1].patent_name
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.patent_name === dataSource1[trueIndex + i].patent_name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "เลขที่สิทธิบัตร/อนุสิทธิบัตร",
      dataIndex: "patent_number",
      key: "patent_number",
      align: "center",
      width: 100,
      // sorter: (a, b) => a.patent_number - b.patent_number
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.patent_name === dataSource1[trueIndex - 1].patent_name
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.patent_name === dataSource1[trueIndex + i].patent_name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "วันที่เผยแพร่",
      dataIndex: "date_publish",
      key: "date_publish",
      align: "center",
      width: 100,
      // sorter: (a, b) =>
      //   moment(moment(a.date_publish, "DD/MM/YYYY")).unix() -
      //   moment(moment(b.date_publish, "DD/MM/YYYY")).unix()
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.patent_name === dataSource1[trueIndex - 1].patent_name
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.patent_name === dataSource1[trueIndex + i].patent_name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "วันที่หมดอายุ",
      dataIndex: "date_expire",
      key: "date_expire",
      align: "center",
      width: 100,
      // sorter: (a, b) =>
      //   moment(moment(a.date_expire, "DD/MM/YYYY")).unix() -
      //   moment(moment(b.date_expire, "DD/MM/YYYY")).unix()
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.patent_name === dataSource1[trueIndex - 1].patent_name
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.patent_name === dataSource1[trueIndex + i].patent_name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ระดับการเผยแพร่",
      dataIndex: "patent_scope",
      key: "patent_scope",
      align: "center",
      width: 100,
      // sorter: (a, b) => {
      //   return a.patent_scope.localeCompare(b.patent_scope);
      // }
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.patent_name === dataSource1[trueIndex - 1].patent_name
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.patent_name === dataSource1[trueIndex + i].patent_name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ไฟล์เอกสารคำขอ",
      dataIndex: "patent_submit_file ",
      key: "patent_submit_file ",
      align: "center",
      width: 100,
      render(text, record, index) {
        if (record.chkfile1) {
          return {
            children: (
              <a href={record.linkfile} target="_blank">
                <PaperClipOutlined /> ไฟล์เอกสารคำขอ
              </a>
            )
          };
        }
      }
    },
    {
      title: "ไฟล์ Certificate",
      dataIndex: "patent_certificate_file",
      key: "patent_certificate_file",
      align: "center",
      width: 100,
      render(text, record, index) {
        if (record.chkfile2) {
          return {
            children: (
              <a href={record.linkfile2} target="_blank">
                <PaperClipOutlined /> ไฟล์ Certificate
              </a>
            )
          };
        }
      }
    },
    {
      title: "แก้ไข",
      dataIndex: "update",
      key: "update",
      align: "center",
      width: 80,
      render(text, record, index) {
        return {
          children: (
            <Button
              icon={<EditOutlined />}
              size="small"
              onClick={e => {
                //console.log("corresponding email is :", record.i_sum3);
                bEdit(record);
              }}
            />
          )
        };
      },
      fixed: "right"
    },
    {
      title: "ลบ",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      width: 80,
      render(text, record, index) {
        return {
          children: (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="small"
              onClick={e => {
                //console.log("corresponding email is :", record.i_sum3);
                bDelete(record);
              }}
            />
          )
        };
      },
      fixed: "right"
    }
  ];

  function handleChange(value) {
    _xPatent_typeValue(value);
  }

  function handleChange2(value) {
    _xResearchValue(value);
  }

  function handleChange3(value) {
    _xPatent_roleValue(value);
  }

  function handleChange4(value) {
    _xPatent_scopeValue(value);
  }

  const onChangeU = e => {
    _ListFile(e.target.files[0]);
  };

  const onChangeU2 = e => {
    _ListFile2(e.target.files[0]);
  };

  const onChangeU3 = e => {
    _ListFile3(e.target.files[0]);
  };

  const onChangeU4 = e => {
    _ListFile4(e.target.files[0]);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    bClearclick();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    bClearclick();
  };

  return (
    <React.Fragment>
      <StyledForm />
      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />
      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="space-around">
          <Col xs={23} sm={23} md={23} lg={18} xl={16}>
            <PageHeader
              className="site-page-header"
              title="เพิ่ม/แก้ไข ทรัพย์สินทางปัญญา"
            />
            <Divider />

            <Card>
              <Form
                form={form1}
                initialValues={{
                  edpatent_name: "",
                  patent_type: "เลือกรายการ",
                  slpatent_researchers: "เลือกรายการ",
                  patent_role: "เลือกรายการ",
                  date_input: "",
                  patent_request_number: "",
                  patent_number: "",
                  date_publish: "",
                  date_expire: "",
                  patent_scope: "เลือกรายการ"
                }}
                {...layout}
              >
                <Form.Item
                  label="ชื่อที่แสดง"
                  name="edpatent_name"
                  rules={[
                    {
                      required: true,
                      message: "กรุณากรอก ชื่อที่แสดง"
                    }
                  ]}
                >
                  <Input
                    allowClear
                    placeholder="ชื่อที่แสดงถึงการประดิษฐ์/การออกแบบผลิตภัณฑ์"
                    onPressEnter={() => {
                      // txtname_eng.current.focus();
                    }}
                    //style={{ width: 400 }}
                  />
                </Form.Item>

                <Form.Item
                  label="ประเภท"
                  name="patent_type"
                  rules={[
                    {
                      required: true
                    }
                  ]}
                >
                  <Select onChange={handleChange}>
                    {xPatent_type.length > 0
                      ? xPatent_type.map((i, idx) => {
                          let xpatent_type = "";

                          if (i.patent_type == "1") {
                            xpatent_type = "อนุสิทธิบัตร";
                          } else if (i.patent_type == "2") {
                            xpatent_type = "สิทธิบัตร";
                          }

                          return (
                            <Option value={i.patent_type}>
                              {xpatent_type}
                            </Option>
                          );
                        })
                      : <Option value="0">
                          {}
                        </Option>}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="ผู้ประดิษฐ์"
                  name="slpatent_researchers"
                  rules={[
                    {
                      required: true
                    }
                  ]}
                >
                  <Select
                    showSearch
                    onChange={handleChange2}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())}
                  >
                    {xResearch.length > 0
                      ? xResearch.map((i, idx) => {
                          let xname = i.pre + " " + i.name + " " + i.lname;

                          if (i.pre == null) {
                            xname = i.name + " " + i.lname;
                          }

                          return (
                            <Option value={i.id}>
                              {xname}
                            </Option>
                          );
                        })
                      : <Option value="0">
                          {}
                        </Option>}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="สถานะผู้ประดิษฐ์"
                  name="patent_role"
                  rules={[
                    {
                      required: true
                    }
                  ]}
                >
                  <Select onChange={handleChange3}>
                    {xPatent_type.length > 0
                      ? xPatent_role.map((i, idx) => {
                          let xpatent_role = "";

                          if (i.patent_role == "1") {
                            xpatent_role = "ผู้ประดิษฐ์หลัก";
                          } else if (i.patent_role == "2") {
                            xpatent_role = "ผู้ประดิษฐ์ร่วม";
                          }

                          return (
                            <Option value={i.patent_role}>
                              {xpatent_role}
                            </Option>
                          );
                        })
                      : <Option value="0">
                          {}
                        </Option>}
                  </Select>
                </Form.Item>

                <ConfigProvider locale={configuredLocale}>
                  <Form.Item label="วันที่ยื่นจด" name="date_input">
                    <DatePicker format="DD/MM/BBBB" />
                  </Form.Item>
                </ConfigProvider>

                <Form.Item
                  label="เลขที่คำขอ"
                  name="patent_request_number"
                  rules={[
                    {
                      required: true,
                      message: "กรุณากรอก เลขที่คำขอ"
                    }
                  ]}
                >
                  <Input allowClear placeholder="เลขที่คำขอ" />
                </Form.Item>

                <Form.Item
                  label="เลขที่สิทธิบัตร/อนุสิทธิบัตร"
                  name="patent_number"
                >
                  <Input
                    allowClear
                    placeholder="เลขที่สิทธิบัตร/อนุสิทธิบัตร"
                  />
                </Form.Item>

                <ConfigProvider locale={configuredLocale}>
                  <Form.Item label="วันที่เผยแพร่" name="date_publish">
                    <DatePicker format="DD/MM/BBBB" />
                  </Form.Item>

                  <Form.Item label="วันที่หมดอายุ" name="date_expire">
                    <DatePicker format="DD/MM/BBBB" />
                  </Form.Item>
                </ConfigProvider>

                <Form.Item
                  label="ระดับการเผยแพร่"
                  name="patent_scope"
                  rules={[
                    {
                      required: true
                    }
                  ]}
                >
                  <Select onChange={handleChange4}>
                    {xPatent_scope.length > 0
                      ? xPatent_scope.map((i, idx) => {
                          let xpatent_scope = "";

                          if (i.patent_scope == "1") {
                            xpatent_scope = "ระดับชาติ";
                          } else if (i.patent_scope == "2") {
                            xpatent_scope = "ระดับนานาชาติ";
                          }

                          return (
                            <Option value={i.patent_scope}>
                              {xpatent_scope}
                            </Option>
                          );
                        })
                      : <Option value="0">
                          {}
                        </Option>}
                  </Select>
                </Form.Item>

                <Form.Item label="ไฟล์เอกสารคำขอ" name="patent_submit_file">
                  <Input
                    className="form-control"
                    id="drop-area2"
                    type="file"
                    onChange={e => onChangeU(e)}
                  />
                </Form.Item>

                <Form.Item
                  label="ไฟล์ Certificate"
                  name="patent_certificate_file"
                >
                  <Input
                    className="form-control"
                    id="drop-area2"
                    type="file"
                    onChange={e => onChangeU2(e)}
                  />
                </Form.Item>
              </Form>
            </Card>

            <br />
            <br />

            <Row justify="center">
              <Space>
                <Button ghost type="primary" onClick={bSaveclick}>
                  <SaveOutlined />บันทึก
                </Button>

                <Button ghost type="danger" onClick={bClearclick}>
                  <ClearOutlined /> เคลียร์ทั้งหมด
                </Button>
              </Space>
            </Row>

            <Divider />
          </Col>
        </Row>
      </Content>

      <Row justify="center" style={{ marginBottom: 10 }}>
        <Col span={23}>
          <Table
            bordered
            scroll={{ x: "max-content" }}
            dataSource={dataSource1}
            columns={columns1}
            size="small"
            pagination={false}
          />
        </Col>
      </Row>

      <div style={{ height: "80px" }} />

      <Footer_last />

      <Modal
        title="แก้ไข ทรัพย์สินทางปัญญา"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1500}
      >
        <Card>
          <Form
            form={form2}
            initialValues={{
              edpatent_name: "",
              patent_type: "เลือกรายการ",
              slpatent_researchers: "เลือกรายการ",
              patent_role: "เลือกรายการ",
              date_input: "",
              patent_request_number: "",
              patent_number: "",
              date_publish: "",
              date_expire: "",
              patent_scope: "เลือกรายการ"
            }}
            {...layout}
          >
            <Form.Item
              label="ชื่อที่แสดง"
              name="edpatent_name"
              rules={[
                {
                  required: true,
                  message: "กรุณากรอก ชื่อที่แสดง"
                }
              ]}
            >
              <Input
                allowClear
                placeholder="ชื่อที่แสดงถึงการประดิษฐ์/การออกแบบผลิตภัณฑ์"
              />
            </Form.Item>

            <Form.Item
              label="ประเภท"
              name="patent_type"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select onChange={handleChange}>
                {xPatent_type.length > 0
                  ? xPatent_type.map((i, idx) => {
                      let xpatent_type = "";

                      if (i.patent_type == "1") {
                        xpatent_type = "อนุสิทธิบัตร";
                      } else if (i.patent_type == "2") {
                        xpatent_type = "สิทธิบัตร";
                      }

                      return (
                        <Option value={i.patent_type}>
                          {xpatent_type}
                        </Option>
                      );
                    })
                  : <Option value="0">
                      {}
                    </Option>}
              </Select>
            </Form.Item>

            <Form.Item
              label="ผู้ประดิษฐ์"
              name="slpatent_researchers"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select
                showSearch
                onChange={handleChange2}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())}
              >
                {xResearch.length > 0
                  ? xResearch.map((i, idx) => {
                      let xname = i.pre + " " + i.name + " " + i.lname;

                      if (i.pre == null) {
                        xname = i.name + " " + i.lname;
                      }

                      return (
                        <Option value={i.id}>
                          {xname}
                        </Option>
                      );
                    })
                  : <Option value="0">
                      {}
                    </Option>}
              </Select>
            </Form.Item>

            <Form.Item
              label="สถานะผู้ประดิษฐ์"
              name="patent_role"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select onChange={handleChange3}>
                {xPatent_type.length > 0
                  ? xPatent_role.map((i, idx) => {
                      let xpatent_role = "";

                      if (i.patent_role == "1") {
                        xpatent_role = "ผู้ประดิษฐ์หลัก";
                      } else if (i.patent_role == "2") {
                        xpatent_role = "ผู้ประดิษฐ์ร่วม";
                      }

                      return (
                        <Option value={i.patent_role}>
                          {xpatent_role}
                        </Option>
                      );
                    })
                  : <Option value="0">
                      {}
                    </Option>}
              </Select>
            </Form.Item>

            <ConfigProvider locale={configuredLocale}>
              <Form.Item label="วันที่ยื่นจด" name="date_input">
                <DatePicker format="DD/MM/BBBB" />
              </Form.Item>
            </ConfigProvider>

            <Form.Item
              label="เลขที่คำขอ"
              name="patent_request_number"
              rules={[
                {
                  required: true,
                  message: "กรุณากรอก เลขที่คำขอ"
                }
              ]}
            >
              <Input allowClear placeholder="เลขที่คำขอ" />
            </Form.Item>

            <Form.Item
              label="เลขที่สิทธิบัตร/อนุสิทธิบัตร"
              name="patent_number"
            >
              <Input allowClear placeholder="เลขที่สิทธิบัตร/อนุสิทธิบัตร" />
            </Form.Item>

            <ConfigProvider locale={configuredLocale}>
              <Form.Item label="วันที่เผยแพร่" name="date_publish">
                <DatePicker format="DD/MM/BBBB" />
              </Form.Item>

              <Form.Item label="วันที่หมดอายุ" name="date_expire">
                <DatePicker format="DD/MM/BBBB" />
              </Form.Item>
            </ConfigProvider>

            <Form.Item
              label="ระดับการเผยแพร่"
              name="patent_scope"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select onChange={handleChange4}>
                {xPatent_scope.length > 0
                  ? xPatent_scope.map((i, idx) => {
                      let xpatent_scope = "";

                      if (i.patent_scope == "1") {
                        xpatent_scope = "ระดับชาติ";
                      } else if (i.patent_scope == "2") {
                        xpatent_scope = "ระดับนานาชาติ";
                      }

                      return (
                        <Option value={i.patent_scope}>
                          {xpatent_scope}
                        </Option>
                      );
                    })
                  : <Option value="0">
                      {}
                    </Option>}
              </Select>
            </Form.Item>

            <Form.Item label="ไฟล์เอกสารคำขอ" name="patent_submit_file">
              <Input
                className="form-control"
                id="drop-area2"
                type="file"
                onChange={e => onChangeU3(e)}
              />
            </Form.Item>

            <Form.Item label="ไฟล์ Certificate" name="patent_certificate_file">
              <Input
                className="form-control"
                id="drop-area2"
                type="file"
                onChange={e => onChangeU4(e)}
              />
            </Form.Item>
          </Form>
        </Card>

        <br />
        <br />

        <Row justify="center">
          <Space>
            <Button ghost type="primary" onClick={bEditClick}>
              <EditOutlined />แก้ไข
            </Button>
          </Space>
        </Row>
      </Modal>
    </React.Fragment>
  );
};

export default Research_input_patent;
