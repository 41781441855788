import "antd/dist/antd.min.css";
import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  SaveOutlined,
  ClearOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import { createGlobalStyle } from "styled-components";

import {
  Row,
  Col,
  Form,
  Layout,
  Card,
  Input,
  Select,
  Space,
  Button,
  PageHeader,
  Divider
} from "antd";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import swal from "sweetalert";

const { Content } = Layout;
const { Option } = Select;

const StyledForm = createGlobalStyle`.ant-form-item {
  margin-bottom: 5px;
}
`;

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 }
};

const Research_input_researcher = () => {
  const [form1] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [xEmp, _xEmp] = useState("");
  const [xPosition, _xPosition] = useState("");
  const [xProject_Position, _xProject_Position] = useState("");
  const [xCenter, _xCenter] = useState("");

  const [xEmpV, _xEmpV] = useState("");
  const [xPositionV, _xPositionV] = useState("");
  const [xProject_PositionV, _xProject_PositionV] = useState("");
  const [xCenterV, _xCenterV] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    //console.log(token);

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");

          //ค้นหา emp
          axios
            .get(xURL + "research_emp")
            .then(result => {
              //console.log(result.data);

              _xEmp(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหา position
          axios
            .get(xURL + "research_position")
            .then(result => {
              //console.log(result.data);

              _xPosition(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหา project_position
          axios
            .get(xURL + "research_project_position")
            .then(result => {
              //console.log(result.data);

              _xProject_Position(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหา center
          axios
            .get(xURL + "center")
            .then(result => {
              //console.log(result.data);

              _xCenter(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const bClearclick = () => {
    form1.resetFields();

    _xEmpV("");
    _xPositionV("");
    _xProject_PositionV("");
    _xCenterV("");
  };

  const onFinish = values => {
    //console.log(values);

    swal({
      title: "ยืนยันเพิ่มนักวิจัย",
      text: ":-)",
      icon: "warning",
      buttons: ["ยกเลิก", "ยืนยัน"]
    }).then(willOk => {
      if (willOk) {
        axios
          .post(xURL + "insert", {
            title_name: values.edtitle_name,
            fname_th: values.edfirst_name_th,
            lname_th: values.edlast_name_th,
            fname_eng: values.edfirst_name_eng,
            lname_eng: values.edlast_name_eng,
            emp: xEmpV,
            position: xPositionV,
            project_position: xProject_PositionV,
            center: xCenterV,
            email: values.edemail,
            phone: values.edphone,
            linetk: values.edline,
            zorder: 10
          })
          .then(result => {
            //console.log(result.data);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        swal("แจ้งเตือน!", "เพิ่มนักวิจัยสำเร็จ!", "success").then(() => {
          window.open("/Research_staff", "_self");
        });
      }
    });
  };

  function handleChange(value) {
    _xEmpV(value);
  }

  function handleChange2(value) {
    _xPositionV(value);
  }

  function handleChange3(value) {
    _xProject_PositionV(value);
  }

  function handleChange4(value) {
    _xCenterV(value);
  }

  return (
    <React.Fragment>
      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <StyledForm />

      <Content
        className="site-layout"
        style={{
          padding: "0 20px",
          marginTop: 64,
          height: "calc(80vh - 16px)"
        }}
      >
        <Row justify="space-around">
          <Col
            xs={23}
            sm={23}
            md={23}
            lg={18}
            xl={16}
            //justify="space-around"
            //style={{ padding: "10px", paddingTop: "24px" }}
          >
            <PageHeader
              className="site-page-header"
              title="เพิ่มนักวิจัยใหม่"
            />

            <Divider />

            <Card title="">
              <Form
                form={form1}
                onFinish={onFinish}
                initialValues={{
                  edtitle_name: "",
                  edfirst_name_th: "",
                  edlast_name_th: "",
                  edfirst_name_eng: "",
                  edlast_name_eng: "",
                  slemp: "",
                  slposition: "",
                  slproject_position: "",
                  slcenter: "",
                  edemail: "",
                  edphone: "",
                  edline: ""
                }}
                {...layout}
              >
                <Form.Item
                  label="ชื่อนำ"
                  name="edtitle_name"
                  rules={[
                    {
                      required: true,
                      message: "กรุณากรอก ชื่อนำ"
                    }
                  ]}
                >
                  <Input
                    autoFocus
                    allowClear
                    placeholder="ชื่อนำ"
                    style={{ width: "20%" }}
                  />
                </Form.Item>

                <Form.Item
                  label="ชื่อ ภาษาไทย"
                  name="edfirst_name_th"
                  rules={[
                    {
                      required: true,
                      message: "กรุณากรอก ชื่อ"
                    },
                    {
                      pattern: new RegExp(/^[ก-๏\s]+$/i),
                      message: "ภาษาไม่ถูกต้อง"
                    }
                  ]}
                >
                  <Input
                    allowClear
                    placeholder="ชื่อ ภาษาไทย"
                    style={{ width: "50%" }}
                  />
                </Form.Item>

                <Form.Item
                  label="นามสกุล ภาษาไทย"
                  name="edlast_name_th"
                  rules={[
                    {
                      required: true,
                      message: "กรุณากรอก นามสกุล"
                    },
                    {
                      pattern: new RegExp(/^[ก-๏\s]+$/i),
                      message: "ภาษาไม่ถูกต้อง"
                    }
                  ]}
                >
                  <Input
                    allowClear
                    placeholder="นามสกุล ภาษาไทย"
                    style={{ width: "50%" }}
                  />
                </Form.Item>

                <Form.Item
                  label="ชื่อ ภาษาอังกฤษ"
                  name="edfirst_name_eng"
                  rules={[
                    {
                      pattern: new RegExp(/^[a-zA-Z\s]+$/i),
                      message: "ภาษาไม่ถูกต้อง"
                    }
                  ]}
                >
                  <Input
                    allowClear
                    placeholder="ชื่อ ภาษาอังกฤษ"
                    style={{ width: "50%" }}
                  />
                </Form.Item>

                <Form.Item
                  label="นามสกุล ภาษาอังกฤษ"
                  name="edlast_name_eng"
                  rules={[
                    {
                      pattern: new RegExp(/^[a-zA-Z\s]+$/i),
                      message: "ภาษาไม่ถูกต้อง"
                    }
                  ]}
                >
                  <Input
                    allowClear
                    placeholder="นามสกุล ภาษาอังกฤษ"
                    style={{ width: "50%" }}
                  />
                </Form.Item>

                <Form.Item label="ระดับ" name="slemp">
                  <Select onChange={handleChange} style={{ width: "50%" }}>
                    {xEmp.length > 0
                      ? xEmp.map((i, idx) => {
                          return (
                            <Option value={i.id}>
                              {i.name}
                            </Option>
                          );
                        })
                      : <Option value="">
                          {}
                        </Option>}
                  </Select>
                </Form.Item>

                <Form.Item label="ตำแหน่ง" name="slposition">
                  <Select onChange={handleChange2} style={{ width: "50%" }}>
                    {xPosition.length > 0
                      ? xPosition.map((i, idx) => {
                          return (
                            <Option value={i.id}>
                              {i.name}
                            </Option>
                          );
                        })
                      : <Option value="">
                          {}
                        </Option>}
                  </Select>
                </Form.Item>

                <Form.Item label="ตำแหน่งนักวิจัย" name="slproject_position">
                  <Select onChange={handleChange3} style={{ width: "50%" }}>
                    {xProject_Position.length > 0
                      ? xProject_Position.map((i, idx) => {
                          return (
                            <Option value={i.id}>
                              {i.name}
                            </Option>
                          );
                        })
                      : <Option value="">
                          {}
                        </Option>}
                  </Select>
                </Form.Item>

                <Form.Item label="หน่วยงาน" name="slcenter">
                  <Select onChange={handleChange4} style={{ width: "50%" }}>
                    {xCenter.length > 0
                      ? xCenter.map((i, idx) => {
                          return (
                            <Option value={i.id}>
                              {i.name}
                            </Option>
                          );
                        })
                      : <Option value="">
                          {}
                        </Option>}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="E-mail"
                  name="edemail"
                  rules={[
                    {
                      type: "email",
                      message: "กรอก E-mail ไม่ถูกต้อง!"
                    }
                  ]}
                >
                  <Input
                    allowClear
                    placeholder="E-mail"
                    style={{ width: "50%" }}
                  />
                </Form.Item>

                <Form.Item label="เบอร์ติดต่อ" name="edphone">
                  <Input
                    allowClear
                    placeholder="เบอร์ติดต่อ"
                    style={{ width: "50%" }}
                  />
                </Form.Item>

                <Form.Item label="Line-Token" name="edline">
                  <Input
                    allowClear
                    placeholder="Line-Token"
                    style={{ width: "50%" }}
                  />
                </Form.Item>

                <br />
                <br />

                <Row justify="center">
                  <Space>
                    <Button ghost type="primary" htmlType="submit">
                      <SaveOutlined />บันทึก
                    </Button>

                    <Button ghost type="danger" onClick={bClearclick}>
                      <ClearOutlined /> เคลียร์ทั้งหมด
                    </Button>
                  </Space>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
        <br />
        <br />
      </Content>

      <Footer_last />
    </React.Fragment>
  );
};
export default Research_input_researcher;
