import "antd/dist/antd.min.css";
import "antd-button-color/dist/css/style.css";

import axios from "axios";
import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Layout,
  Table,
  Card,
  Divider,
  Tabs,
  Input,
  Space,
  BackTop,
  PageHeader,
  Spin,
  Select,
  Button,
  Form,
  Modal,
  InputNumber
} from "antd";

import moment from "moment";

import { SaveOutlined, ClearOutlined } from "@ant-design/icons";

import { createGlobalStyle } from "styled-components";

import Hightchart from "../controls/Highchart";
import Piechart from "../controls/Piechart";
import Verticalchart from "../controls/Verticalchart";

import swal from "sweetalert";

import "./dashboard.css";

const { Content } = Layout;
const { Option } = Select;

const StyledForm = createGlobalStyle`.ant-form-item {
    margin-bottom: 5px;
  }
  `;

const Research_dashboard = () => {
  const [form1] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  let YearNow = moment().format("YYYY");

  const [xYearpapers, _xYearpapers] = useState([]);
  const [YearValue, _YearValue] = useState(YearNow);

  const [Staff_num, _Staff_num] = useState("");
  const [H_index, _H_index] = useState("");

  const [Num_Color, _Num_Color] = useState("red");
  const [Num_Color2, _Num_Color2] = useState("red");
  const [Num_Color3, _Num_Color3] = useState("red");
  const [Num_Color4, _Num_Color4] = useState("red");
  const [Num_Color5, _Num_Color5] = useState("red");
  const [Num_Color6, _Num_Color6] = useState("red");
  const [Num_Color7, _Num_Color7] = useState("red");
  const [Num_Color8, _Num_Color8] = useState("red");

  const [modalOpen, setModalOpen] = useState(false);

  const [emplydata, _emplydata] = useState(0);

  const [dataPub_scopus, _dataPub_scopus] = useState();
  const [goalPub_scopus, _goalPub_scopus] = useState();
  const [dataPub_q1, _dataPub_q1] = useState();
  const [goalPub_q1, _goalPub_q1] = useState();
  const [dataPub_pFac, _dataPub_pFac] = useState();
  const [goalPub_pFac, _goalPub_pFac] = useState();
  const [dataPub_pFC, _dataPub_pFC] = useState();
  const [goalPub_pFC, _goalPub_pFC] = useState();
  const [dataPub_if, _dataPub_if] = useState();
  const [goalPub_if, _goalPub_if] = useState();
  const [dataPub_thf, _dataPub_thf] = useState();
  const [goalPub_thf, _goalPub_thf] = useState();

  const [dataPub_ttf, _dataPub_ttf] = useState();
  const [goalPub_ttf, _goalPub_ttf] = useState();
  const [dataPub_ic, _dataPub_ic] = useState();
  const [goalPub_ic, _goalPub_ic] = useState();

  const [dataHigh1, _dataHigh1] = useState([]);
  const [dataHigh2, _dataHigh2] = useState([]);
  const [dataHigh3, _dataHigh3] = useState([]);
  const [dataHigh4, _dataHigh4] = useState([]);
  const [dataHigh5, _dataHigh5] = useState([]);

  const [dataPie1, _dataPie1] = useState([]);
  const [dataPie2, _dataPie2] = useState([]);
  const [dataPie3, _dataPie3] = useState([]);

  useEffect(() => {
    axios
      .get(xURL + "year_papers")
      .then(result => {
        //console.log(result.data);

        _xYearpapers(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    Find_staff(YearValue);
    Find_h_index(YearValue);

    Find_scopus(1, YearValue);
    Find_scopus(2, YearValue);
    Find_scopus(3, YearValue);
    Find_scopus(4, YearValue);
    Find_scopus(5, YearValue);
    Find_scopus(6, YearValue);
    Find_scopus(7, YearValue);
    Find_scopus(8, YearValue);

    Find_highData(1, YearValue);
    Find_highData(2, YearValue);
    Find_highData(3, YearValue);
    Find_highData(4, YearValue);
    Find_highData(5, YearValue);

    Find_PieData(1, YearValue);
    Find_PieData(2, YearValue);
    Find_PieData(3, YearValue);
  }, []);

  const bClearclick = () => {
    form1.resetFields();

    findGoal();
  };

  const bSaveclick = () => {
    const f = form1.getFieldsValue();

    if (!f.ipscopus) {
      swal("Save Error!", "Data entry fields Pub in SCOPUS (Goal)!", "warning");
    } else if (!f.ipq1) {
      swal("Save Error!", "Data entry fields Pub in Q1 (Goal)!", "warning");
    } else if (!f.ipppf) {
      swal(
        "Save Error!",
        "Data entry fields Pub per faculty (Goal)!",
        "warning"
      );
    } else if (!f.ipif) {
      swal(
        "Save Error!",
        "Data entry fields First/Corresponding (Goal)!",
        "warning"
      );
    } else if (!f.ipttf) {
      swal("Save Error!", "Data entry fields Total funding (Goal)!", "warning");
    } else if (!f.ipif) {
      swal("Save Error!", "Data entry fields Int. funding (Goal)!", "warning");
    } else if (!f.ipthf) {
      swal("Save Error!", "Data entry fields Thai. funding (Goal)!", "warning");
    } else if (!f.ipic) {
      swal("Save Error!", "Data entry fields Income (Goal)!", "warning");
    } else {
      swal({
        title: "Confirm Edit Goal",
        text: "Year " + YearValue,
        icon: "warning",
        buttons: ["Cancle", "Ok"]
      }).then(willOk => {
        if (willOk) {
          updatedata();
        }
      });
    }
  };

  const updatedata = () => {
    const f = form1.getFieldsValue();

    let xdata = [
      { name: "Pub in SCOPUS", goal: f.ipscopus },
      { name: "Pub in Q1", goal: f.ipq1 },
      { name: "Pub per faculty", goal: f.ipppf },
      { name: "First / Corresponding", goal: f.ipfc },
      { name: "Total funding", goal: f.ipttf },
      { name: "Int. funding", goal: f.ipif },
      { name: "Thai. funding", goal: f.ipthf },
      { name: "Income", goal: f.ipic }
    ];
    // console.log(emplydata);

    if (emplydata == 1) {
      //insert new
      // console.log("insert");
      xdata.forEach((i, idx) => {
        axios
          .post(xURL + "insert_goal", {
            year: YearValue,
            name: i.name,
            goal: i.goal
          })
          .then(result => {
            swal("Save Complete!", "", "success");
            setModalOpen(false);
            Find_scopus(1, YearValue);
            Find_scopus(2, YearValue);
            Find_scopus(3, YearValue);
            Find_scopus(4, YearValue);
            Find_scopus(5, YearValue);
            Find_scopus(6, YearValue);
            Find_scopus(7, YearValue);
            Find_scopus(8, YearValue);

            Find_highData(1, YearValue);
            Find_highData(2, YearValue);
            Find_highData(3, YearValue);
            Find_highData(4, YearValue);
            Find_highData(5, YearValue);

            Find_PieData(1, YearValue);
            Find_PieData(2, YearValue);
            Find_PieData(3, YearValue);

            // console.log(result.data);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      });
    } else if (emplydata == 0) {
      //update
      // console.log("update");
      xdata.forEach((i, idx) => {
        axios
          .post(xURL + "update_goal", {
            year: YearValue,
            name: i.name,
            goal: i.goal
          })
          .then(result => {
            swal("Save Complete!", "", "success");
            setModalOpen(false);
            Find_scopus(1, YearValue);
            Find_scopus(2, YearValue);
            Find_scopus(3, YearValue);
            Find_scopus(4, YearValue);
            Find_scopus(5, YearValue);
            Find_scopus(6, YearValue);
            Find_scopus(7, YearValue);
            Find_scopus(8, YearValue);

            Find_highData(1, YearValue);
            Find_highData(2, YearValue);
            Find_highData(3, YearValue);
            Find_highData(4, YearValue);
            Find_highData(5, YearValue);

            Find_PieData(1, YearValue);
            Find_PieData(2, YearValue);
            Find_PieData(3, YearValue);

            // console.log(result.data);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      });
    }
  };

  const Find_staff = YearValue => {
    axios
      .post(xURL + "db_staff", { year: YearValue })
      .then(result => {
        //console.log(result.data);

        _Staff_num(result.data[0].staff_num);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Find_h_index = YearValue => {
    axios
      .post(xURL + "db_h_index", { year: YearValue })
      .then(result => {
        let xsum = 0,
          xidx = 0,
          xsum2 = 0;

        result.data.forEach((i, idx) => {
          xsum = xsum + i.h_index;
          xidx = xidx + 1;
        });

        xsum2 = xsum / xidx;

        _H_index(xsum2.toFixed(1));
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const findGoal = () => {
    axios
      .post(xURL + "db_findGoal", { year: YearValue })
      .then(result => {
        // console.log("data", result.data);

        if (result.data.length == 0) {
          _emplydata(1);
        } else {
          _emplydata(0);
        }

        result.data.forEach((i, idx) => {
          if (i.topic == "Pub in SCOPUS") {
            form1.setFieldsValue({
              ipscopus: i.goal
            });
          } else if (i.topic == "Pub in Q1") {
            form1.setFieldsValue({
              ipq1: i.goal
            });
          } else if (i.topic == "Pub per faculty") {
            form1.setFieldsValue({
              ipppf: i.goal
            });
          } else if (i.topic == "First / Corresponding") {
            form1.setFieldsValue({
              ipfc: i.goal
            });
          } else if (i.topic == "Total funding") {
            form1.setFieldsValue({
              ipttf: i.goal
            });
          } else if (i.topic == "Int. funding") {
            form1.setFieldsValue({
              ipif: i.goal
            });
          } else if (i.topic == "Thai. funding") {
            form1.setFieldsValue({
              ipthf: i.goal
            });
          } else if (i.topic == "Income") {
            form1.setFieldsValue({
              ipic: i.goal
            });
          }
        });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Find_scopus = (order, YearValue) => {
    axios
      .post(xURL + "db_scopus", {
        order: order,
        year: YearValue,
        year2: parseInt(YearValue) + 543
      })
      .then(result => {
        if (result.data[0]) {
          if (order == 1) {
            _dataPub_scopus(result.data[0].pub_data);
            _goalPub_scopus(result.data[0].goal);

            if (
              parseFloat(result.data[0].pub_data) >
              parseFloat(result.data[0].goal) / 2
            ) {
              _Num_Color("green");
            } else {
              _Num_Color("red");
            }
          } else if (order == 2) {
            _dataPub_q1(result.data[0].pub_data);
            _goalPub_q1(result.data[0].goal);

            if (
              parseFloat(result.data[0].pub_data) >
              parseFloat(result.data[0].goal) / 2
            ) {
              _Num_Color2("green");
            } else {
              _Num_Color2("red");
            }
          } else if (order == 3) {
            _dataPub_pFac(result.data[0].pub_data);
            _goalPub_pFac(result.data[0].goal);

            if (
              parseFloat(result.data[0].pub_data) >
              parseFloat(result.data[0].goal) / 2
            ) {
              _Num_Color3("green");
            } else {
              _Num_Color3("red");
            }
          } else if (order == 4) {
            _dataPub_pFC(result.data[0].pub_data);
            _goalPub_pFC(result.data[0].goal);

            if (
              parseFloat(result.data[0].pub_data) >
              parseFloat(result.data[0].goal) / 2
            ) {
              _Num_Color4("green");
            } else {
              _Num_Color4("red");
            }
          } else if (order == 5) {
            _dataPub_ttf(result.data[0].pub_data);
            _goalPub_ttf(result.data[0].goal);

            if (
              parseFloat(result.data[0].pub_data) >
              parseFloat(result.data[0].goal) / 2
            ) {
              _Num_Color5("green");
            } else {
              _Num_Color5("red");
            }
          } else if (order == 6) {
            _dataPub_if(result.data[0].pub_data);
            _goalPub_if(result.data[0].goal);

            if (
              parseFloat(result.data[0].pub_data) >
              parseFloat(result.data[0].goal) / 2
            ) {
              _Num_Color6("green");
            } else {
              _Num_Color6("red");
            }
          } else if (order == 7) {
            _dataPub_thf(result.data[0].pub_data);
            _goalPub_thf(result.data[0].goal);

            if (
              parseFloat(result.data[0].pub_data) >
              parseFloat(result.data[0].goal) / 2
            ) {
              _Num_Color7("green");
            } else {
              _Num_Color7("red");
            }
          } else if (order == 8) {
            _dataPub_ic(result.data[0].pub_data);
            _goalPub_ic(result.data[0].goal);

            if (
              parseFloat(result.data[0].pub_data) >
              parseFloat(result.data[0].goal) / 2
            ) {
              _Num_Color8("green");
            } else {
              _Num_Color8("red");
            }
          }
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Find_highData = (order, YearValue) => {
    axios
      .post(xURL + "db_Highdata", {
        order: order,
        year: YearValue,
        year2: parseInt(YearValue) + 543
      })
      .then(result => {
        let Tb1 = [];

        let xsum = 0;

        let xgoal12 = 0,
          xgoal11 = 0,
          xgoal10 = 0,
          xgoal9 = 0,
          xgoal8 = 0,
          xgoal7 = 0,
          xgoal6 = 0,
          xgoal5 = 0,
          xgoal4 = 0,
          xgoal3 = 0,
          xgoal2 = 0,
          xgoal1 = 0;

        xgoal12 = parseFloat(result.data[0].goal).toFixed(1);
        xgoal11 = parseFloat(xgoal12 - xgoal12 / 12).toFixed(1);
        xgoal10 = parseFloat(xgoal11 - xgoal12 / 12).toFixed(1);
        xgoal9 = parseFloat(xgoal10 - xgoal12 / 12).toFixed(1);
        xgoal8 = parseFloat(xgoal9 - xgoal12 / 12).toFixed(1);
        xgoal7 = parseFloat(xgoal8 - xgoal12 / 12).toFixed(1);
        xgoal6 = parseFloat(xgoal7 - xgoal12 / 12).toFixed(1);
        xgoal5 = parseFloat(xgoal6 - xgoal12 / 12).toFixed(1);
        xgoal4 = parseFloat(xgoal5 - xgoal12 / 12).toFixed(1);
        xgoal3 = parseFloat(xgoal4 - xgoal12 / 12).toFixed(1);
        xgoal2 = parseFloat(xgoal3 - xgoal12 / 12).toFixed(1);
        xgoal1 = parseFloat(xgoal2 - xgoal12 / 12).toFixed(1);

        if (result.data[0]) {
          xsum = result.data[0].m1;
          Tb1.push({
            name: "1",
            data_in_Month: result.data[0].m1,
            data: xsum,
            goal: xgoal1
          });

          xsum = xsum + result.data[0].m2;
          Tb1.push({
            name: "2",
            data_in_Month: result.data[0].m2,
            data: xsum,
            goal: xgoal2
          });

          xsum = xsum + result.data[0].m3;
          Tb1.push({
            name: "3",
            data_in_Month: result.data[0].m3,
            data: xsum,
            goal: xgoal3
          });

          xsum = xsum + result.data[0].m4;
          Tb1.push({
            name: "4",
            data_in_Month: result.data[0].m4,
            data: xsum,
            goal: xgoal4
          });

          xsum = xsum + result.data[0].m5;
          Tb1.push({
            name: "5",
            data_in_Month: result.data[0].m5,
            data: xsum,
            goal: xgoal5
          });

          xsum = xsum + result.data[0].m6;
          Tb1.push({
            name: "6",
            data_in_Month: result.data[0].m6,
            data: xsum,
            goal: xgoal6
          });

          xsum = xsum + result.data[0].m7;
          Tb1.push({
            name: "7",
            data_in_Month: result.data[0].m7,
            data: xsum,
            goal: xgoal7
          });

          xsum = xsum + result.data[0].m8;
          Tb1.push({
            name: "8",
            data_in_Month: result.data[0].m8,
            data: xsum,
            goal: xgoal8
          });

          xsum = xsum + result.data[0].m9;
          Tb1.push({
            name: "9",
            data_in_Month: result.data[0].m9,
            data: xsum,
            goal: xgoal9
          });

          xsum = xsum + result.data[0].m10;
          Tb1.push({
            name: "10",
            data_in_Month: result.data[0].m10,
            data: xsum,
            goal: xgoal10
          });

          xsum = xsum + result.data[0].m11;
          Tb1.push({
            name: "11",
            data_in_Month: result.data[0].m11,
            data: xsum,
            goal: xgoal11
          });

          xsum = xsum + result.data[0].m12;
          Tb1.push({
            name: "12",
            data_in_Month: result.data[0].m12,
            data: xsum,
            goal: result.data[0].goal
          });
        }

        if (order == 1) {
          _dataHigh1(Tb1);
        } else if (order == 2) {
          _dataHigh2(Tb1);
        } else if (order == 3) {
          _dataHigh3(Tb1);
        } else if (order == 4) {
          _dataHigh4(Tb1);
        } else if (order == 5) {
          _dataHigh5(Tb1);
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Find_PieData = (order, YearValue) => {
    axios
      .post(xURL + "db_Piedata", {
        order: order,
        year: YearValue,
        year2: parseInt(YearValue) + 543
      })
      .then(result => {
        let Tb1 = [];
        if (result.data[0]) {
          if (order == 1) {
            Tb1.push({
              name: "Q1",
              value: result.data[0].q1
            });
            Tb1.push({
              name: "Q2",
              value: result.data[0].q2
            });
            Tb1.push({
              name: "Q3",
              value: result.data[0].q3
            });
            Tb1.push({
              name: "Q4",
              value: result.data[0].q4
            });
            Tb1.push({
              name: "N/A",
              value: result.data[0].na
            });

            _dataPie1(Tb1);
          } else if (order == 2) {
            Tb1.push({
              name: "Int. fund",
              value: result.data[0].int_fund
            });
            Tb1.push({
              name: "CMU fund",
              value: result.data[0].cmu_fund
            });
            Tb1.push({
              name: "Thai fund",
              value: result.data[0].thai_fund
            });

            _dataPie2(Tb1);
          } else if (order == 3) {
            Tb1.push({
              name: "IDSU",
              value: result.data[0].idsu
            });
            Tb1.push({
              name: "NINE",
              value: result.data[0].nine
            });
            Tb1.push({
              name: "CMBID",
              value: result.data[0].cmbid
            });
            Tb1.push({
              name: "RSO",
              value: result.data[0].rso
            });
            Tb1.push({
              name: "SHSR",
              value: result.data[0].shsr
            });

            _dataPie3(Tb1);
          }
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const dataVertical = [
    {
      name: "CMU fund",
      uv: 6.1,
      pv: 0
    },
    {
      name: "Int. fund",
      uv: 72.6,
      pv: 26.1
    },
    {
      name: "Thai fund",
      uv: 22.9,
      pv: 0.8
    }
  ];

  const handleChange = value => {
    // console.log(`selected ${value}`);
    Find_staff(value);
    Find_h_index(value);

    Find_scopus(1, value);
    Find_scopus(2, value);
    Find_scopus(3, value);
    Find_scopus(4, value);
    Find_scopus(5, value);
    Find_scopus(6, value);
    Find_scopus(7, value);
    Find_scopus(8, value);

    Find_highData(1, value);
    Find_highData(2, value);
    Find_highData(3, value);
    Find_highData(4, value);
    Find_highData(5, value);

    Find_PieData(1, value);
    Find_PieData(2, value);
    Find_PieData(3, value);

    _YearValue(value);
  };

  return (
    <React.Fragment>
      <StyledForm />

      <Content className="site-layout" style={{ padding: "0 0px" }}>
        <Row justify="center" style={{ background: "#F5EFE6" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={23}>
            <Row>
              <h2
                style={{
                  marginTop: "40px",
                  fontSize: "25px",
                  fontWeight: "bold"
                }}
              >
                Dashboard
              </h2>

              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "-10px",
                  width: "90px",
                  height: "90px",
                  borderRadius: "50%",
                  background: "#9AC8CD",
                  marginLeft: "40px"
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "38px"
                  }}
                >
                  {Staff_num}
                </p>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "10px",
                    marginTop: "-45px"
                  }}
                >
                  Staff number
                </p>
              </div>

              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "-10px",
                  marginLeft: "15px",
                  width: "90px",
                  height: "90px",
                  borderRadius: "50%",
                  background: "#9AC8CD"
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "38px"
                  }}
                >
                  {H_index}
                </p>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "10px",
                    marginTop: "-45px"
                  }}
                >
                  H-index
                </p>
              </div>
            </Row>
            <Row>
              <div style={{ marginTop: "40px", marginLeft: "25px" }}>
                <Form.Item label="Year" name="slyear">
                  <Select
                    defaultValue={YearNow}
                    style={{
                      width: 250
                    }}
                    onChange={handleChange}
                  >
                    {xYearpapers.map((i, idx) => {
                      return (
                        <Option value={i.year}>
                          {i.year} ({i.name})
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>

              <div style={{ marginTop: "40px", marginLeft: "25px" }}>
                <Button
                  type="primary"
                  onClick={() => {
                    form1.resetFields();

                    findGoal();

                    setModalOpen(true);
                  }}
                >
                  Edit Goal
                </Button>
              </div>
            </Row>
            <Divider />

            <div>
              <Row gutter={3}>
                <Col xs={23} sm={12} md={8} lg={6} xl={3}>
                  <Card style={{ height: 140 }}>
                    <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
                      <Col span={18}>
                        <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                          Pub in SCOPUS
                        </p>
                        <hr
                          style={{ marginRight: "10px", marginTop: "-5px" }}
                        />
                        <Row>
                          <Col span={18}>
                            <p
                              style={{
                                fontWeight: "bold",
                                marginRight: "20px",
                                marginTop: "12px",
                                fontSize: "40px",
                                color: Num_Color
                              }}
                            >
                              {dataPub_scopus}
                            </p>
                          </Col>
                          <Col span={3}>
                            <p
                              style={{
                                fontWeight: "bold",
                                // marginRight: "20px",
                                marginLeft: "7px",
                                marginTop: "40px",
                                fontSize: "16px"
                              }}
                            >
                              %
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        span={6}
                        style={{ background: "#1679AB", height: "120px" }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            color: "#fff",
                            marginTop: "25px"
                          }}
                        >
                          Goal
                        </p>

                        <p
                          style={{
                            textAlign: "center",
                            color: "#fff",
                            fontSize: "14px",
                            fontWeight: "bold"
                          }}
                        >
                          {goalPub_scopus}
                        </p>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xs={23} sm={12} md={8} lg={6} xl={3}>
                  <Card style={{ height: 140 }}>
                    <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
                      <Col span={18}>
                        <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                          %Pub in Q1
                        </p>
                        <hr
                          style={{ marginRight: "10px", marginTop: "-5px" }}
                        />
                        <Row>
                          <Col span={18}>
                            <p
                              style={{
                                fontWeight: "bold",
                                marginRight: "20px",
                                marginTop: "12px",
                                fontSize: "40px",
                                color: Num_Color2
                              }}
                            >
                              {dataPub_q1}
                            </p>
                          </Col>
                          <Col span={3}>
                            <p
                              style={{
                                fontWeight: "bold",
                                // marginRight: "20px",
                                marginLeft: "7px",
                                marginTop: "40px",
                                fontSize: "16px"
                              }}
                            >
                              %
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        span={6}
                        style={{ background: "#1679AB", height: "120px" }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            color: "#fff",
                            marginTop: "25px"
                          }}
                        >
                          Goal
                        </p>

                        <p
                          style={{
                            textAlign: "center",
                            color: "#fff",
                            fontSize: "14px",
                            fontWeight: "bold"
                          }}
                        >
                          {goalPub_q1} %
                        </p>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xs={23} sm={12} md={8} lg={6} xl={3}>
                  <Card style={{ height: 140 }}>
                    <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
                      <Col span={18}>
                        <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                          %Pub per faculty
                        </p>
                        <hr
                          style={{ marginRight: "10px", marginTop: "-5px" }}
                        />

                        <Row>
                          <p
                            style={{
                              fontWeight: "bold",
                              marginRight: "20px",
                              marginTop: "12px",
                              fontSize: "40px",
                              color: Num_Color3
                            }}
                          >
                            {dataPub_pFac}
                          </p>
                        </Row>
                      </Col>
                      <Col
                        span={6}
                        style={{ background: "#1679AB", height: "120px" }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            color: "#fff",
                            marginTop: "25px"
                          }}
                        >
                          Goal
                        </p>

                        <p
                          style={{
                            textAlign: "center",
                            color: "#fff",
                            fontSize: "14px",
                            fontWeight: "bold"
                          }}
                        >
                          {goalPub_pFac}
                        </p>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xs={23} sm={12} md={8} lg={6} xl={3}>
                  <Card style={{ height: 140 }}>
                    <Row
                      style={{
                        marginRight: "-15px",
                        marginLeft: "-10px",
                        marginTop: "-15px"
                      }}
                    >
                      <Col span={18}>
                        <p style={{ fontWeight: "bold", fontSize: "11px" }}>
                          First / Corresponding
                        </p>
                        <hr
                          style={{ marginRight: "10px", marginTop: "-5px" }}
                        />
                        <Row>
                          <Col span={18}>
                            <p
                              style={{
                                fontWeight: "bold",
                                marginRight: "20px",
                                marginTop: "12px",
                                fontSize: "40px",
                                color: Num_Color4
                              }}
                            >
                              {dataPub_pFC}
                            </p>
                          </Col>
                          <Col span={3}>
                            <p
                              style={{
                                fontWeight: "bold",
                                // marginRight: "20px",
                                marginLeft: "7px",
                                marginTop: "40px",
                                fontSize: "16px"
                              }}
                            >
                              %
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        span={6}
                        style={{ background: "#1679AB", height: "120px" }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            color: "#fff",
                            marginTop: "25px"
                          }}
                        >
                          Goal
                        </p>

                        <p
                          style={{
                            textAlign: "center",
                            color: "#fff",
                            fontSize: "14px",
                            fontWeight: "bold"
                          }}
                        >
                          {goalPub_pFC} %
                        </p>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xs={23} sm={12} md={8} lg={6} xl={3}>
                  <Card style={{ height: 140 }}>
                    <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
                      <Col span={18}>
                        <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                          Total funding
                        </p>
                        <hr
                          style={{ marginRight: "10px", marginTop: "-5px" }}
                        />
                        <Row>
                          <Col span={18}>
                            <p
                              style={{
                                fontWeight: "bold",
                                marginRight: "20px",
                                marginTop: "12px",
                                fontSize: "40px",
                                color: Num_Color5
                              }}
                            >
                              {dataPub_ttf}
                            </p>
                          </Col>
                          <Col span={3}>
                            <p
                              style={{
                                fontWeight: "bold",
                                // marginRight: "20px",
                                marginLeft: "7px",
                                marginTop: "40px",
                                fontSize: "16px"
                              }}
                            >
                              %
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        span={6}
                        style={{ background: "#1679AB", height: "120px" }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            color: "#fff",
                            marginTop: "25px"
                          }}
                        >
                          Goal
                        </p>

                        <p
                          style={{
                            textAlign: "center",
                            color: "#fff",
                            fontSize: "14px",
                            fontWeight: "bold"
                          }}
                        >
                          {goalPub_ttf}
                        </p>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xs={23} sm={12} md={8} lg={6} xl={3}>
                  <Card style={{ height: 140 }}>
                    <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
                      <Col span={18}>
                        <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                          Int. funding
                        </p>
                        <hr
                          style={{ marginRight: "10px", marginTop: "-5px" }}
                        />
                        <Row>
                          <Col span={18}>
                            <p
                              style={{
                                fontWeight: "bold",
                                marginRight: "20px",
                                marginTop: "12px",
                                fontSize: "40px",
                                color: Num_Color6
                              }}
                            >
                              {dataPub_if}
                            </p>
                          </Col>
                          <Col span={3}>
                            <p
                              style={{
                                fontWeight: "bold",
                                // marginRight: "20px",
                                marginLeft: "7px",
                                marginTop: "40px",
                                fontSize: "16px"
                              }}
                            >
                              %
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        span={6}
                        style={{ background: "#1679AB", height: "120px" }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            color: "#fff",
                            marginTop: "25px"
                          }}
                        >
                          Goal
                        </p>

                        <p
                          style={{
                            textAlign: "center",
                            color: "#fff",
                            fontSize: "14px",
                            fontWeight: "bold"
                          }}
                        >
                          {goalPub_if}
                        </p>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xs={23} sm={12} md={8} lg={6} xl={3}>
                  <Card style={{ height: 140 }}>
                    <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
                      <Col span={18}>
                        <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                          Thai. funding
                        </p>
                        <hr
                          style={{ marginRight: "10px", marginTop: "-5px" }}
                        />
                        <Row>
                          <Col span={18}>
                            <p
                              style={{
                                fontWeight: "bold",
                                marginRight: "20px",
                                marginTop: "12px",
                                fontSize: "40px",
                                color: Num_Color7
                              }}
                            >
                              {dataPub_thf}
                            </p>
                          </Col>
                          <Col span={3}>
                            <p
                              style={{
                                fontWeight: "bold",
                                // marginRight: "20px",
                                marginLeft: "7px",
                                marginTop: "40px",
                                fontSize: "16px"
                              }}
                            >
                              %
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        span={6}
                        style={{ background: "#1679AB", height: "120px" }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            color: "#fff",
                            marginTop: "25px"
                          }}
                        >
                          Goal
                        </p>

                        <p
                          style={{
                            textAlign: "center",
                            color: "#fff",
                            fontSize: "14px",
                            fontWeight: "bold"
                          }}
                        >
                          {goalPub_thf}
                        </p>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xs={23} sm={12} md={8} lg={6} xl={3}>
                  <Card style={{ height: 140 }}>
                    <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
                      <Col span={18}>
                        <p
                          style={{ fontWeight: "bold", fontSize: "12px" }}
                          onClick={() => {
                            window.open("/Research_dashboard_income", "_blank");
                          }}
                        >
                          Income
                        </p>
                        <hr
                          style={{ marginRight: "10px", marginTop: "-5px" }}
                        />
                        <Row>
                          <Col span={18}>
                            <p
                              style={{
                                fontWeight: "bold",
                                marginRight: "20px",
                                marginTop: "12px",
                                fontSize: "40px",
                                color: Num_Color8
                              }}
                            >
                              {dataPub_ic}
                            </p>
                          </Col>
                          <Col span={3}>
                            <p
                              style={{
                                fontWeight: "bold",
                                // marginRight: "20px",
                                marginLeft: "7px",
                                marginTop: "40px",
                                fontSize: "16px"
                              }}
                            >
                              %
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        span={6}
                        style={{ background: "#1679AB", height: "120px" }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            color: "#fff",
                            marginTop: "25px"
                          }}
                        >
                          Goal
                        </p>

                        <p
                          style={{
                            textAlign: "center",
                            color: "#fff",
                            fontSize: "14px",
                            fontWeight: "bold"
                          }}
                        >
                          {goalPub_ic}
                        </p>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </div>
            {/* <br /> */}
            <div style={{ height: "10px" }} />

            <div>
              <Row gutter={16}>
                <Col xs={23} sm={12} md={8} lg={8} xl={5}>
                  <Card style={{ height: 280 }}>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        fontWeight: "bold"
                      }}
                    >
                      Pub in SCOPUS
                    </p>
                    <Hightchart dataHigh={dataHigh1} order={0} />
                  </Card>
                </Col>
                <Col xs={23} sm={12} md={8} lg={8} xl={5}>
                  <Card style={{ height: 280 }}>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        fontWeight: "bold"
                      }}
                    >
                      Total funding (MB)
                    </p>
                    <Hightchart dataHigh={dataHigh2} order={1} />
                  </Card>
                </Col>
                <Col xs={23} sm={12} md={8} lg={8} xl={5}>
                  <Card style={{ height: 280 }}>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        fontWeight: "bold"
                      }}
                    >
                      Int. funding (MB)
                    </p>
                    <Hightchart dataHigh={dataHigh3} order={1} />
                  </Card>
                </Col>
                <Col xs={23} sm={12} md={8} lg={8} xl={5}>
                  <Card style={{ height: 280 }}>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        fontWeight: "bold"
                      }}
                    >
                      Thai funding (MB)
                    </p>
                    <Hightchart dataHigh={dataHigh4} order={1} />
                  </Card>
                </Col>
                <Col xs={23} sm={12} md={8} lg={8} xl={4}>
                  <Card style={{ height: 280 }}>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        fontWeight: "bold"
                      }}
                    >
                      Income (MB)
                    </p>
                    <Hightchart dataHigh={dataHigh5} order={1} />
                  </Card>
                </Col>
              </Row>
            </div>

            {/* <br /> */}
            <div style={{ height: "10px" }} />

            <div>
              <Row gutter={16}>
                <Col xs={23} sm={12} md={8} lg={8} xl={8}>
                  <Card style={{ height: 320 }}>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        fontWeight: "bold"
                      }}
                    >
                      %Quarties
                    </p>
                    <Piechart dataPie={dataPie1} />
                  </Card>
                </Col>
                <Col xs={23} sm={12} md={8} lg={8} xl={8}>
                  <Card style={{ height: 320 }}>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        fontWeight: "bold"
                      }}
                    >
                      %Funding
                    </p>
                    <Piechart dataPie={dataPie2} />
                  </Card>
                </Col>
                {/* <Col xs={23} sm={12} md={8} lg={8} xl={6}>
                  <Card style={{ height: 320 }}>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        fontWeight: "bold"
                      }}
                    >
                      Grant type (MB)
                    </p>
                    <Verticalchart dataVertical={dataVertical} />
                  </Card>
                </Col> */}
                <Col xs={23} sm={12} md={8} lg={8} xl={8}>
                  <Card style={{ height: 320 }}>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        fontWeight: "bold"
                      }}
                    >
                      %Income
                    </p>
                    <Piechart dataPie={dataPie3} />
                  </Card>
                </Col>
              </Row>
            </div>
            <br />
            <br />
          </Col>
        </Row>
      </Content>

      <Modal
        title="Edit Goal"
        centered
        visible={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        footer={null}
        width={1000}
      >
        <p style={{ fontWeight: "bold", fontSize: "18px" }}>
          Year {YearValue}
        </p>
        <hr className="style-two" style={{ marginBottom: "20px" }} />

        <Form form={form1} initialValues={{}}>
          <Row>
            <Col span={4}>
              <p className="t-goal">Pub in SCOPUS</p>
            </Col>
            <Col>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipscopus">
                  <InputNumber
                    autoFocus
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={4}>
              <p className="t-goal">Pub in Q1</p>
            </Col>
            <Col span={3}>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipq1">
                  <InputNumber
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col>
              <p> %</p>
            </Col>
          </Row>

          <Row>
            <Col span={4}>
              <p className="t-goal">Pub per faculty</p>
            </Col>
            <Col>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipppf">
                  <InputNumber
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={4}>
              <p className="t-goal">First/Corresponding</p>
            </Col>
            <Col span={3}>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipfc">
                  <InputNumber
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col>
              <p> %</p>
            </Col>
          </Row>

          <Row>
            <Col span={4}>
              <p className="t-goal">Total funding</p>
            </Col>
            <Col span={3}>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipttf">
                  <InputNumber
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col>
              <p> (ล้านบาท)</p>
            </Col>
          </Row>

          <Row>
            <Col span={4}>
              <p className="t-goal">Int. funding</p>
            </Col>
            <Col span={3}>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipif">
                  <InputNumber
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col>
              <p> (ล้านบาท)</p>
            </Col>
          </Row>

          <Row>
            <Col span={4}>
              <p className="t-goal">Thai. funding</p>
            </Col>
            <Col span={3}>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipthf">
                  <InputNumber
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col>
              <p> (ล้านบาท)</p>
            </Col>
          </Row>

          <Row>
            <Col span={4}>
              <p className="t-goal">Income</p>
            </Col>
            <Col span={3}>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipic">
                  <InputNumber
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col>
              <p> (ล้านบาท)</p>
            </Col>
          </Row>
          <hr className="style-two" style={{ marginBottom: "20px" }} />

          <Row justify="center">
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  bSaveclick();
                }}
              >
                <SaveOutlined /> Save
              </Button>

              <Button
                type="danger"
                onClick={() => {
                  bClearclick();
                }}
              >
                <ClearOutlined /> Clear
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default Research_dashboard;
