import React from "react";
import "antd/dist/antd.min.css";

import { Layout } from "antd";

const { Footer } = Layout;

const Footer_last = () => {
  return (
    <Footer style={{ textAlign: "center" }}>
      Copyright &copy; 2022 Research RIHES CMU
    </Footer>
  );
};
export default Footer_last;
