import React, { useEffect, useState } from "react";
import "antd/dist/antd.min.css";
import axios from "axios";
import swal from "sweetalert";
import { createGlobalStyle } from "styled-components";

import {
  Layout,
  Row,
  Col,
  PageHeader,
  Divider,
  Space,
  Button,
  Table,
  Typography,
  Select
} from "antd";

import { FileExcelOutlined } from "@ant-design/icons";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import thTH from "antd/lib/locale/th_TH";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

import * as xlsx from "xlsx";

const { Content } = Layout;
const { Text } = Typography;
const { Option } = Select;

const StyledForm = createGlobalStyle`.ant-form-item {
    margin-bottom: 5px;
  }
  `;

const configuredLocale = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    dateFormat: "DD MM BBBB", // DD MM BBBB
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker.lang,
      dateFormat: "DD MM BBBB", // DD MM BBBB
      dateTimeFormat: "DD MM BBBB HH:mm:ss",
      yearFormat: "BBBB"
    }
  }
};

dayjs.locale("th");
dayjs.extend(buddhistEra);

const Research_pub_type_cmu = () => {
  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [levelU, _levelU] = useState("");
  const [r_id, _r_id] = useState("");

  const [dataSource1, _dataSource1] = useState();
  const [dataSourceEx, _dataSourceEx] = useState();
  const [columns1, _column1] = useState([]);

  const [dataYear, _dataYear] = useState([]);
  const [xValues, _xValues] = useState(5);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const xresearch_id = localStorage.getItem("research_id");

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          axios
            .post(xURL + "findlevel", { research_id: xresearch_id })
            .then(result => {
              // console.log(result.data.length);
              if (result.data.length > 0) {
                if (result.data[0].user_level_id == 4) {
                  _r_id(xresearch_id);
                  _levelU("research");
                } else {
                  _levelU("");
                }
              } else {
                _levelU("");
              }

              GetTable();
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const GetTable = () => {
    getcolumn(5);
  };

  const getcolumn = num => {
    axios
      .post(xURL + "find_project_cyear", {
        ldata: num
      })
      .then(result => {
        let cl = [],
          syear = [];
        let xyear = "";

        cl.push({
          title: "รายการข้อมูล",
          dataIndex: "listdata",
          key: "listdata",
          render: (text, record) => {
            return {
              props: {
                style: {
                  background: record.listdata == "รวมทั้งหมด" ? "#b5f5ec" : ""
                }
              },
              children: (
                <div>
                  {text}
                </div>
              )
            };
          }
        });

        result.data.forEach((i, idx) => {
          xyear = dayjs("01/01/" + i.years).format("BBBB");

          cl.push({
            title: xyear,
            dataIndex: "year" + idx,
            key: "year" + idx,
            align: "center",
            render: (text, record) => {
              return {
                props: {
                  style: {
                    background: record.listdata == "รวมทั้งหมด" ? "#b5f5ec" : ""
                  }
                },
                children: (
                  <a
                    onClick={e => {
                      if (idx == 0) {
                        //console.log(cl[idx+1].title)

                        Showlist(cl[idx + 1].title, record.id, record.year0);
                      } else if (idx == 1) {
                        Showlist(cl[idx + 1].title, record.id, record.year1);
                      } else if (idx == 2) {
                        Showlist(cl[idx + 1].title, record.id, record.year2);
                      } else if (idx == 3) {
                        Showlist(cl[idx + 1].title, record.id, record.year3);
                      } else if (idx == 4) {
                        Showlist(cl[idx + 1].title, record.id, record.year4);
                      } else if (idx == 5) {
                        Showlist(cl[idx + 1].title, record.id, record.year5);
                      } else if (idx == 6) {
                        Showlist(cl[idx + 1].title, record.id, record.year6);
                      } else if (idx == 7) {
                        Showlist(cl[idx + 1].title, record.id, record.year7);
                      } else if (idx == 8) {
                        Showlist(cl[idx + 1].title, record.id, record.year8);
                      } else if (idx == 9) {
                        Showlist(cl[idx + 1].title, record.id, record.year9);
                      }
                    }}
                  >
                    {text}
                  </a>
                )
              };
            }
          });

          syear.push({
            year: i.years
          });

          _dataYear(syear);
        });

        _column1(cl);

        /////////////////////////////////////////////////
        axios
          .post(xURL + "find_project_type", {
            year: syear
          })
          .then(result => {
            //console.log(result.data);
            Show_projet(result.data, num);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Show_projet = (zdata, num) => {
    const Tb1 = [],
      Tb2 = [];

    let xsum1 = 0,
      xsum2 = 0,
      xsum3 = 0,
      xsum4 = 0,
      xsum5 = 0,
      xsum6 = 0,
      xsum7 = 0,
      xsum8 = 0,
      xsum9 = 0,
      xsum10 = 0;

    if (num == 5) {
      zdata.forEach((i, idx) => {
        Tb1.push({
          key: idx,
          listdata: "จำนวนโครงการวิจัย (ด้าน " + i.pub_type_short + ")",
          id: i.pub_type_cmu_id,
          year0: i.year0,
          year1: i.year1,
          year2: i.year2,
          year3: i.year3,
          year4: i.year4
        });

        Tb2.push({
          listdata: "จำนวนโครงการวิจัย (ด้าน " + i.pub_type_short + ")",
          year0: i.year0,
          year1: i.year1,
          year2: i.year2,
          year3: i.year3,
          year4: i.year4
        });

        xsum1 = xsum1 + i.year0;
        xsum2 = xsum2 + i.year1;
        xsum3 = xsum3 + i.year2;
        xsum4 = xsum4 + i.year3;
        xsum5 = xsum5 + i.year4;
      });

      Tb1.push({
        key: 10,
        listdata: "รวมทั้งหมด",
        id: 0,
        year0: xsum1,
        year1: xsum2,
        year2: xsum3,
        year3: xsum4,
        year4: xsum5
      });

      Tb2.push({
        listdata: "รวมทั้งหมด",
        year0: xsum1,
        year1: xsum2,
        year2: xsum3,
        year3: xsum4,
        year4: xsum5
      });
    } else if (num == 10) {
      zdata.forEach((i, idx) => {
        Tb1.push({
          key: idx,
          listdata: "จำนวนโครงการวิจัย (ด้าน " + i.pub_type_short + ")",
          id: i.pub_type_cmu_id,
          year0: i.year0,
          year1: i.year1,
          year2: i.year2,
          year3: i.year3,
          year4: i.year4,
          year5: i.year5,
          year6: i.year6,
          year7: i.year7,
          year8: i.year8,
          year9: i.year9
        });

        Tb2.push({
          listdata: "จำนวนโครงการวิจัย (ด้าน " + i.pub_type_short + ")",
          year0: i.year0,
          year1: i.year1,
          year2: i.year2,
          year3: i.year3,
          year4: i.year4,
          year5: i.year5,
          year6: i.year6,
          year7: i.year7,
          year8: i.year8,
          year9: i.year9
        });

        xsum1 = xsum1 + i.year0;
        xsum2 = xsum2 + i.year1;
        xsum3 = xsum3 + i.year2;
        xsum4 = xsum4 + i.year3;
        xsum5 = xsum5 + i.year4;
        xsum6 = xsum6 + i.year5;
        xsum7 = xsum7 + i.year6;
        xsum8 = xsum8 + i.year7;
        xsum9 = xsum9 + i.year8;
        xsum10 = xsum10 + i.year9;
      });

      Tb1.push({
        key: 10,
        listdata: "รวมทั้งหมด",
        id: 0,
        year0: xsum1,
        year1: xsum2,
        year2: xsum3,
        year3: xsum4,
        year4: xsum5,
        year5: xsum6,
        year6: xsum7,
        year7: xsum8,
        year8: xsum9,
        year9: xsum10
      });

      Tb2.push({
        listdata: "รวมทั้งหมด",
        year0: xsum1,
        year1: xsum2,
        year2: xsum3,
        year3: xsum4,
        year4: xsum5,
        year5: xsum6,
        year6: xsum7,
        year7: xsum8,
        year8: xsum9,
        year9: xsum10
      });
    }

    _dataSource1(Tb1);
    _dataSourceEx(Tb2);
  };

  const bExcelClick = () => {
    let xdata = [];

    let tb = [],
      xyear = "";

    tb.push("รายการข้อมูล");
    dataYear.forEach((i, idx) => {
      xyear = dayjs("01/01/" + i.year).format("BBBB");

      tb.push(xyear);
    });

    xdata.push(["ทิศทางการวิจัยของโครงการวิจัย"]);
    xdata.push([""]);
    xdata.push(tb);

    // console.log(dataSourceEx);
    for (var row in dataSourceEx) {
      const xx = [];

      for (var col in dataSourceEx[row]) {
        xx.push(dataSourceEx[row][col]);
      }

      xdata.push(xx);
    }

    var wb = xlsx.utils.book_new(),
      ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, "ทิศทางการวิจัยของโครงการวิจัย.xlsx");
  };

  const Showlist = (zyear, zhead, zcount) => {
    // console.log(zyear, zhead, zcount);
    let xyear = zyear - 543;
    if (zcount > 0) {
      window.open(
        "/Research_main?PTCyear=" + xyear + "&PTCtype=" + zhead,
        "_blank"
      );
      // window.open(
      //   "/Research_scopus_list?year=" +
      //     zyear +
      //     "&head=" +
      //     zhead +
      //     "&count=" +
      //     zcount,
      //   "_blank"
      // );
    } else {
      swal("แจ้งเตือน!", "ไม่มีข้อมูลตามเงื่อนไขที่เลือก!", "warning");
    }
  };

  const handleChange = value => {
    //console.log(`selected ${value}`);
    if (value == 0) {
      getcolumn(5);
      _xValues(5);
    } else if (value == 1) {
      getcolumn(10);
      _xValues(10);
    }
  };

  return (
    <React.Fragment>
      <StyledForm />
      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={23}>
            <PageHeader
              className="site-page-header"
              title="ทิศทางการวิจัยของโครงการวิจัย"
            />
            <Divider />
          </Col>
        </Row>

        <Row>
          <Col span={23}>
            <Space style={{ float: "right" }}>
              <Button ghost type="success" onClick={bExcelClick}>
                <FileExcelOutlined /> Excel
              </Button>
            </Space>
          </Col>
        </Row>
        <br />

        <Row justify="center" style={{ marginBottom: 10 }}>
          <Col span={23}>
            <Table
              bordered
              scroll={{ x: "max-content" }}
              dataSource={dataSource1}
              columns={columns1}
              size="small"
              pagination={false}
            />

            <Divider />

            <Row style={{ float: "right" }}>
              <Space>
                <p>แสดงรายการ :</p>
                <Select
                  defaultValue="0"
                  style={{ width: 120 }}
                  onChange={handleChange}
                >
                  <Option value="0">5 ปี</Option>
                  <Option value="1">10 ปี</Option>
                </Select>
              </Space>
            </Row>
          </Col>
        </Row>
      </Content>

      <div style={{ height: "250px" }} />

      <Footer_last />
    </React.Fragment>
  );
};

export default Research_pub_type_cmu;
