import "antd/dist/antd.min.css";
import "antd-button-color/dist/css/style.css";
import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Layout,
  Card,
  Typography,
  Table,
  Modal,
  BackTop,
  PageHeader,
  Divider
} from "antd";
import Button from "antd-button-color";

import { FileExcelOutlined } from "@ant-design/icons";

import * as xlsx from "xlsx";

import { createGlobalStyle } from "styled-components";

import moment from "moment";
import "moment/locale/th";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

const { Content } = Layout;
const { Text } = Typography;

const StyledForm = createGlobalStyle`.ant-form-item {
  margin-bottom: 5px;
}
`;

const Research_scopus_list2 = () => {
  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [xtitle, _xtitle] = useState("");
  const [xtitle2, _xtitle2] = useState("");

  const [dataSource1, _dataSource1] = useState([]);
  const [dataSource2, _dataSource2] = useState([]);

  const [paginationInfo] = useState({
    current: 1,
    pageSize: 10
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [xtitleex, _xtitleex] = useState("");
  const [xjournal, _xjournal] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    //console.log(token);

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");

          var url = new URL(window.location.href);

          let xhead = "";
          if (url.searchParams.get("head") === "All") {
            xhead = "ทั้งหมด";
          } else if (url.searchParams.get("head") === "First") {
            xhead = "First/Corresponding author";
          } else if (url.searchParams.get("head") === "Co") {
            xhead = "Co-author";
          } else {
            xhead = url.searchParams.get("head");
          }

          _xtitle(
            <Text
              style={{
                fontWeight: "bold",
                fontSize: "15px",
                color: "white"
              }}
            >
              จำนวนผลงานตีพิมพ์ใน SCOPUS ({xhead}) นักวิจัย {" ["}
              {url.searchParams.get("research")} {"]"} จำนวน{" "}
              {url.searchParams.get("count")} เรื่อง
            </Text>
          );

          _xtitleex(
            "จำนวนผลงานตีพิมพ์ใน SCOPUS (" +
              xhead +
              ") นักวิจัย [" +
              url.searchParams.get("research") +
              "] จำนวน " +
              url.searchParams.get("count") +
              " เรื่อง"
          );

          GetTable(
            url.searchParams.get("research"),
            url.searchParams.get("head")
          );
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const GetTable = (research, head) => {
    axios
      .post(xURL + "find_scopus_list2", {
        research: research,
        head: head
      })
      .then(result => {
        console.log(result);
        const Tb1 = [];

        result.data.forEach((i, idx) => {
          let xisi_q = "";

          if (i.isi_q) {
            xisi_q = i.isi_q;
          }

          Tb1.push({
            key: idx,
            num: idx + 1,
            title: i.title,
            authors: i.first_name,
            role: i.role_name,
            year: i.year,
            journal_name: i.journal_name,
            sjr_q: i.sjr_q,
            isi_q: xisi_q,
            impact_factor: i.impact_factor,
            direction: i.pub_type_cmu_name,
            citation_count: i.citation_count,
            link: i.link
          });
        });

        _dataSource1(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Showscopuslist = zdata => {
    _xjournal(zdata.journal_name);

    axios
      .post(xURL + "find_scopus_journal", {
        journal: zdata.journal_name
      })
      .then(result => {
        //console.log(result);
        const Tb1 = [];

        let xnum = 0,
          xtitle = "",
          xyear = "";

        result.data.forEach((i, idx) => {
          if (i.title !== xtitle) {
            xnum = xnum + 1;
          } else {
            if (i.year !== xyear) {
              xnum = xnum + 1;
            }
          }

          Tb1.push({
            key: idx,
            num: xnum,
            title: i.title,
            authors: i.first_name,
            role: i.role_name,
            year: i.year,
            journal_name: i.journal_name,
            sjr_q: i.sjr_q,
            isi_q: i.isi_q,
            impact_factor: i.impact_factor,
            direction: i.pub_type_cmu_name,
            citation_count: i.citation_count,
            link: i.link
          });

          xtitle = i.title;
          xyear = i.year;
        });

        _dataSource2(Tb1);

        _xtitle2(
          "ผลงานตีพิมพ์ใน SCOPUS (ทั้งหมด) ของ วารสาร [" +
            zdata.journal_name +
            " ] จำนวน " +
            xnum +
            " เรื่อง"
        );
        setIsModalVisible(true);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bExcelClick = () => {
    var url = new URL(window.location.href);

    let zresearch = url.searchParams.get("research"),
      zhead = url.searchParams.get("head");

    axios
      .post(xURL + "scopuslist2Ex", {
        research: zresearch,
        head: zhead
      })
      .then(result => {
        let xdata = [],
          header = [];

        header.push(
          "ชื่อเรื่อง",
          "นักวิจัย",
          "บทบาท",
          "ปี",
          "ชื่อวารสาร",
          "SJR (Q)",
          "ISI (Q)",
          "impact factor",
          "ทิศทางการวิจัย",
          "Citation Count"
        );

        xdata.push([xtitleex]);
        xdata.push([""]);

        xdata.push(header);

        for (var row in result.data) {
          const xx = [];

          for (var col in result.data[row]) {
            xx.push(result.data[row][col]);
          }

          xdata.push(xx);
        }

        var wb = xlsx.utils.book_new(),
          ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

        xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
        xlsx.writeFile(wb, "Excel.xlsx");
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bExcelClick2 = () => {
    axios
      .post(xURL + "scopuslistEx3", { journal: xjournal })
      .then(result => {
        let xdata = [],
          header = [];

        header.push(
          "ชื่อเรื่อง",
          "นักวิจัย",
          "บทบาท",
          "ปี",
          "ชื่อวารสาร",
          "SJR (Q)",
          "ISI (Q)",
          "impact factor",
          "ทิศทางการวิจัย",
          "Citation Count"
        );

        xdata.push([xtitle2]);
        xdata.push([""]);

        xdata.push(header);

        for (var row in result.data) {
          const xx = [];

          for (var col in result.data[row]) {
            xx.push(result.data[row][col]);
          }

          xdata.push(xx);
        }

        var wb = xlsx.utils.book_new(),
          ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

        xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
        xlsx.writeFile(wb, "Excel.xlsx");
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const columns1 = [
    {
      title: "ลำดับที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 80,
      sorter: (a, b) => a.num - b.num,
      render: (text, record, index) => {
        return index + 1;
      }
    },
    {
      title: "ชื่อเรื่อง",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.num - b.num,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={() => {
                window.open(record.link, "_blank");
              }}
            >
              {text}
            </a>
          )
        };
      },
      width: 420
    },
    {
      title: "นักวิจัย",
      dataIndex: "authors",
      key: "authors",
      width: 200,
      sorter: (a, b) => {
        return a.authors.localeCompare(b.authors);
      }
    },
    {
      title: "บทบาท",
      dataIndex: "role",
      key: "role",
      align: "center",
      width: 150,
      sorter: (a, b) => {
        return a.role.localeCompare(b.role);
      }
    },
    {
      title: "ปี",
      dataIndex: "year",
      key: "year",
      align: "center",
      width: 80,
      sorter: (a, b) =>
        moment(moment(a.year, "YYYY")).unix() -
        moment(moment(b.year, "YYYY")).unix()
    },
    {
      title: "ชื่อวารสาร",
      dataIndex: "journal_name",
      key: "journal_name",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={() => {
                Showscopuslist(record);
              }}
            >
              {text}
            </a>
          )
        };
      },
      width: 200,
      sorter: (a, b) => {
        return a.journal_name.localeCompare(b.journal_name);
      }
    },
    {
      title: "SJR (Q)",
      dataIndex: "sjr_q",
      key: "sjr_q",
      align: "center",
      width: 80,
      sorter: (a, b) => {
        return a.sjr_q.localeCompare(b.sjr_q);
      }
    },
    {
      title: "ISI (Q)",
      dataIndex: "isi_q",
      key: "isi_q",
      align: "center",
      width: 80,
      sorter: (a, b) => {
        return a.isi_q.localeCompare(b.isi_q);
      }
    },
    {
      title: "impact factor",
      dataIndex: "impact_factor",
      key: "impact_factor",
      align: "center",
      width: 120,
      sorter: (a, b) => a.impact_factor - b.impact_factor
    },
    {
      title: "ทิศทางการวิจัย",
      dataIndex: "direction",
      key: "direction",
      align: "center",
      width: 180,
      sorter: (a, b) => {
        return a.direction.localeCompare(b.direction);
      }
    },
    {
      title: "Citation Count",
      dataIndex: "citation_count",
      key: "citation_count",
      align: "center",
      width: 120
    }
  ];

  const columns2 = [
    {
      title: "ลำดับที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 80,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.num === dataSource2[trueIndex - 1].num) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource2.length &&
            record.num === dataSource2[trueIndex + i].num;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ชื่อเรื่อง",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.num - b.num,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: (
            <a
              onClick={() => {
                window.open(record.link, "_blank");
              }}
            >
              {text}
            </a>
          ),
          props: {}
        };

        if (index >= 1 && record.num === dataSource2[trueIndex - 1].num) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource2.length &&
            record.num === dataSource2[trueIndex + i].num;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      },
      width: 420
    },
    {
      title: "นักวิจัย",
      dataIndex: "authors",
      key: "authors",
      width: 200
    },
    {
      title: "บทบาท",
      dataIndex: "role",
      key: "role",
      align: "center",
      width: 150
    },
    {
      title: "ปี",
      dataIndex: "year",
      key: "year",
      align: "center",
      width: 80,
      sorter: (a, b) =>
        moment(moment(a.year, "YYYY")).unix() -
        moment(moment(b.year, "YYYY")).unix(),
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.num === dataSource2[trueIndex - 1].num) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource2.length &&
            record.num === dataSource2[trueIndex + i].num;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ชื่อวารสาร",
      dataIndex: "journal_name",
      key: "journal_name",
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: (
            <a
              onClick={() => {
                Showscopuslist(record);
              }}
            >
              {text}
            </a>
          ),
          props: {}
        };

        if (index >= 1 && record.num === dataSource2[trueIndex - 1].num) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource2.length &&
            record.num === dataSource2[trueIndex + i].num;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      },
      width: 200,
      sorter: (a, b) => {
        return a.journal_name.localeCompare(b.journal_name);
      }
    },
    {
      title: "SJR (Q)",
      dataIndex: "sjr_q",
      key: "sjr_q",
      align: "center",
      width: 80,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.num === dataSource2[trueIndex - 1].num) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource2.length &&
            record.num === dataSource2[trueIndex + i].num;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ISI (Q)",
      dataIndex: "isi_q",
      key: "isi_q",
      align: "center",
      width: 80,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.num === dataSource2[trueIndex - 1].num) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource2.length &&
            record.num === dataSource2[trueIndex + i].num;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "impact factor",
      dataIndex: "impact_factor",
      key: "impact_factor",
      align: "center",
      width: 120,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.num === dataSource2[trueIndex - 1].num) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource2.length &&
            record.num === dataSource2[trueIndex + i].num;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ทิศทางการวิจัย",
      dataIndex: "direction",
      key: "direction",
      align: "center",
      width: 180,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.num === dataSource2[trueIndex - 1].num) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource2.length &&
            record.num === dataSource2[trueIndex + i].num;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "Citation Count",
      dataIndex: "citation_count",
      key: "citation_count",
      align: "center",
      width: 120,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.num === dataSource2[trueIndex - 1].num) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource2.length &&
            record.num === dataSource2[trueIndex + i].num;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    }
  ];

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <React.Fragment>
      <StyledForm />

      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{
          padding: "0 20px",
          marginTop: 64
        }}
      >
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={23}>
            <PageHeader
              className="site-page-header"
              title={
                <a
                  style={{
                    color: "black"
                  }}
                  onClick={() => {
                    window.close();
                  }}
                >
                  SCOPUS Journal List
                </a>
              }
              onBack={() => window.close()}
            />

            <Divider />

            <Button
              ghost
              type="success"
              onClick={bExcelClick}
              style={{ float: "right" }}
            >
              <FileExcelOutlined /> Excel
            </Button>
            <br />
            <br />

            <Card
              title={xtitle}
              headStyle={{
                backgroundColor: "#00474f"
              }}
              style={{
                //margin: "10px",
                borderRadius: "15px",
                overflow: "hidden"
              }}
            >
              <Table
                bordered
                scroll={{ x: "max-content" }}
                dataSource={dataSource1}
                columns={columns1}
                size="small"
                pagination={false}
              />
            </Card>
          </Col>
        </Row>

        <br />
      </Content>

      <Footer_last />

      <Modal
        title={xtitle2}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1800}
      >
        <Button
          ghost
          type="success"
          onClick={bExcelClick2}
          style={{ float: "right" }}
        >
          <FileExcelOutlined /> Excel
        </Button>
        <br />
        <br />

        <Card size="small">
          <Table
            bordered
            scroll={{ x: "max-content" }}
            dataSource={dataSource2}
            columns={columns2}
            size="small"
            pagination={false}
          />
        </Card>
      </Modal>
      <BackTop />
    </React.Fragment>
  );
};
export default Research_scopus_list2;
