import React, { useEffect, useState } from "react";
import axios from "axios";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import { createGlobalStyle } from "styled-components";

import {
  SearchOutlined,
  ClearOutlined,
  FileExcelOutlined
} from "@ant-design/icons";

import {
  Layout,
  Row,
  Col,
  PageHeader,
  Divider,
  Card,
  Form,
  Space,
  Button,
  Table,
  Typography,
  Select
} from "antd";

import * as xlsx from "xlsx";

import swal from "sweetalert";

const { Content } = Layout;

const StyledForm = createGlobalStyle`.ant-form-item {
    margin-bottom: 5px;
  }
  `;

const Research_report3 = () => {
  const [form1] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [Xvalue1, _Xvalue1] = useState("value1");
  const [Xvalue2, _Xvalue2] = useState("v1");

  const [dataSource1, _dataSource1] = useState();
  const [dataSource2, _dataSource2] = useState();
  const [dataSourceEx, _dataSourceEx] = useState();
  const [dataSourceEx2, _dataSourceEx2] = useState();

  const [titlecolumn, _titlecolumn] = useState("รายการข้อมูล");

  const [titleEx, _titleEx] = useState("จำนวนโครงการวิจัยทั้งหมด");

  const [xsumary, _xsumary] = useState("จำนวนโครงการวิจัยรวมทั้งหมด *");

  const [xsum61, _xsum61] = useState(0);
  const [xsum62, _xsum62] = useState(0);
  const [xsum63, _xsum63] = useState(0);
  const [xsum64, _xsum64] = useState(0);
  const [xsum65, _xsum65] = useState(0);
  const [xsum66, _xsum66] = useState(0);
  const [xsum67, _xsum67] = useState(0);

  const [xsum61_2, _xsum61_2] = useState(0);
  const [xsum62_2, _xsum62_2] = useState(0);
  const [xsum63_2, _xsum63_2] = useState(0);
  const [xsum64_2, _xsum64_2] = useState(0);
  const [xsum65_2, _xsum65_2] = useState(0);
  const [xsum66_2, _xsum66_2] = useState(0);
  const [xsum67_2, _xsum67_2] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //   alert("authen sucess");

          bSearch("value1", "v1");
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const bClear = () => {
    window.location.reload(false);
  };

  const bSearch = (v1, v2) => {
    // console.log(v1, v2);

    if (v1 == "value1") {
      if (v2 == "v1") {
        //โครงการวิจัยใหม่
        axios
          .post(xURL + "reportv11", { order: 1 })
          .then(result => {
            // console.log(111);
            let Tb1 = [],
              Tbex = [];
            let xtitle = "";

            let xsum1 = 0,
              xsum2 = 0,
              xsum3 = 0,
              xsum4 = 0,
              xsum5 = 0,
              xsum6 = 0,
              xsum7 = 0;

            result.data.forEach((i, idx) => {
              if (i.name == "ต่างประเทศ") {
                xtitle = "จำนวนโครงการวิจัยต่างประเทศ";
              } else if (i.name == "ในประเทศ") {
                xtitle = "จำนวนโครงการวิจัยในประเทศ";
              }

              Tb1.push({
                key: idx,
                htb_list: xtitle,
                y61: i.y61,
                y62: i.y62,
                y63: i.y63,
                y64: i.y64,
                y65: i.y65,
                y66: i.y66,
                y67: i.y67
              });

              Tbex.push({
                htb_list: xtitle,
                y61: i.y61,
                y62: i.y62,
                y63: i.y63,
                y64: i.y64,
                y65: i.y65,
                y66: i.y66,
                y67: i.y67
              });

              // xsum1 = xsum1 + i.y61;
              // xsum2 = xsum2 + i.y62;
              // xsum3 = xsum3 + i.y63;
              // xsum4 = xsum4 + i.y64;
              // xsum5 = xsum5 + i.y65;
              // xsum6 = xsum6 + i.y66;
              // xsum7 = xsum7 + i.y67;
            });

            axios
              .post(xURL + "reportv11", { order: 3 })
              .then(result => {
                xsum1 = result.data[0].y61;
                xsum2 = result.data[0].y62;
                xsum3 = result.data[0].y63;
                xsum4 = result.data[0].y64;
                xsum5 = result.data[0].y65;
                xsum6 = result.data[0].y66;
                xsum7 = result.data[0].y67;

                Tbex.push({
                  htb_list: xsumary,
                  y61: xsum1,
                  y62: xsum2,
                  y63: xsum3,
                  y64: xsum4,
                  y65: xsum5,
                  y66: xsum6,
                  y67: xsum7
                });

                _dataSource1(Tb1);
                _dataSourceEx(Tbex);

                _xsum61(xsum1);
                _xsum62(xsum2);
                _xsum63(xsum3);
                _xsum64(xsum4);
                _xsum65(xsum5);
                _xsum66(xsum6);
                _xsum67(xsum7);
              })
              .catch(function(error) {
                // handle error
                console.log("problem here", error);
              });
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        //โครงการวิจัยต่อเนื่อง
        axios
          .post(xURL + "reportv11", { order: 2 })
          .then(result => {
            let Tb1 = [],
              Tbex = [];
            let xtitle = "";

            let xsum1 = 0,
              xsum2 = 0,
              xsum3 = 0,
              xsum4 = 0,
              xsum5 = 0,
              xsum6 = 0,
              xsum7 = 0;

            result.data.forEach((i, idx) => {
              if (i.name == "ต่างประเทศ") {
                xtitle = "จำนวนโครงการวิจัยต่างประเทศ";
              } else if (i.name == "ในประเทศ") {
                xtitle = "จำนวนโครงการวิจัยในประเทศ";
              }

              Tb1.push({
                key: idx,
                htb_list: xtitle,
                y61: i.y61,
                y62: i.y62,
                y63: i.y63,
                y64: i.y64,
                y65: i.y65,
                y66: i.y66,
                y67: i.y67
              });

              Tbex.push({
                htb_list: xtitle,
                y61: i.y61,
                y62: i.y62,
                y63: i.y63,
                y64: i.y64,
                y65: i.y65,
                y66: i.y66,
                y67: i.y67
              });

              // xsum1 = xsum1 + i.y61;
              // xsum2 = xsum2 + i.y62;
              // xsum3 = xsum3 + i.y63;
              // xsum4 = xsum4 + i.y64;
              // xsum5 = xsum5 + i.y65;
              // xsum6 = xsum6 + i.y66;
              // xsum7 = xsum7 + i.y67;
            });

            axios
              .post(xURL + "reportv11", { order: 4 })
              .then(result => {
                xsum1 = result.data[0].y61;
                xsum2 = result.data[0].y62;
                xsum3 = result.data[0].y63;
                xsum4 = result.data[0].y64;
                xsum5 = result.data[0].y65;
                xsum6 = result.data[0].y66;
                xsum7 = result.data[0].y67;

                Tbex.push({
                  htb_list: xsumary,
                  y61: xsum1,
                  y62: xsum2,
                  y63: xsum3,
                  y64: xsum4,
                  y65: xsum5,
                  y66: xsum6,
                  y67: xsum7
                });

                _dataSource2(Tb1);
                _dataSourceEx2(Tbex);

                _xsum61_2(xsum1);
                _xsum62_2(xsum2);
                _xsum63_2(xsum3);
                _xsum64_2(xsum4);
                _xsum65_2(xsum5);
                _xsum66_2(xsum6);
                _xsum67_2(xsum7);
              })
              .catch(function(error) {
                // handle error
                console.log("problem here", error);
              });
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      } else if (v2 == "v2") {
        //โครงการวิจัยใหม่
        axios
          .post(xURL + "reportv12", { order: 1, orderh: 1 })
          .then(result => {
            //  console.log(result.data);

            let Tb1 = [],
              Tbex = [];
            let xtitle = "";

            let xsum1 = 0,
              xsum2 = 0,
              xsum3 = 0,
              xsum4 = 0,
              xsum5 = 0,
              xsum6 = 0,
              xsum7 = 0;

            result.data.forEach((i, idx) => {
              if (i.name == "ภาครัฐ") {
                xtitle = "จำนวนโครงการวิจัย (ภาครัฐ)";
              } else if (i.name == "ภาคเอกชน") {
                xtitle = "จำนวนโครงการวิจัย (ภาคเอกชน)";
              }

              Tb1.push({
                key: idx,
                htb_list: xtitle,
                y61: i.y61,
                y62: i.y62,
                y63: i.y63,
                y64: i.y64,
                y65: i.y65,
                y66: i.y66,
                y67: i.y67
              });
              Tbex.push({
                htb_list: xtitle,
                y61: i.y61,
                y62: i.y62,
                y63: i.y63,
                y64: i.y64,
                y65: i.y65,
                y66: i.y66,
                y67: i.y67
              });

              xsum1 = xsum1 + i.y61;
              xsum2 = xsum2 + i.y62;
              xsum3 = xsum3 + i.y63;
              xsum4 = xsum4 + i.y64;
              xsum5 = xsum5 + i.y65;
              xsum6 = xsum6 + i.y66;
              xsum7 = xsum7 + i.y67;
            });

            axios
              .post(xURL + "reportv12", { order: 2, orderh: 1 })
              .then(result2 => {
                result2.data.forEach((j, idx2) => {
                  Tb1.push({
                    key: idx2,
                    htb_list: j.name,
                    y61: j.y61,
                    y62: j.y62,
                    y63: j.y63,
                    y64: j.y64,
                    y65: j.y65,
                    y66: j.y66,
                    y67: j.y67
                  });
                  Tbex.push({
                    htb_list: j.name,
                    y61: j.y61,
                    y62: j.y62,
                    y63: j.y63,
                    y64: j.y64,
                    y65: j.y65,
                    y66: j.y66,
                    y67: j.y67
                  });

                  xsum1 = xsum1 + j.y61;
                  xsum2 = xsum2 + j.y62;
                  xsum3 = xsum3 + j.y63;
                  xsum4 = xsum4 + j.y64;
                  xsum5 = xsum5 + j.y65;
                  xsum6 = xsum6 + j.y66;
                  xsum7 = xsum7 + j.y67;
                });

                Tbex.push({
                  htb_list: xsumary,
                  y61: xsum1,
                  y62: xsum2,
                  y63: xsum3,
                  y64: xsum4,
                  y65: xsum5,
                  y66: xsum6,
                  y67: xsum7
                });

                _dataSource1(Tb1);
                _dataSourceEx(Tbex);

                _xsum61(xsum1);
                _xsum62(xsum2);
                _xsum63(xsum3);
                _xsum64(xsum4);
                _xsum65(xsum5);
                _xsum66(xsum6);
                _xsum67(xsum7);
              })
              .catch(function(error) {
                // handle error
                console.log("problem here", error);
              });
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        //โครงการวิจัยต่อเนื่อง
        axios
          .post(xURL + "reportv12", { order: 1, orderh: 2 })
          .then(result => {
            //  console.log(result.data);

            let Tb1 = [],
              Tbex = [];
            let xtitle = "";

            let xsum1 = 0,
              xsum2 = 0,
              xsum3 = 0,
              xsum4 = 0,
              xsum5 = 0,
              xsum6 = 0,
              xsum7 = 0;

            result.data.forEach((i, idx) => {
              if (i.name == "ภาครัฐ") {
                xtitle = "จำนวนโครงการวิจัย (ภาครัฐ)";
              } else if (i.name == "ภาคเอกชน") {
                xtitle = "จำนวนโครงการวิจัย (ภาคเอกชน)";
              }

              Tb1.push({
                key: idx,
                htb_list: xtitle,
                y61: i.y61,
                y62: i.y62,
                y63: i.y63,
                y64: i.y64,
                y65: i.y65,
                y66: i.y66,
                y67: i.y67
              });
              Tbex.push({
                htb_list: xtitle,
                y61: i.y61,
                y62: i.y62,
                y63: i.y63,
                y64: i.y64,
                y65: i.y65,
                y66: i.y66,
                y67: i.y67
              });

              xsum1 = xsum1 + i.y61;
              xsum2 = xsum2 + i.y62;
              xsum3 = xsum3 + i.y63;
              xsum4 = xsum4 + i.y64;
              xsum5 = xsum5 + i.y65;
              xsum6 = xsum6 + i.y66;
              xsum7 = xsum7 + i.y67;
            });

            axios
              .post(xURL + "reportv12", { order: 2, orderh: 2 })
              .then(result2 => {
                result2.data.forEach((j, idx2) => {
                  Tb1.push({
                    key: idx2,
                    htb_list: j.name,
                    y61: j.y61,
                    y62: j.y62,
                    y63: j.y63,
                    y64: j.y64,
                    y65: j.y65,
                    y66: j.y66,
                    y67: j.y67
                  });
                  Tbex.push({
                    htb_list: j.name,
                    y61: j.y61,
                    y62: j.y62,
                    y63: j.y63,
                    y64: j.y64,
                    y65: j.y65,
                    y66: j.y66,
                    y67: j.y67
                  });

                  xsum1 = xsum1 + j.y61;
                  xsum2 = xsum2 + j.y62;
                  xsum3 = xsum3 + j.y63;
                  xsum4 = xsum4 + j.y64;
                  xsum5 = xsum5 + j.y65;
                  xsum6 = xsum6 + j.y66;
                  xsum7 = xsum7 + j.y67;
                });

                Tbex.push({
                  htb_list: xsumary,
                  y61: xsum1,
                  y62: xsum2,
                  y63: xsum3,
                  y64: xsum4,
                  y65: xsum5,
                  y66: xsum6,
                  y67: xsum7
                });

                _dataSource2(Tb1);
                _dataSourceEx2(Tbex);

                _xsum61_2(xsum1);
                _xsum62_2(xsum2);
                _xsum63_2(xsum3);
                _xsum64_2(xsum4);
                _xsum65_2(xsum5);
                _xsum66_2(xsum6);
                _xsum67_2(xsum7);
              })
              .catch(function(error) {
                // handle error
                console.log("problem here", error);
              });
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      } else if (v2 == "v3") {
        axios
          .post(xURL + "reportv13", { order: 1 })
          .then(result => {
            //  console.log(result.data);

            let Tb1 = [],
              Tbex = [];
            let xtitle = "";

            let xsum1 = 0,
              xsum2 = 0,
              xsum3 = 0,
              xsum4 = 0,
              xsum5 = 0,
              xsum6 = 0,
              xsum7 = 0;

            result.data.forEach((i, idx) => {
              if (i.name == "ภาครัฐ") {
                xtitle = "ลูกค้าภาครัฐ";
              } else if (i.name == "ภาคเอกชน") {
                xtitle = "ลูกค้าภาคเอกชน";
              }

              Tb1.push({
                key: idx,
                htb_list: xtitle,
                y61: i.y61,
                y62: i.y62,
                y63: i.y63,
                y64: i.y64,
                y65: i.y65,
                y66: i.y66,
                y67: i.y67
              });
              Tbex.push({
                htb_list: xtitle,
                y61: i.y61,
                y62: i.y62,
                y63: i.y63,
                y64: i.y64,
                y65: i.y65,
                y66: i.y66,
                y67: i.y67
              });

              xsum1 = xsum1 + i.y61;
              xsum2 = xsum2 + i.y62;
              xsum3 = xsum3 + i.y63;
              xsum4 = xsum4 + i.y64;
              xsum5 = xsum5 + i.y65;
              xsum6 = xsum6 + i.y66;
              xsum7 = xsum7 + i.y67;
            });

            axios
              .post(xURL + "reportv13", { order: 2 })
              .then(result2 => {
                //  console.log(result.data);
                result2.data.forEach((j, idx2) => {
                  Tb1.push({
                    key: idx2,
                    htb_list: j.name,
                    y61: j.y61,
                    y62: j.y62,
                    y63: j.y63,
                    y64: j.y64,
                    y65: j.y65,
                    y66: j.y66,
                    y67: j.y67
                  });
                  Tbex.push({
                    htb_list: j.name,
                    y61: j.y61,
                    y62: j.y62,
                    y63: j.y63,
                    y64: j.y64,
                    y65: j.y65,
                    y66: j.y66,
                    y67: j.y67
                  });

                  xsum1 = xsum1 + j.y61;
                  xsum2 = xsum2 + j.y62;
                  xsum3 = xsum3 + j.y63;
                  xsum4 = xsum4 + j.y64;
                  xsum5 = xsum5 + j.y65;
                  xsum6 = xsum6 + j.y66;
                  xsum7 = xsum7 + j.y67;
                });

                Tbex.push({
                  htb_list: xsumary,
                  y61: xsum1,
                  y62: xsum2,
                  y63: xsum3,
                  y64: xsum4,
                  y65: xsum5,
                  y66: xsum6,
                  y67: xsum7
                });

                _dataSource1(Tb1);
                _dataSourceEx(Tbex);

                _xsum61(xsum1);
                _xsum62(xsum2);
                _xsum63(xsum3);
                _xsum64(xsum4);
                _xsum65(xsum5);
                _xsum66(xsum6);
                _xsum67(xsum7);
              })
              .catch(function(error) {
                // handle error
                console.log("problem here", error);
              });
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      } else if (v2 == "v4") {
        //โครงการวิจัยใหม่
        axios
          .post(xURL + "reportv14", { order: 1 })
          .then(result => {
            //  console.log(result.data);

            let Tb1 = [],
              Tbex = [];
            let xtitle = "";

            let xsum1 = 0,
              xsum2 = 0,
              xsum3 = 0,
              xsum4 = 0,
              xsum5 = 0,
              xsum6 = 0,
              xsum7 = 0;

            result.data.forEach((i, idx) => {
              if (i.pub_type_short == "NCDs") {
                xtitle = "จำนวนโครงการวิจัย (ด้าน NCDs)";
              } else if (i.pub_type_short == "EIDs") {
                xtitle = "จำนวนโครงการวิจัย (ด้าน EIDs)";
              } else if (i.pub_type_short == "EH") {
                xtitle = "จำนวนโครงการวิจัย (ด้าน Environmental health)";
              } else if (i.pub_type_short == "BI") {
                xtitle = "จำนวนโครงการวิจัย (ด้าน Bio Innovation)";
              } else if (i.pub_type_short == "HIV") {
                xtitle = "จำนวนโครงการวิจัย (HIV)";
              } else if (i.pub_type_short == "etc.") {
                xtitle = "จำนวนโครงการวิจัย (อื่นๆ)";
              }

              Tb1.push({
                key: idx,
                htb_list: xtitle,
                y61: i.y61,
                y62: i.y62,
                y63: i.y63,
                y64: i.y64,
                y65: i.y65,
                y66: i.y66,
                y67: i.y67
              });
              Tbex.push({
                htb_list: xtitle,
                y61: i.y61,
                y62: i.y62,
                y63: i.y63,
                y64: i.y64,
                y65: i.y65,
                y66: i.y66,
                y67: i.y67
              });

              xsum1 = xsum1 + i.y61;
              xsum2 = xsum2 + i.y62;
              xsum3 = xsum3 + i.y63;
              xsum4 = xsum4 + i.y64;
              xsum5 = xsum5 + i.y65;
              xsum6 = xsum6 + i.y66;
              xsum7 = xsum7 + i.y67;
            });

            Tbex.push({
              htb_list: xsumary,
              y61: xsum1,
              y62: xsum2,
              y63: xsum3,
              y64: xsum4,
              y65: xsum5,
              y66: xsum6,
              y67: xsum7
            });

            _dataSource1(Tb1);
            _dataSourceEx(Tbex);

            _xsum61(xsum1);
            _xsum62(xsum2);
            _xsum63(xsum3);
            _xsum64(xsum4);
            _xsum65(xsum5);
            _xsum66(xsum6);
            _xsum67(xsum7);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        //โครงการวิจัยต่อเนื่อง

        axios
          .post(xURL + "reportv14", { order: 2 })
          .then(result => {
            //  console.log(result.data);

            let Tb1 = [],
              Tbex = [];
            let xtitle = "";

            let xsum1 = 0,
              xsum2 = 0,
              xsum3 = 0,
              xsum4 = 0,
              xsum5 = 0,
              xsum6 = 0,
              xsum7 = 0;

            result.data.forEach((i, idx) => {
              if (i.pub_type_short == "NCDs") {
                xtitle = "จำนวนโครงการวิจัย (ด้าน NCDs)";
              } else if (i.pub_type_short == "EIDs") {
                xtitle = "จำนวนโครงการวิจัย (ด้าน EIDs)";
              } else if (i.pub_type_short == "EH") {
                xtitle = "จำนวนโครงการวิจัย (ด้าน Environmental health)";
              } else if (i.pub_type_short == "BI") {
                xtitle = "จำนวนโครงการวิจัย (ด้าน Bio Innovation)";
              } else if (i.pub_type_short == "HIV") {
                xtitle = "จำนวนโครงการวิจัย (HIV)";
              } else if (i.pub_type_short == "etc.") {
                xtitle = "จำนวนโครงการวิจัย (อื่นๆ)";
              }

              Tb1.push({
                key: idx,
                htb_list: xtitle,
                y61: i.y61,
                y62: i.y62,
                y63: i.y63,
                y64: i.y64,
                y65: i.y65,
                y66: i.y66,
                y67: i.y67
              });
              Tbex.push({
                htb_list: xtitle,
                y61: i.y61,
                y62: i.y62,
                y63: i.y63,
                y64: i.y64,
                y65: i.y65,
                y66: i.y66,
                y67: i.y67
              });

              xsum1 = xsum1 + i.y61;
              xsum2 = xsum2 + i.y62;
              xsum3 = xsum3 + i.y63;
              xsum4 = xsum4 + i.y64;
              xsum5 = xsum5 + i.y65;
              xsum6 = xsum6 + i.y66;
              xsum7 = xsum7 + i.y67;
            });

            Tbex.push({
              htb_list: xsumary,
              y61: xsum1,
              y62: xsum2,
              y63: xsum3,
              y64: xsum4,
              y65: xsum5,
              y66: xsum6,
              y67: xsum7
            });

            _dataSource2(Tb1);
            _dataSourceEx2(Tbex);

            _xsum61_2(xsum1);
            _xsum62_2(xsum2);
            _xsum63_2(xsum3);
            _xsum64_2(xsum4);
            _xsum65_2(xsum5);
            _xsum66_2(xsum6);
            _xsum67_2(xsum7);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      } else if (v2 == "v5") {
        axios
          .get(xURL + "reportv15")
          .then(result => {
            //  console.log(result.data);

            let Tb1 = [],
              Tbex = [];

            result.data.forEach((i, idx) => {
              Tb1.push({
                key: idx,
                htb_list: i.name,
                y61: i.y61,
                y62: i.y62,
                y63: i.y63,
                y64: i.y64,
                y65: i.y65,
                y66: i.y66,
                y67: i.y67
              });
              Tbex.push({
                htb_list: i.name,
                y61: i.y61,
                y62: i.y62,
                y63: i.y63,
                y64: i.y64,
                y65: i.y65,
                y66: i.y66,
                y67: i.y67
              });
            });

            Tbex.push({
              htb_list: xsumary,
              y61: 40,
              y62: 45,
              y63: 50,
              y64: 55,
              y65: 60,
              y66: 112,
              y67: 0
            });

            _dataSource1(Tb1);
            _dataSourceEx(Tbex);

            _xsum61(40);
            _xsum62(45);
            _xsum63(50);
            _xsum64(55);
            _xsum65(60);
            _xsum66(112);
            _xsum67(0);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      } else if (v2 == "v6") {
        axios
          .get(xURL + "reportv16")
          .then(result => {
            //  console.log(result.data);

            let Tb1 = [],
              Tbex = [];
            let xtitle = "";

            let xsum1 = 0,
              xsum2 = 0,
              xsum3 = 0,
              xsum4 = 0,
              xsum5 = 0,
              xsum6 = 0,
              xsum7 = 0;

            result.data.forEach((i, idx) => {
              if (i.pub_type_short == "NCDs") {
                xtitle = "จำนวนผลงานตีพิมพ์ใน SCOPUS (ด้าน NCDs)";
              } else if (i.pub_type_short == "EIDs") {
                xtitle = "จำนวนผลงานตีพิมพ์ใน SCOPUS (ด้าน EIDs)";
              } else if (i.pub_type_short == "EH") {
                xtitle =
                  "จำนวนผลงานตีพิมพ์ใน SCOPUS (ด้าน Environmental health)";
              } else if (i.pub_type_short == "BI") {
                xtitle = "จำนวนโผลงานตีพิมพ์ใน SCOPUS (ด้าน Bio Innovation)";
              } else if (i.pub_type_short == "HIV") {
                xtitle = "จำนวนผลงานตีพิมพ์ใน SCOPUS (HIV)";
              } else if (i.pub_type_short == "etc.") {
                xtitle = "จำนวนผลงานตีพิมพ์ใน SCOPUS (อื่นๆ)";
              }

              Tb1.push({
                key: idx,
                htb_list: xtitle,
                y61: i.y61,
                y62: i.y62,
                y63: i.y63,
                y64: i.y64,
                y65: i.y65,
                y66: i.y66,
                y67: i.y67
              });
              Tbex.push({
                htb_list: xtitle,
                y61: i.y61,
                y62: i.y62,
                y63: i.y63,
                y64: i.y64,
                y65: i.y65,
                y66: i.y66,
                y67: i.y67
              });

              xsum1 = xsum1 + i.y61;
              xsum2 = xsum2 + i.y62;
              xsum3 = xsum3 + i.y63;
              xsum4 = xsum4 + i.y64;
              xsum5 = xsum5 + i.y65;
              xsum6 = xsum6 + i.y66;
              xsum7 = xsum7 + i.y67;
            });

            Tbex.push({
              htb_list: xsumary,
              y61: xsum1,
              y62: xsum2,
              y63: xsum3,
              y64: xsum4,
              y65: xsum5,
              y66: xsum6,
              y67: xsum7
            });

            _dataSource1(Tb1);
            _dataSourceEx(Tbex);

            _xsum61(xsum1);
            _xsum62(xsum2);
            _xsum63(xsum3);
            _xsum64(xsum4);
            _xsum65(xsum5);
            _xsum66(xsum6);
            _xsum67(xsum7);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      } else if (v2 == "v7") {
        axios
          .get(xURL + "reportv17")
          .then(result => {
            //  console.log(result.data);

            let Tb1 = [],
              Tbex = [];
            let xtitle = "";

            let xsum1 = 0,
              xsum2 = 0,
              xsum3 = 0,
              xsum4 = 0,
              xsum5 = 0,
              xsum6 = 0,
              xsum7 = 0;

            result.data.forEach((i, idx) => {
              if (i.sjr_q == "Q1") {
                xtitle = "จำนวนผลงานตีพิมพ์ใน SCOPUS Q1";
              } else if (i.sjr_q == "Q2") {
                xtitle = "จำนวนผลงานตีพิมพ์ใน SCOPUS Q2";
              } else if (i.sjr_q == "Q3") {
                xtitle = "จำนวนผลงานตีพิมพ์ใน SCOPUS Q3";
              } else if (i.sjr_q == "Q4") {
                xtitle = "จำนวนผลงานตีพิมพ์ใน SCOPUS Q4";
              } else if (i.sjr_q == "N/A") {
                xtitle = "จำนวนผลงานตีพิมพ์ใน SCOPUS ไม่มี Q";
              }

              Tb1.push({
                key: idx,
                htb_list: xtitle,
                y61: i.y61,
                y62: i.y62,
                y63: i.y63,
                y64: i.y64,
                y65: i.y65,
                y66: i.y66,
                y67: i.y67
              });
              Tbex.push({
                htb_list: xtitle,
                y61: i.y61,
                y62: i.y62,
                y63: i.y63,
                y64: i.y64,
                y65: i.y65,
                y66: i.y66,
                y67: i.y67
              });

              xsum1 = xsum1 + i.y61;
              xsum2 = xsum2 + i.y62;
              xsum3 = xsum3 + i.y63;
              xsum4 = xsum4 + i.y64;
              xsum5 = xsum5 + i.y65;
              xsum6 = xsum6 + i.y66;
              xsum7 = xsum7 + i.y67;
            });

            Tbex.push({
              htb_list: xsumary,
              y61: xsum1,
              y62: xsum2,
              y63: xsum3,
              y64: xsum4,
              y65: xsum5,
              y66: xsum6,
              y67: xsum7
            });

            _dataSource1(Tb1);
            _dataSourceEx(Tbex);

            _xsum61(xsum1);
            _xsum62(xsum2);
            _xsum63(xsum3);
            _xsum64(xsum4);
            _xsum65(xsum5);
            _xsum66(xsum6);
            _xsum67(xsum7);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      } else if (v2 == "v8") {
      } else if (v2 == "v9") {
      } else if (v2 == "v10") {
        axios
          .get(xURL + "reportv110")
          .then(result => {
            //  console.log(result.data);

            let Tb1 = [],
              Tbex = [];

            result.data.forEach((i, idx) => {
              Tb1.push({
                key: idx,
                htb_list: i.name,
                y61: i.y61,
                y62: i.y62,
                y63: i.y63,
                y64: i.y64,
                y65: i.y65,
                y66: i.y66,
                y67: i.y67
              });
              Tbex.push({
                htb_list: i.name,
                y61: i.y61,
                y62: i.y62,
                y63: i.y63,
                y64: i.y64,
                y65: i.y65,
                y66: i.y66,
                y67: i.y67
              });
            });

            Tbex.push({
              htb_list: xsumary,
              y61: 3,
              y62: 3,
              y63: 3,
              y64: 3,
              y65: 3,
              y66: 3,
              y67: 3
            });

            _dataSource1(Tb1);
            _dataSourceEx(Tbex);

            _xsum61(3);
            _xsum62(3);
            _xsum63(3);
            _xsum64(3);
            _xsum65(3);
            _xsum66(3);
            _xsum67(3);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      }
    } else if (v1 == "value2") {
      if (v2 == "v1") {
      }
    }
  };

  const bExcel = () => {
    const f = form1.getFieldsValue();

    let xdata = [],
      xtabtitle = "",
      xtabtitle2 = "",
      header = [];

    xtabtitle = titleEx;

    header.push(titlecolumn, "61", "62", "63", "64", "65", "66", "67");

    xdata.push([xtabtitle]);

    if (
      Xvalue1 == "value1" &&
      (Xvalue2 == "v1" || Xvalue2 == "v2" || Xvalue2 == "v4")
    ) {
      xdata.push([""]);
      xdata.push(["โครงการวิจัยใหม่"]);
    }

    xdata.push([""]);
    xdata.push(header);

    for (var row in dataSourceEx) {
      const xx = [];
      for (var col in dataSourceEx[row]) {
        xx.push(dataSourceEx[row][col]);
      }

      xdata.push(xx);
    }

    if (
      Xvalue1 == "value1" &&
      (Xvalue2 == "v1" || Xvalue2 == "v2" || Xvalue2 == "v4")
    ) {
      xdata.push([""]);
      xdata.push(["โครงการวิจัยต่อเนื่อง"]);

      xdata.push([""]);
      xdata.push(header);

      for (var row in dataSourceEx2) {
        const xx = [];
        for (var col in dataSourceEx2[row]) {
          xx.push(dataSourceEx2[row][col]);
        }

        xdata.push(xx);
      }
    }

    var wb = xlsx.utils.book_new(),
      ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, "Excel.xlsx");
  };

  const [slcomfig, _slconfig] = useState([
    {
      value: "v1",
      label: "จำนวนโครงการวิจัยทั้งหมด"
    },
    {
      value: "v2",
      label: "จำนวนโครงการวิจัย จำแนกตามแหล่งทุน (ภาครัฐ/ภาคเอกชน/ร่วมทุน)"
    },
    {
      value: "v3",
      label: "จำนวนบริการจำแนกตามลูกค้า"
    },
    {
      value: "v4",
      label: "จำนวนโครงการวิจัย จำแนกตามทิศทางวิจัย"
    },
    {
      value: "v5",
      label: "จำนวนผลงานตีพิมพ์ใน SCOPUS ทั้งหมด"
    },
    {
      value: "v6",
      label: "จำนวนผลงานตีพิมพ์ใน SCOPUS จำแนกตามทิศทางวิจัย"
    },
    {
      value: "v7",
      label: "จำนวนผลงานตีพิมพ์ใน SCOPUS จำแนกตาม Quartile"
    },
    {
      value: "v8",
      label: "จำนวนการอ้างอิงผลงานวิจัย (Citation)",
      disabled: true
    },
    {
      value: "v9",
      label:
        "จำนวนผลงานที่ยื่นจดแจ้งอนุสิทธิบัติ หรือ สิทธิบัตร จำแนกตามทิศทางวิจัย ",
      disabled: true
    },
    {
      value: "v10",
      label: "จำนวนโครงการวิจัยรับใช้สังคม"
    }
  ]);

  const columns1 = [
    {
      title: titlecolumn,
      dataIndex: "htb_list",
      key: "htb_list",
      width: 400,
      align: "center"
    },
    {
      title: "61",
      dataIndex: "y61",
      key: "y61",
      width: 100,
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                if (Xvalue1 == "value1") {
                  if (Xvalue2 == "v1") {
                    let xindex = 0;
                    if (index == 0) {
                      xindex = 2;
                    } else if (index == 1) {
                      xindex = 1;
                    }

                    GoPage("report1", 2018, xindex, text);
                  } else if (Xvalue2 == "v2") {
                    GoPage("report2", 2018, index, text);
                  } else if (Xvalue2 == "v3") {
                    GoPage("report3", 2018, index, text);
                  } else if (Xvalue2 == "v4") {
                    GoPage("report4", 2018, index, text);
                  } else if (Xvalue2 == "v10") {
                    GoPage("report10", 2018, 2, text);
                  } else if (Xvalue2 == "v5") {
                    GoPage("report5", 2561, "All", text);
                  } else if (Xvalue2 == "v6") {
                    GoPage(
                      "report6",
                      2561,
                      record.htb_list + " " + parseInt(index + 1),
                      text
                    );
                  } else if (Xvalue2 == "v7") {
                    if (index == 4) {
                      GoPage("report7", 2561, "N/A", text);
                    } else {
                      GoPage("report7", 2561, "Q" + parseInt(index + 1), text);
                    }
                  }
                }
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "62",
      dataIndex: "y62",
      key: "y62",
      width: 100,
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                if (Xvalue1 == "value1") {
                  if (Xvalue2 == "v1") {
                    let xindex = 0;
                    if (index == 0) {
                      xindex = 2;
                    } else if (index == 1) {
                      xindex = 1;
                    }

                    GoPage("report1", 2019, xindex, text);
                  } else if (Xvalue2 == "v2") {
                    GoPage("report2", 2019, index, text);
                  } else if (Xvalue2 == "v3") {
                    GoPage("report3", 2019, index, text);
                  } else if (Xvalue2 == "v4") {
                    GoPage("report4", 2019, index, text);
                  } else if (Xvalue2 == "v10") {
                    GoPage("report10", 2019, 2, text);
                  } else if (Xvalue2 == "v5") {
                    GoPage("report5", 2562, "All", text);
                  } else if (Xvalue2 == "v6") {
                    GoPage(
                      "report6",
                      2562,
                      record.htb_list + " " + parseInt(index + 1),
                      text
                    );
                  } else if (Xvalue2 == "v7") {
                    if (index == 4) {
                      GoPage("report7", 2562, "N/A", text);
                    } else {
                      GoPage("report7", 2562, "Q" + parseInt(index + 1), text);
                    }
                  }
                }
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "63",
      dataIndex: "y63",
      key: "y63",
      width: 100,
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                if (Xvalue1 == "value1") {
                  if (Xvalue2 == "v1") {
                    let xindex = 0;
                    if (index == 0) {
                      xindex = 2;
                    } else if (index == 1) {
                      xindex = 1;
                    }

                    GoPage("report1", 2020, xindex, text);
                  } else if (Xvalue2 == "v2") {
                    GoPage("report2", 2020, index, text);
                  } else if (Xvalue2 == "v3") {
                    GoPage("report3", 2020, index, text);
                  } else if (Xvalue2 == "v4") {
                    GoPage("report4", 2020, index, text);
                  } else if (Xvalue2 == "v10") {
                    GoPage("report10", 2020, 2, text);
                  } else if (Xvalue2 == "v5") {
                    GoPage("report5", 2563, "All", text);
                  } else if (Xvalue2 == "v6") {
                    GoPage(
                      "report6",
                      2563,
                      record.htb_list + " " + parseInt(index + 1),
                      text
                    );
                  } else if (Xvalue2 == "v7") {
                    if (index == 4) {
                      GoPage("report7", 2563, "N/A", text);
                    } else {
                      GoPage("report7", 2563, "Q" + parseInt(index + 1), text);
                    }
                  }
                }
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "64",
      dataIndex: "y64",
      key: "y64",
      width: 100,
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                if (Xvalue1 == "value1") {
                  if (Xvalue2 == "v1") {
                    let xindex = 0;
                    if (index == 0) {
                      xindex = 2;
                    } else if (index == 1) {
                      xindex = 1;
                    }

                    GoPage("report1", 2021, xindex, text);
                  } else if (Xvalue2 == "v2") {
                    GoPage("report2", 2021, index, text);
                  } else if (Xvalue2 == "v3") {
                    GoPage("report3", 2021, index, text);
                  } else if (Xvalue2 == "v4") {
                    GoPage("report4", 2021, index, text);
                  } else if (Xvalue2 == "v10") {
                    GoPage("report10", 2021, 2, text);
                  } else if (Xvalue2 == "v5") {
                    GoPage("report5", 2564, "All", text);
                  } else if (Xvalue2 == "v6") {
                    GoPage(
                      "report6",
                      2564,
                      record.htb_list + " " + parseInt(index + 1),
                      text
                    );
                  } else if (Xvalue2 == "v7") {
                    if (index == 4) {
                      GoPage("report7", 2564, "N/A", text);
                    } else {
                      GoPage("report7", 2564, "Q" + parseInt(index + 1), text);
                    }
                  }
                }
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "65",
      dataIndex: "y65",
      key: "y65",
      width: 100,
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                if (Xvalue1 == "value1") {
                  if (Xvalue2 == "v1") {
                    let xindex = 0;
                    if (index == 0) {
                      xindex = 2;
                    } else if (index == 1) {
                      xindex = 1;
                    }

                    GoPage("report1", 2022, xindex, text);
                  } else if (Xvalue2 == "v2") {
                    GoPage("report2", 2022, index, text);
                  } else if (Xvalue2 == "v3") {
                    GoPage("report3", 2022, index, text);
                  } else if (Xvalue2 == "v4") {
                    GoPage("report4", 2022, index, text);
                  } else if (Xvalue2 == "v10") {
                    GoPage("report10", 2022, 2, text);
                  } else if (Xvalue2 == "v5") {
                    GoPage("report5", 2565, "All", text);
                  } else if (Xvalue2 == "v6") {
                    GoPage(
                      "report6",
                      2565,
                      record.htb_list + " " + parseInt(index + 1),
                      text
                    );
                  } else if (Xvalue2 == "v7") {
                    if (index == 4) {
                      GoPage("report7", 2565, "N/A", text);
                    } else {
                      GoPage("report7", 2565, "Q" + parseInt(index + 1), text);
                    }
                  }
                }
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "66",
      dataIndex: "y66",
      key: "y66",
      width: 100,
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                if (Xvalue1 == "value1") {
                  if (Xvalue2 == "v1") {
                    let xindex = 0;
                    if (index == 0) {
                      xindex = 2;
                    } else if (index == 1) {
                      xindex = 1;
                    }

                    GoPage("report1", 2023, xindex, text);
                  } else if (Xvalue2 == "v2") {
                    GoPage("report2", 2023, index, text);
                  } else if (Xvalue2 == "v3") {
                    GoPage("report3", 2023, index, text);
                  } else if (Xvalue2 == "v4") {
                    GoPage("report4", 2023, index, text);
                  } else if (Xvalue2 == "v10") {
                    GoPage("report10", 2023, 2, text);
                  } else if (Xvalue2 == "v5") {
                    GoPage("report5", 2566, "All", text);
                  } else if (Xvalue2 == "v6") {
                    GoPage(
                      "report6",
                      2566,
                      record.htb_list + " " + parseInt(index + 1),
                      text
                    );
                  } else if (Xvalue2 == "v7") {
                    if (index == 4) {
                      GoPage("report7", 2566, "N/A", text);
                    } else {
                      GoPage("report7", 2566, "Q" + parseInt(index + 1), text);
                    }
                  }
                }
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "67",
      dataIndex: "y67",
      key: "y67",
      width: 100,
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                if (Xvalue1 == "value1") {
                  if (Xvalue2 == "v1") {
                    let xindex = 0;
                    if (index == 0) {
                      xindex = 2;
                    } else if (index == 1) {
                      xindex = 1;
                    }

                    GoPage("report1", 2024, xindex, text);
                  } else if (Xvalue2 == "v2") {
                    GoPage("report2", 2024, index, text);
                  } else if (Xvalue2 == "v3") {
                    GoPage("report3", 2024, index, text);
                  } else if (Xvalue2 == "v4") {
                    GoPage("report4", 2024, index, text);
                  } else if (Xvalue2 == "v10") {
                    GoPage("report10", 2024, 2, text);
                  } else if (Xvalue2 == "v5") {
                    GoPage("report5", 2567, "All", text);
                  } else if (Xvalue2 == "v6") {
                    GoPage(
                      "report6",
                      2567,
                      record.htb_list + " " + parseInt(index + 1),
                      text
                    );
                  } else if (Xvalue2 == "v7") {
                    if (index == 4) {
                      GoPage("report7", 2567, "N/A", text);
                    } else {
                      GoPage("report7", 2567, "Q" + parseInt(index + 1), text);
                    }
                  }
                }
              }}
            >
              {text}
            </a>
          )
        };
      }
    }
  ];

  const columns2 = [
    {
      title: titlecolumn,
      dataIndex: "htb_list",
      key: "htb_list",
      width: 400,
      align: "center"
    },
    {
      title: "61",
      dataIndex: "y61",
      key: "y61",
      width: 100,
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                if (Xvalue1 == "value1") {
                  if (Xvalue2 == "v1") {
                    let xindex = 0;
                    if (index == 0) {
                      xindex = 2;
                    } else if (index == 1) {
                      xindex = 1;
                    }

                    GoPage("report1_1", 2018, xindex, text);
                  } else if (Xvalue2 == "v2") {
                    GoPage("report2_1", 2018, index, text);
                  } else if (Xvalue2 == "v4") {
                    GoPage("report4_1", 2018, index, text);
                  }
                }
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "62",
      dataIndex: "y62",
      key: "y62",
      width: 100,
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                if (Xvalue1 == "value1") {
                  if (Xvalue2 == "v1") {
                    let xindex = 0;
                    if (index == 0) {
                      xindex = 2;
                    } else if (index == 1) {
                      xindex = 1;
                    }

                    GoPage("report1_1", 2019, xindex, text);
                  } else if (Xvalue2 == "v2") {
                    GoPage("report2_1", 2019, index, text);
                  } else if (Xvalue2 == "v4") {
                    GoPage("report4_1", 2019, index, text);
                  }
                }
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "63",
      dataIndex: "y63",
      key: "y63",
      width: 100,
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                if (Xvalue1 == "value1") {
                  if (Xvalue2 == "v1") {
                    let xindex = 0;
                    if (index == 0) {
                      xindex = 2;
                    } else if (index == 1) {
                      xindex = 1;
                    }

                    GoPage("report1_1", 2020, xindex, text);
                  } else if (Xvalue2 == "v2") {
                    GoPage("report2_1", 2020, index, text);
                  } else if (Xvalue2 == "v4") {
                    GoPage("report4_1", 2020, index, text);
                  }
                }
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "64",
      dataIndex: "y64",
      key: "y64",
      width: 100,
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                if (Xvalue1 == "value1") {
                  if (Xvalue2 == "v1") {
                    let xindex = 0;
                    if (index == 0) {
                      xindex = 2;
                    } else if (index == 1) {
                      xindex = 1;
                    }

                    GoPage("report1_1", 2021, xindex, text);
                  } else if (Xvalue2 == "v2") {
                    GoPage("report2_1", 2021, index, text);
                  } else if (Xvalue2 == "v4") {
                    GoPage("report4_1", 2021, index, text);
                  }
                }
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "65",
      dataIndex: "y65",
      key: "y65",
      width: 100,
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                if (Xvalue1 == "value1") {
                  if (Xvalue2 == "v1") {
                    let xindex = 0;
                    if (index == 0) {
                      xindex = 2;
                    } else if (index == 1) {
                      xindex = 1;
                    }

                    GoPage("report1_1", 2022, xindex, text);
                  } else if (Xvalue2 == "v2") {
                    GoPage("report2_1", 2022, index, text);
                  } else if (Xvalue2 == "v4") {
                    GoPage("report4_1", 2022, index, text);
                  }
                }
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "66",
      dataIndex: "y66",
      key: "y66",
      width: 100,
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                if (Xvalue1 == "value1") {
                  if (Xvalue2 == "v1") {
                    let xindex = 0;
                    if (index == 0) {
                      xindex = 2;
                    } else if (index == 1) {
                      xindex = 1;
                    }

                    GoPage("report1_1", 2023, xindex, text);
                  } else if (Xvalue2 == "v2") {
                    GoPage("report2_1", 2023, index, text);
                  } else if (Xvalue2 == "v4") {
                    GoPage("report4_1", 2023, index, text);
                  }
                }
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "67",
      dataIndex: "y67",
      key: "y67",
      width: 100,
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                if (Xvalue1 == "value1") {
                  if (Xvalue2 == "v1") {
                    let xindex = 0;
                    if (index == 0) {
                      xindex = 2;
                    } else if (index == 1) {
                      xindex = 1;
                    }

                    GoPage("report1_1", 2024, xindex, text);
                  } else if (Xvalue2 == "v2") {
                    GoPage("report2_1", 2024, index, text);
                  } else if (Xvalue2 == "v4") {
                    GoPage("report4_1", 2024, index, text);
                  }
                }
              }}
            >
              {text}
            </a>
          )
        };
      }
    }
  ];

  const GoPage = (page, year, idx, num) => {
    if (num == 0) {
      swal("แจ้งเตือน!", "ไม่มีข้อมูลตามเงื่อนไขที่เลือก!", "warning");
    } else {
      if (page == "report1" || page == "report1_1" || page == "report10") {
        window.open(
          "/Research_main?page=" + page + "&year=" + year + "&type=" + idx,
          "_blank"
        );
      } else if (
        page == "report2" ||
        page == "report2_1" ||
        page == "report3" ||
        page == "report4" ||
        page == "report4_1"
      ) {
        window.open(
          "/Research_main?page=" +
            page +
            "&year=" +
            year +
            "&type=" +
            parseInt(idx + 1),
          "_blank"
        );
      } else if (page == "report5" || page == "report6" || page == "report7") {
        let xidx;

        if (page == "report6") {
          if (idx.slice(-1) == 3) {
            xidx = "จำนวนผลงานตีพิมพ์ใน SCOPUS (ด้าน EH) " + idx.slice(-1);
          } else if (idx.slice(-1) == 4) {
            xidx = "จำนวนผลงานตีพิมพ์ใน SCOPUS (ด้าน BI) " + idx.slice(-1);
          } else if (idx.slice(-1) == 5) {
            xidx = "จำนวนผลงานตีพิมพ์ใน SCOPUS (ด้าน HIV) " + idx.slice(-1);
          } else if (idx.slice(-1) == 6) {
            xidx = "จำนวนผลงานตีพิมพ์ใน SCOPUS (ด้าน etc.) " + idx.slice(-1);
          } else {
            xidx = idx;
          }
        } else {
          xidx = idx;
        }

        window.open(
          "/Research_scopus_list?year=" +
            year +
            "&head=" +
            xidx +
            "&count=" +
            num,
          "_blank"
        );
      }
    }
  };

  const handleChange = value => {
    _Xvalue1(value);
    //  console.log(`selected ${value}`);
    if (value == "value1") {
      _slconfig([
        {
          value: "v1",
          label: "จำนวนโครงการวิจัยทั้งหมด"
        },
        {
          value: "v2",
          label: "จำนวนโครงการวิจัย จำแนกตามแหล่งทุน (ภาครัฐ/ภาคเอกชน/ร่วมทุน)"
        },
        {
          value: "v3",
          label: "จำนวนบริการจำแนกตามลูกค้า"
        },
        {
          value: "v4",
          label: "จำนวนโครงการวิจัย จำแนกตามทิศทางวิจัย"
        },
        {
          value: "v5",
          label: "จำนวนผลงานตีพิมพ์ใน SCOPUS ทั้งหมด"
        },
        {
          value: "v6",
          label: "จำนวนผลงานตีพิมพ์ใน SCOPUS จำแนกตามทิศทางวิจัย"
        },
        {
          value: "v7",
          label: "จำนวนผลงานตีพิมพ์ใน SCOPUS จำแนกตาม Quartile"
        },
        {
          value: "v8",
          label: "จำนวนการอ้างอิงผลงานวิจัย (Citation)",
          disabled: true
        },
        {
          value: "v9",
          label:
            "จำนวนผลงานที่ยื่นจดแจ้งอนุสิทธิบัติ หรือ สิทธิบัตร จำแนกตามทิศทางวิจัย ",
          disabled: true
        },
        {
          value: "v10",
          label: "จำนวนโครงการวิจัยรับใช้สังคม"
        }
      ]);
    } else if (value == "value2") {
      _slconfig([
        {
          value: "v1",
          label: "ร้อยละของข้อเสนอโครงการที่ได้รับทุน"
        },
        {
          value: "v2",
          label: "ร้อยละของโครงการวิจัยที่ได้รับทุนจากแหล่งทุนต่างประเทศ"
        },
        {
          value: "v3",
          label:
            "ร้อยละของโครงการวิจัยที่ได้รับทุนจากแหล่งทุนในประเทศ (นอก มช.)"
        },
        {
          value: "v4",
          label: "อัตราส่วนของผลงานตีพิมพ์ต่อโครงการวิจัย"
        },
        {
          value: "v5",
          label: "ร้อยละของผลงานวิจัยที่เผยแพร่ใน SCOPUS Q1"
        },
        {
          value: "v6",
          label: "ร้อยละของผลงานวิจัยที่เผยแพร่ใน SCOPUS Q1+Q2"
        },
        {
          value: "v7",
          label: "ร้อยละของ First/Corresponding author"
        },
        {
          value: "v8",
          label: "อัตราส่วนของ Citation (Scopus) ต่อนักวิจัย"
        }
      ]);
    }
  };

  const handleChange2 = (value, select) => {
    // console.log(value,select);

    _dataSource1([]);
    _dataSource2([]);

    _xsum61(0);
    _xsum62(0);
    _xsum63(0);
    _xsum64(0);
    _xsum65(0);
    _xsum66(0);
    _xsum67(0);

    _xsum61_2(0);
    _xsum62_2(0);
    _xsum63_2(0);
    _xsum64_2(0);
    _xsum65_2(0);
    _xsum66_2(0);
    _xsum67_2(0);

    _Xvalue2(value);
    _titleEx(select.label);

    if (Xvalue1 == "value1") {
      if (value == "v3") {
        _titlecolumn("ประเภทของลูกค้า");
      } else {
        _titlecolumn("รายการข้อมูล");
      }

      if (value == "v1") {
        _xsumary("จำนวนโครงการวิจัยรวมทั้งหมด *");
      } else if (value == "v2") {
        _xsumary("รวมทั้งหมด");
      } else if (value == "v3") {
        _xsumary("รวมทั้งหมด");
      } else if (value == "v4") {
        _xsumary("รวมทั้งหมด");
      } else if (value == "v5") {
        _xsumary("เป้าหมาย ");
      } else if (value == "v6") {
        _xsumary("รวมทั้งหมด");
      } else if (value == "v7") {
        _xsumary("รวมทั้งหมด");
      } else if (value == "v10") {
        _xsumary("เป้าหมาย = 3");
      }

      bSearch(Xvalue1, value);
    }
  };

  return (
    <React.Fragment>
      <StyledForm />

      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={23}>
            <PageHeader
              className="site-page-header"
              title="ผลลัพธ์ด้านผลิตภัณฑ์และกระบวนการ"
            />
            <Divider />

            <Row justify="center">
              <Col span={23}>
                <Card
                  style={{
                    borderRadius: "15px",
                    overflow: "hidden"
                  }}
                >
                  <Form form={form1}>
                    <Space wrap>
                      <Form.Item label="" name="sl1">
                        <Select
                          defaultValue="value1"
                          style={{ width: 320 }}
                          onChange={handleChange}
                          options={[
                            {
                              value: "value1",
                              label:
                                "ผลลัพธ์ด้านผลิตภัณฑ์และบริการที่มุ่งเน้นลูกค้า"
                            },
                            {
                              value: "value2",
                              label: "ผลลัพธ์ด้านประสิทธิผลของกระบวนการทำงาน",
                              disabled: true
                            }
                          ]}
                        />
                      </Form.Item>

                      <Form.Item label="" name="sl2">
                        <Select
                          defaultValue="v1"
                          style={{ width: 450 }}
                          onChange={handleChange2}
                          options={slcomfig}
                        />
                      </Form.Item>
                    </Space>
                  </Form>
                  <br />
                  <Row>
                    <Space>
                      <Button
                        ghost
                        type="primary"
                        onClick={() => bSearch(Xvalue1, Xvalue2)}
                        hidden
                      >
                        <SearchOutlined /> แสดงข้อมูล
                      </Button>

                      <Button ghost type="danger" onClick={bClear}>
                        <ClearOutlined /> เคลียร์
                      </Button>

                      <Button ghost type="success" onClick={bExcel}>
                        <FileExcelOutlined /> Excel
                      </Button>
                    </Space>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        <br />
        <br />

        <Row justify="center" style={{ marginBottom: 10 }}>
          <Col span={23}>
            {Xvalue1 == "value1" &&
            (Xvalue2 == "v1" || Xvalue2 == "v2" || Xvalue2 == "v4")
              ? <p style={{ fontSize: "18px" }}>โครงการวิจัยใหม่</p>
              : <div />}

            <Table
              bordered
              scroll={{ x: "max-content" }}
              dataSource={dataSource1}
              columns={columns1}
              size="small"
              pagination={false}
              summary={() =>
                <Table.Summary fixed>
                  <Table.Summary.Row
                    style={{
                      textAlign: "center",
                      backgroundColor: "#b5f5ec"
                    }}
                  >
                    <Table.Summary.Cell>
                      {xsumary}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <a
                        onClick={() => {
                          if (Xvalue1 == "value1") {
                            if (Xvalue2 == "v1") {
                              GoPage("report1", 2018, 3, xsum61);
                            } else if (Xvalue2 == "v2") {
                              GoPage("report2", 2018, 3, xsum61);
                            } else if (Xvalue2 == "v3") {
                              GoPage("report3", 2018, 3, xsum61);
                            } else if (Xvalue2 == "v4") {
                              GoPage("report4", 2018, 6, xsum61);
                            } else if (Xvalue2 == "v6") {
                              GoPage("report6", 2561, "รวมทั้งหมด 0", xsum61);
                            } else if (Xvalue2 == "v7") {
                              GoPage("report7", 2561, "All", xsum61);
                            }
                          }
                        }}
                      >
                        {xsum61}
                      </a>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <a
                        onClick={() => {
                          if (Xvalue1 == "value1") {
                            if (Xvalue2 == "v1") {
                              GoPage("report1", 2019, 3, xsum62);
                            } else if (Xvalue2 == "v2") {
                              GoPage("report2", 2019, 3, xsum62);
                            } else if (Xvalue2 == "v3") {
                              GoPage("report3", 2019, 3, xsum62);
                            } else if (Xvalue2 == "v4") {
                              GoPage("report4", 2019, 6, xsum62);
                            } else if (Xvalue2 == "v6") {
                              GoPage("report6", 2562, "รวมทั้งหมด 0", xsum62);
                            } else if (Xvalue2 == "v7") {
                              GoPage("report7", 2562, "All", xsum62);
                            }
                          }
                        }}
                      >
                        {xsum62}
                      </a>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <a
                        onClick={() => {
                          if (Xvalue1 == "value1") {
                            if (Xvalue2 == "v1") {
                              GoPage("report1", 2020, 3, xsum63);
                            } else if (Xvalue2 == "v2") {
                              GoPage("report2", 2020, 3, xsum63);
                            } else if (Xvalue2 == "v3") {
                              GoPage("report3", 2020, 3, xsum63);
                            } else if (Xvalue2 == "v4") {
                              GoPage("report4", 2020, 6, xsum63);
                            } else if (Xvalue2 == "v6") {
                              GoPage("report6", 2563, "รวมทั้งหมด 0", xsum63);
                            } else if (Xvalue2 == "v7") {
                              GoPage("report7", 2563, "All", xsum63);
                            }
                          }
                        }}
                      >
                        {xsum63}
                      </a>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <a
                        onClick={() => {
                          if (Xvalue1 == "value1") {
                            if (Xvalue2 == "v1") {
                              GoPage("report1", 2021, 3, xsum64);
                            } else if (Xvalue2 == "v2") {
                              GoPage("report2", 2021, 3, xsum64);
                            } else if (Xvalue2 == "v3") {
                              GoPage("report3", 2021, 3, xsum64);
                            } else if (Xvalue2 == "v4") {
                              GoPage("report4", 2021, 6, xsum64);
                            } else if (Xvalue2 == "v6") {
                              GoPage("report6", 2564, "รวมทั้งหมด 0", xsum64);
                            } else if (Xvalue2 == "v7") {
                              GoPage("report7", 2564, "All", xsum64);
                            }
                          }
                        }}
                      >
                        {xsum64}
                      </a>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <a
                        onClick={() => {
                          if (Xvalue1 == "value1") {
                            if (Xvalue2 == "v1") {
                              GoPage("report1", 2022, 3, xsum65);
                            } else if (Xvalue2 == "v2") {
                              GoPage("report2", 2022, 3, xsum65);
                            } else if (Xvalue2 == "v3") {
                              GoPage("report3", 2022, 3, xsum65);
                            } else if (Xvalue2 == "v4") {
                              GoPage("report4", 2022, 6, xsum65);
                            } else if (Xvalue2 == "v6") {
                              GoPage("report6", 2565, "รวมทั้งหมด 0", xsum65);
                            } else if (Xvalue2 == "v7") {
                              GoPage("report7", 2565, "All", xsum65);
                            }
                          }
                        }}
                      >
                        {xsum65}
                      </a>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <a
                        onClick={() => {
                          if (Xvalue1 == "value1") {
                            if (Xvalue2 == "v1") {
                              GoPage("report1", 2023, 3, xsum66);
                            } else if (Xvalue2 == "v2") {
                              GoPage("report2", 2023, 3, xsum66);
                            } else if (Xvalue2 == "v3") {
                              GoPage("report3", 2023, 3, xsum66);
                            } else if (Xvalue2 == "v4") {
                              GoPage("report4", 2023, 6, xsum66);
                            } else if (Xvalue2 == "v6") {
                              GoPage("report6", 2566, "รวมทั้งหมด 0", xsum66);
                            } else if (Xvalue2 == "v7") {
                              GoPage("report7", 2566, "All", xsum66);
                            }
                          }
                        }}
                      >
                        {xsum66}
                      </a>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <a
                        onClick={() => {
                          if (Xvalue1 == "value1") {
                            if (Xvalue2 == "v1") {
                              GoPage("report1", 2024, 3, xsum67);
                            } else if (Xvalue2 == "v2") {
                              GoPage("report2", 2024, 3, xsum67);
                            } else if (Xvalue2 == "v3") {
                              GoPage("report3", 2024, 3, xsum67);
                            } else if (Xvalue2 == "v4") {
                              GoPage("report4", 2024, 6, xsum67);
                            } else if (Xvalue2 == "v6") {
                              GoPage("report6", 2567, "รวมทั้งหมด 0", xsum67);
                            } else if (Xvalue2 == "v7") {
                              GoPage("report7", 2567, "All", xsum67);
                            }
                          }
                        }}
                      >
                        {xsum67}
                      </a>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>}
            />

            {Xvalue1 == "value1" && Xvalue2 == "v1"
              ? <p style={{ fontSize: "15px", float: "right" }}>
                  <b>
                    * จำนวนโครงการวิจัยบางโครงการได้รับทุนวิจัยทั้งต่างประเทศ
                    และไนประเทศ
                  </b>
                </p>
              : <div />}

            {Xvalue1 == "value1" &&
            (Xvalue2 == "v1" || Xvalue2 == "v2" || Xvalue2 == "v4")
              ? <div>
                  <br />
                  <p style={{ fontSize: "18px" }}>โครงการวิจัยต่อเนื่อง</p>

                  <Table
                    bordered
                    scroll={{ x: "max-content" }}
                    dataSource={dataSource2}
                    columns={columns2}
                    size="small"
                    pagination={false}
                    summary={() =>
                      <Table.Summary fixed>
                        <Table.Summary.Row
                          style={{
                            textAlign: "center",
                            backgroundColor: "#b5f5ec"
                          }}
                        >
                          <Table.Summary.Cell>
                            {xsumary}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <a
                              onClick={() => {
                                if (Xvalue1 == "value1") {
                                  if (Xvalue2 == "v1") {
                                    GoPage("report1_1", 2018, 3, xsum61_2);
                                  } else if (Xvalue2 == "v2") {
                                    GoPage("report2_1", 2018, 3, xsum61_2);
                                  } else if (Xvalue2 == "v4") {
                                    GoPage("report4_1", 2018, 6, xsum61_2);
                                  }
                                }
                              }}
                            >
                              {xsum61_2}
                            </a>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <a
                              onClick={() => {
                                if (Xvalue1 == "value1") {
                                  if (Xvalue2 == "v1") {
                                    GoPage("report1_1", 2019, 3, xsum62_2);
                                  } else if (Xvalue2 == "v2") {
                                    GoPage("report2_1", 2019, 3, xsum62_2);
                                  } else if (Xvalue2 == "v4") {
                                    GoPage("report4_1", 2019, 6, xsum62_2);
                                  }
                                }
                              }}
                            >
                              {xsum62_2}
                            </a>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <a
                              onClick={() => {
                                if (Xvalue1 == "value1") {
                                  if (Xvalue2 == "v1") {
                                    GoPage("report1_1", 2020, 3, xsum63_2);
                                  } else if (Xvalue2 == "v2") {
                                    GoPage("report2_1", 2020, 3, xsum63_2);
                                  } else if (Xvalue2 == "v4") {
                                    GoPage("report4_1", 2020, 6, xsum63_2);
                                  }
                                }
                              }}
                            >
                              {xsum63_2}
                            </a>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <a
                              onClick={() => {
                                if (Xvalue1 == "value1") {
                                  if (Xvalue2 == "v1") {
                                    GoPage("report1_1", 2021, 3, xsum64_2);
                                  } else if (Xvalue2 == "v2") {
                                    GoPage("report2_1", 2021, 3, xsum64_2);
                                  } else if (Xvalue2 == "v4") {
                                    GoPage("report4_1", 2021, 6, xsum64_2);
                                  }
                                }
                              }}
                            >
                              {xsum64_2}
                            </a>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <a
                              onClick={() => {
                                if (Xvalue1 == "value1") {
                                  if (Xvalue2 == "v1") {
                                    GoPage("report1_1", 2022, 3, xsum65_2);
                                  } else if (Xvalue2 == "v2") {
                                    GoPage("report2_1", 2022, 3, xsum65_2);
                                  } else if (Xvalue2 == "v4") {
                                    GoPage("report4_1", 2022, 6, xsum65_2);
                                  }
                                }
                              }}
                            >
                              {xsum65_2}
                            </a>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <a
                              onClick={() => {
                                if (Xvalue1 == "value1") {
                                  if (Xvalue2 == "v1") {
                                    GoPage("report1_1", 2023, 3, xsum66_2);
                                  } else if (Xvalue2 == "v2") {
                                    GoPage("report2_1", 2023, 3, xsum66_2);
                                  } else if (Xvalue2 == "v4") {
                                    GoPage("report4_1", 2023, 6, xsum66_2);
                                  }
                                }
                              }}
                            >
                              {xsum66_2}
                            </a>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <a
                              onClick={() => {
                                if (Xvalue1 == "value1") {
                                  if (Xvalue2 == "v1") {
                                    GoPage("report1_1", 2024, 3, xsum67_2);
                                  } else if (Xvalue2 == "v2") {
                                    GoPage("report2_1", 2024, 3, xsum67_2);
                                  } else if (Xvalue2 == "v4") {
                                    GoPage("report4_1", 2024, 6, xsum67_2);
                                  }
                                }
                              }}
                            >
                              {xsum67_2}
                            </a>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </Table.Summary>}
                  />
                </div>
              : <div />}

            {Xvalue1 == "value1" && Xvalue2 == "v1"
              ? <p style={{ fontSize: "15px", float: "right" }}>
                  <b>
                    * จำนวนโครงการวิจัยบางโครงการได้รับทุนวิจัยทั้งต่างประเทศ
                    และไนประเทศ
                  </b>
                </p>
              : <div />}
          </Col>
        </Row>
      </Content>

      <div style={{ height: "280px" }} />

      <Footer_last />
    </React.Fragment>
  );
};

export default Research_report3;
