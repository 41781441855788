import "antd/dist/antd.min.css";
import axios from "axios";
import React, { useState, useEffect } from "react";

import {
  SaveOutlined,
  MinusOutlined,
  PlusOutlined,
  DeleteOutlined,
  PaperClipOutlined,
  CheckOutlined,
  EditOutlined,
  FileExcelOutlined
} from "@ant-design/icons";
import { createGlobalStyle } from "styled-components";

import {
  Row,
  Col,
  Form,
  Layout,
  Divider,
  PageHeader,
  Descriptions,
  Input,
  Select,
  Space,
  Button,
  Card,
  Typography,
  Table,
  InputNumber,
  Tabs,
  ConfigProvider
} from "antd";
import moment from "moment";
import "moment/locale/th";
import locale from "antd/es/date-picker/locale/th_TH";
import * as xlsx from "xlsx";

import swal from "sweetalert";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import thTH from "antd/lib/locale/th_TH";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

const { Content } = Layout;
const { Option } = Select;
const { Text } = Typography;
const { TabPane } = Tabs;

const StyledForm = createGlobalStyle`.ant-form-item {
  margin-bottom: 5px;
}
`;

const configuredLocale = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    dateFormat: "DD MM BBBB", // DD MM BBBB
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker.lang,
      dateFormat: "DD MM BBBB", // DD MM BBBB
      dateTimeFormat: "DD MM BBBB HH:mm:ss",
      yearFormat: "BBBB"
    }
  }
};

dayjs.locale("th");
dayjs.extend(buddhistEra);

const Research_Edit = () => {
  const [form1] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [xStatus, _xStatus] = useState("");
  const [xPaper, _xPaper] = useState("");
  const [xReceipt, _xReceipt] = useState("");

  const [xStatusValue, _xStatusValue] = useState("0");
  const [xPaperValue, _xPaperValue] = useState("0");
  const [xReceiptValue, _xReceiptValue] = useState("0");

  const [xAgency, _xAgency] = useState("");
  const [xAgencyValue, _xAgencyValue] = useState("0");
  const [xAgencyName, _xAgencyName] = useState("");

  const [xAgencyValue2, _xAgencyValue2] = useState("0");
  const [xAgencyName2, _xAgencyName2] = useState("");

  const [xResearch, _xResearch] = useState("");
  const [xResearchP, _xResearchP] = useState("");
  const [xResearchValue, _xResearchValue] = useState("0");
  const [xResearchPValue, _xResearchPValue] = useState("0");

  const [xResearchName, _xResearchName] = useState("");
  const [xRPName, _xRPName] = useState("");

  const [xid, _xid] = useState(1);
  const [xidE, _xidE] = useState();
  const [xBudget, _xBudget] = useState(0);
  const [xBudget2, _xBudget2] = useState(0);

  const [xidR, _xidR] = useState(1);

  const [dataSource1, _dataSource1] = useState();
  const [dataSource2, _dataSource2] = useState();
  const [dataSource3, _dataSource3] = useState();

  const [show1, _show1] = useState(false);
  const [show2, _show2] = useState(false);
  const [show1edit, _show1edit] = useState(false);

  const [xcmumis_id, _xcmumis_id] = useState("");

  const [xSumAmount, _xSumAmount] = useState(0);
  const [xSumAmount2, _xSumAmount2] = useState(0);
  const [xSumAmount3, _xSumAmount3] = useState(0);

  const [xSumDirect, _xSumDirect] = useState(0);
  const [xSumF_A, _xSumF_A] = useState(0);
  const [xSumOver70, _xSumOver70] = useState(0);
  const [xSumOver30, _xSumOver30] = useState(0);
  const [xSumTo_cmu, _xSumTo_cmu] = useState(0);

  const [dataContract, _dataContract] = useState();
  const [dataContract2, _dataContract2] = useState();

  const [dataFile1, _dataFile1] = useState();
  const [dataFile2, _dataFile2] = useState();
  const [dataFile3, _dataFile3] = useState();

  const [activeKeyshow, _activeKeyshow] = useState("1");

  const DatePicker = generatePicker(dayjsGenerateConfig);

  const [pageTable, _pageTable] = useState(1);
  const [pageSizeTable, _pageSizeTable] = useState(5);

  const [xsummary, _xsummary] = useState(0);
  const [xcrc, _xcrc] = useState("");

  const [xoagency, _xoagency] = useState("");
  const [xobudget, _xobudget] = useState("");

  const [dataTocmu, _dataTocmu] = useState();
  const [dataSourceEx, _dataSourceEx] = useState();
  const [xshort_name, _xshort_name] = useState("");

  const [selectionType, setSelectionType] = useState("radio");
  const [dataselectradio, _dataselectradio] = useState();

  const [xType, _xType] = useState("");
  const [xTypeValue, _xTypeValue] = useState("0");

  useEffect(() => {
    const token = localStorage.getItem("token");

    //console.log(token);

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");

          //ค้นหาสถานะโครงการ
          axios
            .get(xURL + "Project_status")
            .then(result => {
              //console.log(result.data);

              _xStatus(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหายุทธศาสตร์ของมหาวิทยาลัย
          axios
            .get(xURL + "ppt_cmu")
            .then(result => {
              //console.log(result.data);

              _xPaper(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหาแหล่งทุน
          axios
            .get(xURL + "agency")
            .then(result => {
              //console.log(result.data);

              _xAgency(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหานักวิจัย
          axios
            .get(xURL + "research")
            .then(result => {
              //console.log(result.data);

              _xResearch(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหาตำแหน่งนักวิจัย
          axios
            .get(xURL + "researchP")
            .then(result => {
              //console.log(result.data);

              _xResearchP(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหาใบเสร็จแหล่งทุน
          axios
            .get(xURL + "Receiptname")
            .then(result => {
              //console.log(result.data);

              _xReceipt(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหาประเภทโครงการ
          axios
            .get(xURL + "ptype")
            .then(result => {
              //console.log(result.data);

              _xType(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          /////////////////////////////////////////////////////////////////////////////

          form1.resetFields();

          var url = new URL(window.location.href);

          _xStatusValue("0");
          _xPaperValue("0");
          _xReceiptValue("0");
          _xTypeValue("0");

          _xSumAmount(0);
          _xSumAmount2(0);
          _xSumAmount3(0);

          _xSumDirect(0);
          _xSumF_A(0);
          _xSumOver70(0);
          _xSumOver30(0);
          _xSumTo_cmu(0);

          _dataselectradio();

          _show1(false);
          form1.setFieldsValue({ slagency: "เลือกรายการ", edbudget: "" });
          _xAgencyValue(0);
          _xBudget(0);

          _show1edit(false);

          _show2(false);
          form1.setFieldsValue({
            slresearchname: "เลือกรายการ",
            slresearchposition: "เลือกรายการ"
          });
          _xResearchValue(0);
          _xResearchPValue(0);

          axios
            .post(xURL + "Findproject", {
              cmumis_id: url.searchParams.get("cmumis_id")
            })
            .then(result => {
              /*
              let xsdate = moment(
                moment(result.data[0].start_date.substr(0, 10))
                  .add(1, "day")
                  .format("L"),
                "DD/MM/YYYY"
              )._i;

              let xedate = moment(
                moment(result.data[0].end_date.substr(0, 10))
                  .add(1, "day")
                  .format("L"),
                "DD/MM/YYYY"
              )._i;*/

              let xsdate = dayjs(result.data[0].start_date);
              let xedate = dayjs(result.data[0].end_date);

              let xpub_type_cmu_name = "",
                xpub_type_short = "";

              if (result.data[0].pub_type_cmu_name != null) {
                xpub_type_cmu_name = result.data[0].pub_type_cmu_name;
              }
              if (result.data[0].pub_type_short != null) {
                xpub_type_short = result.data[0].pub_type_short;
              }

              form1.setFieldsValue({
                edcmumis_id: result.data[0].cmumis_id,
                edname_th: result.data[0].name_thai,
                edname_eng: result.data[0].name_eng,
                edshort_name: result.data[0].short_name,
                slppt_cmu: xpub_type_cmu_name + " " + xpub_type_short,
                sdate: xsdate,
                edate: xedate,
                slstatus2: result.data[0].status,
                slreceipt: result.data[0].receipt,
                sltype:
                  result.data[0].project_type_id +
                  " : " +
                  result.data[0].project_type_name
              });

              _xcmumis_id(url.searchParams.get("cmumis_id"));
              _xshort_name(result.data[0].short_name);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          _xid(0);
          _xidR(0);
          _dataSource1([]);
          _dataSource2([]);
          _dataSource3([]);
          _dataTocmu([]);

          GetAgency(url.searchParams.get("cmumis_id"));
          GetResearch(url.searchParams.get("cmumis_id"));
          GetInstallment1(url.searchParams.get("cmumis_id"));

          GetContract(url.searchParams.get("cmumis_id"));

          GetTable(url.searchParams.get("cmumis_id"));
          GetTable2(url.searchParams.get("cmumis_id"));
          GetTable3(url.searchParams.get("cmumis_id"));

          GetToCmu(url.searchParams.get("cmumis_id"));
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const bUpdateclick = () => {
    //console.log(xStatusValue2, xPaperValue, xData.cmumis_id);
    const f = form1.getFieldsValue();

    //console.log(dataSource7, dataSource8);

    if (f.edcmumis_id.length !== 10) {
      swal("แก้ไข ไม่ได้!", "คีย์ รหัส CMU MIS ไม่ถูกต้อง!", "warning");
    } else if (!f.sdate) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ วันที่เริ่มต้นโครงการ!", "warning");
    } else if (!f.edate) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ วันที่สิ้นสุดโครงการ!", "warning");
    } else {
      swal({
        title: "ยืนยัน แก้ไขรายละเอียดโครงการวิจัย",
        text: "รหัส CMU MIS " + xcmumis_id,
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          updatedata();
        }
      });
    }
  };

  const updatedata = () => {
    const f = form1.getFieldsValue();

    axios
      .post(xURL + "update", {
        cmumis_id: xcmumis_id,
        ncmumis_id: f.edcmumis_id,
        xcheck: 1
      })
      .then(result => {})
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    axios
      .post(xURL + "update", {
        cmumis_id: xcmumis_id,
        name_th: f.edname_th,
        xcheck: 4
      })
      .then(result => {})
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    axios
      .post(xURL + "update", {
        cmumis_id: xcmumis_id,
        name_eng: f.edname_eng,
        xcheck: 5
      })
      .then(result => {})
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    axios
      .post(xURL + "update", {
        cmumis_id: xcmumis_id,
        short_name: f.edshort_name,
        xcheck: 6
      })
      .then(result => {})
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    axios
      .post(xURL + "update", {
        cmumis_id: xcmumis_id,
        start_date: dayjs(f.sdate).format("YYYY-MM-DD"),
        xcheck: 7
      })
      .then(result => {})
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    axios
      .post(xURL + "update", {
        cmumis_id: xcmumis_id,
        end_date: dayjs(f.edate).format("YYYY-MM-DD"),
        xcheck: 8
      })
      .then(result => {})
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    if (xPaperValue !== "0") {
      axios
        .post(xURL + "update", {
          cmumis_id: xcmumis_id,
          pub_type_cmu_id: parseInt(xPaperValue),
          xcheck: 2
        })
        .then(result => {})
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    }

    if (xStatusValue !== "0") {
      axios
        .post(xURL + "update", {
          cmumis_id: xcmumis_id,
          status_id: xStatusValue,
          xcheck: 3
        })
        .then(result => {})
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    }

    if (xReceiptValue !== "0") {
      axios
        .post(xURL + "update", {
          cmumis_id: xcmumis_id,
          receipt_id: xReceiptValue,
          xcheck: 21
        })
        .then(result => {})
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    }

    if (xTypeValue !== "0") {
      axios
        .post(xURL + "update", {
          cmumis_id: xcmumis_id,
          type_id: xTypeValue,
          xcheck: 22
        })
        .then(result => {})
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    }

    swal({
      title: "แจ้งเตือน!",
      text: "บันทึก สำเร็จ",
      icon: "success",
      buttons: false,
      timer: 2000
    }).then(() => {
      window.location.reload();
    });

    //setIsModalVisible3(false);
    //bOkclick();

    //bClearclick();
  };

  const GetAgency = cmumis_id => {
    axios
      .post(xURL + "find_agency", {
        cmumis_id: cmumis_id
      })
      .then(result => {
        //console.log(result);
        const Tb1 = [];
        let xnum = 0,
          xsum = 0;

        result.data.forEach((i, idx) => {
          Tb1.push({
            key: idx,
            c1: "แหล่งทุนที่ " + parseInt(idx + 1),
            c2: i.agency,
            c3: "งบประมาณ",
            c4: (
              <Text style={{ fontWeight: "bold" }}>
                {i.budget.toLocaleString(undefined, {
                  minimumFractionDigits: 2
                })}
              </Text>
            ),
            c5: "บาท",
            agency_id: i.agency_id
          });

          if (i.budget !== "") {
            xsum = parseFloat(xsum) + parseFloat(i.budget);
            xnum = xnum + 1;
          }
        });

        Tb1.push({
          key: xnum - 1,
          c1: "",
          c2: "",
          c3: "รวมงบประมาณ",
          c4: (
            <Text style={{ fontWeight: "bold" }}>
              {xsum.toLocaleString(undefined, {
                minimumFractionDigits: 2
              })}
            </Text>
          ),
          c5: "บาท"
        });

        _xid(xnum + 1);

        _dataSource1(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetResearch = cmumis_id => {
    axios
      .post(xURL + "find_research", {
        cmumis_id: cmumis_id
      })
      .then(result => {
        //console.log(result);
        const Tb1 = [];
        let xnum = 0;

        result.data.forEach((i, idx) => {
          Tb1.push({
            key: idx,
            c1: "นักวิจัยที่ " + parseInt(idx + 1),
            c2: i.researname,
            c3: i.researcher_p_position_name,
            re_staff_id: i.researcher_staff_id,
            //re_p_id: i.researcher_position_id,
            re_pp_id: i.researcher_project_position_id
          });

          xnum = xnum + 1;
        });

        _xidR(xnum + 1);

        _dataSource2(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetInstallment1 = cmumis_id => {
    axios
      .post(xURL + "find_installment1", {
        cmumis_id: cmumis_id,
        order: 1
      })
      .then(result => {
        //console.log(result);
        const Tb1 = [],
          Tb2 = [];

        let xsum = 0,
          xsum2 = 0;

        result.data.forEach((i, idx) => {
          let xmoney_bath = "",
            xdetail = "",
            xremark = "",
            xdate_warn = "",
            xdate_due = "",
            xdate_receive = "",
            xtotal_receive = "";

          if (i.money_bath != null) {
            xmoney_bath = i.money_bath.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.total_receive != null) {
            xtotal_receive = i.total_receive.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.detail != null) {
            xdetail = i.detail;
          }

          if (i.remark != null) {
            xremark = i.remark;
          }

          if (i.tracking_date && i.tracking_date != "0000-00-00") {
            xdate_warn = dayjs(i.tracking_date).format("DD/MM/BBBB");
          }
          if (i.deadline_date && i.deadline_date != "0000-00-00") {
            xdate_due = dayjs(i.deadline_date).format("DD/MM/BBBB");
          }
          if (i.date_receive && i.date_receive != "0000-00-00") {
            xdate_receive = dayjs(i.date_receive).format("DD/MM/BBBB");
          }

          let url_file = "",
            url_fileiv = "";

          if (i.file_transfer) {
            if (i.file_transfer !== "undefined") {
              url_file = `${xURL}file4/${i.file_transfer}`;
            } else {
              url_file = "";
            }
          }

          if (i.file_invoice) {
            if (i.file_invoice !== "undefined") {
              url_fileiv = `${xURL}file3/${i.file_invoice}`;
            } else {
              url_fileiv = "";
            }
          }

          Tb1.push({
            key: idx,
            term: idx + 1,
            amount: xmoney_bath,
            detail: xdetail,
            remark: xremark,
            date_warn: xdate_warn,
            date_due: xdate_due,
            date_receive: xdate_receive,
            total_usd: i.total_usd,
            total_receive: xtotal_receive,
            linkfile: url_file,
            linkfile2: i.file_invoice,
            linkfileiv: url_fileiv
          });

          xsum = xsum + parseFloat(i.money_bath);

          if (i.total_receive) {
            xsum2 = xsum2 + parseFloat(i.total_receive);
          }
        });

        _dataSource3(Tb1);
        _xSumAmount(xsum);
        _xSumAmount2(xsum2);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetContract = cmumis_id => {
    axios
      .post(xURL + "find_mod", {
        cmumis_id: cmumis_id
      })
      .then(result => {
        // console.log(result);
        const Tb1 = [];
        let xsum = 0,
          xname = "";

        result.data.forEach((i, idx) => {
          let xsdate = dayjs(i.start_date).format("DD/MM/BBBB");
          let xedate = dayjs(i.end_date).format("DD/MM/BBBB");

          let url_file = "",
            xbudget = "0";

          url_file = `${xURL}file2/${i.file}`;

          if (i.budget) {
            xbudget = i.budget.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });

            xsum = xsum + i.budget;
          }

          Tb1.push({
            key: idx,
            num: idx + 1,
            grant_no: i.grant_number,
            po: i.po_number,
            Modification: i.mod_no,
            sdate: xsdate,
            edate: xedate,
            budget: xbudget,
            currency: i.currency_name,
            description: i.remark,
            linkfile: url_file
          });

          xname = i.currency_name;
        });

        _xcrc(xname);
        _xsummary(xsum.toLocaleString());
        _xSumAmount3(xsum);

        // _dataMod(Tb1);
        _dataContract2(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    // axios
    //   .post(xURL + "find_contract", {
    //     cmumis_id: cmumis_id
    //   })
    //   .then(result => {
    //     //console.log(result);
    //     const Tb1 = [];

    //     result.data.forEach((i, idx) => {
    //       let xsdate = "",
    //         xedate = "",
    //         xbudget = "";

    //       if (i.start_date != null) {
    //         /*
    //         xsdate = moment(
    //           moment(i.start_date.substr(0, 10)).add(1, "day").format("L"),
    //           "DD/MM/YYYY"
    //         )._i;

    //         xedate = moment(
    //           moment(i.end_date.substr(0, 10)).add(1, "day").format("L"),
    //           "DD/MM/YYYY"
    //         )._i;*/

    //         xsdate = dayjs(i.start_date).format("DD/MM/BBBB");
    //         xedate = dayjs(i.end_date).format("DD/MM/BBBB");

    //         xbudget = i.budget.toLocaleString();
    //       } else {
    //         xsdate = "";
    //         xedate = "";
    //         xbudget = "";
    //       }

    //       Tb1.push({
    //         key: idx,
    //         num: idx + 1,
    //         grant_no: i.grant_number,
    //         po: i.po_number,
    //         description: i.description,
    //         cgrant: i.cmod_no,
    //         sdate: xsdate,
    //         edate: xedate,
    //         budget: xbudget,
    //         money: i.currency
    //       });
    //     });

    //     _dataContract(Tb1);
    //   })
    //   .catch(function(error) {
    //     // handle error
    //     console.log("problem here", error);
    //   });
  };

  const GetTable = cmumis_id => {
    axios
      .post(xURL + "find_file", {
        cmumis_id: cmumis_id,
        zorder: 1
      })
      .then(result => {
        //console.log(result);
        const Tb1 = [];

        result.data.forEach((i, idx) => {
          /*
          let xsdate = moment(i.date.substr(0, 10))
            .add(543, "year")
            .add(1, "day")
            .format("L");*/

          let xsdate = dayjs(i.date).format("DD/MM/BBBB");

          let url_file = "";

          if (xlocation === "localhost") {
            url_file = `${xURL}file1_1/${i.file_name}`;
          } else {
            url_file = `${xURL}file1/${i.file_name}`;
          }

          Tb1.push({
            key: idx,
            num: idx + 1,
            sdate: xsdate,
            iddocget: i.doc_no_receive,
            iddoc: i.doc_no,
            docname: i.name,
            tfrom: i.doc_from,
            tto: i.doc_to,
            remark: i.remark,
            linkfile: url_file
          });
        });

        _dataFile1(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetTable2 = cmumis_id => {
    axios
      .post(xURL + "find_file", {
        cmumis_id: cmumis_id,
        zorder: 2
      })
      .then(result => {
        //console.log(result);
        const Tb1 = [];

        result.data.forEach((i, idx) => {
          /*
          let xsdate = moment(i.date.substr(0, 10))
            .add(543, "year")
            .add(1, "day")
            .format("L");*/

          let xsdate = dayjs(i.date).format("DD/MM/BBBB");

          let url_file = "";

          if (xlocation === "localhost") {
            url_file = `${xURL}file1_1/${i.file_name}`;
          } else {
            url_file = `${xURL}file1/${i.file_name}`;
          }

          Tb1.push({
            key: idx,
            num: idx + 1,
            sdate: xsdate,
            iddocpush: i.doc_no_send,
            docname: i.name,
            tfrom: i.doc_from,
            tto: i.doc_to,
            remark: i.remark,
            linkfile: url_file
          });
        });

        _dataFile2(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetTable3 = cmumis_id => {
    axios
      .post(xURL + "find_file", {
        cmumis_id: cmumis_id,
        zorder: 3
      })
      .then(result => {
        //console.log(result);
        const Tb1 = [];

        result.data.forEach((i, idx) => {
          /*
          let xsdate = moment(i.date.substr(0, 10))
            .add(543, "year")
            .add(1, "day")
            .format("L");*/

          let xsdate = dayjs(i.date).format("DD/MM/BBBB");

          let url_file = "";

          if (xlocation === "localhost") {
            url_file = `${xURL}file1_1/${i.file_name}`;
          } else {
            url_file = `${xURL}file1/${i.file_name}`;
          }

          Tb1.push({
            key: idx,
            num: idx + 1,
            sdate: xsdate,
            iddoc: i.doc_no,
            remark: i.remark,
            linkfile: url_file
          });
        });

        _dataFile3(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const AddMoney = (term, amount, detail) => {
    let xdetail = detail.replace("#", "%23");

    window.open(
      "/Research_transfer?cmumis_id=" +
        xcmumis_id +
        "&term=" +
        term +
        "&amount=" +
        amount +
        "&detail=" +
        xdetail,
      "_blank"
    );
  };

  const showMoney = (term, amount, detail) => {
    let xdetail = detail.replace("#", "%23");

    window.open(
      "/Research_show_transfer?cmumis_id=" +
        xcmumis_id +
        "&term=" +
        term +
        "&amount=" +
        amount +
        "&detail=" +
        xdetail,
      "_blank"
    );
  };

  const GetToCmu = cmumis_id => {
    axios
      .post(xURL + "find_to_cmu", {
        cmumis_id: cmumis_id
      })
      .then(result => {
        const Tb1 = [],
          Tb2 = [];

        let xsum1 = 0,
          xsum2 = 0,
          xsum3 = 0,
          xsum4 = 0,
          xsum5 = 0,
          xsum0 = 0;

        result.data.forEach((i, idx) => {
          let xdate_receive = "",
            xtotal_receive = "",
            xdirect = "",
            xf_a = "",
            xoverhead70 = "",
            xoverhead30 = "",
            xdate_to_cmu = "",
            xmoney_to_cmu = "";

          if (i.total_receive != null) {
            xtotal_receive = i.total_receive.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.date_receive && i.date_receive != "0000-00-00") {
            xdate_receive = dayjs(i.date_receive).format("DD/MM/BBBB");
          }

          if (i.direct != null) {
            xdirect = i.direct.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.fa != null) {
            xf_a = i.fa.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.overhead70 != null) {
            xoverhead70 = i.overhead70.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.overhead30 != null) {
            xoverhead30 = i.overhead30.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.date_to_cmu && i.date_to_cmu != "0000-00-00") {
            xdate_to_cmu = dayjs(i.date_to_cmu).format("DD/MM/BBBB");
          }

          if (i.money_to_cmu != null) {
            xmoney_to_cmu = i.money_to_cmu.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          let url_file_to_cmu = "",
            url_file_invoice = "",
            url_file_transfer = "";

          if (i.file_to_cmu) {
            if (i.file_to_cmu !== "undefined") {
              url_file_to_cmu = `${xURL}file5/${i.file_to_cmu}`;
            } else {
              url_file_to_cmu = "";
            }
          }

          if (i.file_invoice) {
            if (i.file_invoice !== "undefined") {
              url_file_invoice = `${xURL}file3/${i.file_invoice}`;
            } else {
              url_file_invoice = "";
            }
          }

          if (i.file_transfer) {
            if (i.file_transfer !== "undefined") {
              url_file_transfer = `${xURL}file4/${i.file_transfer}`;
            } else {
              url_file_transfer = "";
            }
          }

          if (i.direct) {
            xsum1 = xsum1 + i.direct;
          }
          if (i.fa) {
            xsum2 = xsum2 + i.fa;
          }
          if (i.overhead70) {
            xsum3 = xsum3 + i.overhead70;
          }
          if (i.overhead30) {
            xsum4 = xsum4 + i.overhead30;
          }
          if (i.money_to_cmu) {
            xsum5 = xsum5 + i.money_to_cmu;
          }
          if (i.total_receive) {
            xsum0 = xsum0 + i.total_receive;
          }

          Tb1.push({
            key: idx,
            num: idx + 1,
            detail: i.detail,
            date_receive: xdate_receive,
            total_receive: xtotal_receive,
            direct: xdirect,
            f_a: xf_a,
            overhead70: xoverhead70,
            overhead30: xoverhead30,
            date_to_cmu: xdate_to_cmu,
            money_to_cmu: xmoney_to_cmu,
            file_to_cmu: url_file_to_cmu,
            fileinvoice: url_file_invoice,
            filetransfer: url_file_transfer
          });

          Tb2.push({
            num: idx + 1,
            detail: i.detail,
            date_receive: xdate_receive,
            total_receive: xtotal_receive,
            direct: xdirect,
            f_a: xf_a,
            overhead70: xoverhead70,
            overhead30: xoverhead30,
            date_to_cmu: xdate_to_cmu,
            money_to_cmu: xmoney_to_cmu
          });
        });

        Tb2.push({
          num: "",
          detail: "",
          date_receive: "รวม",
          total_receive: xsum0,
          direct: xsum1,
          f_a: xsum2,
          overhead70: xsum3,
          overhead30: xsum4,
          date_to_cmu: "",
          money_to_cmu: xsum5
        });

        _dataTocmu(Tb1);
        _dataSourceEx(Tb2);

        _xSumDirect(xsum1);
        _xSumF_A(xsum2);
        _xSumOver70(xsum3);
        _xSumOver30(xsum4);
        _xSumTo_cmu(xsum5);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bAddToCmu = () => {
    if (!dataselectradio) {
      swal("เพิ่ม ไม่ได้!", "ยังไม่ได้เลือกรายการในตาราง!", "warning");
    } else {
      // console.log(dataselectradio[0].detail);

      let xdetail = "",
        xdetail2 = "",
        xtype = 0;

      if (dataselectradio[0].detail.split("#")[1]) {
        xdetail = dataselectradio[0].detail.split("#")[0];
        xdetail2 = dataselectradio[0].detail.split("#")[1];
      } else {
        xdetail = dataselectradio[0].detail;
        xdetail2 = "";
      }

      if (dataselectradio[0].money_to_cmu == "") {
        xtype = 1;
      }

      window.open(
        "/Research_input_to_cmu?cmumis_id=" +
          xcmumis_id +
          "&num=" +
          dataselectradio[0].num +
          "&detail=" +
          xdetail +
          "&detail2=" +
          xdetail2 +
          "&type=" +
          xtype,
        "_blank"
      );
    }
  };

  const bExcelTocmu = () => {
    if (dataSourceEx[0].num == "") {
      swal("เปิด Excel ไม่ได้!", "ไม่มีข้อมูล!", "warning");
    } else {
      let xdata = [];
      let header = [
        "ที่",
        "รายละเอียด",
        "วันที่รับเงิน",
        "เงินที่ได้รับ(บาท)",
        "Direct",
        "F&A",
        "Overhead 70%",
        "Overhead 30%",
        "วันที่นำส่งเงินสมทบ มช.",
        "จำนวนเงินที่นำส่ง"
      ];

      xdata.push(["เงินสมทบ"]);
      xdata.push([""]);
      xdata.push(["รหัส CMU MIS : " + xcmumis_id]);
      xdata.push(["ชื่อย่อโครงการ : " + xshort_name]);
      xdata.push([""]);
      xdata.push(header);

      for (var row in dataSourceEx) {
        const xx = [];

        for (var col in dataSourceEx[row]) {
          xx.push(dataSourceEx[row][col]);
        }

        xdata.push(xx);
      }

      var wb = xlsx.utils.book_new(),
        ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

      xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
      xlsx.writeFile(wb, "เงินสมทบ.xlsx");
    }
  };

  const columns1 = [
    {
      title: "c1",
      dataIndex: "c1",
      key: "c1"
    },
    {
      title: "c2",
      dataIndex: "c2",
      key: "c2"
    },
    {
      title: "c3",
      dataIndex: "c3",
      key: "c3",
      render: (text, record) => __TBShowText(record.c3, 1, 0)
    },
    {
      title: "c4",
      dataIndex: "c4",
      key: "c4",
      render: (text, record) => __TBShowText(record.c4, 1, 0)
    },
    {
      title: "c5",
      dataIndex: "c5",
      key: "c5"
    },
    {
      title: "แก้ไข",
      dataIndex: "edit",
      key: "edit",
      align: "center",
      width: 50,
      render(text, record, index) {
        if (record.c1 !== "") {
          return {
            children: (
              <Button
                ghost
                type="primary"
                icon={<EditOutlined />}
                size="small"
                onClick={e => {
                  /*console.log(
                    record.c1.split(" ")[1],
                    record.c2,
                    parseFloat(
                      record.c4.props.children.replace(",", "").replace(",", "")
                    )
                  );*/
                  bEAgencyclick(record);
                  _xidE(record.c1.split(" ")[1]);
                  // swal({
                  //   title: "แจ้งเตือน!",
                  //   text: "กำลังทำการพัฒนา!",
                  //   icon: "error",
                  //   buttons: false,
                  //   timer: 2000
                  // }).then(() => {});
                }}
              />
            )
          };
        }
      }
    },
    {
      title: "ลบ",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      width: 50,
      render(text, record, index) {
        if (record.c1 !== "") {
          return {
            children: (
              <Button
                danger
                icon={<DeleteOutlined />}
                size="small"
                onClick={e => {
                  //console.log("corresponding email is :", record.c4);
                  bDAgencyclick(
                    record.c1,
                    record.c2,
                    record.c4,
                    record.agency_id
                  );
                }}
              />
            )
          };
        }
      }
    }
  ];

  const columns2 = [
    {
      title: "c1",
      dataIndex: "c1",
      key: "c1"
    },
    {
      title: "c2",
      dataIndex: "c2",
      key: "c2"
    },
    {
      title: "c3",
      dataIndex: "c3",
      key: "c3"
    },
    {
      title: "ลบ",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      width: 50,
      render(text, record, index) {
        return {
          children: (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="small"
              onClick={e => {
                //console.log("corresponding email is :", record.c4);
                bDAReclick(
                  record.c1,
                  record.c2,
                  record.c3,
                  record.re_staff_id,
                  record.re_pp_id
                );
              }}
            />
          )
        };
      }
    }
  ];

  const columns3 = [
    {
      title: "งวดที่",
      dataIndex: "term",
      key: "term",
      align: "center"
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "amount",
      key: "amount",
      align: "center",

      render: (text, record) => __TBShowText(record.amount, 1, 0)
    },
    {
      title: "รายละเอียด",
      dataIndex: "detail",
      key: "detail",
      width: 400
      //align: "center",

      //render: (text, record) => __TBShowText(text, 0, 1)
    },
    {
      title: "วันที่รับเงิน(ล่าสุด)",
      dataIndex: "date_receive",
      key: "date_receive",
      align: "center"
    },
    {
      title: "เงินที่ได้รับ(ทั้งหมด)",
      dataIndex: "total_receive",
      key: "total_receive",
      align: "center",
      render: (text, record) => __TBShowText(record.total_receive, 1, 0)
    },
    {
      title: "หมายเหตุ",
      dataIndex: "remark",
      key: "remark",
      width: 350
      //align: "center",

      //render: (text, record) => __TBShowText(text, 0, 1)
    },
    {
      title: "Invoice",
      dataIndex: "tfileiv",
      key: "tfileiv",
      align: "center",
      width: 130,
      render(text, record, index) {
        if (record.linkfileiv) {
          return {
            children: (
              <a href={record.linkfileiv} target="_blank">
                <PaperClipOutlined /> ไฟล์แนบ
              </a>
            )
          };
        }
      }
    },
    {
      title: "ใบโอน",
      dataIndex: "tfile",
      key: "tfile",
      align: "center",
      width: 130,
      render(text, record, index) {
        if (record.linkfile) {
          return {
            children: (
              <a href={record.linkfile} target="_blank">
                <PaperClipOutlined /> ไฟล์แนบ
              </a>
            )
          };
        }
      }
    },
    {
      title: "สถานะเงินโอน",
      dataIndex: "status_money",
      key: "status_money",
      align: "center",
      render(text, record, index) {
        //  if (!record.linkfile || !record.linkfile2) {
        // if (parseFloat(record.total_receive) <= 0 || !record.total_receive) {
        if (!record.total_receive) {
          return {
            children: (
              <Button
                ghost
                icon={<PlusOutlined />}
                type="primary"
                size="small"
                onClick={e => {
                  //console.log("corresponding email is :", record.c4);
                  AddMoney(record.term, record.amount, record.detail);
                }}
              >
                เพิ่ม/แก้ไขเงินโอน
              </Button>
            )
          };
        } else {
          return {
            children: (
              <a
                style={{ color: "tomato" }}
                onClick={e => {
                  showMoney(record.term, record.amount, record.detail);
                }}
              >
                <CheckOutlined /> รับเงินโอนแล้ว
              </a>
            )
          };
        }
      }
    },
    {
      title: "วันที่แจ้งเตือน",
      dataIndex: "date_warn",
      key: "date_warn",
      align: "center"
    },
    {
      title: "วันที่ครบกำหนด",
      dataIndex: "date_due",
      key: "date_due",
      align: "center"
    }
  ];
  // const cContract = [
  //   {
  //     title: "ที่",
  //     dataIndex: "num",
  //     key: "num",
  //     align: "center",
  //     width: 50
  //   },
  //   {
  //     title: "เลขที่สัญญา / Grant No.",
  //     dataIndex: "grant_no",
  //     key: "grant_no",
  //     align: "center"
  //   },
  //   {
  //     title: "PO",
  //     dataIndex: "po",
  //     key: "po",
  //     align: "center"
  //   },
  //   {
  //     title: "จำนวนสัญญา",
  //     dataIndex: "cgrant",
  //     key: "cgrant",
  //     align: "center"
  //   },
  //   {
  //     title: "วันที่เริ่มต้น (สัญญาล่าสุด)",
  //     dataIndex: "sdate",
  //     key: "cgrant",
  //     align: "center"
  //   },
  //   {
  //     title: "วันที่สิ้นสุด (สัญญาล่าสุด)",
  //     dataIndex: "edate",
  //     key: "edate",
  //     align: "center"
  //   },
  //   {
  //     title: "งบประมาณ (สัญญาล่าสุด)",
  //     dataIndex: "budget",
  //     key: "budget",
  //     align: "center",

  //     render: (text, record) => __TBShowText(record.budget, 1, 0)
  //   },
  //   {
  //     title: "หน่วย (สัญญาล่าสุด)",
  //     dataIndex: "money",
  //     key: "money",
  //     align: "center"
  //   },
  //   {
  //     title: "คำอธิบาย",
  //     dataIndex: "description",
  //     key: "description",
  //     ellipsis: true
  //     //align: "center",
  //     //render: (text, record) => __TBShowText(record.description, 0, 1)
  //   }
  // ];

  const cContract2 = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 40
    },
    {
      title: "เลขที่สัญญา / Grant No.",
      dataIndex: "grant_no",
      key: "grant_no",
      align: "center",
      width: 130
    },
    {
      title: "PO / Protocol No.",
      dataIndex: "po",
      key: "po",
      align: "center",
      width: 130
    },
    {
      title: "ฉบับที่ / Modification",
      dataIndex: "Modification",
      key: "Modification",
      align: "center",
      width: 110
    },
    {
      title: "วันที่เริ่มต้น",
      dataIndex: "sdate",
      key: "cgrant",
      align: "center",
      width: 120
    },
    {
      title: "วันที่สิ้นสุด",
      dataIndex: "edate",
      key: "edate",
      align: "center",
      width: 120
    },
    {
      title: "งบประมาณ",
      dataIndex: "budget",
      key: "budget",
      align: "center",
      width: 150,
      render: (text, record) => __TBShowText(record.budget, 1, 0)
    },
    {
      title: "หน่วย",
      dataIndex: "currency",
      key: "currency",
      align: "center",
      width: 80
    },
    {
      title: "คำอธิบาย",
      dataIndex: "description",
      key: "description",
      ellipsis: true
    },
    {
      title: "ไฟล์สัญญา",
      dataIndex: "tfile",
      key: "tfile",
      align: "center",
      width: 130,
      render(text, record, index) {
        return {
          children: (
            <a href={record.linkfile} target="_blank">
              <PaperClipOutlined /> ไฟล์สัญญา
            </a>
          )
        };
      },
      fixed: "right"
    }
  ];

  const cFile1 = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 50
    },
    {
      title: "วันที่",
      dataIndex: "sdate",
      key: "sdate",
      align: "center"
    },
    {
      title: "เลขที่รับเอกสาร",
      dataIndex: "iddocget",
      key: "iddocget",
      align: "center"
    },
    {
      title: "เลขที่เอกสาร",
      dataIndex: "iddoc",
      key: "iddoc",
      align: "center"
    },
    {
      title: "ชื่อเรื่อง",
      dataIndex: "docname",
      key: "docname",

      ellipsis: true
    },
    {
      title: "จาก",
      dataIndex: "tfrom",
      key: "tfrom",

      ellipsis: true
    },
    {
      title: "ถึง",
      dataIndex: "tto",
      key: "tto",

      ellipsis: true
    },
    {
      title: "รายละเอียด/หมายเหตุ",
      dataIndex: "remark",
      key: "remark",

      ellipsis: true
    },
    {
      title: "ไฟล์",
      dataIndex: "tfile",
      key: "tfile",
      align: "center",

      render(text, record, index) {
        return {
          children: (
            <a href={record.linkfile} target="_blank">
              <PaperClipOutlined /> ไฟล์เอกสาร
            </a>
          )
        };
      }
    }
  ];

  const cFile2 = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 50
    },
    {
      title: "วันที่",
      dataIndex: "sdate",
      key: "sdate",
      align: "center"
    },
    {
      title: "เลขที่ส่งเอกสาร",
      dataIndex: "iddocpush",
      key: "iddocpush",
      align: "center"
    },
    {
      title: "ชื่อเรื่อง",
      dataIndex: "docname",
      key: "docname",

      ellipsis: true
    },
    {
      title: "จาก",
      dataIndex: "tfrom",
      key: "tfrom",

      ellipsis: true
    },
    {
      title: "ถึง",
      dataIndex: "tto",
      key: "tto",

      ellipsis: true
    },
    {
      title: "รายละเอียด/หมายเหตุ",
      dataIndex: "remark",
      key: "remark",

      ellipsis: true
    },
    {
      title: "ไฟล์",
      dataIndex: "tfile",
      key: "tfile",
      align: "center",

      render(text, record, index) {
        return {
          children: (
            <a href={record.linkfile} target="_blank">
              <PaperClipOutlined /> ไฟล์เอกสาร
            </a>
          )
        };
      },
      fixed: "right"
    }
  ];

  const cFile3 = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 50
    },
    {
      title: "วันที่",
      dataIndex: "sdate",
      key: "sdate",
      align: "center"
    },
    {
      title: "เลขที่เอกสาร",
      dataIndex: "iddoc",
      key: "iddoc",
      align: "center"
    },
    {
      title: "รายละเอียด",
      dataIndex: "remark",
      key: "remark",

      ellipsis: true
    },
    {
      title: "ไฟล์",
      dataIndex: "tfile",
      key: "tfile",
      align: "center",

      render(text, record, index) {
        return {
          children: (
            <a href={record.linkfile} target="_blank">
              <PaperClipOutlined /> ไฟล์เอกสาร
            </a>
          )
        };
      }
    }
  ];

  const cTocmu = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 40
    },
    {
      title: "รายละเอียด",
      dataIndex: "detail",
      key: "detail",
      width: 450
    },
    {
      title: "วันที่รับเงิน",
      dataIndex: "date_receive",
      key: "date_receive",
      align: "center"
    },
    {
      title: "เงินที่ได้รับ(บาท)",
      dataIndex: "total_receive",
      key: "total_receive",
      align: "center",
      render: (text, record) => __TBShowText(record.total_receive, 1, 0)
    },
    {
      title: "Direct",
      dataIndex: "direct",
      key: "direct",
      align: "center",
      render: (text, record) => __TBShowText(record.direct, 1, 0)
    },
    {
      title: "F&A",
      dataIndex: "f_a",
      key: "f_a",
      align: "center",
      render: (text, record) => __TBShowText(record.f_a, 1, 0)
    },
    {
      title: "Overhead 70%",
      dataIndex: "overhead70",
      key: "overhead70",
      align: "center",
      render: (text, record) => __TBShowText(record.overhead70, 1, 0)
    },
    {
      title: "Overhead 30%",
      dataIndex: "overhead30",
      key: "overhead30",
      align: "center",
      render: (text, record) => __TBShowText(record.overhead30, 1, 0)
    },
    {
      title: "วันที่นำส่งเงินสมทบ มช.",
      dataIndex: "date_to_cmu",
      key: "date_to_cmu",
      align: "center"
    },
    {
      title: "จำนวนเงินที่นำส่ง",
      dataIndex: "money_to_cmu",
      key: "money_to_cmu",
      align: "center",
      render: (text, record) => __TBShowText(record.money_to_cmu, 1, 0)
    },
    {
      title: "ไฟล์นำส่ง มช.",
      dataIndex: "file_to_cmu",
      key: "file_to_cmu",
      align: "center",
      width: 130,
      render(text, record, index) {
        if (record.file_to_cmu) {
          return {
            children: (
              <a href={record.file_to_cmu} target="_blank">
                <PaperClipOutlined /> ไฟล์แนบ
              </a>
            )
          };
        }
      }
    },
    {
      title: "ไฟล์ invoice",
      dataIndex: "fileinvoice",
      key: "fileinvoice",
      align: "center",
      width: 130,
      render(text, record, index) {
        if (record.fileinvoice) {
          return {
            children: (
              <a href={record.fileinvoice} target="_blank">
                <PaperClipOutlined /> ไฟล์แนบ
              </a>
            )
          };
        }
      }
    },
    {
      title: "ไฟล์ใบโอน",
      dataIndex: "filetransfer",
      key: "filetransfer",
      align: "center",
      width: 130,
      render(text, record, index) {
        if (record.filetransfer) {
          return {
            children: (
              <a href={record.filetransfer} target="_blank">
                <PaperClipOutlined /> ไฟล์แนบ
              </a>
            )
          };
        }
      }
    }
  ];

  const bAddAgencyclick = () => {
    if (show1 === false) {
      _show1(true);
    } else if (show1 === true) {
      _show1(false);
    }
  };

  const bAddAgencyclick2 = () => {
    if (xAgencyValue == "0") {
      swal("เพิ่ม ไม่ได้!", "ยังไม่ได้คีย์ชื่อแหล่งทุน!", "warning");
    } else if (xBudget === 0) {
      swal("เพิ่ม ไม่ได้!", "ยังไม่ได้คีย์จำนวนเงิน!", "warning");
    } else {
      swal({
        title: "ยืนยัน เพิ่มแหล่งทุน",
        text:
          "แหล่งทุนที่ : " +
          xid +
          "\nชื่อแหล่งทุน : " +
          xAgencyName +
          "\nจำนวนเงิน : " +
          xBudget +
          " บาท",
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          axios
            .post(xURL + "insert", {
              cmumis_id: xcmumis_id,
              agency_id: xAgencyValue,
              budget: xBudget,
              zorder: 2
            })
            .then(result => {})
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          dataSource1.forEach(i => {
            if (i.c1 === "") {
              let xsumBudget =
                parseInt(xBudget) +
                parseInt(i.c4.props.children.replace(",", "").replace(",", ""));
              /*
              console.log(xcmumis_id,xAgencyValue,xBudget,i.c4.props.children.replace(",", "").replace(",", "")
              );*/
              //console.log(xsumBudget);

              axios
                .post(xURL + "update", {
                  cmumis_id: xcmumis_id,
                  budget: xsumBudget,
                  xcheck: 9
                })
                .then(result => {})
                .catch(function(error) {
                  // handle error
                  console.log("problem here", error);
                });
            }
          });

          swal({
            title: "แจ้งเตือน!",
            text: "เพิ่มแหล่งทุนสำเร็จ!",
            icon: "success",
            buttons: false,
            timer: 2000
          }).then(() => {
            _show1(false);
            form1.setFieldsValue({ slagency: "เลือกรายการ", edbudget: "" });
            _xAgencyValue(0);
            _xBudget(0);

            window.location.reload();
          });
        }
      });
    }
  };

  const bAddagency = () => {
    window.open("/Research_input_agency", "_blank");
    //window.location = "/Research_input_agency";
  };

  const bAddresearch = () => {
    window.open("/Research_staff", "_self");
  };

  const bDAgencyclick = (xnum, name, budget, agency_id) => {
    swal({
      title: "ยืนยัน ลบแหล่งทุน",
      text:
        xnum +
        "\nชื่อแหล่งทุน : " +
        name +
        "\nจำนวนเงิน : " +
        budget.props.children +
        " บาท",
      icon: "warning",
      dangerMode: true,
      buttons: ["ยกเลิก", "ยืนยัน"]
    }).then(willDelete => {
      if (willDelete) {
        //console.log(xcmumis_id, agency_id);

        axios
          .post(xURL + "delete", {
            cmumis_id: xcmumis_id,
            agency_id: agency_id,
            xcheck: 21
          })
          .then(result => {})
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        dataSource1.forEach(i => {
          if (i.c1 === "") {
            let xsumBudget =
              parseInt(i.c4.props.children.replace(",", "").replace(",", "")) -
              parseInt(budget.props.children.replace(",", "").replace(",", ""));
            /*
              console.log(xcmumis_id,xAgencyValue,xBudget,i.c4.props.children.replace(",", "").replace(",", "")
              );*/
            //console.log(xsumBudget);

            axios
              .post(xURL + "update", {
                cmumis_id: xcmumis_id,
                budget: xsumBudget,
                xcheck: 9
              })
              .then(result => {})
              .catch(function(error) {
                // handle error
                console.log("problem here", error);
              });
          }
        });

        swal({
          title: "แจ้งเตือน!",
          text: "ลบแหล่งทุนสำเร็จ!",
          icon: "success",
          buttons: false,
          timer: 2000
        }).then(() => {
          window.location.reload();
        });
      }
    });
  };

  const bDAReclick = (xnum, name, p_position, re_staff_id, re_pp_id) => {
    swal({
      title: "ยืนยัน ลบนักวิจัย",
      text:
        xnum + "\nชื่อนักวิจัย : " + name + "\nตำแหน่งนักวิจัย : " + p_position,
      icon: "warning",
      dangerMode: true,
      buttons: ["ยกเลิก", "ยืนยัน"]
    }).then(willDelete => {
      if (willDelete) {
        //console.log(xnum, name, p_position, re_staff_id, re_pp_id);

        axios
          .post(xURL + "delete", {
            cmumis_id: xcmumis_id,
            re_staff_id: re_staff_id,
            re_pp_id: re_pp_id,
            xcheck: 22
          })
          .then(result => {})
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        swal({
          title: "แจ้งเตือน!",
          text: "ลบนักวิจัยสำเร็จ!",
          icon: "success",
          buttons: false,
          timer: 2000
        }).then(() => {
          window.location.reload();
        });
      }
    });
  };

  const bAddReclick = () => {
    if (show2 === false) {
      _show2(true);
    } else if (show2 === true) {
      _show2(false);
    }
  };

  const bAddReclick2 = () => {
    //console.log(dataSource8);
    if (xResearchValue == "0") {
      swal("เพิ่ม ไม่ได้!", "ยังไม่ได้คีย์ชื่อนักวิจัย!", "warning");
    } else if (xResearchPValue == "0") {
      swal("เพิ่ม ไม่ได้!", "ยังไม่ได้คีย์ตำแหน่งนักวิจัย!", "warning");
    } else {
      swal({
        title: "ยืนยัน เพิ่มนักวิจัย",
        text:
          "นักวิจัยที่ : " +
          xidR +
          "\nชื่อนักวิจัย : " +
          xResearchName +
          "\nตำแหน่งนักวิจัย : " +
          xRPName,
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          axios
            .post(xURL + "insert", {
              cmumis_id: xcmumis_id,
              researcher_staff_id: xResearchValue,
              researcher_project_position_id: xResearchPValue,
              zorder: 3
            })
            .then(result => {})
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          swal({
            title: "แจ้งเตือน!",
            text: "เพิ่มนักวิจัยสำเร็จ!",
            icon: "success",
            buttons: false,
            timer: 2000
          }).then(() => {
            _show2(false);
            form1.setFieldsValue({
              slresearchname: "เลือกรายการ",
              slresearchposition: "เลือกรายการ"
            });
            _xResearchValue(0);
            _xResearchPValue(0);

            window.location.reload();
          });
        }
      });
    }
  };

  const bAddismclick = () => {
    const f = form1.getFieldsValue();
    let xcmumis_id = "",
      xname = "",
      xshortname = "",
      xresearch = "",
      xdate = "";

    xcmumis_id = f.edcmumis_id;

    if (f.edname_th === "") {
      xname = f.edname_eng;
    } else {
      xname = f.edname_th;
    }

    xshortname = f.edshort_name;

    xresearch = dataSource2[0].c2;

    xdate =
      dayjs(f.sdate).format("DD/MM/BBBB") +
      " - " +
      dayjs(f.edate).format("DD/MM/BBBB");

    window.open(
      "/Research_input_installment?cmumis_id=" +
        xcmumis_id +
        "&name=" +
        xname +
        "&shortname=" +
        xshortname +
        "&research=" +
        xresearch +
        "&date=" +
        xdate,
      "_blank"
    );

    //setIsModalVisible3(false);
  };

  const bContractclick = () => {
    const f = form1.getFieldsValue();
    let xcmumis_id = "",
      xname = "",
      xshortname = "",
      xresearch = "",
      xdate = "";

    xcmumis_id = f.edcmumis_id;

    if (f.edname_th === "") {
      xname = f.edname_eng;
    } else {
      xname = f.edname_th;
    }

    xshortname = f.edshort_name;

    xresearch = dataSource2[0].c2;

    xdate =
      dayjs(f.sdate).format("DD/MM/BBBB") +
      " - " +
      dayjs(f.edate).format("DD/MM/BBBB");

    window.open(
      "/Research_input_contract?cmumis_id=" +
        xcmumis_id +
        "&name=" +
        xname +
        "&shortname=" +
        xshortname +
        "&research=" +
        xresearch +
        "&date=" +
        xdate,
      "_blank"
    );

    //setIsModalVisible3(false);
  };

  const bAddfileclick = () => {
    const f = form1.getFieldsValue();
    let xcmumis_id = "",
      xname = "",
      xshortname = "",
      xresearch = "",
      xdate = "";

    xcmumis_id = f.edcmumis_id;

    if (f.edname_th === "") {
      xname = f.edname_eng;
    } else {
      xname = f.edname_th;
    }

    xshortname = f.edshort_name;

    xresearch = dataSource2[0].c2;

    xdate =
      dayjs(f.sdate).format("DD/MM/BBBB") +
      " - " +
      dayjs(f.edate).format("DD/MM/BBBB");

    window.open(
      "/Research_input_file?cmumis_id=" +
        xcmumis_id +
        "&name=" +
        xname +
        "&shortname=" +
        xshortname +
        "&research=" +
        xresearch +
        "&date=" +
        xdate,
      "_blank"
    );

    //setIsModalVisible3(false);
  };

  const bEAgencyclick = zdata => {
    const f = form1.getFieldsValue();

    if (show1edit === false) {
      _show1edit(true);

      // console.log(
      //   zdata.c1.split(" ")[1],
      //   zdata.c2,
      //   parseFloat(zdata.c4.props.children.replace(",", "").replace(",", ""))
      // );

      _xoagency(zdata.c2);
      _xobudget(
        parseFloat(zdata.c4.props.children.replace(",", "").replace(",", ""))
      );
    } else if (show1edit === true) {
      _show1edit(false);
    }
  };

  const bEAgencyclick2 = () => {
    // console.log(xAgencyValue, xAgencyName, xBudget);

    if (xAgencyValue2 == 0 || xAgencyName2 == "") {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์แหล่งทุน!", "warning");
    } else if (xBudget2 == 0) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์งบประมาณ!", "warning");
    } else {
      swal({
        title: "ยืนยัน แก้ไขแหล่งทุน",
        text:
          "แหล่งทุนที่ : " +
          xidE +
          "\nชื่อแหล่งทุน : " +
          xAgencyName2 +
          "\nจำนวนเงิน : " +
          xBudget2 +
          " บาท",
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          EditAgency();
        }
      });
    }
  };

  const EditAgency = () => {
    // console.log(xcmumis_id,xoagency,xobudget,xAgencyValue2,xBudget2);

    axios
      .post(xURL + "updateAgency", {
        cmumis_id: xcmumis_id,
        oagency: xoagency,
        obudget: xobudget,
        agency: xAgencyValue2,
        budget: xBudget2
      })
      .then(result => {
        swal({
          title: "แจ้งเตือน!",
          text: "แก้ไขแหล่งทุน สำเร็จ",
          icon: "success",
          buttons: false,
          timer: 2000
        }).then(() => {
          window.location.reload();
        });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  function callback(key) {
    //console.log(key);

    //*** tab Choose ***
    if (key === "1") {
      _activeKeyshow("1");
    }
    if (key === "2") {
      _activeKeyshow("2");
    }
    if (key === "3") {
      _activeKeyshow("3");
    }
  }

  function handleChange1(value) {
    //console.log(`selected ${value}`);
    _xStatusValue(value);
  }

  function handleChange2(value) {
    //console.log(`selected ${value}`);
    _xPaperValue(value);
  }

  function handleChange3(value) {
    _xAgencyValue(value);

    xAgency.forEach((i, idx) => {
      if (i.id === value) {
        //console.log(i.name);
        _xAgencyName(i.name);
      }
    });
  }

  function handleChange3_1(value) {
    _xAgencyValue2(value);

    xAgency.forEach((i, idx) => {
      if (i.id === value) {
        //console.log(i.name);
        _xAgencyName2(i.name);
      }
    });
  }

  function handleChange4(value) {
    _xResearchValue(value);

    xResearch.forEach((i, idx) => {
      if (i.id === value) {
        _xResearchName(i.name + " " + i.lname);
      }
    });
  }

  function handleChange5(value) {
    _xResearchPValue(value);

    xResearchP.forEach((i, idx) => {
      if (i.id === value) {
        //console.log(i.name);
        _xRPName(i.name);
      }
    });
  }

  function handleChange6(value) {
    //console.log(`selected ${value}`);
    _xReceiptValue(value);
  }

  function handleChange7(value) {
    //console.log(`selected ${value}`);
    // _xReceiptValue(value);
    _xTypeValue(value);
  }

  const onChangeedbudget = e => {
    if (e == null) {
      _xBudget(0);
    } else {
      _xBudget(e);
    }
  };

  const onChangeedbudget2 = e => {
    if (e == null) {
      _xBudget2(0);
    } else {
      _xBudget2(e);
    }
  };

  const pageOnChange = (zpage, zpageSize) => {
    _pageTable(zpage);
    _pageSizeTable(zpageSize);
  };

  //formatttable (Rigth or Left)
  function __TBShowText(zData, zRigth, zLeft) {
    if (zRigth === 1 && zLeft === 0) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    } else if (zRigth === 0 && zLeft === 1) {
      return (
        <div style={{ display: "flex" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      // console.log(selectedRows);
      _dataselectradio(selectedRows);
    },
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name
    })
  };

  return (
    <React.Fragment>
      <StyledForm />
      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col span={23}>
            <PageHeader
              className="site-page-header"
              title={
                <a
                  style={{
                    color: "black"
                  }}
                  onClick={() => {
                    window.close();
                  }}
                >
                  แก้ไข รายละเอียดโครงการวิจัย
                </a>
              }
              onBack={() => window.close()}
            />

            <Divider />

            <Form form={form1} initialValues={{}}>
              <ConfigProvider locale={configuredLocale}>
                <Descriptions bordered size={"small"}>
                  <Descriptions.Item
                    label="รหัส CMU MIS"
                    span={3}
                    labelStyle={{ justifyContent: "flex-end", minWidth: 200 }}
                  >
                    <Form.Item label="" name="edcmumis_id">
                      <Input allowClear maxLength="10" style={{ width: 200 }} />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label="ชื่อโครงการ: (ภาษาไทย)" span={3}>
                    <Form.Item label="" name="edname_th">
                      <Input allowClear />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label="ชื่อโครงการ: (ภาษาอังกฤษ)" span={3}>
                    <Form.Item label="" name="edname_eng">
                      <Input allowClear />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label="ชื่อย่อโครงการ:" span={3}>
                    <Form.Item label="" name="edshort_name">
                      <Input allowClear />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label="ยุทธศาสตร์ของมหาวิทยาลัย" span={3}>
                    <Form.Item label="" name="slppt_cmu">
                      <Select onChange={handleChange2}>
                        {xPaper.length > 0
                          ? xPaper.map((i, idx) => {
                              return (
                                <Option value={i.pub_type_cmu_id}>
                                  {i.pub_type_cmu_name} {i.pub_type_short}
                                </Option>
                              );
                            })
                          : <Option value="0">
                              {}
                            </Option>}
                      </Select>
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label="วันที่เริ่มต้นโครงการ" span={3}>
                    <Form.Item label="" name="sdate">
                      <DatePicker format="DD/MM/BBBB" />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label="วันที่สิ้นสุดโครงการ" span={3}>
                    <Form.Item label="" name="edate">
                      <DatePicker format="DD/MM/BBBB" />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label="สถานะโครงการ" span={3}>
                    <Form.Item label="" name="slstatus2">
                      <Select style={{ width: 400 }} onChange={handleChange1}>
                        {xStatus.length > 0
                          ? xStatus.map((i, idx) => {
                              return (
                                <Option value={i.id}>
                                  {i.id} : {i.name}
                                </Option>
                              );
                            })
                          : <Option value="0">
                              {}
                            </Option>}
                      </Select>
                    </Form.Item>
                  </Descriptions.Item>

                  <Descriptions.Item label="ประเภทโครงการ" span={3}>
                    <Form.Item label="" name="sltype">
                      <Select style={{ width: 400 }} onChange={handleChange7}>
                        {xType.length > 0
                          ? xType.map((i, idx) => {
                              return (
                                <Option value={i.id}>
                                  {i.id} : {i.name}
                                </Option>
                              );
                            })
                          : <Option value="0">
                              {}
                            </Option>}
                      </Select>
                    </Form.Item>
                  </Descriptions.Item>

                  <Descriptions.Item label="นำส่งใบเสร็จให้แหล่งทุน" span={3}>
                    <Form.Item label="" name="slreceipt">
                      <Select style={{ width: 400 }} onChange={handleChange6}>
                        {xReceipt.length > 0
                          ? xReceipt.map((i, idx) => {
                              return (
                                <Option value={i.id}>
                                  {i.name}
                                </Option>
                              );
                            })
                          : <Option value="0">
                              {}
                            </Option>}
                      </Select>
                    </Form.Item>
                  </Descriptions.Item>
                </Descriptions>
              </ConfigProvider>
              <br />
              <Row justify="center">
                <Space>
                  <Button type="primary" onClick={bUpdateclick}>
                    <SaveOutlined />บันทึกข้อมูล
                  </Button>
                </Space>
              </Row>
              <Divider />
              <Button
                style={{ width: "200px" }}
                onClick={() => {
                  window.open(
                    "/Research_to_cmu?cmumis_id=" + xcmumis_id,
                    "_blank"
                  );
                }}
              >
                <Text style={{ fontWeight: "bold" }}>การส่งเงินสมทบ</Text>
              </Button>
              <br />
              <br />
              <Card>
                <Divider orientation="left" style={{ borderColor: "#C2CFFF" }}>
                  <Text style={{ fontWeight: "bold" }}>แหล่งทุน</Text>
                </Divider>

                {xid > 1
                  ? <Table
                      scroll={{ x: "max-content" }}
                      dataSource={dataSource1}
                      columns={columns1}
                      size="small"
                      pagination={false}
                      showHeader={false}
                    />
                  : <p>---ไม่มีข้อมูล---</p>}

                <br />
                <Row justify="">
                  <Space>
                    {show1
                      ? <Button ghost type="danger " onClick={bAddAgencyclick}>
                          <MinusOutlined />เพิ่มแหล่งทุน
                        </Button>
                      : <Button ghost type="primary" onClick={bAddAgencyclick}>
                          <PlusOutlined />เพิ่มแหล่งทุน
                        </Button>}

                    <Button onClick={bAddagency}>
                      <SaveOutlined /> บันทึกแหล่งทุนใหม่
                    </Button>
                  </Space>
                </Row>

                {show1
                  ? <div>
                      <br />
                      <Divider />
                      <Row>
                        <Col span={4}>
                          <Form.Item label="แหล่งทุนที่" name="edbudget_id">
                            <Row>
                              <Input
                                disabled
                                style={{
                                  width: 35,
                                  height: 30,
                                  color: "red",
                                  textAlign: "center"
                                }}
                                value={xid}
                              />
                            </Row>
                          </Form.Item>
                        </Col>

                        <Col span={11}>
                          <Form.Item label="ชื่อแหล่งทุน" name="slagency">
                            <Select
                              showSearch
                              onChange={handleChange3}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())}
                            >
                              {xAgency.length > 0
                                ? xAgency.map((i, idx) => {
                                    return (
                                      <Option value={i.id}>
                                        {i.name}
                                      </Option>
                                    );
                                  })
                                : <Option value="0">
                                    {}
                                  </Option>}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={8} offset={1}>
                          <Form.Item label="จำนวนเงิน" name="edbudget">
                            <InputNumber
                              style={{ width: 150 }}
                              //disabled={Export}
                              min="0"
                              max="9999999999"
                              maxLength="12"
                              onChange={e => onChangeedbudget(e)}
                              formatter={value =>
                                `${value}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                              parser={value => value.replace(/\$\s?|(,*)/g, "")}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <br />
                      <Row justify="center">
                        <Button ghost type="primary" onClick={bAddAgencyclick2}>
                          <SaveOutlined />บันทึก
                        </Button>
                      </Row>
                    </div>
                  : <div />}

                {show1edit
                  ? <div>
                      <br />
                      <Divider />

                      <Row>
                        <Col span={4}>
                          <Form.Item label="แหล่งทุนที่" name="edbudget_id2">
                            <Row>
                              <Input
                                disabled
                                style={{
                                  width: 35,
                                  height: 30,
                                  color: "red",
                                  textAlign: "center"
                                }}
                                value={xidE}
                              />
                            </Row>
                          </Form.Item>
                        </Col>

                        <Col span={11}>
                          <Form.Item label="ชื่อแหล่งทุนใหม่" name="slagency2">
                            <Select
                              showSearch
                              onChange={handleChange3_1}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())}
                            >
                              {xAgency.length > 0
                                ? xAgency.map((i, idx) => {
                                    return (
                                      <Option value={i.id}>
                                        {i.name}
                                      </Option>
                                    );
                                  })
                                : <Option value="0">
                                    {}
                                  </Option>}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={8} offset={1}>
                          <Form.Item label="งบประมาณ" name="edbudget2">
                            <InputNumber
                              style={{ width: 150 }}
                              //disabled={Export}
                              min="0"
                              max="9999999999"
                              maxLength="12"
                              onChange={e => onChangeedbudget2(e)}
                              formatter={value =>
                                `${value}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                              parser={value => value.replace(/\$\s?|(,*)/g, "")}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <br />
                      <Row justify="center">
                        <Button ghost type="primary" onClick={bEAgencyclick2}>
                          <EditOutlined />แก้ไข
                        </Button>
                      </Row>
                    </div>
                  : <div />}

                <Divider orientation="left" style={{ borderColor: "#C2CFFF" }}>
                  <Text style={{ fontWeight: "bold" }}>ผู้ร่วมวิจัย</Text>
                </Divider>

                {dataSource2 != ""
                  ? <Table
                      scroll={{ x: "max-content" }}
                      dataSource={dataSource2}
                      columns={columns2}
                      size="small"
                      pagination={false}
                      showHeader={false}
                    />
                  : <p>---ไม่มีข้อมูล---</p>}

                <br />

                <Row justify="">
                  <Space>
                    {show2
                      ? <Button ghost type="danger" onClick={bAddReclick}>
                          <MinusOutlined />เพิ่มผู้ร่วมวิจัย
                        </Button>
                      : <Button ghost type="primary" onClick={bAddReclick}>
                          <PlusOutlined />เพิ่มผู้ร่วมวิจัย
                        </Button>}

                    <Button onClick={bAddresearch}>
                      <SaveOutlined /> บันทึกผู้ร่วมวิจัยใหม่
                    </Button>
                  </Space>
                </Row>

                {show2
                  ? <div>
                      <br />
                      <Divider />
                      <Row>
                        <Col span={4}>
                          <Form.Item label="นักวิจัยที่" name="edresearch_id">
                            <Row>
                              <Input
                                disabled
                                style={{
                                  width: 35,
                                  height: 30,
                                  color: "red",
                                  textAlign: "center"
                                }}
                                value={xidR}
                              />
                            </Row>
                          </Form.Item>
                        </Col>

                        <Col span={11}>
                          <Form.Item label="ชื่อนักวิจัย" name="slresearchname">
                            <Select
                              showSearch
                              onChange={handleChange4}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children[0]
                                  .toLowerCase()
                                  .includes(input.toLowerCase())}
                            >
                              {xResearch.length > 0
                                ? xResearch.map((i, idx) => {
                                    return (
                                      <Option value={i.id}>
                                        {i.name} {i.lname}
                                      </Option>
                                    );
                                  })
                                : <Option value="0">
                                    {}
                                  </Option>}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={8} offset={1}>
                          <Form.Item
                            label="ตำแหน่งนักวิจัย"
                            name="slresearchposition"
                          >
                            <Select onChange={handleChange5}>
                              {xResearchP.length > 0
                                ? xResearchP.map((i, idx) => {
                                    return (
                                      <Option value={i.id}>
                                        {i.name}
                                      </Option>
                                    );
                                  })
                                : <Option value="0">
                                    {}
                                  </Option>}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      <br />
                      <Row justify="center">
                        <Button ghost type="primary" onClick={bAddReclick2}>
                          <SaveOutlined />บันทึก
                        </Button>
                      </Row>
                    </div>
                  : <div />}

                <Divider orientation="left" style={{ borderColor: "#C2CFFF" }}>
                  <Text style={{ fontWeight: "bold" }}>วิธีการเบิกจ่าย</Text>
                </Divider>

                {xSumAmount !== 0
                  ? <div>
                      <Table
                        bordered
                        scroll={{ x: "max-content" }}
                        dataSource={dataSource3}
                        columns={columns3}
                        size="small"
                        pagination={false}
                        summary={() =>
                          <Table.Summary fixed>
                            <Table.Summary.Row
                              style={{
                                textAlign: "right",
                                backgroundColor: "#b5f5ec"
                              }}
                            >
                              <Table.Summary.Cell>
                                รวมทั้งหมด
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                {xSumAmount.toLocaleString(undefined, {
                                  minimumFractionDigits: 2
                                })}
                              </Table.Summary.Cell>
                              <Table.Summary.Cell />
                              <Table.Summary.Cell>
                                รวมเงินที่ได้รับ
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                {xSumAmount2.toLocaleString(undefined, {
                                  minimumFractionDigits: 2
                                })}
                              </Table.Summary.Cell>
                              <Table.Summary.Cell /> <Table.Summary.Cell />
                              <Table.Summary.Cell />
                              <Table.Summary.Cell />
                              <Table.Summary.Cell />
                              <Table.Summary.Cell />
                            </Table.Summary.Row>
                          </Table.Summary>}
                      />
                      <br />
                    </div>
                  : <p>---ไม่มีข้อมูล---</p>}

                <Row>
                  <Space>
                    <Button ghost type="primary" onClick={bAddismclick}>
                      <PlusOutlined />เพิ่ม/แก้ไข งวดเงิน
                    </Button>
                  </Space>
                </Row>

                {/* <Divider orientation="left" style={{ borderColor: "#C2CFFF" }}>
                  <Text style={{ fontWeight: "bold" }}>การส่งเงินสมทบ</Text>
                </Divider>
                <div style={{ float: "right" }}>
                  <Form.Item>
                    <Button ghost type="success" onClick={bExcelTocmu}>
                      <FileExcelOutlined /> Excel
                    </Button>
                  </Form.Item>
                </div>
                {dataTocmu != ""
                  ? <Table
                      bordered
                      scroll={{ x: "max-content" }}
                      dataSource={dataTocmu}
                      columns={cTocmu}
                      size="small"
                      pagination={false}
                      summary={() =>
                        <Table.Summary fixed>
                          <Table.Summary.Row
                            style={{
                              textAlign: "right",
                              backgroundColor: "#b5f5ec"
                            }}
                          >
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell>รวม</Table.Summary.Cell>
                            <Table.Summary.Cell>
                              {xSumAmount2.toLocaleString(undefined, {
                                minimumFractionDigits: 2
                              })}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              {xSumDirect.toLocaleString(undefined, {
                                minimumFractionDigits: 2
                              })}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              {xSumF_A.toLocaleString(undefined, {
                                minimumFractionDigits: 2
                              })}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              {xSumOver70.toLocaleString(undefined, {
                                minimumFractionDigits: 2
                              })}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              {xSumOver30.toLocaleString(undefined, {
                                minimumFractionDigits: 2
                              })}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell />
                            <Table.Summary.Cell>
                              {xSumTo_cmu.toLocaleString(undefined, {
                                minimumFractionDigits: 2
                              })}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                          </Table.Summary.Row>
                        </Table.Summary>}
                      rowSelection={{
                        type: selectionType,
                        ...rowSelection
                      }}
                    />
                  : <p>---ไม่มีข้อมูล---</p>}
                <Row>
                  <Space>
                    <Button ghost type="primary" onClick={bAddToCmu}>
                      <PlusOutlined />เพิ่ม/แก้ไข เงินสมทบ
                    </Button>
                  </Space>
                </Row> */}

                <Divider orientation="left" style={{ borderColor: "#C2CFFF" }}>
                  <Text style={{ fontWeight: "bold" }}>สัญญาโครงการวิจัย</Text>
                </Divider>

                {dataContract2 != ""
                  ? <Table
                      bordered
                      scroll={{ x: "max-content" }}
                      dataSource={dataContract2}
                      columns={cContract2}
                      size="small"
                      // pagination={{
                      //   onChange: (zpage, zpageSize) => {
                      //     pageOnChange(zpage, zpageSize);
                      //   },
                      //   current: pageTable,
                      //   pageSize: pageSizeTable
                      // }}
                      pagination={false}
                      summary={() =>
                        <Table.Summary fixed>
                          <Table.Summary.Row
                            style={{
                              textAlign: "right",
                              backgroundColor: "#b5f5ec"
                            }}
                          >
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell>รวมทั้งหมด</Table.Summary.Cell>
                            <Table.Summary.Cell>
                              {xSumAmount3.toLocaleString(undefined, {
                                minimumFractionDigits: 2
                              })}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                          </Table.Summary.Row>
                        </Table.Summary>}
                    />
                  : <p>---ไม่มีข้อมูล---</p>}

                {/* {dataContract != ""
                  ? <div>
                      <Table
                        bordered
                        scroll={{ x: "max-content" }}
                        dataSource={dataContract}
                        columns={cContract}
                        size="small"
                        pagination={false}
                      />
                      <br />
                    </div>
                  : <p>---ไม่มีข้อมูล---</p>} */}
                <Button ghost type="primary" onClick={bContractclick}>
                  <PlusOutlined />เพิ่ม/แก้ไข สัญญาโครงการวิจัย
                </Button>

                <Divider orientation="left" style={{ borderColor: "#C2CFFF" }}>
                  <Text style={{ fontWeight: "bold" }}>เอกสารโครงการวิจัย</Text>
                </Divider>

                {dataFile1 != "" || dataFile2 != "" || dataFile3 != ""
                  ? <Tabs
                      onChange={callback}
                      type="card"
                      size="small"
                      defaultActiveKey="1"
                      activeKey={activeKeyshow}
                    >
                      <TabPane tab="เอกสารรับเข้า" key="1">
                        <Table
                          bordered
                          scroll={{ x: "max-content" }}
                          dataSource={dataFile1}
                          columns={cFile1}
                          size="small"
                          pagination={false}
                        />
                      </TabPane>
                      <TabPane tab="เอกสารส่งออก" key="2">
                        <Table
                          bordered
                          scroll={{ x: "max-content" }}
                          dataSource={dataFile2}
                          columns={cFile2}
                          size="small"
                          pagination={false}
                        />
                      </TabPane>
                      <TabPane tab="เอกสารอื่นๆ" key="3">
                        <Table
                          bordered
                          scroll={{ x: "max-content" }}
                          dataSource={dataFile3}
                          columns={cFile3}
                          size="small"
                          pagination={false}
                        />
                      </TabPane>
                    </Tabs>
                  : <p>---ไม่มีข้อมูล---</p>}
                <br />
                <Button ghost type="primary" onClick={bAddfileclick}>
                  <PlusOutlined />เพิ่ม/แก้ไข เอกสารโครงการวิจัย
                </Button>
              </Card>
              <br /> <br />
            </Form>
          </Col>
        </Row>
      </Content>

      <Footer_last />
    </React.Fragment>
  );
};
export default Research_Edit;
