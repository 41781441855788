import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import axios from "axios";

import {
  SearchOutlined,
  ClearOutlined,
  DeleteOutlined,
  EditOutlined,
  PaperClipOutlined,
  FileExcelOutlined,
  CheckOutlined
} from "@ant-design/icons";
import { createGlobalStyle } from "styled-components";

import {
  Row,
  Col,
  Button,
  Form,
  Table,
  Layout,
  Typography,
  Divider,
  Input,
  Space,
  Modal,
  Descriptions,
  Card,
  Select,
  Badge,
  BackTop,
  Tabs,
  Tag,
  PageHeader,
  Carousel,
  ConfigProvider
} from "antd";
import moment from "moment";
import "moment/locale/th";
import locale from "antd/es/date-picker/locale/th_TH";
import * as xlsx from "xlsx";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";
import "./index.css";

import swal from "sweetalert";

import background1 from "../pics/research_pic1.jpg";
import background2 from "../pics/research_pic2.jpg";
import background3 from "../pics/research_pic3.jpg";
import background4 from "../pics/research_pic4.jpg";
import background5 from "../pics/research_pic5.jpg";
import background6 from "../pics/research_pic6.jpg";
import background7 from "../pics/research_pic7.jpg";
import background8 from "../pics/research_pic8.jpg";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import thTH from "antd/lib/locale/th_TH";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

const { Content } = Layout;
const { Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const StyledForm = createGlobalStyle`.ant-form-item {
  margin-bottom: 5px;
}
`;

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 }
};

const configuredLocale = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    dateFormat: "DD MM BBBB", // DD MM BBBB
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker.lang,
      dateFormat: "DD MM BBBB", // DD MM BBBB
      dateTimeFormat: "DD MM BBBB HH:mm:ss",
      yearFormat: "BBBB"
    }
  }
};

dayjs.locale("th");
dayjs.extend(buddhistEra);

const Research_main = () => {
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [form4] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }
  const [dataSource1, _dataSource1] = useState();
  const [dataSource2, _dataSource2] = useState();
  const [dataSource4, _dataSource4] = useState();
  const [dataSource5, _dataSource5] = useState();
  const [dataSource6, _dataSource6] = useState();

  const [dataSourceEx, _dataSourceEx] = useState();
  const [dataSourceEx2, _dataSourceEx2] = useState();

  const [pageTable, _pageTable] = useState(1);
  const [pageSizeTable, _pageSizeTable] = useState(100);

  const [pageTable2, _pageTable2] = useState(1);
  const [pageSizeTable2, _pageSizeTable2] = useState(100);

  const [pageTable3, _pageTable3] = useState(1);
  const [pageSizeTable3, _pageSizeTable3] = useState(5);

  const [pageTable4, _pageTable4] = useState(1);
  const [pageSizeTable4, _pageSizeTable4] = useState(5);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);

  const [xtitle, _xtitle] = useState("");
  const [xtitle2, _xtitle2] = useState("");
  const [xNumData, _xNumData] = useState("");

  const [xSumAmount, _xSumAmount] = useState(0);
  const [xSumAmount2, _xSumAmount2] = useState(0);
  const [xSumAmount3, _xSumAmount3] = useState(0);

  const [xSumDirect, _xSumDirect] = useState(0);
  const [xSumF_A, _xSumF_A] = useState(0);
  const [xSumOver70, _xSumOver70] = useState(0);
  const [xSumOver30, _xSumOver30] = useState(0);
  const [xSumTo_cmu, _xSumTo_cmu] = useState(0);

  const [xStatus, _xStatus] = useState("");
  const [xStatusValue, _xStatusValue] = useState("0");

  const [xType, _xType] = useState("");
  const [xTypeValue, _xTypeValue] = useState("0");

  const [xData, _xData] = useState({
    cmumis_id: "",
    name_thai: "",
    name_eng: "",
    short_name: "",
    start_date: "",
    end_date: "",
    receipt: ""
  });

  const [xresearname, _xresearname] = useState("");

  const [xCountP1, _xCountP1] = useState("");
  const [xCountI1, _xCountI1] = useState("");
  const [xCountC1, _xCountC1] = useState("");
  const [xCountF1, _xCountF1] = useState("");
  const [xCountS1, _xCountS1] = useState("");

  const [xCountP2, _xCountP2] = useState("");
  const [xCountI2, _xCountI2] = useState("");
  const [xCountC2, _xCountC2] = useState("");
  const [xCountF2, _xCountF2] = useState("");
  const [xCountS2, _xCountS2] = useState("");

  const [show1, _show1] = useState(false);
  const [show2, _show2] = useState(true);
  const [show3, _show3] = useState(false);

  const [xid, _xid] = useState(1);
  const [xidR, _xidR] = useState(1);

  const [activeKeyshow, _activeKeyshow] = useState("1");

  const [dataFile1, _dataFile1] = useState();
  const [dataFile2, _dataFile2] = useState();
  const [dataFile3, _dataFile3] = useState();

  const [dataContract, _dataContract] = useState();
  const [dataContract2, _dataContract2] = useState();

  const [xcmumis_id, _xcmumis_id] = useState();

  const DatePicker = generatePicker(dayjsGenerateConfig);

  const [dataMod, _dataMod] = useState();

  const [xsummary, _xsummary] = useState(0);
  const [xcrc, _xcrc] = useState("");

  const [levelU, _levelU] = useState("");
  const [r_id, _r_id] = useState("");

  const [dataAgency, _dataAgency] = useState("");

  const [dataTocmu, _dataTocmu] = useState();

  const [xPCT_year, _xPCT_year] = useState();
  const [xPCT_type, _xPCT_type] = useState();
  const [xPCT_count, _xPCT_count] = useState();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const xresearch_id = localStorage.getItem("research_id");

    //console.log(token);

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");

          //ค้นหาสถานะโครงการ
          axios
            .get(xURL + "Project_status")
            .then(result => {
              //console.log(result.data);

              _xStatus(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหาประเภทโครงการ
          axios
            .get(xURL + "ptype")
            .then(result => {
              //console.log(result.data);

              _xType(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          var url = new URL(window.location.href);

          if (url.searchParams.get("cmumis_id")) {
            form1.setFieldsValue({
              edid: url.searchParams.get("cmumis_id")
            });
          }

          axios
            .post(xURL + "findlevel", { research_id: xresearch_id })
            .then(result => {
              // console.log(result.data.length);
              if (result.data.length > 0) {
                if (result.data[0].user_level_id == 4) {
                  bOkclick(xresearch_id, 0);

                  _r_id(xresearch_id);
                  _levelU("research");
                } else if (result.data[0].user_level_id == 3) {
                  bOkclick("", 0);
                  _levelU("finance");
                } else if (result.data[0].user_level_id == 7) {
                  bOkclick("", 4);

                  _xTypeValue("4");
                  _levelU("student");
                } else {
                  bOkclick("", 0);
                  _levelU("");
                }
              } else {
                bOkclick();
                _levelU("");
              }
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const bClearclick = () => {
    form1.resetFields();
    form2.resetFields();
    form3.resetFields();
    form4.resetFields();

    _dataSource1([]);
    _dataSource2([]);

    _dataSource4([]);
    _dataSource5([]);
    _dataSource6([]);

    _dataSourceEx([]);
    _dataSourceEx2([]);

    _pageTable(1);
    _pageSizeTable(100);

    _pageTable2(1);
    _pageSizeTable2(100);

    _xData({
      cmumis_id: "",
      name_thai: "",
      name_eng: "",
      short_name: "",
      start_date: "",
      end_date: "",
      receipt: ""
    });

    _xtitle("");
    _xtitle2("");
    _xNumData("");

    _xSumAmount(0);
    _xSumAmount2(0);
    _xSumAmount3(0);

    _xSumDirect(0);
    _xSumF_A(0);
    _xSumOver70(0);
    _xSumOver30(0);
    _xSumTo_cmu(0);

    _xresearname("");

    _xStatusValue("0");

    _xCountP1("");
    _xCountI1("");
    _xCountC1("");
    _xCountF1("");
    _xCountS1("");

    _show1(false);
    _show2(true);
    _show3(false);

    _xid(1);
    _xidR(0);

    _activeKeyshow(1);

    _dataFile1([]);
    _dataFile2([]);
    _dataFile3([]);

    _dataTocmu([]);

    if (levelU == "research") {
      bOkclick(r_id, 0);
    } else if (levelU == "student") {
      bOkclick("", 4);
    } else {
      bOkclick("", 0);
    }
  };

  const onFinish = f => {
    let ztitle = "";
    let xyearsearch = 0;

    let xdatesearch = 0,
      xsdate = "",
      xedate = "";

    let xstatussearch = 0,
      xstatus = "";

    let xreserchsearch = 0,
      xreserch_name = "";

    let xcmumis_idsearch = 0,
      xcmumis_id = "";

    let xprojectsearch = 0,
      xprojectname = "";

    let xtypesearch = 0,
      xtype = "";

    //ช่วงปี,วันที่
    if (f.ydate) {
      xyearsearch = 1;
      //console.log(dayjs(f.ydate).format('YYYY'));
    }

    if (f.sedate) {
      xdatesearch = 1;
      xsdate = dayjs(f.sedate[0]).format("YYYY-MM-DD");
      xedate = dayjs(f.sedate[1]).format("YYYY-MM-DD");

      //console.log(xsdate,xedate);
    }

    //สถานะโครงการ
    if (xStatusValue !== "0") {
      xstatussearch = 1;
      xstatus = xStatusValue;
    }

    //ชื่อนักวิจัย
    if (f.edresercher !== "") {
      xreserchsearch = 1;
      xreserch_name = f.edresercher;
    }

    //รหัสโครงการ
    if (f.edid !== "") {
      xcmumis_idsearch = 1;
      xcmumis_id = f.edid;
    }

    //ชื่อโครงการวิจัย
    if (f.edpjname !== "") {
      xprojectsearch = 1;
      xprojectname = f.edpjname;
    }

    //ประเภทโครงการ
    if (xTypeValue !== "0") {
      xtypesearch = 1;
      xtype = xTypeValue;
    }

    //หัวข้อ
    if (
      xyearsearch === 0 &&
      xdatesearch === 0 &&
      xstatussearch === 0 &&
      xreserchsearch === 0 &&
      xcmumis_idsearch === 0 &&
      xprojectsearch === 0 &&
      xtypesearch === 0
    ) {
      ztitle = "ทั้งหมด";
    } else {
      ztitle = "ตามเงื่อนไขที่เลือก";
    }

    let xidlevel = "";
    if (levelU == "research") {
      xidlevel = r_id;
    } else {
      xidlevel = "";
    }

    // ลิงค์มาจากหน้ายุทธศาสตร์
    var url = new URL(window.location.href);

    let zPTCyear = "",
      zPTCtype = 0;

    if (url.searchParams.get("PTCyear")) {
      zPTCyear = url.searchParams.get("PTCyear");
      zPTCtype = url.searchParams.get("PTCtype");
    }

    axios
      .post(xURL + "Allproject", {
        yearsearch: xyearsearch,
        ydate: dayjs(f.ydate).format("YYYY"),
        datesearch: xdatesearch,
        sdate: xsdate,
        edate: xedate,
        statussearch: xstatussearch,
        status: xstatus,
        reserchsearch: xreserchsearch,
        reserch_name: xreserch_name,
        cmumis_idsearch: xcmumis_idsearch,
        cmumis_id: xcmumis_id,
        projectsearch: xprojectsearch,
        projectname: xprojectname,
        idlevel: xidlevel,
        typesearch: xtypesearch,
        type2: xtype,
        ptcyear: zPTCyear,
        pcttype: zPTCtype,
        showdata: "all"
      })
      .then(result => {
        //console.log(result.data);

        if (result.data === "") {
          Modal.warning({
            title: "แสดงรายการไม่ได้",
            content: "ไม่มีข้อมูลตามเงื่อนไขที่เลือก"
          });
        } else {
          ShowAll(result.data);
          _xtitle(ztitle);
          //_xtitle("ตามปีงบประมาณ " + moment(f.ydate).format("YYYY"));
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    axios
      .post(xURL + "Allproject", {
        yearsearch: xyearsearch,
        ydate: dayjs(f.ydate).format("YYYY"),
        datesearch: xdatesearch,
        sdate: xsdate,
        edate: xedate,
        statussearch: xstatussearch,
        status: xstatus,
        reserchsearch: xreserchsearch,
        reserch_name: xreserch_name,
        cmumis_idsearch: xcmumis_idsearch,
        cmumis_id: xcmumis_id,
        projectsearch: xprojectsearch,
        projectname: xprojectname,
        idlevel: xidlevel,
        typesearch: xtypesearch,
        type2: xtype,
        ptcyear: zPTCyear,
        pcttype: zPTCtype,
        showdata: "excel"
      })
      .then(result => {
        //.log(result.data);

        if (result.data === "") {
          Modal.warning({
            title: "แสดงรายการไม่ได้",
            content: "ไม่มีข้อมูลตามเงื่อนไขที่เลือก"
          });
        } else {
          ShowAllexcel(result.data);
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    /*
  axios
    .get(xURL + "Allproject")
    .then(result => {
      ShowAll(result.data);
      _xtitle("ทั้งหมด");
    })
    .catch(function(error) {
      // handle error
      console.log("problem here", error);
    });*/
  };

  const bOkclick = (id, ztype) => {
    const f = form1.getFieldsValue();

    let ztitle = "";
    let xyearsearch = 0;

    let xdatesearch = 0,
      xsdate = "",
      xedate = "";

    let xstatussearch = 0,
      xstatus = "";

    let xreserchsearch = 0,
      xreserch_name = "";

    let xcmumis_idsearch = 0,
      xcmumis_id = "";

    let xprojectsearch = 0,
      xprojectname = "";

    let xtypesearch = 0,
      xtype2 = "";

    //ช่วงปี,วันที่
    if (f.ydate != null) {
      xyearsearch = 1;
    }

    if (typeof f.sedate != "undefined") {
      xdatesearch = 1;
      xsdate = dayjs(f.sedate[0]).format("YYYY-MM-DD");
      xedate = dayjs(f.sedate[1]).format("YYYY-MM-DD");
    }

    //สถานะโครงการ
    if (xStatusValue !== "0") {
      xstatussearch = 1;
      xstatus = xStatusValue;
    }

    //ชื่อนักวิจัย
    if (f.edresercher !== "") {
      xreserchsearch = 1;
      xreserch_name = f.edresercher;
    }

    //รหัสโครงการ
    if (f.edid !== "") {
      xcmumis_idsearch = 1;
      xcmumis_id = f.edid;
    }

    //ชื่อโครงการวิจัย
    if (f.edpjname !== "") {
      xprojectsearch = 1;
      xprojectname = f.edpjname;
    }

    //ประเภทโครงการ
    if (xTypeValue !== "0") {
      xtypesearch = 1;
      xtype2 = xTypeValue;
    }

    if (ztype == 4) {
      xtypesearch = 1;
      xtype2 = 4;
    }

    //หัวข้อ
    if (
      xyearsearch === 0 &&
      xdatesearch === 0 &&
      xstatussearch === 0 &&
      xreserchsearch === 0 &&
      xcmumis_idsearch === 0 &&
      xprojectsearch === 0 &&
      xtypesearch === 0
    ) {
      ztitle = "ทั้งหมด";
    } else {
      ztitle = "ตามเงื่อนไขที่เลือก";
    }

    let xidlevel = "";

    if (id !== "") {
      xidlevel = id;
    } else {
      xidlevel = "";
    }

    // console.log(dataAgency)
    var url = new URL(window.location.href);
    let agency_id = "";

    if (url.searchParams.get("agency_id")) {
      agency_id = url.searchParams.get("agency_id");
    }

    let xtype = "",
      xreportYear = "",
      xpage = "";

    if (url.searchParams.get("page")) {
      xpage = url.searchParams.get("page");
      xtype = url.searchParams.get("type");
      xreportYear = url.searchParams.get("year");
    }

    // ลิงค์มาจากหน้ายุทธศาสตร์
    let zPTCyear = "",
      zPTCtype = 0;

    if (url.searchParams.get("PTCyear")) {
      zPTCyear = url.searchParams.get("PTCyear");
      zPTCtype = url.searchParams.get("PTCtype");
    }

    axios
      .post(xURL + "Allproject", {
        yearsearch: xyearsearch,
        ydate: dayjs(f.ydate).format("YYYY"),
        datesearch: xdatesearch,
        sdate: xsdate,
        edate: xedate,
        statussearch: xstatussearch,
        status: xstatus,
        reserchsearch: xreserchsearch,
        reserch_name: xreserch_name,
        cmumis_idsearch: xcmumis_idsearch,
        cmumis_id: xcmumis_id,
        projectsearch: xprojectsearch,
        projectname: xprojectname,
        idlevel: xidlevel,
        agency_id: agency_id,
        page: xpage,
        type: xtype,
        reportyear: xreportYear,
        typesearch: xtypesearch,
        type2: xtype2,
        ptcyear: zPTCyear,
        pcttype: zPTCtype,
        showdata: "all"
      })
      .then(result => {
        //.log(result.data);

        if (result.data === "") {
          Modal.warning({
            title: "แสดงรายการไม่ได้",
            content: "ไม่มีข้อมูลตามเงื่อนไขที่เลือก"
          });
        } else {
          ShowAll(result.data);
          _xtitle(ztitle);
          //_xtitle("ตามปีงบประมาณ " + moment(f.ydate).format("YYYY"));
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    axios
      .post(xURL + "Allproject", {
        yearsearch: xyearsearch,
        ydate: dayjs(f.ydate).format("YYYY"),
        datesearch: xdatesearch,
        sdate: xsdate,
        edate: xedate,
        statussearch: xstatussearch,
        status: xstatus,
        reserchsearch: xreserchsearch,
        reserch_name: xreserch_name,
        cmumis_idsearch: xcmumis_idsearch,
        cmumis_id: xcmumis_id,
        projectsearch: xprojectsearch,
        projectname: xprojectname,
        idlevel: xidlevel,
        agency_id: agency_id,
        page: xpage,
        type: xtype,
        reportyear: xreportYear,
        typesearch: xtypesearch,
        type2: xtype2,
        ptcyear: zPTCyear,
        pcttype: zPTCtype,
        showdata: "excel"
      })
      .then(result => {
        //.log(result.data);

        if (result.data === "") {
          Modal.warning({
            title: "แสดงรายการไม่ได้",
            content: "ไม่มีข้อมูลตามเงื่อนไขที่เลือก"
          });
        } else {
          ShowAllexcel(result.data);
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    /*
  axios
    .get(xURL + "Allproject")
    .then(result => {
      ShowAll(result.data);
      _xtitle("ทั้งหมด");
    })
    .catch(function(error) {
      // handle error
      console.log("problem here", error);
    });*/
  };

  const ShowAll = alldata => {
    const Tb1 = [],
      Tb2 = [];
    let xCount = 0;

    let xP = 0,
      xI = 0,
      xC = 0,
      xF = 0,
      xS = 0;

    alldata.forEach((i, idx) => {
      //let xsdate = moment(i.start_date.substr(0, 10)).format("DD/MM/YYYY");
      /*
      let xsdate = moment(i.start_date.substr(0, 10))
        .add(543, "year")
        .add(1, "day")
        .format("L");

      let xedate = moment(i.end_date.substr(0, 10))
        .add(543, "year")
        .add(1, "day")
        .format("L");

      xsdate = moment(xsdate, "DD/MM/YYYY");
      xedate = moment(xedate, "DD/MM/YYYY");*/

      let xsdate = dayjs(i.start_date).format("DD/MM/BBBB");
      let xedate = dayjs(i.end_date).format("DD/MM/BBBB");

      //console.log(xsdate._i);

      xCount = xCount + 1;

      let xbudget = 0,
        xresearch = "";

      if (i.budget > 0) {
        xbudget = i.budget.toLocaleString(undefined, {
          minimumFractionDigits: 2
        });
      }

      if (!i.researname) {
        xresearch = "";
      } else {
        xresearch = i.researname;
      }

      let xreceipt = "";

      if (i.receipt == "Y") {
        xreceipt = "ส่งใบเสร็จ";
      } else if (i.receipt == "N") {
        xreceipt = "ไม่ส่งใบเสร็จ";
      } else {
        xreceipt = "";
      }

      Tb1.push({
        key: idx,
        num: idx + 1,
        cmumis_id: i.cmumis_id,
        name_P: i.name_p,
        name_shotP: i.short_name,
        reseatcher_name: xresearch,
        start_date: xsdate,
        end_date: xedate,
        budget: (
          <Text style={{ fontWeight: "bold" }}>
            {xbudget}
          </Text>
        ),
        status: i.status_id,
        receipt: xreceipt
      });

      // Tb2.push({
      //   cmumis_id: i.cmumis_id,
      //   name_P: i.name_p,
      //   name_shotP: i.short_name,
      //   pub_type_cmu_name: i.pub_type_cmu_name,
      //   reseatcher_name: xresearch,
      //   start_date: xsdate,
      //   end_date: xedate,
      //   budget: xbudget,
      //   status: i.status_id,
      //   agency: i.agency,
      //   agency_type: i.agency_type,
      //   agency_type2: i.agency_type2,
      //   receipt: xreceipt
      // });

      if (i.status_id === "P") {
        xP = xP + 1;
      }
      if (i.status_id === "I") {
        xI = xI + 1;
      }
      if (i.status_id === "C") {
        xC = xC + 1;
      }
      if (i.status_id === "F") {
        xF = xF + 1;
      }
      if (i.status_id === "S") {
        xS = xS + 1;
      }
    });

    _xCountP1(xP + " โครงการ");
    _xCountI1(xI + " โครงการ");

    _xCountC1(xC + " โครงการ");
    _xCountF1(xF + " โครงการ");
    _xCountS1(xS + " โครงการ");

    _xNumData(" * " + xCount + " โครงการ");

    _show1(true);

    //console.log(Tb1);
    _dataSource1(Tb1);
    // _dataSourceEx(Tb2);
  };

  const ShowAllexcel = alldata => {
    const Tb2 = [];
    let xCount = 0;

    alldata.forEach((i, idx) => {
      let xsdate = dayjs(i.start_date).format("DD/MM/BBBB");
      let xedate = dayjs(i.end_date).format("DD/MM/BBBB");

      xCount = xCount + 1;

      let xbudget = 0,
        xbudget2 = 0,
        xresearch = "";

      if (i.budget > 0) {
        xbudget = parseFloat(i.budget).toFixed(2);
      }

      if (i.budget2 > 0) {
        xbudget2 = parseFloat(i.budget2).toFixed(2);
      }

      if (!i.researname) {
        xresearch = "";
      } else {
        xresearch = i.researname;
      }

      let xreceipt = "";

      if (i.receipt == "Y") {
        xreceipt = "ส่งใบเสร็จ";
      } else if (i.receipt == "N") {
        xreceipt = "ไม่ส่งใบเสร็จ";
      } else {
        xreceipt = "";
      }

      Tb2.push({
        cmumis_id: i.cmumis_id,
        name_P: i.name_p,
        name_shotP: i.short_name,
        pub_type_cmu_name: i.pub_type_cmu_name,
        reseatcher_name: xresearch,
        start_date: xsdate,
        end_date: xedate,
        budgetSum: xbudget2,
        budget: xbudget,
        status: i.status_id,
        agency: i.agency,
        agency_type: i.agency_type,
        agency_type2: i.agency_type2,
        receipt: xreceipt
      });
    });

    _dataSourceEx(Tb2);
  };

  const Showlist = cmumis_id => {
    form2.resetFields();

    _xSumAmount(0);
    _xSumAmount2(0);
    _xSumAmount3(0);

    _xSumDirect(0);
    _xSumF_A(0);
    _xSumOver70(0);
    _xSumOver30(0);
    _xSumTo_cmu(0);

    _dataSourceEx2([]);

    axios
      .post(xURL + "Findproject", {
        cmumis_id: cmumis_id
      })
      .then(result => {
        //console.log(result.data);

        let xpub_type_cmu_name = "",
          xpub_type_short = "";

        if (result.data[0].pub_type_cmu_name != null) {
          xpub_type_cmu_name = result.data[0].pub_type_cmu_name;
        }
        if (result.data[0].pub_type_short != null) {
          xpub_type_short = result.data[0].pub_type_short;
        }

        form3.setFieldsValue({
          slstatus2: result.data[0].status,
          slppt_cmu: xpub_type_cmu_name + " " + xpub_type_short
        });

        _xData(result.data[0]);

        setIsModalVisible(true);
        setIsModalVisible2(false);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    _dataSource2([]);
    _dataSource5([]);
    _dataSource6([]);

    GetAgency(cmumis_id);
    GetResearch(cmumis_id);
    GetInstallment1(cmumis_id);

    GetTable(cmumis_id);
    GetTable2(cmumis_id);
    GetTable3(cmumis_id);

    GetContract(cmumis_id);

    GetToCmu(cmumis_id);
  };

  const GetAgency = cmumis_id => {
    axios
      .post(xURL + "find_agency", {
        cmumis_id: cmumis_id
      })
      .then(result => {
        //console.log(result);
        const Tb1 = [];
        let xnum = 1,
          xsum = 0;

        result.data.forEach((i, idx) => {
          Tb1.push({
            key: idx,
            c1: "แหล่งทุนที่ " + parseInt(idx + 1),
            c2: i.agency,
            c3: "งบประมาณ",
            c4: (
              <Text style={{ fontWeight: "bold" }}>
                {i.budget.toLocaleString(undefined, {
                  minimumFractionDigits: 2
                })}
              </Text>
            ),
            c5: "บาท",
            agency_id: i.agency_id
          });

          if (i.budget !== "") {
            xsum = parseFloat(xsum) + parseFloat(i.budget);
            xnum = xnum + 1;
          }
        });

        Tb1.push({
          key: xnum - 1,
          c1: "",
          c2: "",
          c3: "รวมงบประมาณ",
          c4: (
            <Text style={{ fontWeight: "bold" }}>
              {xsum.toLocaleString(undefined, {
                minimumFractionDigits: 2
              })}
            </Text>
          ),
          c5: "บาท"
        });

        _xid(xnum + 1);

        //  console.log(Tb1);

        _dataSource2(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetResearch = cmumis_id => {
    axios
      .post(xURL + "find_research", {
        cmumis_id: cmumis_id
      })
      .then(result => {
        //console.log(result);
        const Tb1 = [];
        let xnum = 0;

        result.data.forEach((i, idx) => {
          Tb1.push({
            key: idx,
            c1: "นักวิจัยที่ " + parseInt(idx + 1),
            c2: i.researname,
            c3: i.researcher_p_position_name,
            re_staff_id: i.researcher_staff_id,
            re_p_id: i.researcher_position_id,
            re_pp_id: i.researcher_project_position_id
          });

          xnum = xnum + 1;
        });

        _xidR(xnum + 1);

        _dataSource5(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetInstallment1 = cmumis_id => {
    axios
      .post(xURL + "find_installment1", {
        cmumis_id: cmumis_id,
        order: 1
      })
      .then(result => {
        //console.log(result);
        const Tb1 = [];

        let xsum = 0,
          xsum2 = 0;

        result.data.forEach((i, idx) => {
          let xmoney_bath = "",
            xdetail = "",
            xremark = "",
            xdate_warn = "",
            xdate_due = "",
            xdate_receive = "",
            xtotal_receive = "";

          if (i.money_bath != null) {
            xmoney_bath = i.money_bath.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.total_receive != null) {
            xtotal_receive = i.total_receive.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.detail != null) {
            xdetail = i.detail;
          }

          if (i.remark != null) {
            xremark = i.remark;
          }

          if (i.tracking_date && i.tracking_date != "0000-00-00") {
            xdate_warn = dayjs(i.tracking_date).format("DD/MM/BBBB");
          }
          if (i.deadline_date && i.deadline_date != "0000-00-00") {
            xdate_due = dayjs(i.deadline_date).format("DD/MM/BBBB");
          }
          if (i.date_receive && i.date_receive != "0000-00-00") {
            xdate_receive = dayjs(i.date_receive).format("DD/MM/BBBB");
          }

          let url_file = "",
            url_fileiv = "";

          if (i.file_transfer) {
            if (i.file_transfer !== "undefined") {
              url_file = `${xURL}file4/${i.file_transfer}`;
            } else {
              url_file = "";
            }
          }

          if (i.file_invoice) {
            if (i.file_invoice !== "undefined") {
              url_fileiv = `${xURL}file3/${i.file_invoice}`;
            } else {
              url_fileiv = "";
            }
          }

          Tb1.push({
            key: idx,
            term: idx + 1,
            amount: xmoney_bath,
            detail: xdetail,
            remark: xremark,
            date_warn: xdate_warn,
            date_due: xdate_due,
            date_receive: xdate_receive,
            total_usd: i.total_usd,
            total_receive: xtotal_receive,
            linkfile: url_file,
            linkfile2: i.file_invoice,
            linkfileiv: url_fileiv
          });

          xsum = xsum + parseFloat(i.money_bath);

          if (i.total_receive) {
            xsum2 = xsum2 + parseFloat(i.total_receive);
          }
        });

        _dataSource6(Tb1);
        _xSumAmount(xsum);
        _xSumAmount2(xsum2);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetTable = cmumis_id => {
    axios
      .post(xURL + "find_file", {
        cmumis_id: cmumis_id,
        zorder: 1
      })
      .then(result => {
        //console.log(result);
        const Tb1 = [];

        result.data.forEach((i, idx) => {
          /*
          let xsdate = moment(i.date.substr(0, 10))
            .add(543, "year")
            .add(1, "day")
            .format("L");*/

          let xsdate = dayjs(i.date).format("DD/MM/BBBB");

          let url_file = "";

          if (xlocation === "localhost") {
            url_file = `${xURL}file1_1/${i.file_name}`;
          } else {
            url_file = `${xURL}file1/${i.file_name}`;
          }

          Tb1.push({
            key: idx,
            num: idx + 1,
            sdate: xsdate,
            iddocget: i.doc_no_receive,
            iddoc: i.doc_no,
            docname: i.name,
            tfrom: i.doc_from,
            tto: i.doc_to,
            remark: i.remark,
            linkfile: url_file
          });
        });

        _dataFile1(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetTable2 = cmumis_id => {
    axios
      .post(xURL + "find_file", {
        cmumis_id: cmumis_id,
        zorder: 2
      })
      .then(result => {
        //console.log(result);
        const Tb1 = [];

        result.data.forEach((i, idx) => {
          /*
          let xsdate = moment(i.date.substr(0, 10))
            .add(543, "year")
            .add(1, "day")
            .format("L");*/

          let xsdate = dayjs(i.date).format("DD/MM/BBBB");

          let url_file = "";

          if (xlocation === "localhost") {
            url_file = `${xURL}file1_1/${i.file_name}`;
          } else {
            url_file = `${xURL}file1/${i.file_name}`;
          }

          Tb1.push({
            key: idx,
            num: idx + 1,
            sdate: xsdate,
            iddocpush: i.doc_no_send,
            docname: i.name,
            tfrom: i.doc_from,
            tto: i.doc_to,
            remark: i.remark,
            linkfile: url_file
          });
        });

        _dataFile2(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetTable3 = cmumis_id => {
    axios
      .post(xURL + "find_file", {
        cmumis_id: cmumis_id,
        zorder: 3
      })
      .then(result => {
        //console.log(result);
        const Tb1 = [];

        result.data.forEach((i, idx) => {
          /*
          let xsdate = moment(i.date.substr(0, 10))
            .add(543, "year")
            .add(1, "day")
            .format("L");*/

          let xsdate = dayjs(i.date).format("DD/MM/BBBB");

          let url_file = "";

          if (xlocation === "localhost") {
            url_file = `${xURL}file1_1/${i.file_name}`;
          } else {
            url_file = `${xURL}file1/${i.file_name}`;
          }

          Tb1.push({
            key: idx,
            num: idx + 1,
            sdate: xsdate,
            iddoc: i.doc_no,
            remark: i.remark,
            linkfile: url_file
          });
        });

        _dataFile3(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetContract = cmumis_id => {
    // axios
    //   .post(xURL + "find_contract", {
    //     cmumis_id: cmumis_id
    //   })
    //   .then(result => {
    //     //console.log(result);
    //     const Tb1 = [];

    //     result.data.forEach((i, idx) => {
    //       let xsdate = "",
    //         xedate = "",
    //         xbudget = "";

    //       if (i.start_date != null) {
    //         /*
    //         xsdate = moment(
    //           moment(i.start_date.substr(0, 10)).add(1, "day").format("L"),
    //           "DD/MM/YYYY"
    //         )._i;

    //         xedate = moment(
    //           moment(i.end_date.substr(0, 10)).add(1, "day").format("L"),
    //           "DD/MM/YYYY"
    //         )._i;*/

    //         xsdate = dayjs(i.start_date).format("DD/MM/BBBB");
    //         xedate = dayjs(i.end_date).format("DD/MM/BBBB");

    //         xbudget = i.budget.toLocaleString();
    //       } else {
    //         xsdate = "";
    //         xedate = "";
    //         xbudget = "";
    //       }

    //       Tb1.push({
    //         key: idx,
    //         num: idx + 1,
    //         grant_no: i.grant_number,
    //         po: i.po_number,
    //         description: i.description,
    //         cgrant: i.cmod_no,
    //         sdate: xsdate,
    //         edate: xedate,
    //         budget: xbudget,
    //         money: i.currency
    //       });
    //     });

    //     _dataContract(Tb1);
    //   })
    //   .catch(function(error) {
    //     // handle error
    //     console.log("problem here", error);
    //   });

    axios
      .post(xURL + "find_mod", {
        cmumis_id: cmumis_id
      })
      .then(result => {
        //console.log(result);
        const Tb1 = [];
        let xsum = 0,
          xname = "";

        result.data.forEach((i, idx) => {
          let xsdate = dayjs(i.start_date).format("DD/MM/BBBB");
          let xedate = dayjs(i.end_date).format("DD/MM/BBBB");

          let url_file = "",
            xbudget = "0";

          url_file = `${xURL}file2/${i.file}`;

          if (i.budget) {
            xbudget = i.budget.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });

            xsum = xsum + i.budget;
          }

          Tb1.push({
            key: idx,
            num: idx + 1,
            grant_no: i.grant_number,
            po: i.po_number,
            Modification: i.mod_no,
            sdate: xsdate,
            edate: xedate,
            budget: xbudget,
            currency: i.currency_name,
            description: i.remark,
            linkfile: url_file
          });

          xname = i.currency_name;
        });

        _xcrc(xname);
        _xsummary(xsum.toLocaleString());
        _xSumAmount3(xsum);

        // _dataMod(Tb1);
        _dataContract2(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetToCmu = cmumis_id => {
    axios
      .post(xURL + "find_to_cmu", {
        cmumis_id: cmumis_id
      })
      .then(result => {
        const Tb1 = [],
          Tb2 = [];

        let xsum1 = 0,
          xsum2 = 0,
          xsum3 = 0,
          xsum4 = 0,
          xsum5 = 0,
          xsum0 = 0;

        result.data.forEach((i, idx) => {
          let xdate_receive = "",
            xtotal_receive = "",
            xdirect = "",
            xf_a = "",
            xoverhead70 = "",
            xoverhead30 = "",
            xdate_to_cmu = "",
            xmoney_to_cmu = "";

          if (i.total_receive != null) {
            xtotal_receive = i.total_receive.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.date_receive && i.date_receive != "0000-00-00") {
            xdate_receive = dayjs(i.date_receive).format("DD/MM/BBBB");
          }

          if (i.direct != null) {
            xdirect = i.direct.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.fa != null) {
            xf_a = i.fa.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.overhead70 != null) {
            xoverhead70 = i.overhead70.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.overhead30 != null) {
            xoverhead30 = i.overhead30.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.date_to_cmu && i.date_to_cmu != "0000-00-00") {
            xdate_to_cmu = dayjs(i.date_to_cmu).format("DD/MM/BBBB");
          }

          if (i.money_to_cmu != null) {
            xmoney_to_cmu = i.money_to_cmu.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          let url_file_to_cmu = "",
            url_file_invoice = "",
            url_file_transfer = "";

          if (i.file_to_cmu) {
            if (i.file_to_cmu !== "undefined") {
              url_file_to_cmu = `${xURL}file5/${i.file_to_cmu}`;
            } else {
              url_file_to_cmu = "";
            }
          }

          if (i.file_invoice) {
            if (i.file_invoice !== "undefined") {
              url_file_invoice = `${xURL}file3/${i.file_invoice}`;
            } else {
              url_file_invoice = "";
            }
          }

          if (i.file_transfer) {
            if (i.file_transfer !== "undefined") {
              url_file_transfer = `${xURL}file4/${i.file_transfer}`;
            } else {
              url_file_transfer = "";
            }
          }

          if (i.direct) {
            xsum1 = xsum1 + i.direct;
          }
          if (i.fa) {
            xsum2 = xsum2 + i.fa;
          }
          if (i.overhead70) {
            xsum3 = xsum3 + i.overhead70;
          }
          if (i.overhead30) {
            xsum4 = xsum4 + i.overhead30;
          }
          if (i.money_to_cmu) {
            xsum5 = xsum5 + i.money_to_cmu;
          }
          if (i.total_receive) {
            xsum0 = xsum0 + i.total_receive;
          }

          Tb1.push({
            key: idx,
            num: idx + 1,
            detail: i.detail,
            date_receive: xdate_receive,
            total_receive: xtotal_receive,
            direct: xdirect,
            f_a: xf_a,
            overhead70: xoverhead70,
            overhead30: xoverhead30,
            date_to_cmu: xdate_to_cmu,
            money_to_cmu: xmoney_to_cmu,
            file_to_cmu: url_file_to_cmu,
            fileinvoice: url_file_invoice,
            filetransfer: url_file_transfer
          });

          Tb2.push({
            num: idx + 1,
            detail: i.detail,
            date_receive: xdate_receive,
            total_receive: xtotal_receive,
            direct: xdirect,
            f_a: xf_a,
            overhead70: xoverhead70,
            overhead30: xoverhead30,
            date_to_cmu: xdate_to_cmu,
            money_to_cmu: xmoney_to_cmu
          });
        });

        Tb2.push({
          num: "",
          detail: "",
          date_receive: "รวม",
          total_receive: xsum0,
          direct: xsum1,
          f_a: xsum2,
          overhead70: xsum3,
          overhead30: xsum4,
          date_to_cmu: "",
          money_to_cmu: xsum5
        });

        _dataTocmu(Tb1);
        _dataSourceEx2(Tb2);

        _xSumDirect(xsum1);
        _xSumF_A(xsum2);
        _xSumOver70(xsum3);
        _xSumOver30(xsum4);
        _xSumTo_cmu(xsum5);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Showlist2 = reseatcher_name => {
    axios
      .post(xURL + "Findproject2", {
        reseatcher_name: reseatcher_name
      })
      .then(result => {
        const Tb1 = [];
        let xcount = 0;

        let xP = 0,
          xI = 0,
          xC = 0,
          xF = 0,
          xS = 0;

        result.data.forEach((i, idx) => {
          //let xsdate = moment(i.start_date.substr(0, 10)).format("DD/MM/YYYY");
          /*
          let xsdate = moment(i.start_date.substr(0, 10))
            .add(543, "year")
            .add(1, "day")
            .format("L");

          let xedate = moment(i.end_date.substr(0, 10))
            .add(543, "year")
            .add(1, "day")
            .format("L");

          xsdate = moment(xsdate, "DD/MM/YYYY");
          xedate = moment(xedate, "DD/MM/YYYY");*/

          let xsdate = dayjs(i.start_date).format("DD/MM/BBBB");
          let xedate = dayjs(i.end_date).format("DD/MM/BBBB");

          //console.log(xsdate._i);

          Tb1.push({
            key: idx,
            num: idx + 1,
            cmumis_id: i.cmumis_id,
            name_P: i.name_p,
            start_date: xsdate,
            end_date: xedate,
            budget: (
              <Text style={{ fontWeight: "bold" }}>
                {i.budget.toLocaleString()}
              </Text>
            ),
            status: i.status_id
          });

          _xresearname("โครงการวิจัย [" + i.researname + "]");

          if (i.status_id === "P") {
            xP = xP + 1;
          }
          if (i.status_id === "I") {
            xI = xI + 1;
          }
          if (i.status_id === "C") {
            xC = xC + 1;
          }
          if (i.status_id === "F") {
            xF = xF + 1;
          }
          if (i.status_id === "S") {
            xS = xS + 1;
          }

          xcount = xcount + 1;
        });

        _xCountP2(xP + " โครงการ");
        _xCountI2(xI + " โครงการ");

        _xCountC2(xC + " โครงการ");
        _xCountF2(xF + " โครงการ");
        _xCountS2(xS + " โครงการ");

        _xtitle2("จำนวนโครงการวิจัย " + xcount + " โครงการ");
        _dataSource4(Tb1);

        setIsModalVisible2(true);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bDeleteclick = cmumis_id => {
    swal({
      title: "ยืนยัน ลบโครงการวิจัย",
      text: "รหัส CMU MIS: " + cmumis_id,
      icon: "warning",
      dangerMode: true,
      buttons: ["ยกเลิก", "ยืนยัน"]
    }).then(willDelete => {
      if (willDelete) {
        axios
          .post(xURL + "select_delete", {
            cmumis_id: cmumis_id,
            xcheck: 1
          })
          .then(result => {
            deletedata(cmumis_id, result.data[0].id);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        //bClearclick();

        swal("แจ้งเตือน!", "ลบโครงการวิจัยสำเร็จ!", "success").then(() => {
          if (levelU == "research") {
            bOkclick(r_id, 0);
          } else if (levelU == "student") {
            bOkclick("", 4);
          } else {
            bOkclick("", 0);
          }
        });
      }
    });
    /*
    confirm({
      title: <Text style={{ color: "#003a8c" }}>ยืนยัน ลบโครงการวิจัย</Text>,
      icon: <ExclamationCircleOutlined />,
      content: "รหัส CMU MIS: " + cmumis_id,
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk() {
        axios
          .post(xURL + "select_delete", {
            cmumis_id: cmumis_id,
            xcheck: 1
          })
          .then(result => {
            deletedata(cmumis_id, result.data[0].id);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        //bClearclick();
        bOkclick();
      }
    });*/
  };

  const deletedata = (cmumis_id, project_id) => {
    //console.log(cmumis_id, project_id);

    axios
      .post(xURL + "delete", {
        project_id: project_id.toString(),
        xcheck: 2
      })
      .then(result => {})
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    axios
      .post(xURL + "delete", {
        project_id: project_id.toString(),
        xcheck: 3
      })
      .then(result => {})
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    axios
      .post(xURL + "delete", {
        project_id: project_id.toString(),
        xcheck: 4
      })
      .then(result => {})
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    axios
      .post(xURL + "delete", {
        project_id: project_id.toString(),
        xcheck: 5
      })
      .then(result => {})
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    axios
      .post(xURL + "delete", {
        project_id: project_id.toString(),
        xcheck: 6
      })
      .then(result => {})
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    axios
      .post(xURL + "delete", {
        cmumis_id: cmumis_id,
        xcheck: 1
      })
      .then(result => {})
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Showlistcontract = zdata => {
    if (zdata.cgrant < 1) {
      swal("แจ้งเตือน!", "ไม่มีสัญญาย่อย!", "warning").then(() => {
        if (levelU == "research") {
          bOkclick(r_id, 0);
        } else if (levelU == "student") {
          bOkclick("", 4);
        } else {
          bOkclick("", 0);
        }
      });
    } else {
      axios
        .post(xURL + "find_mod", {
          cmumis_id: xData.cmumis_id,
          grant_no: zdata.grant_no,
          po_no: zdata.po
        })
        .then(result => {
          //console.log(result);
          const Tb1 = [];
          let xsum = 0,
            xname = "";

          result.data.forEach((i, idx) => {
            let xsdate = dayjs(i.start_date).format("DD/MM/BBBB");
            let xedate = dayjs(i.end_date).format("DD/MM/BBBB");

            let url_file = "";

            url_file = `${xURL}file2/${i.file}`;

            Tb1.push({
              key: idx,
              num: idx + 1,
              grant_no: i.grant_number,
              po: i.po_number,
              Modification: i.mod_no,
              sdate: xsdate,
              edate: xedate,
              budget: i.budget.toLocaleString(),
              currency: i.currency_name,
              description: i.remark,
              linkfile: url_file
            });

            xsum = xsum + i.budget;
            xname = i.currency_name;
          });

          _xcrc(xname);
          _xsummary(xsum.toLocaleString());

          _dataMod(Tb1);

          _show3(true);
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    }
  };

  const bExcelclick = () => {
    let xdata = [];
    let header = [
      "รหัสโครงการ",
      "ชื่อโครงการวิจัย",
      "ชื่อย่อโครงการ",
      "ยุทธศาสตร์ของมหาวิทยาลัย",
      "ชื่อหัวหน้าโครงการวิจัย",
      "วันที่เริ่มต้น",
      "วันที่สิ้นสุด",
      "งบประมาณรวมทั้งโครงการ",
      "งบประมาณ",
      "สถานะโครงการวิจัย",
      "แหล่งทุน",
      "ประเภทแหล่งทุน",
      "สังกัด",
      "นำส่งใบเสร็จให้แหล่งทุน"
    ];

    xdata.push(["โครงการวิจัย"]);
    xdata.push([""]);
    xdata.push(header);

    let excmumis_id = "",
      exname_P = "",
      exname_shotP = "",
      expub_type_cmu_name = "",
      exreseatcher_name = "",
      exstart_date = "",
      exend_date = "",
      exbudgetSum = "",
      exbudget = "";

    let chk = 0;

    for (var row in dataSourceEx) {
      const xx = [];

      for (var col in dataSourceEx[row]) {
        if (col == "cmumis_id") {
          if (excmumis_id == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (dataSourceEx[row]["cmumis_id"] == excmumis_id) {
              xx.push("");

              chk = 1;
            } else {
              xx.push(dataSourceEx[row][col]);

              chk = 0;
            }
          }

          excmumis_id = dataSourceEx[row]["cmumis_id"];
        } else if (col == "name_P") {
          if (exname_P == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (chk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exname_P = dataSourceEx[row]["name_P"];
        } else if (col == "name_shotP") {
          if (exname_shotP == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (chk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exname_shotP = dataSourceEx[row]["name_shotP"];
        } else if (col == "pub_type_cmu_name") {
          if (expub_type_cmu_name == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (chk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          expub_type_cmu_name = dataSourceEx[row]["pub_type_cmu_name"];
        } else if (col == "reseatcher_name") {
          if (exreseatcher_name == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (chk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exreseatcher_name = dataSourceEx[row]["reseatcher_name"];
        } else if (col == "start_date") {
          if (exstart_date == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (chk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exstart_date = dataSourceEx[row]["start_date"];
        } else if (col == "end_date") {
          if (exend_date == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (chk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exend_date = dataSourceEx[row]["end_date"];
        } else if (col == "budgetSum") {
          if (exbudgetSum == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (chk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exbudgetSum = dataSourceEx[row]["budgetSum"];
        } else {
          xx.push(dataSourceEx[row][col]);
        }
      }

      xdata.push(xx);
    }

    var wb = xlsx.utils.book_new(),
      ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, "โครงการวิจัย.xlsx");
  };

  const bExcelTocmu = () => {
    if (dataSourceEx2[0].num == "") {
      swal("เปิด Excel ไม่ได้!", "ไม่มีข้อมูล!", "warning");
    } else {
      let xdata = [];
      let header = [
        "ที่",
        "รายละเอียด",
        "วันที่รับเงิน",
        "เงินที่ได้รับ(บาท)",
        "Direct",
        "F&A",
        "Overhead 70%",
        "Overhead 30%",
        "วันที่นำส่งเงินสมทบ มช.",
        "จำนวนเงินที่นำส่ง"
      ];

      xdata.push(["เงินสมทบ"]);
      xdata.push([""]);
      xdata.push(["รหัส CMU MIS : " + xData.cmumis_id]);
      xdata.push(["ชื่อย่อโครงการ : " + xData.short_name]);
      xdata.push([""]);
      xdata.push(header);

      for (var row in dataSourceEx2) {
        const xx = [];

        for (var col in dataSourceEx2[row]) {
          xx.push(dataSourceEx2[row][col]);
        }

        xdata.push(xx);
      }

      var wb = xlsx.utils.book_new(),
        ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

      xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
      xlsx.writeFile(wb, "เงินสมทบ.xlsx");
    }
  };

  const bclose = () => {
    _show3(false);
  };

  const showMoney = (term, amount, detail) => {
    window.open(
      "/Research_show_transfer?cmumis_id=" +
        xData.cmumis_id +
        "&term=" +
        term +
        "&amount=" +
        amount +
        "&detail=" +
        detail,
      "_blank"
    );
  };

  const columns1 = [
    {
      title: "ลำดับ",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 60,
      sorter: (a, b) => a.num - b.num,
      render: (text, record, index) => {
        return index + 1;
      }
    },
    {
      title: "รหัสโครงการ",
      dataIndex: "cmumis_id",
      key: "cmumis_id",
      align: "center",
      width: 100,
      sorter: (a, b) => {
        return a.cmumis_id.localeCompare(b.cmumis_id);
      },
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(text);
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "ชื่อโครงการวิจัย",
      dataIndex: "name_P",
      key: "name_P",
      //align: "center",
      width: 500,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(record.cmumis_id);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => {
        return a.name_P.localeCompare(b.name_P);
      }
    },
    {
      title: "ชื่อย่อโครงการ",
      dataIndex: "name_shotP",
      key: "name_shotP",
      //align: "center",
      width: 150,
      sorter: (a, b) => {
        return a.name_shotP.localeCompare(b.name_shotP);
      }
    },
    {
      title: "ชื่อหัวหน้าโครงการวิจัย",
      dataIndex: "reseatcher_name",
      key: "reseatcher_name",
      align: "center",
      width: 180,
      render(text, record, index) {
        return {
          children: __TBShowText(
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(text);
              }}
            >
              {text}
            </a>,
            0,
            1
          )
        };
      },
      sorter: (a, b) => {
        return a.reseatcher_name.localeCompare(b.reseatcher_name);
      }
    },
    {
      title: "วันที่เริ่มต้น",
      dataIndex: "start_date",
      key: "start_date",
      align: "center",
      width: 125,
      sorter: (a, b) =>
        moment(moment(a.start_date, "DD/MM/YYYY")).unix() -
        moment(moment(b.start_date, "DD/MM/YYYY")).unix()
    },
    {
      title: "สิ้นสุด",
      dataIndex: "end_date",
      key: "end_date",
      align: "center",
      width: 125,
      sorter: (a, b) =>
        moment(moment(a.end_date, "DD/MM/YYYY")).unix() -
        moment(moment(b.end_date, "DD/MM/YYYY")).unix()
    },
    {
      title: "งบประมาณรวม",
      dataIndex: "budget",
      key: "budget",
      align: "center",
      width: 130,
      render: (text, record) => __TBShowText(record.budget, 1, 0)
    },
    {
      title: "สถานะโครงการวิจัย",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 130,
      render: status => {
        let xcolor = "";

        if (status === "P") {
          xcolor = "gold";
        } else if (status === "I") {
          xcolor = "geekblue";
        } else if (status === "C") {
          xcolor = "green";
        } else if (status === "F") {
          xcolor = "red";
        } else if (status === "S") {
          xcolor = "red";
        }

        return (
          <Tag color={xcolor} key={status}>
            {status.toUpperCase()}
          </Tag>
        );
      }
    },
    {
      title: "นำส่งใบเสร็จให้แหล่งทุน",
      dataIndex: "receipt",
      key: "receipt",
      align: "center",
      width: 120,
      render: receipt => {
        let xcolor = "";
        if (receipt === "ส่งใบเสร็จ") {
          xcolor = "green";
        } else if (receipt === "ไม่ส่งใบเสร็จ") {
          xcolor = "red";
        }

        return (
          <Tag color={xcolor} key={receipt}>
            {receipt}
          </Tag>
        );
      }
    },
    {
      title: "แก้ไข",
      dataIndex: "update",
      key: "update",
      align: "center",
      width: 80,
      render(text, record, index) {
        return {
          children: (
            <Button
              icon={<EditOutlined />}
              size="small"
              onClick={e => {
                //console.log("corresponding email is :", record.i_sum3);

                window.open(
                  "/Research_Edit?cmumis_id=" + record.cmumis_id,
                  "_blank"
                );
              }}
            />
          )
        };
      },
      fixed: "right",
      hidden: levelU == "research" || levelU == "finance" ? true : false
    },
    {
      title: "ลบ",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      width: 80,
      render(text, record, index) {
        return {
          children: (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="small"
              onClick={e => {
                //console.log("corresponding email is :", record.i_sum3);

                bDeleteclick(record.cmumis_id);
              }}
            />
          )
        };
      },
      fixed: "right",
      hidden:
        levelU == "research" || levelU == "finance" || levelU == "student"
          ? true
          : false
    }
  ].filter(item => !item.hidden);

  const columns2 = [
    {
      title: "c1",
      dataIndex: "c1",
      key: "c1"
    },
    {
      title: "c2",
      dataIndex: "c2",
      key: "c2"
    },
    {
      title: "c3",
      dataIndex: "c3",
      key: "c3",
      render: (text, record) => __TBShowText(record.c3, 1, 0)
    },
    {
      title: "c4",
      dataIndex: "c4",
      key: "c4",
      render: (text, record) => __TBShowText(record.c4, 1, 0)
    },
    {
      title: "c5",
      dataIndex: "c5",
      key: "c5"
    }
  ];

  const columns4 = [
    {
      title: "ลำดับ",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 60,
      sorter: (a, b) => a.num - b.num,
      render: (text, record, index) => {
        return index + 1;
      }
    },
    {
      title: "รหัสโครงการ",
      dataIndex: "cmumis_id",
      key: "cmumis_id",
      align: "center",
      width: 120,
      sorter: (a, b) => {
        return a.cmumis_id.localeCompare(b.cmumis_id);
      },
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(text);
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "ชื่อโครงการวิจัย",
      dataIndex: "name_P",
      key: "name_P",
      //align: "center",
      width: 500,

      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(record.cmumis_id);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => {
        return a.name_P.localeCompare(b.name_P);
      }
    },
    {
      title: "วันที่เริ่มต้น",
      dataIndex: "start_date",
      key: "start_date",
      align: "center",
      width: 150,
      sorter: (a, b) =>
        moment(moment(a.start_date, "DD/MM/YYYY")).unix() -
        moment(moment(b.start_date, "DD/MM/YYYY")).unix()
    },
    {
      title: "สิ้นสุด",
      dataIndex: "end_date",
      key: "end_date",
      align: "center",
      width: 150,
      sorter: (a, b) =>
        moment(moment(a.end_date, "DD/MM/YYYY")).unix() -
        moment(moment(b.end_date, "DD/MM/YYYY")).unix()
    },
    {
      title: "งบประมาณรวม",
      dataIndex: "budget",
      key: "budget",
      align: "center",
      width: 200,
      render: (text, record) => __TBShowText(record.budget, 1, 0)
    },
    {
      title: "สถานะโครงการวิจัย",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 200,
      render: status => {
        let xcolor = "";

        if (status === "P") {
          xcolor = "gold";
        } else if (status === "I") {
          xcolor = "geekblue";
        } else if (status === "C") {
          xcolor = "green";
        } else if (status === "F") {
          xcolor = "red";
        } else if (status === "S") {
          xcolor = "red";
        }

        return (
          <Tag color={xcolor} key={status}>
            {status.toUpperCase()}
          </Tag>
        );
      }
    },
    {
      title: "แก้ไข",
      dataIndex: "update",
      key: "update",
      align: "center",
      width: 80,
      render(text, record, index) {
        return {
          children: (
            <Button
              icon={<EditOutlined />}
              size="small"
              onClick={e => {
                //console.log("corresponding email is :", record.i_sum3);
                window.open(
                  "/Research_Edit?cmumis_id=" + record.cmumis_id,
                  "_blank"
                );
              }}
            />
          )
        };
      },
      fixed: "right",
      hidden: levelU == "research" || levelU == "finance" ? true : false
    },
    {
      title: "ลบ",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      width: 80,
      render(text, record, index) {
        return {
          children: (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="small"
              onClick={e => {
                //console.log("corresponding email is :", record.i_sum3);

                bDeleteclick(record.cmumis_id);
              }}
            />
          )
        };
      },
      fixed: "right",
      hidden:
        levelU == "research" || levelU == "finance" || levelU == "student"
          ? true
          : false
    }
  ].filter(item => !item.hidden);

  const columns5 = [
    {
      title: "c1",
      dataIndex: "c1",
      key: "c1"
    },
    {
      title: "c2",
      dataIndex: "c2",
      key: "c2"
    },
    {
      title: "c3",
      dataIndex: "c3",
      key: "c3"
    }
  ];

  const columns6 = [
    {
      title: "งวดที่",
      dataIndex: "term",
      key: "term",
      align: "center"
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "amount",
      key: "amount",
      align: "center",

      render: (text, record) => __TBShowText(record.amount, 1, 0)
    },
    {
      title: "รายละเอียด",
      dataIndex: "detail",
      key: "detail",
      width: 450
      //align: "center",

      //render: (text, record) => __TBShowText(record.detail, 0, 1)
    },
    {
      title: "วันที่รับเงิน(ล่าสุด)",
      dataIndex: "date_receive",
      key: "date_receive",
      align: "center"
    },
    {
      title: "เงินที่ได้รับ(ทั้งหมด)",
      dataIndex: "total_receive",
      key: "total_receive",
      align: "center",
      render: (text, record) => __TBShowText(record.total_receive, 1, 0)
    },
    {
      title: "หมายเหตุ",
      dataIndex: "remark",
      key: "remark",
      width: 350
      //align: "center",

      //render: (text, record) => __TBShowText(record.remark, 0, 1)
    },
    {
      title: "Invoice",
      dataIndex: "tfileiv",
      key: "tfileiv",
      align: "center",
      width: 130,
      render(text, record, index) {
        if (record.linkfileiv) {
          return {
            children: (
              <a href={record.linkfileiv} target="_blank">
                <PaperClipOutlined /> ไฟล์แนบ
              </a>
            )
          };
        }
      }
    },
    {
      title: "ใบโอน",
      dataIndex: "tfile",
      key: "tfile",
      align: "center",
      width: 130,
      render(text, record, index) {
        if (record.linkfile) {
          return {
            children: (
              <a href={record.linkfile} target="_blank">
                <PaperClipOutlined /> ไฟล์แนบ
              </a>
            )
          };
        }
      }
    },
    {
      title: "สถานะเงินโอน",
      dataIndex: "status_money",
      key: "status_money",
      align: "center",
      render(text, record, index) {
        // if (record.linkfile && record.linkfile2) {
        // if (parseFloat(record.total_receive) > 0) {
        if (!record.total_receive) {
          return {
            children: <a />
          };
        } else {
          return {
            children: (
              <a
                style={{ color: "tomato" }}
                onClick={e => {
                  showMoney(record.term, record.amount, record.detail);
                }}
              >
                <CheckOutlined /> รับเงินโอนแล้ว
              </a>
            )
          };
        }
      }
    },
    {
      title: "วันที่แจ้งเตือน",
      dataIndex: "date_warn",
      key: "date_warn",
      align: "center"
    },
    {
      title: "วันที่ครบกำหนด",
      dataIndex: "date_due",
      key: "date_due",
      align: "center"
    }
  ];

  const cFile1 = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 50
    },
    {
      title: "วันที่",
      dataIndex: "sdate",
      key: "sdate",
      align: "center"
    },
    {
      title: "เลขที่รับเอกสาร",
      dataIndex: "iddocget",
      key: "iddocget",
      align: "center"
    },
    {
      title: "เลขที่เอกสาร",
      dataIndex: "iddoc",
      key: "iddoc",
      align: "center"
    },
    {
      title: "ชื่อเรื่อง",
      dataIndex: "docname",
      key: "docname",

      ellipsis: true
    },
    {
      title: "จาก",
      dataIndex: "tfrom",
      key: "tfrom",

      ellipsis: true
    },
    {
      title: "ถึง",
      dataIndex: "tto",
      key: "tto",

      ellipsis: true
    },
    {
      title: "รายละเอียด/หมายเหตุ",
      dataIndex: "remark",
      key: "remark",

      ellipsis: true
    },
    {
      title: "ไฟล์",
      dataIndex: "tfile",
      key: "tfile",
      align: "center",

      render(text, record, index) {
        return {
          children: (
            <a href={record.linkfile} target="_blank">
              <PaperClipOutlined /> ไฟล์เอกสาร
            </a>
          )
        };
      },
      fixed: "right"
    }
  ];

  const cFile2 = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 50
    },
    {
      title: "วันที่",
      dataIndex: "sdate",
      key: "sdate",
      align: "center"
    },
    {
      title: "เลขที่ส่งเอกสาร",
      dataIndex: "iddocpush",
      key: "iddocpush",
      align: "center"
    },
    {
      title: "ชื่อเรื่อง",
      dataIndex: "docname",
      key: "docname",

      ellipsis: true
    },
    {
      title: "จาก",
      dataIndex: "tfrom",
      key: "tfrom",

      ellipsis: true
    },
    {
      title: "ถึง",
      dataIndex: "tto",
      key: "tto",

      ellipsis: true
    },
    {
      title: "รายละเอียด/หมายเหตุ",
      dataIndex: "remark",
      key: "remark",

      ellipsis: true
    },
    {
      title: "ไฟล์",
      dataIndex: "tfile",
      key: "tfile",
      align: "center",

      render(text, record, index) {
        return {
          children: (
            <a href={record.linkfile} target="_blank">
              <PaperClipOutlined /> ไฟล์เอกสาร
            </a>
          )
        };
      },
      fixed: "right"
    }
  ];

  const cFile3 = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 50
    },
    {
      title: "วันที่",
      dataIndex: "sdate",
      key: "sdate",
      align: "center"
    },
    {
      title: "เลขที่เอกสาร",
      dataIndex: "iddoc",
      key: "iddoc",
      align: "center"
    },
    {
      title: "รายละเอียด",
      dataIndex: "remark",
      key: "remark",

      ellipsis: true
    },
    {
      title: "ไฟล์",
      dataIndex: "tfile",
      key: "tfile",
      align: "center",

      render(text, record, index) {
        return {
          children: (
            <a href={record.linkfile} target="_blank">
              <PaperClipOutlined /> ไฟล์เอกสาร
            </a>
          )
        };
      },
      fixed: "right"
    }
  ];

  const cContract = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 50
    },
    {
      title: "เลขที่สัญญา / Grant No.",
      dataIndex: "grant_no",
      key: "grant_no",
      align: "center"
    },
    {
      title: "PO / Protocol No.",
      dataIndex: "po",
      key: "po",
      align: "center"
    },
    {
      title: "จำนวนสัญญา",
      dataIndex: "cgrant",
      key: "cgrant",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlistcontract(record);
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "วันที่เริ่มต้น (สัญญาล่าสุด)",
      dataIndex: "sdate",
      key: "cgrant",
      align: "center"
    },
    {
      title: "วันที่สิ้นสุด (สัญญาล่าสุด)",
      dataIndex: "edate",
      key: "edate",
      align: "center"
    },
    {
      title: "งบประมาณ (สัญญาล่าสุด)",
      dataIndex: "budget",
      key: "budget",
      align: "center",

      render: (text, record) => __TBShowText(record.budget, 1, 0)
    },
    {
      title: "หน่วย (สัญญาล่าสุด)",
      dataIndex: "money",
      key: "money",
      align: "center"
    },
    {
      title: "คำอธิบาย",
      dataIndex: "description",
      key: "description",
      ellipsis: true
      //align: "center",
      //render: (text, record) => __TBShowText(record.description, 0, 1)
    }
  ];

  const cContract2 = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 40
    },
    {
      title: "เลขที่สัญญา / Grant No.",
      dataIndex: "grant_no",
      key: "grant_no",
      align: "center",
      width: 130
    },
    {
      title: "PO / Protocol No.",
      dataIndex: "po",
      key: "po",
      align: "center",
      width: 130
    },
    {
      title: "ฉบับที่ / Modification",
      dataIndex: "Modification",
      key: "Modification",
      align: "center",
      width: 110
    },
    {
      title: "วันที่เริ่มต้น",
      dataIndex: "sdate",
      key: "cgrant",
      align: "center",
      width: 120
    },
    {
      title: "วันที่สิ้นสุด",
      dataIndex: "edate",
      key: "edate",
      align: "center",
      width: 120
    },
    {
      title: "งบประมาณ",
      dataIndex: "budget",
      key: "budget",
      align: "center",
      width: 150,
      render: (text, record) => __TBShowText(record.budget, 1, 0)
    },
    {
      title: "หน่วย",
      dataIndex: "currency",
      key: "currency",
      align: "center",
      width: 80
    },
    {
      title: "คำอธิบาย",
      dataIndex: "description",
      key: "description",
      ellipsis: true
    },
    {
      title: "ไฟล์สัญญา",
      dataIndex: "tfile",
      key: "tfile",
      align: "center",
      width: 130,
      render(text, record, index) {
        return {
          children: (
            <a href={record.linkfile} target="_blank">
              <PaperClipOutlined /> ไฟล์สัญญา
            </a>
          )
        };
      },
      fixed: "right"
    }
  ];

  const cMod = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 40
    },
    {
      title: "เลขที่สัญญา / Grant No.",
      dataIndex: "grant_no",
      key: "grant_no",
      align: "center",
      width: 130
    },
    {
      title: "PO",
      dataIndex: "po",
      key: "po",
      align: "center",
      width: 130
    },
    {
      title: "ฉบับที่ / Modification",
      dataIndex: "Modification",
      key: "Modification",
      align: "center",
      width: 110
    },
    {
      title: "วันที่เริ่มต้น",
      dataIndex: "sdate",
      key: "cgrant",
      align: "center",
      width: 120
    },
    {
      title: "วันที่สิ้นสุด",
      dataIndex: "edate",
      key: "edate",
      align: "center",
      width: 120
    },
    {
      title: "งบประมาณ",
      dataIndex: "budget",
      key: "budget",
      align: "center",
      width: 150,
      render: (text, record) => __TBShowText(record.budget, 1, 0)
    },
    {
      title: "หน่วย",
      dataIndex: "currency",
      key: "currency",
      align: "center",
      width: 80
    },
    {
      title: "คำอธิบาย",
      dataIndex: "description",
      key: "description",
      ellipsis: true
    },
    {
      title: "ไฟล์สัญญา",
      dataIndex: "tfile",
      key: "tfile",
      align: "center",
      width: 130,
      render(text, record, index) {
        return {
          children: (
            <a href={record.linkfile} target="_blank">
              <PaperClipOutlined /> ไฟล์สัญญา
            </a>
          )
        };
      },
      fixed: "right"
    }
  ];

  const cTocmu = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 40
    },
    {
      title: "รายละเอียด",
      dataIndex: "detail",
      key: "detail",
      width: 450
    },
    {
      title: "วันที่รับเงิน",
      dataIndex: "date_receive",
      key: "date_receive",
      align: "center"
    },
    {
      title: "เงินที่ได้รับ(บาท)",
      dataIndex: "total_receive",
      key: "total_receive",
      align: "center",
      render: (text, record) => __TBShowText(record.total_receive, 1, 0)
    },
    {
      title: "Direct",
      dataIndex: "direct",
      key: "direct",
      align: "center",
      render: (text, record) => __TBShowText(record.direct, 1, 0)
    },
    {
      title: "F&A",
      dataIndex: "f_a",
      key: "f_a",
      align: "center",
      render: (text, record) => __TBShowText(record.f_a, 1, 0)
    },
    {
      title: "Overhead 70%",
      dataIndex: "overhead70",
      key: "overhead70",
      align: "center",
      render: (text, record) => __TBShowText(record.overhead70, 1, 0)
    },
    {
      title: "Overhead 30%",
      dataIndex: "overhead30",
      key: "overhead30",
      align: "center",
      render: (text, record) => __TBShowText(record.overhead30, 1, 0)
    },
    {
      title: "วันที่นำส่งเงินสมทบ มช.",
      dataIndex: "date_to_cmu",
      key: "date_to_cmu",
      align: "center"
    },
    {
      title: "จำนวนเงินที่นำส่ง",
      dataIndex: "money_to_cmu",
      key: "money_to_cmu",
      align: "center",
      render: (text, record) => __TBShowText(record.money_to_cmu, 1, 0)
    },
    {
      title: "ไฟล์นำส่ง มช.",
      dataIndex: "file_to_cmu",
      key: "file_to_cmu",
      align: "center",
      width: 130,
      render(text, record, index) {
        if (record.file_to_cmu) {
          return {
            children: (
              <a href={record.file_to_cmu} target="_blank">
                <PaperClipOutlined /> ไฟล์แนบ
              </a>
            )
          };
        }
      }
    },
    {
      title: "ไฟล์ invoice",
      dataIndex: "fileinvoice",
      key: "fileinvoice",
      align: "center",
      width: 130,
      render(text, record, index) {
        if (record.fileinvoice) {
          return {
            children: (
              <a href={record.fileinvoice} target="_blank">
                <PaperClipOutlined /> ไฟล์แนบ
              </a>
            )
          };
        }
      }
    },
    {
      title: "ไฟล์ใบโอน",
      dataIndex: "filetransfer",
      key: "filetransfer",
      align: "center",
      width: 130,
      render(text, record, index) {
        if (record.filetransfer) {
          return {
            children: (
              <a href={record.filetransfer} target="_blank">
                <PaperClipOutlined /> ไฟล์แนบ
              </a>
            )
          };
        }
      }
    }
  ];

  function handleChange(value) {
    //console.log(`selected ${value}`);

    if (value === "0") {
      _show2(true);
    } else if (value === "1") {
      _show2(false);
    }
  }

  function handleChange2(value) {
    //console.log(`selected ${value}`);

    _xStatusValue(value);
  }

  function handleChange3(value) {
    //console.log(`selected ${value}`);

    _xTypeValue(value);
  }

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk2 = () => {
    setIsModalVisible2(false);
  };

  const handleCancel2 = () => {
    setIsModalVisible2(false);
  };

  const pageOnChange = (zpage, zpageSize) => {
    _pageTable(zpage);
    _pageSizeTable(zpageSize);
  };

  const pageOnChange2 = (zpage, zpageSize) => {
    _pageTable2(zpage);
    _pageSizeTable2(zpageSize);
  };

  const pageOnChange3 = (zpage, zpageSize) => {
    _pageTable3(zpage);
    _pageSizeTable3(zpageSize);
  };

  const pageOnChange4 = (zpage, zpageSize) => {
    _pageTable4(zpage);
    _pageSizeTable4(zpageSize);
  };

  function callback(key) {
    //console.log(key);

    //*** tab Choose ***
    if (key === "1") {
      _activeKeyshow("1");
    }
    if (key === "2") {
      _activeKeyshow("2");
    }
    if (key === "3") {
      _activeKeyshow("3");
    }
  }

  //formatttable (Rigth or Left)
  function __TBShowText(zData, zRigth, zLeft) {
    if (zRigth === 1 && zLeft === 0) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    } else if (zRigth === 0 && zLeft === 1) {
      return (
        <div style={{ display: "flex" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content className="site-layout" style={{ padding: 0, marginTop: 0 }}>
        <Carousel
          autoplay
          slidesToShow={1}
          speed={5000}
          effect="fade"
          dotPosition="right"
        >
          <div>
            <h1
              class="increase"
              style={{ backgroundImage: `url(${background6})` }}
            >
              Research System
            </h1>
          </div>
          <div>
            <h1
              class="increase"
              style={{ backgroundImage: `url(${background2})` }}
            >
              Research System
            </h1>
          </div>
          <div>
            <h1
              class="increase"
              style={{ backgroundImage: `url(${background3})` }}
            >
              Research System
            </h1>
          </div>
          <div>
            <h1
              class="increase"
              style={{ backgroundImage: `url(${background4})` }}
            >
              Research System
            </h1>
          </div>
          <div>
            <h1
              class="increase"
              style={{ backgroundImage: `url(${background5})` }}
            >
              Research System
            </h1>
          </div>
          <div>
            <h1
              class="increase"
              style={{ backgroundImage: `url(${background1})` }}
            >
              Research System
            </h1>
          </div>
          <div>
            <h1
              class="increase"
              style={{ backgroundImage: `url(${background7})` }}
            >
              Research System
            </h1>
          </div>
          <div>
            <h1
              class="increase"
              style={{ backgroundImage: `url(${background8})` }}
            >
              Research System
            </h1>
          </div>
        </Carousel>

        <div
          className="site-layout-background"
          style={{ padding: 30, minHeight: 380 }}
        >
          <PageHeader className="site-page-header" title="ค้นหา โครงการวิจัย" />

          <Divider />

          <Form
            form={form1}
            onFinish={onFinish}
            initialValues={{
              slstatus: "สถานะทั้งหมด",
              edid: "",
              edpjname: "",
              edresercher: ""
            }}
            {...layout}
          >
            <Row justify="space-around">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                //justify="space-around"
                //style={{ padding: "10px", paddingTop: "24px" }}
              >
                <Card
                  style={{
                    borderRadius: "15px",
                    overflow: "hidden"
                  }}
                >
                  <Form.Item label="แสดงตาม" name="sldate">
                    <Select
                      defaultValue="0"
                      style={{ width: 200 }}
                      onChange={handleChange}
                    >
                      <Option value="0">ปีงบประมาณ</Option>
                      <Option value="1">ช่วงวันที่งบประมาณ</Option>
                    </Select>
                  </Form.Item>

                  <ConfigProvider locale={configuredLocale}>
                    {show2
                      ? <Form.Item label="ปี" name="ydate">
                          <DatePicker
                            picker="year"
                            format={"BBBB"}
                            style={{ width: 200 }}
                          />
                        </Form.Item>
                      : <Form.Item label="ช่วงวันที่" name="sedate">
                          <DatePicker.RangePicker
                            format="DD/MM/BBBB"
                            style={{ width: "50%" }}
                          />
                        </Form.Item>}
                  </ConfigProvider>

                  <Form.Item label="สถานะโครงการ" name="slstatus">
                    <Select
                      defaultValue="สถานะทั้งหมด"
                      style={{ width: "50%" }}
                      onChange={handleChange2}
                    >
                      {xStatus.length > 0
                        ? xStatus.map((i, idx) => {
                            return (
                              <Option value={i.id}>
                                {i.id} : {i.name}
                              </Option>
                            );
                          })
                        : <Option value="0">สถานะทั้งหมด</Option>}
                    </Select>
                  </Form.Item>

                  <Form.Item label="ชื่อนักวิจัย" name="edresercher">
                    <Input
                      //disabled={Export}
                      allowClear
                      placeholder="ชื่อนักวิจัย"
                      style={{ width: "50%" }}
                    />
                  </Form.Item>

                  <Form.Item label="รหัสโครงการ" name="edid">
                    <Input
                      //disabled={Export}
                      allowClear
                      placeholder="คีย์รหัสโครงการ"
                      style={{ width: "50%" }}
                      maxLength="10"
                    />
                  </Form.Item>

                  <Form.Item label="ชื่อโครงการวิจัย" name="edpjname">
                    <Input
                      //disabled={Export}
                      allowClear
                      placeholder="ชื่อโครงการวิจัย"
                      style={{ width: "50%" }}
                    />
                  </Form.Item>

                  {levelU !== "student"
                    ? <Form.Item label="ประเภทโครงการ" name="sltype">
                        <Select
                          defaultValue="ประเภททั้งหมด"
                          style={{ width: "50%" }}
                          onChange={handleChange3}
                        >
                          {xType.length > 0
                            ? xType.map((i, idx) => {
                                return (
                                  <Option value={i.id}>
                                    {i.id} : {i.name}
                                  </Option>
                                );
                              })
                            : <Option value="0">ประเภททั้งหมด</Option>}
                        </Select>
                      </Form.Item>
                    : <Form.Item label="ประเภทโครงการ" name="sltype">
                        <p style={{ marginTop: "4px" }}>
                          4 : โครงการของนักศึกษาสำนักวิชาฯ
                        </p>
                      </Form.Item>}
                </Card>
              </Col>
            </Row>

            <Divider />

            <Row justify="center">
              <Space>
                <Button ghost type="primary" htmlType="submit">
                  <SearchOutlined /> แสดงโครงการวิจัย
                </Button>

                <Button ghost type="danger" onClick={bClearclick}>
                  <ClearOutlined /> เคลียร์
                </Button>

                <Button ghost type="success" onClick={bExcelclick}>
                  <FileExcelOutlined /> Excel
                </Button>
              </Space>
            </Row>

            <Divider />

            <Text style={{ fontWeight: "bold" }}>
              {"แสดงโครงการวิจัย "} {xtitle}
            </Text>

            <Text style={{ fontWeight: "bold", textalign: "right" }}>
              {xNumData}
            </Text>

            {show1
              ? <div>
                  <br />
                  <Card
                    //size="small"
                    style={{
                      borderRadius: "15px",
                      overflow: "hidden"
                    }}
                  >
                    <Row>
                      <Col span={8}>
                        <Badge
                          color="yellow"
                          text="โครงการวิจัยที่อยู่ระหว่างยื่นข้อเสนอโครงการ (P)"
                        />
                      </Col>
                      <Col span={16}>
                        <Badge color="yellow" text={xCountP1} />
                      </Col>
                    </Row>

                    <Row>
                      <Col span={8}>
                        <Badge
                          color="blue"
                          text="โครงการวิจัยที่ได้รับอนุมัติและอยู่ระหว่างดำเนินการ (I)"
                        />
                      </Col>
                      <Col span={16}>
                        <Badge color="blue" text={xCountI1} />
                      </Col>
                    </Row>

                    <Row>
                      <Col span={8}>
                        <Badge
                          color="green"
                          text="โครงการวิจัยที่เสร็จสิ้นแล้ว (C)"
                        />
                      </Col>
                      <Col span={16}>
                        <Badge color="green" text={xCountC1} />
                      </Col>
                    </Row>

                    <Row>
                      <Col span={8}>
                        <Badge
                          color="red"
                          text="ข้อเสนอโครงการที่ไม่ได้รับอนุมัติ (F)"
                        />
                      </Col>
                      <Col span={16}>
                        <Badge color="red" text={xCountF1} />
                      </Col>
                    </Row>

                    <Row>
                      <Col span={8}>
                        <Badge
                          color="red"
                          text="โครงการวิจัยที่ได้รับอนุมัติ แต่ภายหลังยกเลิกโครงการ (S)"
                        />
                      </Col>
                      <Col span={16}>
                        <Badge color="red" text={xCountS1} />
                      </Col>
                    </Row>
                  </Card>

                  <br />
                </div>
              : <div />}

            <br />

            <Table
              bordered
              scroll={{ x: "max-content" }}
              dataSource={dataSource1}
              columns={columns1}
              size="small"
              pagination={{
                onChange: (zpage, zpageSize) => {
                  pageOnChange(zpage, zpageSize);
                },
                current: pageTable,
                pageSize: pageSizeTable
              }}
            />
          </Form>
        </div>
      </Content>

      <Footer_last />

      <StyledForm />

      <Modal
        title={
          <Text style={{ fontWeight: "bold" }}>
            แสดง รายละเอียดโครงการวิจัย
          </Text>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1800}
      >
        <Form form={form3}>
          <Descriptions
            bordered
            size={"small"}
            labelStyle={{ justifyContent: "flex-end", minWidth: 200 }}
          >
            <Descriptions.Item label="รหัส CMU MIS" span={3}>
              {xData.cmumis_id}
            </Descriptions.Item>
            <Descriptions.Item label="ชื่อโครงการ: (ภาษาไทย)" span={3}>
              {xData.name_thai}
            </Descriptions.Item>
            <Descriptions.Item label="ชื่อโครงการ: (ภาษาอังกฤษ)" span={3}>
              <p>
                {xData.name_eng}
              </p>
            </Descriptions.Item>
            <Descriptions.Item label="ชื่อย่อโครงการ:" span={3}>
              {xData.short_name}
            </Descriptions.Item>
            <Descriptions.Item label="ยุทธศาสตร์ของมหาวิทยาลัย" span={3}>
              {xData.pub_type_cmu_name} {xData.pub_type_short}
            </Descriptions.Item>
            <Descriptions.Item label="วันที่เริ่มต้นโครงการ" span={3}>
              {dayjs(xData.start_date).format("DD/MM/BBBB")}
            </Descriptions.Item>
            <Descriptions.Item label="วันที่สิ้นสุดโครงการ" span={3}>
              {dayjs(xData.end_date).format("DD/MM/BBBB")}
            </Descriptions.Item>
            <Descriptions.Item label="สถานะโครงการ" span={3}>
              {xData.status}
            </Descriptions.Item>
            <Descriptions.Item label="นำส่งใบเสร็จให้แหล่งทุน" span={3}>
              {xData.receipt}
            </Descriptions.Item>
          </Descriptions>
          <br />
          <Button
            style={{ width: "200px" }}
            onClick={() => {
              window.open(
                "/Research_to_cmu?cmumis_id=" + xData.cmumis_id,
                "_blank"
              );
            }}
          >
            <Text style={{ fontWeight: "bold" }}>การส่งเงินสมทบ</Text>
          </Button>
          <br />
          <br />
          <Card>
            <Divider orientation="left" style={{ borderColor: "#C2CFFF" }}>
              <Text style={{ fontWeight: "bold" }}>แหล่งทุน</Text>
            </Divider>
            {xid > 1
              ? <Table
                  scroll={{ x: "max-content" }}
                  dataSource={dataSource2}
                  columns={columns2}
                  size="small"
                  pagination={false}
                  showHeader={false}
                />
              : <p>---ไม่มีข้อมูล---</p>}

            <Divider orientation="left" style={{ borderColor: "#C2CFFF" }}>
              <Text style={{ fontWeight: "bold" }}>ผู้ร่วมวิจัย</Text>
            </Divider>
            {dataSource5 != ""
              ? <Table
                  scroll={{ x: "max-content" }}
                  dataSource={dataSource5}
                  columns={columns5}
                  size="small"
                  pagination={false}
                  showHeader={false}
                />
              : <p>---ไม่มีข้อมูล---</p>}

            <Divider orientation="left" style={{ borderColor: "#C2CFFF" }}>
              <Text style={{ fontWeight: "bold" }}>วิธีการเบิกจ่าย</Text>
            </Divider>
            {xSumAmount !== 0
              ? <Table
                  bordered
                  scroll={{ x: "max-content" }}
                  dataSource={dataSource6}
                  columns={columns6}
                  size="small"
                  pagination={false}
                  summary={() =>
                    <Table.Summary fixed>
                      <Table.Summary.Row
                        style={{
                          textAlign: "right",
                          backgroundColor: "#b5f5ec"
                        }}
                      >
                        <Table.Summary.Cell>รวมทั้งหมด</Table.Summary.Cell>
                        <Table.Summary.Cell>
                          {xSumAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2
                          })}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell />
                        <Table.Summary.Cell>
                          รวมเงินที่ได้รับ
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          {xSumAmount2.toLocaleString(undefined, {
                            minimumFractionDigits: 2
                          })}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                      </Table.Summary.Row>
                    </Table.Summary>}
                />
              : <p>---ไม่มีข้อมูล---</p>}

            {/* <Divider orientation="left" style={{ borderColor: "#C2CFFF" }}>
              <Text style={{ fontWeight: "bold" }}>การส่งเงินสมทบ</Text>
            </Divider>
            <div style={{ float: "right" }}>
              <Form.Item>
                <Button ghost type="success" onClick={bExcelTocmu}>
                  <FileExcelOutlined /> Excel
                </Button>
              </Form.Item>
            </div>
            {dataTocmu != ""
              ? <Table
                  bordered
                  scroll={{ x: "max-content" }}
                  dataSource={dataTocmu}
                  columns={cTocmu}
                  size="small"
                  pagination={false}
                  summary={() =>
                    <Table.Summary fixed>
                      <Table.Summary.Row
                        style={{
                          textAlign: "right",
                          backgroundColor: "#b5f5ec"
                        }}
                      >
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                        <Table.Summary.Cell>รวม</Table.Summary.Cell>
                        <Table.Summary.Cell>
                          {xSumAmount2.toLocaleString(undefined, {
                            minimumFractionDigits: 2
                          })}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          {xSumDirect.toLocaleString(undefined, {
                            minimumFractionDigits: 2
                          })}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          {xSumF_A.toLocaleString(undefined, {
                            minimumFractionDigits: 2
                          })}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          {xSumOver70.toLocaleString(undefined, {
                            minimumFractionDigits: 2
                          })}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          {xSumOver30.toLocaleString(undefined, {
                            minimumFractionDigits: 2
                          })}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell />
                        <Table.Summary.Cell>
                          {xSumTo_cmu.toLocaleString(undefined, {
                            minimumFractionDigits: 2
                          })}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                      </Table.Summary.Row>
                    </Table.Summary>}
                />
              : <p>---ไม่มีข้อมูล---</p>} */}

            <Divider orientation="left" style={{ borderColor: "#C2CFFF" }}>
              <Text style={{ fontWeight: "bold" }}>สัญญาโครงการวิจัย</Text>
            </Divider>
            {dataContract2 != ""
              ? <Table
                  bordered
                  scroll={{ x: "max-content" }}
                  dataSource={dataContract2}
                  columns={cContract2}
                  size="small"
                  // pagination={{
                  //   onChange: (zpage, zpageSize) => {
                  //     pageOnChange4(zpage, zpageSize);
                  //   },
                  //   current: pageTable4,
                  //   pageSize: pageSizeTable4
                  // }}
                  pagination={false}
                  summary={() =>
                    <Table.Summary fixed>
                      <Table.Summary.Row
                        style={{
                          textAlign: "right",
                          backgroundColor: "#b5f5ec"
                        }}
                      >
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                        <Table.Summary.Cell>รวมทั้งหมด</Table.Summary.Cell>
                        <Table.Summary.Cell>
                          {xSumAmount3.toLocaleString(undefined, {
                            minimumFractionDigits: 2
                          })}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                      </Table.Summary.Row>
                    </Table.Summary>}
                />
              : <p>---ไม่มีข้อมูล---</p>}
            {/* {dataContract != ""
              ? <Table
                  bordered
                  scroll={{ x: "max-content" }}
                  dataSource={dataContract}
                  columns={cContract}
                  size="small"
                  pagination={false}
                />
              : <p>---ไม่มีข้อมูล---</p>}

            {show3
              ? <div>
                  <Divider />
                  <Card
                    title="สัญญาย่อย"
                    extra={
                      <Button ghost type="text" onClick={bclose}>
                        <CloseOutlined />
                      </Button>
                    }
                  >
                    <Table
                      bordered
                      scroll={{ x: "max-content" }}
                      dataSource={dataMod}
                      columns={cMod}
                      size="small"
                      pagination={{
                        onChange: (zpage, zpageSize) => {
                          pageOnChange3(zpage, zpageSize);
                        },
                        current: pageTable3,
                        pageSize: pageSizeTable3
                      }}
                      summary={() =>
                        <Table.Summary fixed>
                          <Table.Summary.Row
                            style={{
                              textAlign: "right",
                              backgroundColor: "#b5f5ec"
                            }}
                          >
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell align="center">
                              รวมทั้งหมด
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>{xsummary}</Table.Summary.Cell>
                            <Table.Summary.Cell align="center">
                              {xcrc}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell /> <Table.Summary.Cell />
                          </Table.Summary.Row>
                        </Table.Summary>}
                    />
                  </Card>
                </div>
              : <div />} */}

            <Divider orientation="left" style={{ borderColor: "#C2CFFF" }}>
              <Text style={{ fontWeight: "bold" }}>เอกสารโครงการวิจัย</Text>
            </Divider>
            {dataFile1 != "" || dataFile2 != "" || dataFile3 != ""
              ? <Tabs
                  onChange={callback}
                  type="card"
                  size="small"
                  defaultActiveKey="1"
                  activeKey={activeKeyshow}
                >
                  <TabPane tab="เอกสารรับเข้า" key="1">
                    <Table
                      bordered
                      scroll={{ x: "max-content" }}
                      dataSource={dataFile1}
                      columns={cFile1}
                      size="small"
                      pagination={false}
                    />
                  </TabPane>
                  <TabPane tab="เอกสารส่งออก" key="2">
                    <Table
                      bordered
                      scroll={{ x: "max-content" }}
                      dataSource={dataFile2}
                      columns={cFile2}
                      size="small"
                      pagination={false}
                    />
                  </TabPane>
                  <TabPane tab="เอกสารอื่นๆ" key="3">
                    <Table
                      bordered
                      scroll={{ x: "max-content" }}
                      dataSource={dataFile3}
                      columns={cFile3}
                      size="small"
                      pagination={false}
                    />
                  </TabPane>
                </Tabs>
              : <p>---ไม่มีข้อมูล---</p>}
          </Card>
          <br /> <br /> <br />
          {levelU == ""
            ? <Row justify="center">
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      window.open(
                        "/Research_Edit?cmumis_id=" + xData.cmumis_id,
                        "_blank"
                      );
                    }}
                  >
                    <EditOutlined />แก้ไขข้อมูล
                  </Button>
                </Space>
              </Row>
            : <div />}
          <br /> <br />
        </Form>
      </Modal>

      <Modal
        title={xresearname}
        visible={isModalVisible2}
        onOk={handleOk2}
        onCancel={handleCancel2}
        footer={null}
        width={1800}
      >
        <Text style={{ fontWeight: "bold" }}>{xtitle2}</Text> <br /> <br />
        <Card
          style={{
            borderRadius: "15px",
            overflow: "hidden"
          }}
        >
          <Row>
            <Col span={8}>
              <Badge
                color="yellow"
                text="โครงการวิจัยที่อยู่ระหว่างยื่นข้อเสนอโครงการ (P)"
              />
            </Col>
            <Col span={16}>
              <Badge color="yellow" text={xCountP2} />
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Badge
                color="blue"
                text="โครงการวิจัยที่ได้รับอนุมัติและอยู่ระหว่างดำเนินการ (I)"
              />
            </Col>
            <Col span={16}>
              <Badge color="blue" text={xCountI2} />
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Badge color="green" text="โครงการวิจัยที่เสร็จสิ้นแล้ว (C)" />
            </Col>
            <Col span={16}>
              <Badge color="green" text={xCountC2} />
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Badge color="red" text="ข้อเสนอโครงการที่ไม่ได้รับอนุมัติ (F)" />
            </Col>
            <Col span={16}>
              <Badge color="red" text={xCountF2} />
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Badge
                color="red"
                text="โครงการวิจัยที่ได้รับอนุมัติ แต่ภายหลังยกเลิกโครงการ (S)"
              />
            </Col>
            <Col span={16}>
              <Badge color="red" text={xCountS2} />
            </Col>
          </Row>
        </Card>
        <br />
        <br />
        <Table
          bordered
          scroll={{ x: "max-content" }}
          dataSource={dataSource4}
          columns={columns4}
          size="small"
          pagination={{
            onChange: (zpage, zpageSize) => {
              pageOnChange2(zpage, zpageSize);
            },
            current: pageTable2,
            pageSize: pageSizeTable2
          }}
        />
      </Modal>

      <BackTop />
    </React.Fragment>
  );
};
export default Research_main;
