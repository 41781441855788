import "antd/dist/antd.min.css";
import React, { useState, useEffect, useRef } from "react";
import {
  ClearOutlined,
  SaveOutlined,
  EditOutlined,
  DeleteOutlined,
  PaperClipOutlined,
  CloseOutlined
} from "@ant-design/icons";
import { createGlobalStyle } from "styled-components";

import {
  Row,
  Col,
  Form,
  Layout,
  Card,
  Input,
  Space,
  Button,
  Table,
  Typography,
  Tabs,
  Modal,
  PageHeader,
  Divider,
  ConfigProvider
} from "antd";

import axios from "axios";
import moment from "moment";
import "moment/locale/th";
import locale from "antd/es/date-picker/locale/th_TH";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import swal from "sweetalert";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import thTH from "antd/lib/locale/th_TH";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

const { Content } = Layout;
const { Text } = Typography;
const { TabPane } = Tabs;
const { confirm } = Modal;

const StyledForm = createGlobalStyle`.ant-form-item {
  margin-bottom: 5px;
}
`;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
};

const layout2 = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 }
};

const configuredLocale = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    dateFormat: "DD MM BBBB", // DD MM BBBB
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker.lang,
      dateFormat: "DD MM BBBB", // DD MM BBBB
      dateTimeFormat: "DD MM BBBB HH:mm:ss",
      yearFormat: "BBBB"
    }
  }
};

dayjs.locale("th");
dayjs.extend(buddhistEra);

const Research_input_file = () => {
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [form4] = Form.useForm();

  const [form5] = Form.useForm();
  const [form6] = Form.useForm();
  const [form7] = Form.useForm();

  const [ListFile, _ListFile] = useState([]);
  const [ListFile2, _ListFile2] = useState([]);
  const [ListFile3, _ListFile3] = useState([]);

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }
  const [dataSource1, _dataSource1] = useState([]);
  const [dataSource2, _dataSource2] = useState([]);
  const [dataSource3, _dataSource3] = useState([]);

  const [activeKeyshow, _activeKeyshow] = useState("1");

  const [xcmumis_id, _xcmumis_id] = useState("");
  const [xname, _xname] = useState("");
  const [xshortname, _xshortname] = useState("");
  const [xresearch, _xresearch] = useState("");
  const [xdate, _xdate] = useState("");

  const [show1, _show1] = useState(false);

  const [xtitle, _xtitle] = useState("");

  const [osdate, _osdate] = useState("");
  const [oidgetdocument, _oidgetdocument] = useState("");
  const [oidpushdocument, _oidpushdocument] = useState("");
  const [oiddocument, _oiddocument] = useState("");
  const [odocumentname, _odocumentname] = useState("");
  const [ofrom, _ofrom] = useState("");
  const [oto, _oto] = useState("");
  const [oremark, _oremark] = useState("");

  const [ofile, _ofile] = useState("");

  const txtidgetdocument = useRef("");
  const txtiddocument = useRef("");
  const txtdocumentname = useRef("");
  const txtfrom = useRef("");
  const txtto = useRef("");
  const txtremark = useRef("");

  const txtidpushdocument = useRef("");
  const txtdocumentname2 = useRef("");
  const txtfrom2 = useRef("");
  const txtto2 = useRef("");
  const txtremark2 = useRef("");

  const txtiddocument2 = useRef("");
  const txtremark3 = useRef("");

  const DatePicker = generatePicker(dayjsGenerateConfig);

  useEffect(() => {
    const token = localStorage.getItem("token");
    //console.log(token);

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");
          var url = new URL(window.location.href);

          _xcmumis_id(url.searchParams.get("cmumis_id"));
          _xname(url.searchParams.get("name"));
          _xshortname(url.searchParams.get("shortname"));
          _xresearch(url.searchParams.get("research"));
          _xdate(url.searchParams.get("date"));

          GetTable(url.searchParams.get("cmumis_id"));
          GetTable2(url.searchParams.get("cmumis_id"));
          GetTable3(url.searchParams.get("cmumis_id"));
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const GetTable = cmumis_id => {
    axios
      .post(xURL + "find_file", {
        cmumis_id: cmumis_id,
        zorder: 1
      })
      .then(result => {
        //console.log(result);
        const Tb1 = [];

        result.data.forEach((i, idx) => {
          /*
          let xsdate = moment(i.date.substr(0, 10))
            .add(543, "year")
            .add(1, "day")
            .format("L");*/

          let xsdate = dayjs(i.date).format("DD/MM/BBBB");

          let url_file = "";

          url_file = `${xURL}file1/${i.file_name}`;

          Tb1.push({
            key: idx,
            num: idx + 1,
            sdate: xsdate,
            iddocget: i.doc_no_receive,
            iddoc: i.doc_no,
            docname: i.name,
            tfrom: i.doc_from,
            tto: i.doc_to,
            remark: i.remark,
            linkfile: url_file
          });
        });

        _dataSource1(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetTable2 = cmumis_id => {
    axios
      .post(xURL + "find_file", {
        cmumis_id: cmumis_id,
        zorder: 2
      })
      .then(result => {
        //console.log(result);
        const Tb1 = [];

        result.data.forEach((i, idx) => {
          /*
          let xsdate = moment(i.date.substr(0, 10))
            .add(543, "year")
            .add(1, "day")
            .format("L");*/

          let xsdate = dayjs(i.date).format("DD/MM/BBBB");

          let url_file = "";

          url_file = `${xURL}file1/${i.file_name}`;

          Tb1.push({
            key: idx,
            num: idx + 1,
            sdate: xsdate,
            iddocpush: i.doc_no_send,
            docname: i.name,
            tfrom: i.doc_from,
            tto: i.doc_to,
            remark: i.remark,
            linkfile: url_file
          });
        });

        _dataSource2(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetTable3 = cmumis_id => {
    axios
      .post(xURL + "find_file", {
        cmumis_id: cmumis_id,
        zorder: 3
      })
      .then(result => {
        //console.log(result);
        const Tb1 = [];

        result.data.forEach((i, idx) => {
          /*
          let xsdate = moment(i.date.substr(0, 10))
            .add(543, "year")
            .add(1, "day")
            .format("L");*/

          let xsdate = dayjs(i.date).format("DD/MM/BBBB");

          let url_file = "";

          url_file = `${xURL}file1/${i.file_name}`;

          Tb1.push({
            key: idx,
            num: idx + 1,
            sdate: xsdate,
            iddoc: i.doc_no,
            remark: i.remark,
            linkfile: url_file
          });
        });

        _dataSource3(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bClearclick1 = () => {
    form1.resetFields();
    _ListFile([]);

    _show1(false);
  };

  const bClearclick2 = () => {
    form2.resetFields();
    _ListFile2([]);

    _show1(false);
  };

  const bClearclick3 = () => {
    form3.resetFields();
    _ListFile3([]);

    _show1(false);
  };

  const bClearEclick1 = () => {
    form5.resetFields();
  };

  const bClearEclick2 = () => {
    form6.resetFields();
  };

  const bClearEclick3 = () => {
    form7.resetFields();
  };

  const bSaveclick1 = () => {
    const f = form1.getFieldValue();

    if (!f.sdate) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ วันที่!", "warning");
    } else if (!f.ed_idgetdocument) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ เลขที่รับเอกสาร!", "warning");
    } else if (!f.ed_iddocument) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ เลขที่เอกสาร!", "warning");
    } else if (!f.eddocumentname) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ ชื่อเรื่อง!", "warning");
    } else if (!f.edfrom) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ จาก!", "warning");
    } else if (!f.edto) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ ถึง!", "warning");
    } else if (ListFile == "" || typeof ListFile == "undefined") {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้เลือก ไฟล์!", "warning");
    } else {
      swal({
        title: "ยืนยันบันทึก เอกสารรับเข้าโครงการวิจัย",
        text:
          "รหัส CMU MIS : " +
          xcmumis_id +
          "\nเลขที่รับเอกสาร : " +
          f.ed_idgetdocument +
          "\nเลขที่เอกสาร : " +
          f.ed_iddocument,
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          insertdata1();
        }
      });
    }
  };

  const bSaveclick2 = () => {
    const f = form2.getFieldValue();

    if (!f.sdate) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ วันที่!", "warning");
    } else if (!f.ed_idpushdocument) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ เลขที่ส่งเอกสาร!", "warning");
    } else if (!f.eddocumentname) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ ชื่อเรื่อง!", "warning");
    } else if (!f.edfrom) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ จาก!", "warning");
    } else if (!f.edto) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ ถึง!", "warning");
    } else if (ListFile2 == "" || typeof ListFile2 == "undefined") {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้เลือก ไฟล์!", "warning");
    } else {
      swal({
        title: "ยืนยันบันทึก เอกสารส่งออกโครงการวิจัย",
        text:
          "รหัส CMU MIS : " +
          xcmumis_id +
          "\nเลขที่ส่งเอกสาร : " +
          f.ed_idpushdocument,
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          insertdata2();
        }
      });
    }
  };

  const bSaveclick3 = () => {
    const f = form3.getFieldValue();

    if (!f.sdate) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ วันที่!", "warning");
    } else if (!f.ed_iddocument) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ เลขที่เอกสาร!", "warning");
    } else if (ListFile3 == "" || typeof ListFile3 == "undefined") {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้เลือก ไฟล์!", "warning");
    } else {
      swal({
        title: "ยืนยันบันทึก เอกสารอื่นๆโครงการวิจัย",
        text:
          "รหัส CMU MIS : " +
          xcmumis_id +
          "\nเลขที่เอกสาร : " +
          f.ed_iddocument,
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          insertdata3();
        }
      });
    }
  };

  const insertdata1 = () => {
    const f = form1.getFieldValue();

    /*
    console.log(
      ListFile.name,
      moment(f.sdate).format("DD/MM/YYYY"),
      f.ed_idgetdocument,
      f.ed_iddocument,
      f.eddocumentname,
      f.edfrom,
      f.edto,
      f.edremark
    );*/

    const fd = new FormData();
    fd.append("file", ListFile);

    axios.post(xURL + "uploadfile", fd).then(res => {
      //Now do what you want with the response;
      //console.log(res.data.filename)

      axios
        .post(xURL + "uploadfile_insert", {
          cmumis_id: xcmumis_id,
          //f_name: ListFile.name,
          f_name: res.data.filename,
          f_date: dayjs(f.sdate).format("YYYY-MM-DD"),
          doc_name: f.eddocumentname,
          doc_no_receive: f.ed_idgetdocument,
          doc_no: f.ed_iddocument,
          doc_from: f.edfrom,
          doc_to: f.edto,
          remark: f.edremark,
          xorder: 1
        })
        .then(result => {
          bClearclick1();
          GetTable(xcmumis_id);
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    });

    //let xdate = moment(Date()).format("DDMMYYYYHHmmss");

    /*
    axios
      .post(xURL + "uploadfile_insert", {
        cmumis_id: xcmumis_id,
        //f_name: ListFile.name,
        f_name: xdate + "." + ListFile.name.split(".")[1],
        f_date: moment(f.sdate).format("YYYY-MM-DD"),
        doc_name: f.eddocumentname,
        doc_no_receive: f.ed_idgetdocument,
        doc_no: f.ed_iddocument,
        doc_from: f.edfrom,
        doc_to: f.edto,
        remark: f.edremark,
        xorder: 1
      })
      .then(result => {
        bClearclick1();
        GetTable(xcmumis_id);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });*/
  };

  const insertdata2 = () => {
    const f = form2.getFieldValue();

    const fd = new FormData();
    fd.append("file", ListFile2);

    axios.post(xURL + "uploadfile", fd).then(res => {
      //Now do what you want with the response;
      axios
        .post(xURL + "uploadfile_insert", {
          cmumis_id: xcmumis_id,
          f_name: res.data.filename,
          f_date: dayjs(f.sdate).format("YYYY-MM-DD"),
          doc_name: f.eddocumentname,
          doc_no_send: f.ed_idpushdocument,
          doc_from: f.edfrom,
          doc_to: f.edto,
          remark: f.edremark,
          xorder: 2
        })
        .then(result => {
          bClearclick2();
          GetTable2(xcmumis_id);
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    });
  };

  const insertdata3 = () => {
    const f = form3.getFieldValue();

    const fd = new FormData();
    fd.append("file", ListFile3);

    axios.post(xURL + "uploadfile", fd).then(res => {
      //Now do what you want with the response;
      //console.log(res);
      axios
        .post(xURL + "uploadfile_insert", {
          cmumis_id: xcmumis_id,
          f_name: res.data.filename,
          f_date: dayjs(f.sdate).format("YYYY-MM-DD"),
          doc_no: f.ed_iddocument,
          remark: f.edremark,
          xorder: 3
        })
        .then(result => {
          bClearclick3();
          GetTable3(xcmumis_id);
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    });
  };

  const bDeleteClick = (doc_no_receive, doc_no, name, type, linkfile) => {
    //console.log(doc_no_receive, doc_no, name, type);

    swal({
      title: "ยืนยัน ลบ " + type,
      text:
        "รหัส CMU MIS : " +
        xcmumis_id +
        "\nเลขที่รับเอกสาร : " +
        doc_no_receive +
        "\nเลขที่เอกสาร : " +
        doc_no,
      icon: "warning",
      dangerMode: true,
      buttons: ["ยกเลิก", "ยืนยัน"]
    }).then(willDelete => {
      if (willDelete) {
        axios
          .post(xURL + "delete_file", {
            linkfile: linkfile.split("/")[5],
            order: 1
          })
          .then(result => {})
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        axios
          .post(xURL + "delete", {
            cmumis_id: xcmumis_id,
            doc_no_receive: doc_no_receive,
            doc_no: doc_no,
            name: name,
            type: type,
            xcheck: 12
          })
          .then(result => {
            //bClearclick1();
            //GetTable(xcmumis_id);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        swal("แจ้งเตือน!", "ลบ " + type + " สำเร็จ!", "success").then(() => {
          bClearclick1();
          GetTable(xcmumis_id);
        });
      }
    });
  };

  const bDeleteClick2 = (doc_no_send, name, type, linkfile) => {
    swal({
      title: "ยืนยัน ลบ " + type,
      text:
        "รหัส CMU MIS : " + xcmumis_id + "\nเลขที่ส่งเอกสาร : " + doc_no_send,
      icon: "warning",
      dangerMode: true,
      buttons: ["ยกเลิก", "ยืนยัน"]
    }).then(willDelete => {
      if (willDelete) {
        axios
          .post(xURL + "delete_file", {
            linkfile: linkfile.split("/")[5],
            order: 1
          })
          .then(result => {})
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        axios
          .post(xURL + "delete", {
            cmumis_id: xcmumis_id,
            doc_no_send: doc_no_send,
            name: name,
            type: type,
            xcheck: 13
          })
          .then(result => {
            //bClearclick2();
            //GetTable2(xcmumis_id);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        swal("แจ้งเตือน!", "ลบ " + type + " สำเร็จ!", "success").then(() => {
          bClearclick2();
          GetTable2(xcmumis_id);
        });
      }
    });
  };

  const bDeleteClick3 = (doc_no, type, linkfile, remark) => {
    let xremark = remark.substring(0, 10);
    swal({
      title: "ยืนยัน ลบ " + type,
      text: "รหัส CMU MIS : " + xcmumis_id + "\nเลขที่เอกสาร : " + doc_no,
      icon: "warning",
      dangerMode: true,
      buttons: ["ยกเลิก", "ยืนยัน"]
    }).then(willDelete => {
      if (willDelete) {
        axios
          .post(xURL + "delete_file", {
            linkfile: linkfile.split("/")[5],
            order: 1
          })
          .then(result => {})
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        axios
          .post(xURL + "delete", {
            cmumis_id: xcmumis_id,
            doc_no: doc_no,
            type: type,
            remark: xremark,
            xcheck: 14
          })
          .then(result => {
            //bClearclick3();
            //GetTable3(xcmumis_id);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        swal("แจ้งเตือน!", "ลบ " + type + " สำเร็จ!", "success").then(() => {
          bClearclick3();
          GetTable3(xcmumis_id);
        });
      }
    });
  };

  const bEditClick = zdata => {
    //console.log(zdata);
    _show1(true);

    _xtitle("แก้ไข - เอกสารโครงการวิจัย (รับเข้า)");

    /*
    let xsdate = moment(zdata.sdate, "DD/MM/YYYY")
      .add(-543, "year")
      .format("L");*/

    axios
      .post(xURL + "getDate_file", {
        cmumis_id: xcmumis_id,
        file_type: "เอกสารรับเข้า",
        doc_no: zdata.iddoc,
        remark: zdata.remark.substring(0, 10)
      })
      .then(result => {
        let xdate = dayjs(result.data[0].date);

        _osdate(dayjs(xdate));
        _oidgetdocument(zdata.iddocget);
        _oiddocument(zdata.iddoc);
        _odocumentname(zdata.docname);
        _ofrom(zdata.tfrom);
        _oto(zdata.tto);
        _oremark(zdata.remark);
        _ofile(zdata.tfile);

        form5.setFieldsValue({
          sdate: xdate,
          ed_idgetdocument: zdata.iddocget,
          ed_iddocument: zdata.iddoc,
          eddocumentname: zdata.docname,
          edfrom: zdata.tfrom,
          edto: zdata.tto,
          edremark: zdata.remark
        });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bclose = () => {
    _show1(false);
  };

  const bEditClick2 = zdata => {
    //console.log(zdata);
    _show1(true);

    _xtitle("แก้ไข - เอกสารโครงการวิจัย (ส่งออก)");

    /*
    let xsdate = moment(zdata.sdate, "DD/MM/YYYY")
      .add(-543, "year")
      .format("L");*/

    axios
      .post(xURL + "getDate_file", {
        cmumis_id: xcmumis_id,
        file_type: "เอกสารส่งออก",
        doc_no: zdata.iddocpush,
        remark: zdata.remark.substring(0, 10)
      })
      .then(result => {
        let xdate = dayjs(result.data[0].date);

        _osdate(dayjs(xdate));
        _oidpushdocument(zdata.iddocpush);
        _odocumentname(zdata.docname);
        _ofrom(zdata.tfrom);
        _oto(zdata.tto);
        _oremark(zdata.remark);

        form6.setFieldsValue({
          sdate: xdate,
          ed_idpushdocument: zdata.iddocpush,
          eddocumentname: zdata.docname,
          edfrom: zdata.tfrom,
          edto: zdata.tto,
          edremark: zdata.remark
        });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bclose2 = () => {
    _show1(false);
  };

  const bEditClick3 = zdata => {
    //console.log(zdata);
    _show1(true);

    _xtitle("แก้ไข - เอกสารโครงการวิจัย (อื่นๆ)");

    /*
    let xsdate = moment(zdata.sdate, "DD/MM/YYYY")
      .add(-543, "year")
      .format("L");*/

    axios
      .post(xURL + "getDate_file", {
        cmumis_id: xcmumis_id,
        file_type: "เอกสารอื่นๆ",
        doc_no: zdata.iddoc,
        remark: zdata.remark.substring(0, 10)
      })
      .then(result => {
        let xdate = dayjs(result.data[0].date);

        _osdate(dayjs(xdate));
        _oiddocument(zdata.iddoc);
        _oremark(zdata.remark);

        form7.setFieldsValue({
          sdate: xdate,
          ed_iddocument: zdata.iddoc,
          edremark: zdata.remark
        });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bclose3 = () => {
    _show1(false);
  };

  const bEditSclick = () => {
    const f = form5.getFieldValue();

    if (!f.sdate) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ วันที่!", "warning");
    } else if (!f.ed_idgetdocument) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ เลขที่รับเอกสาร!", "warning");
    } else if (!f.ed_iddocument) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ เลขที่เอกสาร!", "warning");
    } else if (!f.eddocumentname) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ ชื่อเรื่อง!", "warning");
    } else if (!f.edfrom) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ จาก!", "warning");
    } else if (!f.edto) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ ถึง!", "warning");
    } else {
      swal({
        title: "ยืนยันแก้ไข เอกสารรับเข้าโครงการวิจัย",
        text: "รหัส CMU MIS " + xcmumis_id,
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          if (ListFile == "" || typeof ListFile == "undefined") {
            axios
              .post(xURL + "update", {
                cmumis_id: xcmumis_id,
                f_date: dayjs(f.sdate).format("YYYY-MM-DD"),
                doc_name: f.eddocumentname,
                doc_no_receive: f.ed_idgetdocument,
                doc_no: f.ed_iddocument,
                doc_from: f.edfrom,
                doc_to: f.edto,
                remark: f.edremark,
                of_date: dayjs(osdate).format("YYYY-MM-DD"),
                odoc_name: odocumentname,
                odoc_no_receive: oidgetdocument,
                odoc_no: oiddocument,
                odoc_from: ofrom,
                odoc_to: oto,
                oremark: oremark,
                type: "เอกสารรับเข้า",
                xcheck: 11,
                xcheck2: 1
              })
              .then(result => {
                _show1(false);
                GetTable(xcmumis_id);
              })
              .catch(function(error) {
                // handle error
                console.log("problem here", error);
              });
          } else {
            const fd = new FormData();
            fd.append("file", ListFile);

            axios.post(xURL + "uploadfile", fd).then(res => {
              axios
                .post(xURL + "update", {
                  cmumis_id: xcmumis_id,
                  f_date: dayjs(f.sdate).format("YYYY-MM-DD"),
                  doc_name: f.eddocumentname,
                  doc_no_receive: f.ed_idgetdocument,
                  doc_no: f.ed_iddocument,
                  doc_from: f.edfrom,
                  doc_to: f.edto,
                  remark: f.edremark,
                  of_date: dayjs(osdate).format("YYYY-MM-DD"),
                  odoc_name: odocumentname,
                  odoc_no_receive: oidgetdocument,
                  odoc_no: oiddocument,
                  odoc_from: ofrom,
                  odoc_to: oto,
                  oremark: oremark,
                  type: "เอกสารรับเข้า",
                  f_name: res.data.filename,
                  xcheck: 11,
                  xcheck2: 2
                })
                .then(result => {
                  // _show1(false);
                  // GetTable(xcmumis_id);
                  window.location.reload(false);
                })
                .catch(function(error) {
                  // handle error
                  console.log("problem here", error);
                });
            });
          }
        }
      });
    }
  };

  const bEditSclick2 = () => {
    const f = form6.getFieldValue();

    if (!f.sdate) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ วันที่!", "warning");
    } else if (!f.ed_idpushdocument) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ เลขที่ส่งเอกสาร!", "warning");
    } else if (!f.eddocumentname) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ ชื่อเรื่อง!", "warning");
    } else if (!f.edfrom) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ จาก!", "warning");
    } else if (!f.edto) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ ถึง!", "warning");
    } else {
      swal({
        title: "ยืนยันแก้ไข เอกสารส่งออกโครงการวิจัย",
        text: "รหัส CMU MIS " + xcmumis_id,
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          if (ListFile2 == "" || typeof ListFile2 == "undefined") {
            axios
              .post(xURL + "update", {
                cmumis_id: xcmumis_id,
                f_date: dayjs(f.sdate).format("YYYY-MM-DD"),
                doc_name: f.eddocumentname,
                doc_no_send: f.ed_idpushdocument,
                doc_from: f.edfrom,
                doc_to: f.edto,
                remark: f.edremark,
                of_date: dayjs(osdate).format("YYYY-MM-DD"),
                odoc_name: odocumentname,
                odoc_no_send: oidpushdocument,
                odoc_from: ofrom,
                odoc_to: oto,
                oremark: oremark,
                type: "เอกสารส่งออก",
                xcheck: 12,
                xcheck2: 1
              })
              .then(result => {
                _show1(false);
                GetTable2(xcmumis_id);
              })
              .catch(function(error) {
                // handle error
                console.log("problem here", error);
              });
          } else {
            const fd = new FormData();
            fd.append("file", ListFile2);

            axios.post(xURL + "uploadfile", fd).then(res => {
              axios
                .post(xURL + "update", {
                  cmumis_id: xcmumis_id,
                  f_date: dayjs(f.sdate).format("YYYY-MM-DD"),
                  doc_name: f.eddocumentname,
                  doc_no_send: f.ed_idpushdocument,
                  doc_from: f.edfrom,
                  doc_to: f.edto,
                  remark: f.edremark,
                  of_date: dayjs(osdate).format("YYYY-MM-DD"),
                  odoc_name: odocumentname,
                  odoc_no_send: oidpushdocument,
                  odoc_from: ofrom,
                  odoc_to: oto,
                  oremark: oremark,
                  type: "เอกสารส่งออก",
                  f_name: res.data.filename,
                  xcheck: 12,
                  xcheck2: 2
                })
                .then(result => {
                  // _show1(false);
                  // GetTable2(xcmumis_id);
                  window.location.reload(false);
                })
                .catch(function(error) {
                  // handle error
                  console.log("problem here", error);
                });
            });
          }
        }
      });
    }
  };

  const bEditSclick3 = () => {
    const f = form7.getFieldValue();

    if (!f.sdate) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ วันที่!", "warning");
    } else if (!f.ed_iddocument) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ เลขที่เอกสาร!", "warning");
    } else {
      swal({
        title: "ยืนยันแก้ไข เอกสารอื่นๆโครงการวิจัย",
        text: "รหัส CMU MIS " + xcmumis_id,
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          if (ListFile3 == "" || typeof ListFile3 == "undefined") {
            axios
              .post(xURL + "update", {
                cmumis_id: xcmumis_id,
                f_date: dayjs(f.sdate).format("YYYY-MM-DD"),
                doc_no: f.ed_iddocument,
                remark: f.edremark,
                of_date: dayjs(osdate).format("YYYY-MM-DD"),
                odoc_no: oiddocument,
                oremark: oremark,
                type: "เอกสารอื่นๆ",
                xcheck: 13,
                xcheck2: 1
              })
              .then(result => {
                _show1(false);
                GetTable3(xcmumis_id);
              })
              .catch(function(error) {
                // handle error
                console.log("problem here", error);
              });
          } else {
            const fd = new FormData();
            fd.append("file", ListFile3);

            axios.post(xURL + "uploadfile", fd).then(res => {
              axios
                .post(xURL + "update", {
                  cmumis_id: xcmumis_id,
                  f_date: dayjs(f.sdate).format("YYYY-MM-DD"),
                  doc_no: f.ed_iddocument,
                  remark: f.edremark,
                  of_date: dayjs(osdate).format("YYYY-MM-DD"),
                  odoc_no: oiddocument,
                  oremark: oremark,
                  type: "เอกสารอื่นๆ",
                  f_name: res.data.filename,
                  xcheck: 13,
                  xcheck2: 2
                })
                .then(result => {
                  // _show1(false);
                  // GetTable3(xcmumis_id);
                  window.location.reload(false);
                })
                .catch(function(error) {
                  // handle error
                  console.log("problem here", error);
                });
            });
          }
        }
      });
    }
  };

  const columns1 = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 60
    },
    {
      title: "วันที่",
      dataIndex: "sdate",
      key: "sdate",
      align: "center",
      width: 100
    },
    {
      title: "เลขที่รับเอกสาร",
      dataIndex: "iddocget",
      key: "iddocget",
      align: "center",
      width: 120
    },
    {
      title: "เลขที่เอกสาร",
      dataIndex: "iddoc",
      key: "iddoc",
      align: "center",
      width: 160
    },
    {
      title: "ชื่อเรื่อง",
      dataIndex: "docname",
      key: "docname",
      width: 300,
      ellipsis: true
      //render: (text, record) => __TBShowText(record.docname, 0, 1)
    },
    {
      title: "จาก",
      dataIndex: "tfrom",
      key: "tfrom",
      width: 150,
      ellipsis: true
      //render: (text, record) => __TBShowText(record.tfrom, 0, 1)
    },
    {
      title: "ถึง",
      dataIndex: "tto",
      key: "tto",
      width: 150,
      ellipsis: true
      //render: (text, record) => __TBShowText(record.tto, 0, 1)
    },
    {
      title: "รายละเอียด/หมายเหตุ",
      dataIndex: "remark",
      key: "remark",
      width: 300,
      ellipsis: true
      //render: (text, record) => __TBShowText(record.remark, 0, 1)
    },
    {
      title: "ไฟล์",
      dataIndex: "tfile",
      key: "tfile",
      align: "center",
      width: 130,
      render(text, record, index) {
        return {
          /*
           <Link to={record.linkfile} target="_blank" download>
              <PaperClipOutlined />
              ไฟล์เอกสาร
            </Link>
           */
          /*
              <Button
              ghost
              type="link"
              icon={<PaperClipOutlined />}
              onClick={e => {}}
            >
              ไฟล์เอกสาร
            </Button>
           */

          children: (
            <a href={record.linkfile} target="_blank">
              <PaperClipOutlined /> ไฟล์เอกสาร
            </a>
          )
        };
      }
    },
    {
      title: "แก้ไข",
      dataIndex: "update",
      key: "update",
      align: "center",
      width: 50,
      render(text, record, index) {
        return {
          children: (
            <Button
              icon={<EditOutlined />}
              size="small"
              onClick={e => {
                bEditClick(record);
              }}
            />
          )
        };
      }
    },
    {
      title: "ลบ",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      width: 50,
      render(text, record, index) {
        return {
          children: (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="small"
              onClick={e => {
                bDeleteClick(
                  record.iddocget,
                  record.iddoc,
                  record.docname,
                  "เอกสารรับเข้า",
                  record.linkfile
                );
              }}
            />
          )
        };
      }
    }
  ];

  const columns2 = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 60
    },
    {
      title: "วันที่",
      dataIndex: "sdate",
      key: "sdate",
      align: "center",
      width: 100
    },
    {
      title: "เลขที่ส่งเอกสาร",
      dataIndex: "iddocpush",
      key: "iddocpush",
      align: "center",
      width: 120
    },
    {
      title: "ชื่อเรื่อง",
      dataIndex: "docname",
      key: "docname",
      width: 350,
      ellipsis: true
    },
    {
      title: "จาก",
      dataIndex: "tfrom",
      key: "tfrom",
      width: 150,
      ellipsis: true
    },
    {
      title: "ถึง",
      dataIndex: "tto",
      key: "tto",
      width: 150,
      ellipsis: true
    },
    {
      title: "รายละเอียด/หมายเหตุ",
      dataIndex: "remark",
      key: "remark",
      width: 350,
      ellipsis: true
    },
    {
      title: "ไฟล์",
      dataIndex: "tfile",
      key: "tfile",
      align: "center",
      width: 130,
      render(text, record, index) {
        return {
          children: (
            <a href={record.linkfile} target="_blank">
              <PaperClipOutlined /> ไฟล์เอกสาร
            </a>
          )
        };
      },
      fixed: "right"
    },
    {
      title: "แก้ไข",
      dataIndex: "update",
      key: "update",
      align: "center",
      width: 50,
      render(text, record, index) {
        return {
          children: (
            <Button
              icon={<EditOutlined />}
              size="small"
              onClick={e => {
                bEditClick2(record);
              }}
            />
          )
        };
      },
      fixed: "right"
    },
    {
      title: "ลบ",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      width: 50,
      render(text, record, index) {
        return {
          children: (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="small"
              onClick={e => {
                bDeleteClick2(
                  record.iddocpush,
                  record.docname,
                  "เอกสารส่งออก",
                  record.linkfile
                );
              }}
            />
          )
        };
      },
      fixed: "right"
    }
  ];

  const columns3 = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 60
    },
    {
      title: "วันที่",
      dataIndex: "sdate",
      key: "sdate",
      align: "center",
      width: 100
    },
    {
      title: "เลขที่เอกสาร",
      dataIndex: "iddoc",
      key: "iddoc",
      align: "center",
      width: 200
    },
    {
      title: "รายละเอียด",
      dataIndex: "remark",
      key: "remark",
      align: "center",
      ellipsis: true,
      render: (text, record) => __TBShowText(record.remark, 0, 1)
    },
    {
      title: "ไฟล์",
      dataIndex: "tfile",
      key: "tfile",
      align: "center",
      width: 150,
      render(text, record, index) {
        return {
          children: (
            <a href={record.linkfile} target="_blank">
              <PaperClipOutlined /> ไฟล์เอกสาร
            </a>
          )
        };
      }
    },
    {
      title: "แก้ไข",
      dataIndex: "update",
      key: "update",
      align: "center",
      width: 50,
      render(text, record, index) {
        return {
          children: (
            <Button
              icon={<EditOutlined />}
              size="small"
              onClick={e => {
                bEditClick3(record);
              }}
            />
          )
        };
      }
    },
    {
      title: "ลบ",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      width: 50,
      render(text, record, index) {
        return {
          children: (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="small"
              onClick={e => {
                bDeleteClick3(
                  record.iddoc,
                  "เอกสารอื่นๆ",
                  record.linkfile,
                  record.remark
                );
              }}
            />
          )
        };
      }
    }
  ];

  function callback(key) {
    //console.log(key);

    //*** tab Choose ***
    if (key === "1") {
      _activeKeyshow("1");
      _show1(false);
    }
    if (key === "2") {
      _activeKeyshow("2");
      _show1(false);
    }
    if (key === "3") {
      _activeKeyshow("3");
      _show1(false);
    }
  }

  //formatttable (Rigth or Left)
  function __TBShowText(zData, zRigth, zLeft) {
    if (zRigth === 1 && zLeft === 0) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    } else if (zRigth === 0 && zLeft === 1) {
      return (
        <div style={{ display: "flex" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    }
  }

  const onChangeU = e => {
    //console.log(e, e.target.files[0]);

    _ListFile(e.target.files[0]);
  };

  const onChangeU2 = e => {
    //console.log(e, e.target.files[0]);

    _ListFile2(e.target.files[0]);
  };

  const onChangeU3 = e => {
    //console.log(e, e.target.files[0]);

    _ListFile3(e.target.files[0]);
  };

  return (
    <React.Fragment>
      <StyledForm />

      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col span={23}>
            <PageHeader
              className="site-page-header"
              title={
                <a
                  style={{
                    color: "black"
                  }}
                  onClick={() => {
                    window.close();
                  }}
                >
                  เอกสารโครงการวิจัย
                </a>
              }
              onBack={() => window.close()}
            />

            <Divider />

            <Card title="">
              <Form form={form4} {...layout2}>
                <Form.Item
                  label={
                    <Text style={{ fontWeight: "bold" }}>รหัส CMU MIS</Text>
                  }
                >
                  {xcmumis_id}
                </Form.Item>

                <Form.Item
                  label={
                    <Text style={{ fontWeight: "bold" }}>ชื่อโครงการ</Text>
                  }
                >
                  {xname}
                </Form.Item>

                <Form.Item
                  label={
                    <Text style={{ fontWeight: "bold" }}>ชื่อย่อโครงการ</Text>
                  }
                >
                  {xshortname}
                </Form.Item>

                <Form.Item
                  label={
                    <Text style={{ fontWeight: "bold" }}>หัวหน้าโครงการ</Text>
                  }
                >
                  {xresearch}
                </Form.Item>

                <Form.Item
                  label={<Text style={{ fontWeight: "bold" }}>ระยะเวลา</Text>}
                >
                  {xdate}
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>

        <br />

        <Row justify="center">
          <Col span={23}>
            <Card title="เอกสารโครงการวิจัย">
              <Tabs
                onChange={callback}
                type="card"
                size="small"
                defaultActiveKey="1"
                activeKey={activeKeyshow}
              >
                <TabPane tab="เอกสารรับเข้า" key="1">
                  <Form
                    form={form1}
                    initialValues={{
                      sdate: "",
                      ed_idgetdocument: "",
                      ed_iddocument: "",
                      eddocumentname: "",
                      edfrom: "",
                      edto: "",
                      edremark: "",
                      edfile: ""
                    }}
                    {...layout}
                  >
                    <ConfigProvider locale={configuredLocale}>
                      <Form.Item
                        label="วันที่"
                        name="sdate"
                        rules={[
                          {
                            required: true,
                            message: "กรุณากรอก วันที่"
                          }
                        ]}
                      >
                        <DatePicker
                          autoFocus
                          format="DD/MM/BBBB"
                          onChange={e => {
                            if ((e.length = 10)) {
                              txtidgetdocument.current.focus();
                            }
                          }}
                        />
                      </Form.Item>
                    </ConfigProvider>

                    <Form.Item
                      label="เลขที่รับเอกสาร"
                      name="ed_idgetdocument"
                      rules={[
                        {
                          required: true,
                          message: "กรุณากรอก เลขที่รับเอกสาร"
                        }
                      ]}
                    >
                      <Input
                        allowClear
                        placeholder="เลขที่รับเอกสาร"
                        ref={txtidgetdocument}
                        onPressEnter={() => {
                          txtiddocument.current.focus();
                        }}
                        //style={{ width: 400 }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="เลขที่เอกสาร"
                      name="ed_iddocument"
                      rules={[
                        {
                          required: true,
                          message: "กรุณากรอก เลขที่เอกสาร"
                        }
                      ]}
                    >
                      <Input
                        allowClear
                        placeholder="เลขที่เอกสาร"
                        ref={txtiddocument}
                        onPressEnter={() => {
                          txtdocumentname.current.focus();
                        }}
                        //style={{ width: 400 }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="ชื่อเรื่อง"
                      name="eddocumentname"
                      rules={[
                        {
                          required: true,
                          message: "กรุณากรอก ชื่อเรื่อง"
                        }
                      ]}
                    >
                      <Input
                        allowClear
                        placeholder="ชื่อเรื่อง"
                        ref={txtdocumentname}
                        onPressEnter={() => {
                          txtfrom.current.focus();
                        }}
                        //style={{ width: 400 }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="จาก"
                      name="edfrom"
                      rules={[
                        {
                          required: true,
                          message: "กรุณากรอก จาก"
                        }
                      ]}
                    >
                      <Input
                        allowClear
                        placeholder="จาก"
                        ref={txtfrom}
                        onPressEnter={() => {
                          txtto.current.focus();
                        }}
                        //style={{ width: 400 }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="ถึง"
                      name="edto"
                      rules={[
                        {
                          required: true,
                          message: "กรุณากรอก ถึง"
                        }
                      ]}
                    >
                      <Input
                        allowClear
                        placeholder="ถึง"
                        ref={txtto}
                        onPressEnter={() => {
                          txtremark.current.focus();
                        }}
                        //style={{ width: 400 }}
                      />
                    </Form.Item>

                    <Form.Item label="รายละเอียด/หมายเหตุ" name="edremark">
                      <Input
                        allowClear
                        placeholder="รายละเอียด/หมายเหตุ"
                        ref={txtremark}
                        //style={{ width: 400 }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="ไฟล์"
                      name="edfile"
                      rules={[
                        {
                          required: true
                        }
                      ]}
                    >
                      <Input
                        className="form-control"
                        id="drop-area"
                        type="file"
                        onChange={e => onChangeU(e)}
                      />
                    </Form.Item>

                    <br />

                    <Row justify="center">
                      <Space>
                        <Button ghost type="primary" onClick={bSaveclick1}>
                          <SaveOutlined /> บันทึก
                        </Button>

                        <Button ghost type="danger" onClick={bClearclick1}>
                          <ClearOutlined /> เคลียร์
                        </Button>
                      </Space>
                    </Row>

                    <br />

                    <Table
                      bordered
                      //scroll={{ x: "max-content" }}
                      dataSource={dataSource1}
                      columns={columns1}
                      size="small"
                      pagination={false}
                    />
                  </Form>

                  <br />

                  {show1
                    ? <Form form={form5} initialValues={{}} {...layout}>
                        <Card
                          title={xtitle}
                          extra={
                            <Button ghost type="text" onClick={bclose}>
                              <CloseOutlined />
                            </Button>
                          }
                        >
                          <ConfigProvider locale={configuredLocale}>
                            <Form.Item
                              label="วันที่"
                              name="sdate"
                              rules={[
                                {
                                  required: true,
                                  message: "กรุณากรอก วันที่"
                                }
                              ]}
                            >
                              <DatePicker autoFocus format="DD/MM/BBBB" />
                            </Form.Item>
                          </ConfigProvider>

                          <Form.Item
                            label="เลขที่รับเอกสาร"
                            name="ed_idgetdocument"
                            rules={[
                              {
                                required: true,
                                message: "กรุณากรอก เลขที่รับเอกสาร"
                              }
                            ]}
                          >
                            <Input allowClear />
                          </Form.Item>

                          <Form.Item
                            label="เลขที่เอกสาร"
                            name="ed_iddocument"
                            rules={[
                              {
                                required: true,
                                message: "กรุณากรอก เลขที่เอกสาร"
                              }
                            ]}
                          >
                            <Input allowClear />
                          </Form.Item>

                          <Form.Item
                            label="ชื่อเรื่อง"
                            name="eddocumentname"
                            rules={[
                              {
                                required: true,
                                message: "กรุณากรอก ชื่อเรื่อง"
                              }
                            ]}
                          >
                            <Input allowClear />
                          </Form.Item>

                          <Form.Item
                            label="จาก"
                            name="edfrom"
                            rules={[
                              {
                                required: true,
                                message: "กรุณากรอก จาก"
                              }
                            ]}
                          >
                            <Input allowClear />
                          </Form.Item>

                          <Form.Item
                            label="ถึง"
                            name="edto"
                            rules={[
                              {
                                required: true,
                                message: "กรุณากรอก ถึง"
                              }
                            ]}
                          >
                            <Input allowClear />
                          </Form.Item>

                          <Form.Item
                            label="รายละเอียด/หมายเหตุ"
                            name="edremark"
                          >
                            <Input allowClear />
                          </Form.Item>

                          <Form.Item label="ไฟล์" name="edfile">
                            <Input
                              className="form-control"
                              id="drop-area"
                              type="file"
                              onChange={e => onChangeU(e)}
                            />
                          </Form.Item>

                          <br />
                          <Row justify="center">
                            <Space>
                              <Button
                                ghost
                                type="primary"
                                onClick={bEditSclick}
                              >
                                <SaveOutlined /> แก้ไข
                              </Button>

                              <Button
                                ghost
                                type="danger"
                                onClick={bClearEclick1}
                              >
                                <ClearOutlined /> เคลียร์
                              </Button>
                            </Space>
                          </Row>
                        </Card>
                      </Form>
                    : <div />}
                </TabPane>

                <TabPane tab="เอกสารส่งออก" key="2">
                  <Form
                    form={form2}
                    initialValues={{
                      sdate: "",
                      ed_idpushdocument: "",
                      eddocumentname: "",
                      edfrom: "",
                      edto: "",
                      edremark: "",
                      edfile: ""
                    }}
                    {...layout}
                  >
                    <ConfigProvider locale={configuredLocale}>
                      <Form.Item
                        label="วันที่"
                        name="sdate"
                        rules={[
                          {
                            required: true,
                            message: "กรุณากรอก วันที่"
                          }
                        ]}
                      >
                        <DatePicker
                          autoFocus
                          format="DD/MM/BBBB"
                          onChange={e => {
                            if ((e.length = 10)) {
                              txtidpushdocument.current.focus();
                            }
                          }}
                        />
                      </Form.Item>
                    </ConfigProvider>

                    <Form.Item
                      label="เลขที่ส่งเอกสาร"
                      name="ed_idpushdocument"
                      rules={[
                        {
                          required: true,
                          message: "กรุณากรอก เลขที่ส่งเอกสาร"
                        }
                      ]}
                    >
                      <Input
                        allowClear
                        placeholder="เลขที่ส่งเอกสาร"
                        ref={txtidpushdocument}
                        onPressEnter={() => {
                          txtdocumentname2.current.focus();
                        }}
                        //style={{ width: 400 }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="ชื่อเรื่อง"
                      name="eddocumentname"
                      rules={[
                        {
                          required: true,
                          message: "กรุณากรอก ชื่อเรื่อง"
                        }
                      ]}
                    >
                      <Input
                        allowClear
                        placeholder="ชื่อเรื่อง"
                        ref={txtdocumentname2}
                        onPressEnter={() => {
                          txtfrom2.current.focus();
                        }}
                        //style={{ width: 400 }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="จาก"
                      name="edfrom"
                      rules={[
                        {
                          required: true,
                          message: "กรุณากรอก จาก"
                        }
                      ]}
                    >
                      <Input
                        allowClear
                        placeholder="จาก"
                        ref={txtfrom2}
                        onPressEnter={() => {
                          txtto2.current.focus();
                        }}
                        //style={{ width: 400 }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="ถึง"
                      name="edto"
                      rules={[
                        {
                          required: true,
                          message: "กรุณากรอก ถึง"
                        }
                      ]}
                    >
                      <Input
                        allowClear
                        placeholder="ถึง"
                        ref={txtto2}
                        onPressEnter={() => {
                          txtremark2.current.focus();
                        }}
                        //style={{ width: 400 }}
                      />
                    </Form.Item>

                    <Form.Item label="รายละเอียด/หมายเหตุ" name="edremark">
                      <Input
                        allowClear
                        placeholder="รายละเอียด/หมายเหตุ"
                        ref={txtremark2}
                        //style={{ width: 400 }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="ไฟล์"
                      name="edfile"
                      rules={[
                        {
                          required: true
                        }
                      ]}
                    >
                      <Input
                        className="form-control"
                        id="drop-area"
                        type="file"
                        onChange={e => onChangeU2(e)}
                      />
                    </Form.Item>

                    <br />

                    <Row justify="center">
                      <Space>
                        <Button ghost type="primary" onClick={bSaveclick2}>
                          <SaveOutlined /> บันทึก
                        </Button>

                        <Button ghost type="danger" onClick={bClearclick2}>
                          <ClearOutlined /> เคลียร์
                        </Button>
                      </Space>
                    </Row>

                    <br />

                    <Table
                      bordered
                      scroll={{ x: "max-content" }}
                      dataSource={dataSource2}
                      columns={columns2}
                      size="small"
                      pagination={false}
                    />
                  </Form>

                  <br />

                  {show1
                    ? <Form form={form6} initialValues={{}} {...layout}>
                        <Card
                          title={xtitle}
                          extra={
                            <Button ghost type="text" onClick={bclose2}>
                              <CloseOutlined />
                            </Button>
                          }
                        >
                          <ConfigProvider locale={configuredLocale}>
                            <Form.Item
                              label="วันที่"
                              name="sdate"
                              rules={[
                                {
                                  required: true,
                                  message: "กรุณากรอก วันที่"
                                }
                              ]}
                            >
                              <DatePicker autoFocus format="DD/MM/BBBB" />
                            </Form.Item>
                          </ConfigProvider>

                          <Form.Item
                            label="เลขที่ส่งเอกสาร"
                            name="ed_idpushdocument"
                            rules={[
                              {
                                required: true,
                                message: "กรุณากรอก เลขที่ส่งเอกสาร"
                              }
                            ]}
                          >
                            <Input allowClear />
                          </Form.Item>

                          <Form.Item
                            label="ชื่อเรื่อง"
                            name="eddocumentname"
                            rules={[
                              {
                                required: true,
                                message: "กรุณากรอก ชื่อเรื่อง"
                              }
                            ]}
                          >
                            <Input allowClear />
                          </Form.Item>

                          <Form.Item
                            label="จาก"
                            name="edfrom"
                            rules={[
                              {
                                required: true,
                                message: "กรุณากรอก จาก"
                              }
                            ]}
                          >
                            <Input allowClear />
                          </Form.Item>

                          <Form.Item
                            label="ถึง"
                            name="edto"
                            rules={[
                              {
                                required: true,
                                message: "กรุณากรอก ถึง"
                              }
                            ]}
                          >
                            <Input allowClear />
                          </Form.Item>

                          <Form.Item
                            label="รายละเอียด/หมายเหตุ"
                            name="edremark"
                          >
                            <Input allowClear />
                          </Form.Item>

                          <Form.Item label="ไฟล์" name="edfile">
                            <Input
                              className="form-control"
                              id="drop-area"
                              type="file"
                              onChange={e => onChangeU2(e)}
                            />
                          </Form.Item>

                          <br />
                          <Row justify="center">
                            <Space>
                              <Button
                                ghost
                                type="primary"
                                onClick={bEditSclick2}
                              >
                                <SaveOutlined /> แก้ไข
                              </Button>

                              <Button
                                ghost
                                type="danger"
                                onClick={bClearEclick2}
                              >
                                <ClearOutlined /> เคลียร์
                              </Button>
                            </Space>
                          </Row>
                        </Card>
                      </Form>
                    : <div />}
                </TabPane>

                <TabPane tab="เอกสารอื่นๆ" key="3">
                  <Form
                    form={form3}
                    initialValues={{
                      sdate: "",
                      ed_iddocument: "",
                      eddocumentname: "",
                      edremark: "",
                      edfile: ""
                    }}
                    {...layout}
                  >
                    <ConfigProvider locale={configuredLocale}>
                      <Form.Item
                        label="วันที่"
                        name="sdate"
                        rules={[
                          {
                            required: true,
                            message: "กรุณากรอก วันที่"
                          }
                        ]}
                      >
                        <DatePicker
                          autoFocus
                          format="DD/MM/BBBB"
                          onChange={e => {
                            if ((e.length = 10)) {
                              txtiddocument2.current.focus();
                            }
                          }}
                        />
                      </Form.Item>
                    </ConfigProvider>

                    <Form.Item
                      label="เลขที่เอกสาร"
                      name="ed_iddocument"
                      rules={[
                        {
                          required: true,
                          message: "กรุณากรอก เลขที่เอกสาร"
                        }
                      ]}
                    >
                      <Input
                        allowClear
                        placeholder="เลขที่เอกสาร"
                        ref={txtiddocument2}
                        onPressEnter={() => {
                          txtremark3.current.focus();
                        }}
                        //style={{ width: 400 }}
                      />
                    </Form.Item>

                    <Form.Item label="รายละเอียด" name="edremark">
                      <Input
                        allowClear
                        placeholder="รายละเอียด"
                        ref={txtremark3}
                        //style={{ width: 400 }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="ไฟล์"
                      name="edfile"
                      rules={[
                        {
                          required: true
                        }
                      ]}
                    >
                      <Input
                        className="form-control"
                        id="drop-area"
                        type="file"
                        onChange={e => onChangeU3(e)}
                      />
                    </Form.Item>

                    <br />

                    <Row justify="center">
                      <Space>
                        <Button ghost type="primary" onClick={bSaveclick3}>
                          <SaveOutlined /> บันทึก
                        </Button>

                        <Button ghost type="danger" onClick={bClearclick3}>
                          <ClearOutlined /> เคลียร์
                        </Button>
                      </Space>
                    </Row>

                    <br />

                    <Table
                      bordered
                      scroll={{ x: "max-content" }}
                      dataSource={dataSource3}
                      columns={columns3}
                      size="small"
                      pagination={false}
                    />
                  </Form>

                  <br />
                  {show1
                    ? <Form form={form7} initialValues={{}} {...layout}>
                        <Card
                          title={xtitle}
                          extra={
                            <Button ghost type="text" onClick={bclose3}>
                              <CloseOutlined />
                            </Button>
                          }
                        >
                          <ConfigProvider locale={configuredLocale}>
                            <Form.Item
                              label="วันที่"
                              name="sdate"
                              rules={[
                                {
                                  required: true,
                                  message: "กรุณากรอก วันที่"
                                }
                              ]}
                            >
                              <DatePicker autoFocus format="DD/MM/BBBB" />
                            </Form.Item>
                          </ConfigProvider>

                          <Form.Item
                            label="เลขที่เอกสาร"
                            name="ed_iddocument"
                            rules={[
                              {
                                required: true,
                                message: "กรุณากรอก เลขที่เอกสาร"
                              }
                            ]}
                          >
                            <Input allowClear />
                          </Form.Item>

                          <Form.Item label="รายละเอียด" name="edremark">
                            <Input allowClear />
                          </Form.Item>

                          <Form.Item label="ไฟล์" name="edfile">
                            <Input
                              className="form-control"
                              id="drop-area"
                              type="file"
                              onChange={e => onChangeU3(e)}
                            />
                          </Form.Item>

                          <br />
                          <Row justify="center">
                            <Space>
                              <Button
                                ghost
                                type="primary"
                                onClick={bEditSclick3}
                              >
                                <SaveOutlined /> แก้ไข
                              </Button>

                              <Button
                                ghost
                                type="danger"
                                onClick={bClearEclick3}
                              >
                                <ClearOutlined /> เคลียร์
                              </Button>
                            </Space>
                          </Row>
                        </Card>
                      </Form>
                    : <div />}
                </TabPane>
              </Tabs>
            </Card>
            <br />
          </Col>
        </Row>
      </Content>

      <Footer_last />
    </React.Fragment>
  );
};
export default Research_input_file;
