import "antd/dist/antd.min.css";
import "antd-button-color/dist/css/style.css";

import axios from "axios";
import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Layout,
  Table,
  Card,
  Divider,
  Tabs,
  Input,
  Space,
  BackTop,
  PageHeader,
  Spin,
  Select,
  Typography,
  Button,
  Tag,
  Modal,
  Form,
  InputNumber
} from "antd";

import moment from "moment";
import "moment/locale/th";

import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
  ClearOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";

import { createGlobalStyle } from "styled-components";

const { Content } = Layout;
const { Text } = Typography;
const { Option } = Select;
const { confirm } = Modal;

const StyledForm = createGlobalStyle`.ant-form-item {
  margin-bottom: 5px;
}
`;

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 }
};

const Research_dashboard_income = () => {
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [dataSource1, _dataSource1] = useState([]);
  const [dataSource2, _dataSource2] = useState([]);

  const [xYearincome, _xYearincome] = useState([]);
  const [YearValue, _YearValue] = useState("all");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [Header_Modal1, _Header_Modal1] = useState("");
  const [Header_Modal2, _Header_Modal2] = useState("");

  const xDepartment = [
    { name: "CMBID" },
    { name: "IDSU" },
    { name: "NINE" },
    { name: "RSO" },
    { name: "SHSR" }
  ];
  const [xDepartmentValue, _xDepartmentValue] = useState("");

  const [xAmout, _xAmout] = useState(0);
  const [xAmout2, _xAmout2] = useState(0);

  const xMonth = [
    { name: "มกราคม", name2: "Jan" },
    { name: "กุมภาพันธ์", name2: "Feb" },
    { name: "มีนาคม", name2: "Mar" },
    { name: "เมษายน", name2: "Apr" },
    { name: "พฤษภาคม", name2: "May" },
    { name: "มิถุนายน", name2: "Jun" },
    { name: "กรกฎาคม", name2: "Jul" },
    { name: "สิงหาคม", name2: "Aug" },
    { name: "กันยายน", name2: "Sep" },
    { name: "ตุลาคม", name2: "Oct" },
    { name: "พฤศจิกายน", name2: "Nov" },
    { name: "ธันวาคม", name2: "Dec" }
  ];
  const [xMonthValue, _xMonthValue] = useState("");

  const [xYear, _xYear] = useState("");
  const [xYearValue, _xYearValue] = useState("");

  const [show1, _show1] = useState(false);

  const [xincome_name, _xincome_name] = useState("");
  const [xincome_month, _xincome_month] = useState("");
  const [xincome_year, _xincome_year] = useState("");

  const [xSumAmount, _xSumAmount] = useState(0);
  const [xSumAmount2, _xSumAmount2] = useState(0);

  useEffect(() => {
    findYear();

    axios
      .get(xURL + "year_income")
      .then(result => {
        //console.log(result.data);

        _xYearincome(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const findYear = () => {
    let zyear = moment().format("YYYY");
    let tbyar = [];
    for (let i = 0; i < 5; i++) {
      tbyar.push({
        year: parseInt(zyear)
      });

      zyear = zyear - 1;
    }

    _xYear(tbyar);
  };

  const bSearchclick = () => {
    axios
      .post(xURL + "find_income", {
        year: YearValue
      })
      .then(result => {
        //console.log(result.data);
        Show_income(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Show_income = zdata => {
    const Tb1 = [];
    let xsum = 0;

    zdata.forEach((i, idx) => {
      let xamount = 0;

      if (i.amount > 0) {
        xamount = i.amount.toLocaleString(undefined, {
          minimumFractionDigits: 2
        });
      }

      Tb1.push({
        key: idx,
        no: idx + 1,
        income_name: i.income_name,
        department: i.department,
        amount: xamount,
        income_year: i.income_year
      });

      xsum = xsum + i.amount;
    });

    _dataSource1(Tb1);

    _xSumAmount(
      xsum.toLocaleString(undefined, {
        minimumFractionDigits: 2
      })
    );
  };

  const Showamount = zdata => {
    // console.log(zdata);
    _Header_Modal1(
      "แสดงจำนวนเงินรายเดือน [" +
        zdata.income_name +
        "] ปี " +
        zdata.income_year
    );

    _xincome_name(zdata.income_name);
    _xincome_year(zdata.income_year);

    setIsModalVisible(true);

    axios
      .post(xURL + "find_income_list", {
        income_name: zdata.income_name,
        year: zdata.income_year
      })
      .then(result => {
        //console.log(result.data);

        const Tb1 = [];
        let xsum = 0;

        result.data.forEach((i, idx) => {
          let xamount = 0;

          if (i.amount > 0) {
            xamount = i.amount.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          Tb1.push({
            key: idx,
            no: idx + 1,
            income_month: __monthTh(i.income_month),
            amount: xamount
          });

          xsum = xsum + i.amount;
        });

        _dataSource2(Tb1);

        _xSumAmount2(
          xsum.toLocaleString(undefined, {
            minimumFractionDigits: 2
          })
        );
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bAddIncome = () => {
    form1.resetFields();

    _xDepartmentValue("");
    _xMonthValue("");
    _xYearValue("");

    setIsModalVisible2(true);
  };

  const bclearclick = () => {
    form1.resetFields();

    _xDepartmentValue("");
    _xMonthValue("");
    _xYearValue("");
  };

  const bsaveclick = () => {
    const f = form1.getFieldValue();

    if (!f.income_name) {
      Modal.info({
        title: "บันทึก ไม่ได้",
        content: <p>ยังไม่ได้คีย์ ชื่อรายการ</p>,
        onOk() {}
      });
    } else if (xDepartmentValue == "") {
      Modal.info({
        title: "บันทึก ไม่ได้",
        content: <p>ยังไม่ได้เลือก Department</p>,
        onOk() {}
      });
    } else if (f.edamount == "") {
      Modal.info({
        title: "บันทึก ไม่ได้",
        content: <p>ยังไม่ได้คีย์ จำนวนเงิน</p>,
        onOk() {}
      });
    } else if (xMonthValue == "") {
      Modal.info({
        title: "บันทึก ไม่ได้",
        content: <p>ยังไม่ได้เลือก เดือน</p>,
        onOk() {}
      });
    } else if (xYearValue == "") {
      Modal.info({
        title: "บันทึก ไม่ได้",
        content: <p>ยังไม่ได้เลือก ปี</p>,
        onOk() {}
      });
    } else {
      savedata();
    }
  };

  const savedata = () => {
    const f = form1.getFieldValue();

    let xmonth_num = 0;

    if (xMonthValue == "Jan") {
      xmonth_num = 1;
    } else if (xMonthValue == "Feb") {
      xmonth_num = 2;
    } else if (xMonthValue == "Mar") {
      xmonth_num = 3;
    } else if (xMonthValue == "Apr") {
      xmonth_num = 4;
    } else if (xMonthValue == "May") {
      xmonth_num = 5;
    } else if (xMonthValue == "Jun") {
      xmonth_num = 6;
    } else if (xMonthValue == "Jul") {
      xmonth_num = 7;
    } else if (xMonthValue == "Aug") {
      xmonth_num = 8;
    } else if (xMonthValue == "Sep") {
      xmonth_num = 9;
    } else if (xMonthValue == "Oct") {
      xmonth_num = 10;
    } else if (xMonthValue == "Nov") {
      xmonth_num = 11;
    } else if (xMonthValue == "Dec") {
      xmonth_num = 12;
    }

    axios
      .post(xURL + "insert_income", {
        income_name: f.income_name,
        department: xDepartmentValue,
        amount: f.edamount,
        month: xMonthValue,
        years: xYearValue + 543,
        month_num: xmonth_num
      })
      .then(result => {
        //console.log(result.data);

        setIsModalVisible2(false);
        bSearchclick();
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const editamount = zdata => {
    if (zdata.income_month == "--ไม่มีข้อมูลรายเดือน--") {
      _Header_Modal2("-");
    } else {
      _Header_Modal2(zdata.income_month);
    }

    _xincome_month(zdata.income_month);

    _show1(true);
  };

  const bEditClick = () => {
    const f = form2.getFieldValue();

    // console.log(xAmout2, xincome_name, xincome_year, __monthEng(xincome_month));

    if (f.edamount == "") {
      Modal.info({
        title: "บันทึก ไม่ได้",
        content: <p>ยังไม่ได้คีย์ จำนวนเงิน</p>,
        onOk() {}
      });
    } else {
      axios
        .post(xURL + "edit_income", {
          income_name: xincome_name,
          amount: xAmout2,
          month: __monthEng(xincome_month),
          years: xincome_year
        })
        .then(result => {
          //console.log(result.data);

          _show1(false);
          Showamount({ income_name: xincome_name, income_year: xincome_year });
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    }
  };

  const deleteincome = zdata => {
    confirm({
      title: <Text style={{ color: "#003a8c" }}>ยืนยัน ลบ Income</Text>,
      icon: <ExclamationCircleOutlined />,
      content: (
        <Text>
          รายการ : {xincome_name}
          <br />
          ปี : {xincome_year} เดือน {zdata.income_month}
          <br />
          จำนวนเงิน : {zdata.amount} บาท
        </Text>
      ),
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk() {
        axios
          .post(xURL + "delete_income", {
            income_name: xincome_name,
            amount: zdata.amount.replace(",", "").replace(",", ""),
            month: __monthEng(zdata.income_month),
            years: xincome_year
          })
          .then(result => {
            //console.log(result.data);

            Showamount({
              income_name: xincome_name,
              income_year: xincome_year
            });
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      }
    });
  };

  const columns1 = [
    {
      title: "ที่",
      dataIndex: "no",
      key: "no",
      align: "center",
      width: 100,
      render: (text, record, index) => {
        return index + 1;
      }
    },
    {
      title: "รายการ",
      dataIndex: "income_name",
      key: "income_name",
      align: "center",

      sorter: (a, b) => {
        return a.income_name.localeCompare(b.income_name);
      },
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showamount(record);
              }}
            >
              {__TBShowText(text, 0, 1)}
            </a>
          )
        };
      }
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      align: "center",
      width: 300,
      render: department => {
        let xcolor = "";

        if (department === "IDSU") {
          xcolor = "gold";
        } else if (department === "NINE") {
          xcolor = "geekblue";
        } else if (department === "CMBID") {
          xcolor = "green";
        } else if (department === "RSO") {
          xcolor = "red";
        } else if (department === "SHSR") {
          xcolor = "volcano";
        }

        return (
          <Tag color={xcolor} key={department}>
            {department.toUpperCase()}
          </Tag>
        );
      },
      sorter: (a, b) => {
        return a.department.localeCompare(b.department);
      }
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: 300,
      render: (text, record) => __TBShowText(record.amount, 1, 0),
      sorter: (a, b) =>
        a.amount.replace(",", "").replace(",", "") -
        b.amount.replace(",", "").replace(",", "")
    },
    {
      title: "ปี",
      dataIndex: "income_year",
      key: "income_year",
      align: "center",
      width: 200,
      sorter: (a, b) => a.income_year - b.income_year
    }
  ];

  const columns2 = [
    {
      title: "ที่",
      dataIndex: "no",
      key: "no",
      align: "center",
      width: 100,
      render: (text, record, index) => {
        return index + 1;
      }
    },
    {
      title: "เดือน",
      dataIndex: "income_month",
      key: "income_month",
      align: "center",
      render: (text, record) => __TBShowText(record.income_month, 0, 1)
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: 300,
      render: (text, record) => __TBShowText(record.amount, 1, 0),
      sorter: (a, b) =>
        a.amount.replace(",", "").replace(",", "") -
        b.amount.replace(",", "").replace(",", "")
    },
    {
      title: "แก้ไข",
      dataIndex: "update",
      key: "update",
      align: "center",
      width: 80,
      render(text, record, index) {
        return {
          children: (
            <Button
              icon={<EditOutlined />}
              size="small"
              onClick={e => {
                //console.log("corresponding email is :", record.i_sum3);
                editamount(record);
              }}
            />
          )
        };
      }
    },
    {
      title: "ลบ",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      width: 80,
      render(text, record, index) {
        return {
          children: (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="small"
              onClick={e => {
                //console.log("corresponding email is :", record.i_sum3);
                deleteincome(record);
              }}
            />
          )
        };
      }
    }
  ];

  const handleChange = value => {
    //console.log(`selected ${value}`);
    _dataSource1();

    _xSumAmount(0);
    _xSumAmount2(0);

    _YearValue(value);
  };

  const handleOk = () => {
    form2.resetFields();

    setIsModalVisible(false);
    bSearchclick();

    _show1(false);
  };

  const handleCancel = () => {
    form2.resetFields();

    setIsModalVisible(false);
    bSearchclick();

    _show1(false);
  };

  const handleOk2 = () => {
    setIsModalVisible2(false);
    bSearchclick();
  };

  const handleCancel2 = () => {
    setIsModalVisible2(false);
    bSearchclick();
  };

  function handleChangesl(value) {
    _xDepartmentValue(value);
  }
  function handleChangesl2(value) {
    _xMonthValue(value);
  }
  function handleChangesl3(value) {
    _xYearValue(value);
  }

  const onChangeededamount = e => {
    _xAmout(e);
  };

  const onChangeededamount2 = e => {
    _xAmout2(e);
  };

  //formatttable (Rigth or Left)
  function __TBShowText(zData, zRigth, zLeft) {
    if (zRigth === 1 && zLeft === 0) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Text style={{ fontWeight: "bold" }}>
            {zData}
          </Text>
        </div>
      );
    } else if (zRigth === 0 && zLeft === 1) {
      return (
        <div style={{ display: "flex" }}>
          <Text style={{ fontWeight: "bold" }}>
            {zData}
          </Text>
        </div>
      );
    }
  }

  function __monthTh(zData) {
    if (zData == "Jan") {
      return "มกราคม";
    } else if (zData == "Feb") {
      return "กุมภาพันธ์";
    } else if (zData == "Mar") {
      return "มีนาคม";
    } else if (zData == "Apr") {
      return "เมษายน";
    } else if (zData == "May") {
      return "พฤษภาคม";
    } else if (zData == "Jun") {
      return "มิถุนายน";
    } else if (zData == "Jul") {
      return "กรกฎาคม";
    } else if (zData == "Aug") {
      return "สิงหาคม";
    } else if (zData == "Sep") {
      return "กันยายน";
    } else if (zData == "Oct") {
      return "ตุลาคม";
    } else if (zData == "Nov") {
      return "พฤศจิกายน";
    } else if (zData == "Dec") {
      return "ธันวาคม";
    } else {
      return "--ไม่มีข้อมูลรายเดือน--";
    }
  }

  function __monthEng(zData) {
    if (zData == "มกราคม") {
      return "Jan";
    } else if (zData == "กุมภาพันธ์") {
      return "Feb";
    } else if (zData == "มีนาคม") {
      return "Mar";
    } else if (zData == "Apr") {
      return "เมษายน";
    } else if (zData == "พฤษภาคม") {
      return "May";
    } else if (zData == "มิถุนายน") {
      return "Jun";
    } else if (zData == "กรกฎาคม") {
      return "Jul";
    } else if (zData == "สิงหาคม") {
      return "Aug";
    } else if (zData == "กันยายน") {
      return "Sep";
    } else if (zData == "ตุลาคม") {
      return "Oct";
    } else if (zData == "พฤศจิกายน") {
      return "Nov";
    } else if (zData == "ธันวาคม") {
      return "Dec";
    } else {
      return "";
    }
  }

  return (
    <React.Fragment>
      <StyledForm />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={23}>
            <PageHeader className="site-page-header" title="RIHES INCOME" />
            <Divider />

            <Row>
              <Space>
                <p style={{ marginTop: "10px" }}>เลือกปี :</p>
                <Select
                  defaultValue="all"
                  style={{ width: 100 }}
                  onChange={handleChange}
                >
                  <Option value="all">ทั้งหมด</Option>

                  {xYearincome.map((i, idx) => {
                    return (
                      <Option value={i.income_year}>
                        {i.income_year}
                      </Option>
                    );
                  })}
                </Select>

                <Button type="primary" onClick={bSearchclick}>
                  <SearchOutlined /> ค้นหา
                </Button>

                <Button ghost type="primary" onClick={bAddIncome}>
                  <PlusOutlined /> เพิ่ม Income
                </Button>
              </Space>
            </Row>
            <br />
            <Table
              bordered
              scroll={{ x: "max-content" }}
              dataSource={dataSource1}
              columns={columns1}
              size="small"
              pagination={false}
              summary={() =>
                <Table.Summary fixed>
                  <Table.Summary.Row
                    style={{
                      textAlign: "right",
                      backgroundColor: "#b5f5ec",
                      fontWeight: "bold"
                    }}
                  >
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                    <Table.Summary.Cell>รวมทั้งหมด</Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {xSumAmount}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell />
                  </Table.Summary.Row>
                </Table.Summary>}
            />
          </Col>
        </Row>

        <div style={{ height: "100px" }} />

        <Modal
          title={
            <Text style={{ fontWeight: "bold" }}>
              {Header_Modal1}
            </Text>
          }
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          width={1200}
        >
          <Table
            bordered
            scroll={{ x: "max-content" }}
            dataSource={dataSource2}
            columns={columns2}
            size="small"
            pagination={false}
            summary={() =>
              <Table.Summary fixed>
                <Table.Summary.Row
                  style={{
                    textAlign: "right",
                    backgroundColor: "#b5f5ec",
                    fontWeight: "bold"
                  }}
                >
                  <Table.Summary.Cell />
                  <Table.Summary.Cell>รวมทั้งหมด</Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {xSumAmount2}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell />
                  <Table.Summary.Cell />
                </Table.Summary.Row>
              </Table.Summary>}
          />

          {show1
            ? <div>
                <br />
                <h4 style={{ fontWeight: "bold" }}>
                  แก้ไขจำนวนเงิน [เดือน {Header_Modal2}]
                </h4>

                <Form form={form2} initialValues={{ edamount: "" }}>
                  <Row>
                    <Space>
                      <Form.Item
                        label="จำนวนเงิน"
                        name="edamount"
                        rules={[
                          {
                            required: true,
                            message: "กรุณากรอก จำนวนเงิน"
                          }
                        ]}
                      >
                        <InputNumber
                          autoFocus
                          style={{ width: 200 }}
                          //disabled={Export}
                          min="0"
                          max="9999999999"
                          maxLength="12"
                          onChange={e => onChangeededamount2(e)}
                          formatter={value =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          parser={value => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      </Form.Item>

                      <Button
                        type="primary"
                        style={{ marginTop: "-5px" }}
                        onClick={bEditClick}
                      >
                        <EditOutlined /> แก้ไข
                      </Button>
                    </Space>
                  </Row>
                </Form>
              </div>
            : <div />}
        </Modal>

        <Modal
          title={<Text style={{ fontWeight: "bold" }}>เพิ่ม Income</Text>}
          visible={isModalVisible2}
          onOk={handleOk2}
          onCancel={handleCancel2}
          footer={null}
          width={1200}
        >
          <Form
            form={form1}
            initialValues={{
              income_name: "",
              sldepartment: "เลือกรายการ",
              edamount: "",
              slMonth: "เลือกรายการ",
              slYear: "เลือกรายการ"
            }}
            {...layout}
          >
            <Form.Item
              label="ชื่อรายการ"
              name="income_name"
              rules={[
                {
                  required: true,
                  message: "กรุณากรอก ชื่อรายการ"
                }
              ]}
            >
              <Input
                autoFocus
                allowClear
                placeholder="ชื่อรายการ"
                // maxLength="10"
                //style={{ width: 400 }}
              />
            </Form.Item>

            <Form.Item
              label="Department"
              name="sldepartment"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select onChange={handleChangesl}>
                {xDepartment.length > 0
                  ? xDepartment.map((i, idx) => {
                      return (
                        <Option value={i.name}>
                          {i.name}
                        </Option>
                      );
                    })
                  : <Option value="0">
                      {}
                    </Option>}
              </Select>
            </Form.Item>

            <Form.Item
              label="จำนวนเงิน"
              name="edamount"
              rules={[
                {
                  required: true,
                  message: "กรุณากรอก จำนวนเงิน"
                }
              ]}
            >
              <InputNumber
                style={{ width: 200 }}
                //disabled={Export}
                min="0"
                max="9999999999"
                maxLength="12"
                onChange={e => onChangeededamount(e)}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>

            <Form.Item
              label="เดือน"
              name="slMonth"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select onChange={handleChangesl2} style={{ width: 200 }}>
                {xMonth.length > 0
                  ? xMonth.map((i, idx) => {
                      return (
                        <Option value={i.name2}>
                          {i.name}
                        </Option>
                      );
                    })
                  : <Option value="0">
                      {}
                    </Option>}
              </Select>
            </Form.Item>

            <Form.Item
              label="ปี"
              name="slYear"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select onChange={handleChangesl3} style={{ width: 200 }}>
                {xYear.length > 0
                  ? xYear.map((i, idx) => {
                      return (
                        <Option value={i.year}>
                          {i.year}
                        </Option>
                      );
                    })
                  : <Option value="0">
                      {}
                    </Option>}
              </Select>
            </Form.Item>

            <br />

            <Row justify="center">
              <Space>
                <Button ghost type="primary" onClick={bsaveclick}>
                  <SaveOutlined /> บันทึก
                </Button>

                <Button ghost type="danger" onClick={bclearclick}>
                  <ClearOutlined /> เคลียร์
                </Button>
              </Space>
            </Row>
          </Form>
        </Modal>
      </Content>
    </React.Fragment>
  );
};

export default Research_dashboard_income;
