import React, { useEffect } from "react";
import "antd/dist/antd.min.css";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Row, Col, Button, Typography, Form, Input, Card } from "antd";

import axios from "axios";
import swal from "sweetalert";

import background from "../pics/animation1.mp4";

const { Text } = Typography;

const Research_login = () => {
  const [form1] = Form.useForm();

  useEffect(() => {
    //localStorage.removeItem("token");
    //console.log(localStorage.token);
    if (localStorage.token) {
      window.location = "/Research_scopus";
    }
  }, []);

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const onFinish = values => {
    //console.log("Received values of form: ", values);
    //console.log(values.username);
    //console.log(values.password);

    axios
      .post(xURL + "login", {
        username: values.username,
        password: values.password
      })
      .then(result => {
        //console.log(xURL);
        //console.log(result.data.result[0].full_name);

        if (result.data.status === "ok") {
          //alert("login sucess");

          swal({
            title: "แจ้งเตือน!",
            text: "Login สำเร็จ",
            icon: "success",
            buttons: false,
            timer: 2000
          }).then(() => {
            localStorage.setItem("token", result.data.token);
            localStorage.setItem("tname", result.data.result[0].full_name);
            localStorage.setItem(
              "research_id",
              result.data.result[0].researcher_staff_id
            );
            //window.location = "/Research_main";
            if (result.data.result[0].user_level_id == 7) {
              window.location = "/Research_main";
            } else {
              window.location = "/Research_scopus";
            }
          });
        } else {
          swal(
            "Login ไม่สำเร็จ",
            "Username หรือ Password ไม่ถูกต้อง",
            "error",
            {
              buttons: false,
              timer: 2000
            }
          );
        }
        /*
        if (typeof result.data[0] != "undefined") {
          //console.log(result.data[0]);

          window.open("/Research_main", "_self");
        } else {
          //console.log("no");

          Modal.error({
            title: "Login ไม่สำเร็จ",
            content: <p>Username หรือ Password ไม่ถูกต้อง</p>,
            onOk() {}
          });
        }*/
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  return (
    <React.Fragment>
      <video id="background-fix" loop autoPlay muted>
        <source src={background} type="video/mp4" />
        <source src={background} type="video/ogg" />
      </video>
      <Row justify="space-around">
        <Col
          xs={23}
          sm={23}
          md={16}
          lg={14}
          xl={8}
          justify="space-around"
          style={{ padding: "15px", paddingTop: "200px" }}
        >
          <Card
            id="ant-card-body"
            style={{
              borderRadius: "15px",
              overflow: "hidden"
            }}
          >
            <Form
              form={form1}
              name="normal_login"
              className="login-form"
              initialValues={{}}
              onFinish={onFinish}
            >
              <Row justify="space-around">
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: "40px"
                  }}
                >
                  <UserOutlined /> Login
                </Text>
              </Row>
              <br />
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your Username!"
                  }
                ]}
              >
                <Input
                  autoFocus
                  allowClear
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Username"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!"
                  }
                ]}
              >
                <Input.Password
                  allowClear
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              {/* <a href="#">Forgot Password?</a>
              <br /> 
              <br /> */}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  block
                >
                  Log in
                </Button>
                <br />
                Or <a href="/Research_register">register now!</a>
              </Form.Item>
            </Form>

            <br />
            <Row justify="space-around">
              <h4>Copyright &copy; 2022 Research RIHES CMU</h4>
            </Row>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default Research_login;
