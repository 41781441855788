import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Layout,
  Table,
  Card,
  Divider,
  Tabs,
  Input,
  Space,
  BackTop,
  PageHeader,
  Spin,
  Select,
  Typography
} from "antd";

import Button from "antd-button-color";

import { createGlobalStyle } from "styled-components";

import {
  CalendarOutlined,
  TeamOutlined,
  SearchOutlined,
  FileTextOutlined,
  SolutionOutlined,
  SplitCellsOutlined,
  InteractionOutlined,
  FileExcelOutlined,
  PartitionOutlined
} from "@ant-design/icons";

import moment from "moment";
//import "moment/locale/th";

import Header from "./Header";
import Footer2 from "./Footer";

import swal from "sweetalert";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import thTH from "antd/lib/locale/th_TH";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

import * as xlsx from "xlsx";

const { Content } = Layout;
const { TabPane } = Tabs;
const { Option } = Select;
const { Text } = Typography;

const StyledForm = createGlobalStyle`.ant-form-item {
  margin-bottom: 5px;
}
`;

const configuredLocale = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    dateFormat: "DD MM BBBB", // DD MM BBBB
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker.lang,
      dateFormat: "DD MM BBBB", // DD MM BBBB
      dateTimeFormat: "DD MM BBBB HH:mm:ss",
      yearFormat: "BBBB"
    }
  }
};

dayjs.locale("th");
dayjs.extend(buddhistEra);

const Research_scopus2 = () => {
  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [dataSource1, _dataSource1] = useState([]);
  const [dataSource2, _dataSource2] = useState([]);

  const [Sum_All, _Sum_All] = useState(0);
  const [Sum_Q1, _Sum_Q1] = useState(0);
  const [Sum_Q2, _Sum_Q2] = useState(0);
  const [Sum_Q3, _Sum_Q3] = useState(0);
  const [Sum_Q4, _Sum_Q4] = useState(0);
  const [Sum_NA, _Sum_NA] = useState(0);

  const [loading, _loading] = useState(true);

  const DatePicker = generatePicker(dayjsGenerateConfig);

  const [keyTab, _keyTab] = useState("1");

  const [dataYear, _dataYear] = useState([]);

  const [xYearpapers, _xYearpapers] = useState([]);
  const [YearValue, _YearValue] = useState("all");
  const [YearValue2, _YearValue2] = useState("all");

  useEffect(() => {
    axios
      .get(xURL + "year_papers")
      .then(result => {
        //console.log(result.data);

        _xYearpapers(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    GetTable();
    GetTable2();
  }, []);

  const GetTable = () => {
    axios
      .get(xURL + "find_scopus")
      .then(result => {
        //console.log(result.data);
        Show_scopus(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetTable2 = () => {
    axios
      .post(xURL + "find_scopus2", {
        year: YearValue2
      })
      .then(result => {
        //console.log(result.data);
        Show_scopus2(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Show_scopus = zdata => {
    const Tb1 = [];
    let xsumAll = 0,
      xsumQ1 = 0,
      xsumQ2 = 0,
      xsumQ3 = 0,
      xsumQ4 = 0,
      xsumNA = 0;

    zdata.forEach((i, idx) => {
      let xsdate = moment(i.allpp, "YYYY").add(543, "year").format("L");

      xsdate = moment(xsdate).format("YYYY");

      Tb1.push({
        key: idx,
        pp_year: xsdate,
        pp_all: i.C_allpp,
        q1: i.C_q1pp,
        q2: i.C_q2pp,
        q3: i.C_q3pp,
        q4: i.C_q4pp,
        q_other: i.C_napp
      });

      xsumAll = xsumAll + i.C_allpp;
      xsumQ1 = xsumQ1 + i.C_q1pp;
      xsumQ2 = xsumQ2 + i.C_q2pp;
      xsumQ3 = xsumQ3 + i.C_q3pp;
      xsumQ4 = xsumQ4 + i.C_q4pp;
      xsumNA = xsumNA + i.C_napp;
    });

    _dataSource1(Tb1);

    _Sum_All(xsumAll);
    _Sum_Q1(xsumQ1);
    _Sum_Q2(xsumQ2);
    _Sum_Q3(xsumQ3);
    _Sum_Q4(xsumQ4);
    _Sum_NA(xsumNA);
  };

  const Show_scopus2 = zdata => {
    const Tb1 = [];

    zdata.forEach((i, idx) => {
      Tb1.push({
        key: idx,
        num: idx + 1,
        research: i.research,
        pp_all: i.C_allpp,
        pp_first: i.C_firstpp,
        q1: i.C_q1pp,
        q2: i.C_q2pp,
        q3: i.C_q3pp,
        q4: i.C_q4pp,
        q_other: i.C_napp,
        h_index: i.h_index_link
      });

      _loading(false);
    });

    _dataSource2(Tb1);
  };

  const Showlist = (zyear, zhead, zcount) => {
    //console.log(zyear, zhead, zcount);

    if (zcount > 0) {
      window.open(
        "/Research_scopus2_list?year=" +
          zyear +
          "&head=" +
          zhead +
          "&count=" +
          zcount,
        "_blank"
      );
    } else {
      swal("แจ้งเตือน!", "ไม่มีข้อมูลตามเงื่อนไขที่เลือก!", "warning");
    }
  };

  const Showlist2 = (zresearch, zhead, zcount) => {
    //console.log(zresearch, zhead, zcount);

    if (zcount > 0) {
      window.open(
        "/Research_scopus2_list2?research=" +
          zresearch +
          "&head=" +
          zhead +
          "&count=" +
          zcount,
        "_blank"
      );
    } else {
      swal("แจ้งเตือน!", "ไม่มีข้อมูลตามเงื่อนไขที่เลือก!", "warning");
    }
  };

  const bExcelClick = () => {
    //console.log(keyTab);

    axios
      .post(xURL + "scopusEx", {
        keys: keyTab,
        c2: YearValue2,
        c4: YearValue,
        c5: dataYear
      })
      .then(result => {
        let xdata = [],
          xtabtitle = "",
          header = [],
          header2 = [];

        if (keyTab == "1") {
          xtabtitle = "ปีปฏิทิน";

          header.push(
            "รายการข้อมูล",
            "ทั้งหมด",
            "Q1",
            "Q2",
            "Q3",
            "Q4",
            "ไม่ระบุ"
          );
        } else if (keyTab == "2") {
          xtabtitle = "จำนวนผลงานตีพิมพ์ใน SCOPUS";

          header.push(
            "ชื่อนักวิจัย",
            "จำนวนผลงานตีพิมพ์",
            "จำนวนผลงานที่เป็น First/Corresponding Author",
            "Q1",
            "Q2",
            "Q3",
            "Q4",
            "ไม่มี Q"
          );
        }

        if (keyTab == "2") {
          xdata.push([xtabtitle]);

          if (YearValue2 != "all") {
            xdata.push([
              "แยกตามนักวิจัย ปี พ.ศ. " +
                parseInt(YearValue2 + 543) +
                " (1 มกราคม " +
                parseInt(YearValue2 + 543) +
                " - 31 ธันวาคม " +
                parseInt(YearValue2 + 543) +
                ")"
            ]);
          } else {
            xdata.push(["แยกตามนักวิจัย ปี พ.ศ. ทั้งหมด"]);
          }
        } else {
          xdata.push(["SCOPUS (" + xtabtitle + ")"]);
        }

        xdata.push([""]);

        let tb = [],
          xyear = "";

        tb.push("รายการข้อมูล");
        dataYear.forEach((i, idx) => {
          xyear = dayjs("01/01/" + i.year).format("BBBB");

          tb.push(xyear);
        });

        xdata.push(tb);

        for (var row in result.data) {
          const xx = [];

          for (var col in result.data[row]) {
            xx.push(result.data[row][col]);
          }

          xdata.push(xx);
        }

        if (keyTab == "1") {
          axios
            .post(xURL + "sumscopusEx", { keys: keyTab, c5: dataYear })
            .then(result2 => {
              for (var row in result2.data) {
                const yy = [];
                for (var col in result2.data[row]) {
                  yy.push(result2.data[row][col]);
                }
                xdata.push(yy);
              }
              var wb = xlsx.utils.book_new(),
                ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

              xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
              xlsx.writeFile(wb, "Excel.xlsx");
            });
        } else {
          var wb = xlsx.utils.book_new(),
            ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

          xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
          xlsx.writeFile(wb, "Excel.xlsx");
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };
  const columns1 = [
    {
      title: "รายการข้อมูล",
      dataIndex: "pp_year",
      key: "pp_year",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(record.pp_year, "All", record.pp_all);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.pp_year - b.pp_year
    },
    {
      title: "ทั้งหมด",
      dataIndex: "pp_all",
      key: "pp_all",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(record.pp_year, "All", record.pp_all);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.pp_all - b.pp_all
    },
    {
      title: "Q1",
      dataIndex: "q1",
      key: "q1",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(record.pp_year, "Q1", record.q1);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q1 - b.q1
    },
    {
      title: "Q2",
      dataIndex: "q2",
      key: "q2",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(record.pp_year, "Q2", record.q2);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q2 - b.q2
    },
    {
      title: "Q3",
      dataIndex: "q3",
      key: "q3",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(record.pp_year, "Q3", record.q3);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q3 - b.q3
    },
    {
      title: "Q4",
      dataIndex: "q4",
      key: "q4",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(record.pp_year, "Q4", record.q4);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q4 - b.q4
    },
    {
      title: "ไม่ระบุ",
      dataIndex: "q_other",
      key: "q_other",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(record.pp_year, "N/A", record.q_other);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q_other - b.q_other
    }
  ];

  const columns2 = [
    {
      title: "No.",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 50,
      sorter: (a, b) => a.num - b.num,
      render: (text, record, index) => {
        return index + 1;
      }
    },
    {
      title: "ชื่อนักวิจัย",
      dataIndex: "research",
      key: "research",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                // Showlist2(record.research, "All", record.pp_all);
                window.open(record.h_index, "_blank");
              }}
            >
              {text}
            </a>
          )
        };
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              autoFocus
              allowClear
              placeholder="ค้นหา"
              value={selectedKeys[0]}
              onChange={e => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              style={{ marginBottom: 5 }}
            >
              {}
            </Input>

            <Space>
              <Button
                icon={<SearchOutlined />}
                type="primary"
                size="small"
                style={{ width: 90 }}
                onClick={() => {
                  confirm();
                }}
              >
                ค้นหา
              </Button>
              <Button
                type="danger"
                size="small"
                style={{ width: 90 }}
                onClick={() => {
                  clearFilters();
                }}
              >
                เคลียร์
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.research.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.num - b.num
    },
    {
      title: "จำนวนผลงานตีพิมพ์",
      dataIndex: "pp_all",
      key: "pp_all",
      align: "center",
      width: 150,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.research, "All", record.pp_all);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.pp_all - b.pp_all
    },
    {
      title: "จำนวนผลงานที่เป็น First/Corresponding Author",
      dataIndex: "pp_first",
      key: "pp_first",
      align: "center",
      width: 310,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.research, "First", record.pp_first);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.pp_first - b.pp_first
    },
    {
      title: "Q1",
      dataIndex: "q1",
      key: "q1",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.research, "Q1", record.q1);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q1 - b.q1
    },
    {
      title: "Q2",
      dataIndex: "q2",
      key: "q2",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.research, "Q2", record.q2);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q2 - b.q2
    },
    {
      title: "Q3",
      dataIndex: "q3",
      key: "q3",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.research, "Q3", record.q3);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q3 - b.q3
    },
    {
      title: "Q4",
      dataIndex: "q4",
      key: "q4",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.research, "Q4", record.q4);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q4 - b.q4
    },
    {
      title: "ไม่มี Q",
      dataIndex: "q_other",
      key: "q_other",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.research, "N/A", record.q_other);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q_other - b.q_other
    }
  ];

  const handleChange3 = value => {
    //console.log(`selected ${value}`);

    _YearValue2(value);

    axios
      .post(xURL + "find_scopus2", {
        year: value
      })
      .then(result => {
        //console.log(result.data);
        Show_scopus2(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const TabonChange = e => {
    //console.log(e);
    _keyTab(e);
  };

  return (
    <React.Fragment>
      <StyledForm />

      <Header />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={23}>
            <PageHeader className="site-page-header" title="SCOPUS" />
            <Divider />

            <Card
              title={
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "center",
                    color: "white"
                  }}
                >
                  จำนวนผลงานตีพิมพ์ใน SCOPUS
                </div>
              }
              headStyle={{
                backgroundColor: "#00474f"
              }}
              style={{
                //margin: "10px",
                borderRadius: "15px",
                overflow: "hidden"
              }}
            >
              <Row>
                <Space>
                  <Button
                    ghost
                    type="dark"
                    onClick={() => {
                      Showlist("All", "All", Sum_All);
                    }}
                  >
                    <FileTextOutlined /> ผลงานตีพิมพ์ทั้งหมด
                  </Button>

                  <Button ghost type="success" onClick={bExcelClick}>
                    <FileExcelOutlined /> Excel
                  </Button>
                </Space>
              </Row>

              <Divider />

              <Tabs defaultActiveKey="1" size={"small"} onChange={TabonChange}>
                <TabPane
                  tab={
                    <span>
                      <CalendarOutlined />
                      ปีปฏิทิน
                    </span>
                  }
                  key="1"
                >
                  <Table
                    bordered
                    scroll={{ x: "max-content" }}
                    dataSource={dataSource1}
                    columns={columns1}
                    size="small"
                    pagination={false}
                    summary={() =>
                      <Table.Summary fixed>
                        <Table.Summary.Row
                          style={{
                            textAlign: "center",
                            backgroundColor: "#b5f5ec"
                          }}
                        >
                          <Table.Summary.Cell>รวมทั้งหมด</Table.Summary.Cell>

                          <Table.Summary.Cell>
                            <a
                              onClick={() => {
                                Showlist("All", "All", Sum_All);
                              }}
                            >
                              {Sum_All}
                            </a>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <a
                              onClick={() => {
                                Showlist("All", "Q1", Sum_Q1);
                              }}
                            >
                              {Sum_Q1}
                            </a>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <a
                              onClick={() => {
                                Showlist("All", "Q2", Sum_Q2);
                              }}
                            >
                              {Sum_Q2}
                            </a>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <a
                              onClick={() => {
                                Showlist("All", "Q3", Sum_Q3);
                              }}
                            >
                              {Sum_Q3}
                            </a>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <a
                              onClick={() => {
                                Showlist("All", "Q4", Sum_Q4);
                              }}
                            >
                              {Sum_Q4}
                            </a>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <a
                              onClick={() => {
                                Showlist("All", "N/A", Sum_NA);
                              }}
                            >
                              {Sum_NA}
                            </a>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </Table.Summary>}
                  />
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <TeamOutlined />
                      นักวิจัย
                    </span>
                  }
                  key="2"
                >
                  <Spin tip="Loading..." spinning={loading} size="large">
                    <Row>
                      <Space>
                        <p>แสดงตามปี :</p>
                        <Select
                          defaultValue="all"
                          style={{ width: 100 }}
                          onChange={handleChange3}
                        >
                          <Option value="all">ทั้งหมด</Option>

                          {xYearpapers.map((i, idx) => {
                            return (
                              <Option value={i.year}>
                                {i.year}
                              </Option>
                            );
                          })}
                        </Select>
                      </Space>
                    </Row>

                    <br />

                    <Table
                      bordered
                      scroll={{ x: "max-content" }}
                      dataSource={dataSource2}
                      columns={columns2}
                      size="small"
                      pagination={false}
                    />
                  </Spin>
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </Content>

      <div style={{ height: "100px" }} />

      <Footer2 />
      <BackTop />
    </React.Fragment>
  );
};

export default Research_scopus2;
