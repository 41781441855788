import React, { useState, useEffect, useRef } from "react";
import "antd/dist/antd.min.css";
import axios from "axios";
import swal from "sweetalert";
import { createGlobalStyle } from "styled-components";
import {
  SaveOutlined,
  ClearOutlined,
  PaperClipOutlined,
  EditOutlined,
  DeleteOutlined
} from "@ant-design/icons";

import {
  Layout,
  Row,
  Col,
  Divider,
  PageHeader,
  Table,
  ConfigProvider,
  Form,
  Card,
  InputNumber,
  Input,
  Space,
  Button,
  Select,
  Typography
} from "antd";

import "moment/locale/th";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import thTH from "antd/lib/locale/th_TH";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

import moment from "moment";
import "moment/locale/th";

const { Content } = Layout;
const { Option } = Select;
const { Text } = Typography;

const StyledForm = createGlobalStyle`.ant-form-item {
    margin-bottom: 5px;
  }
  `;

const configuredLocale = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    dateFormat: "DD MM BBBB", // DD MM BBBB
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker.lang,
      dateFormat: "DD MM BBBB", // DD MM BBBB
      dateTimeFormat: "DD MM BBBB HH:mm:ss",
      yearFormat: "BBBB"
    }
  }
};

dayjs.locale("th");
dayjs.extend(buddhistEra);

const layout = {
  labelCol: { xl: 10 },
  wrapperCol: { xl: 14 }
};

const layout2 = {
  labelCol: { xl: 14 },
  wrapperCol: { xl: 10 }
};

const Research_Transfer = () => {
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const DatePicker = generatePicker(dayjsGenerateConfig);

  const [xcmumis_id, _xcmumis_id] = useState();
  const [xterm, _xterm] = useState();
  const [xamount, _xamount] = useState();
  const [xdetail, _xdetail] = useState();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [dataSource1, _dataSource1] = useState();
  const [dataSource2, _dataSource2] = useState();

  const [ListFile, _ListFile] = useState([]);
  const [ListFile2, _ListFile2] = useState([]);

  const [xexpenses, _xexpenses] = useState();
  const [xover70, _xover70] = useState();
  const [xover30, _xover30] = useState();
  const [xtotal, _xtotal] = useState();
  const [xtotal2, _xtotal2] = useState();

  const txtExchange = useRef("");
  const txtMInvoice = useRef("");
  const txtFa = useRef("");
  const txtReceive = useRef("");
  const txtExchange2 = useRef("");

  const [xCurrency, _xCurrency] = useState("");
  const [xCurrencyValue, _xCurrencyValue] = useState("0");

  const [xdis, _xdis] = useState(false);
  const [xdis2, _xdis2] = useState(false);

  const [sdateInvoice, _sdateInvoice] = useState("");
  const [edateInvoice, _edateInvoice] = useState("");

  const [xChkrow, _xChkrow] = useState(0);

  const [odate_receive, _odate_receive] = useState("");
  const [omoney_receive, _omoney_receive] = useState("");
  const [oexchange_receive, _oexchange_receive] = useState("");
  const [ototal_receive, _ototal_receive] = useState("");

  const [xSumAmount, _xSumAmount] = useState(0);
  const [xSumAmount2, _xSumAmount2] = useState(0);

  const [xchkfile, _xchkfile] = useState("");
  const [xchkfile2, _xchkfile2] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");
          var url = new URL(window.location.href);

          //หาสกุลเงิน
          axios
            .get(xURL + "find_currency")
            .then(result => {
              _xCurrency(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          _xcmumis_id(url.searchParams.get("cmumis_id"));
          _xterm(url.searchParams.get("term"));
          _xamount(url.searchParams.get("amount"));
          _xdetail(url.searchParams.get("detail"));

          getInvoice(
            url.searchParams.get("cmumis_id"),
            url.searchParams.get("amount"),
            url.searchParams.get("detail")
          );

          getTransfer(
            url.searchParams.get("cmumis_id"),
            url.searchParams.get("amount"),
            url.searchParams.get("detail")
          );
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const getInvoice = (id, amount, detail) => {
    axios
      .post(xURL + "find_invoice_transfer", {
        cmumis_id: id,
        amount: parseFloat(amount.replace(",", "").replace(",", "")),
        detail: detail.trim().substring(0, 15),
        order: 1
      })
      .then(result => {
        const Tb1 = [];

        result.data.forEach((i, idx) => {
          let xsdate = "",
            xedate = "";

          xsdate = dayjs(i.start_date).format("DD/MM/BBBB");
          xedate = dayjs(i.end_date).format("DD/MM/BBBB");

          _sdateInvoice(dayjs(i.start_date));
          _edateInvoice(dayjs(i.end_date));

          let url_file = "";

          url_file = `${xURL}file3/${i.file_invoice}`;

          if (i.expenses) {
            Tb1.push({
              key: idx,
              no: i.invoice_no,
              period: xsdate + " - " + xedate,
              expenses: i.expenses.toLocaleString(),
              exchange: i.exchange_rate,
              invoice_us: i.money_invoice.toLocaleString(),
              fa: i.overhead_usd.toLocaleString(),
              over70: i.overhead70.toLocaleString(),
              over30: i.overhead30.toLocaleString(),
              total: i.total_usd.toLocaleString(),
              current: i.currency_name,
              linkfile: url_file,
              linkfile2: i.file_invoice
            });

            _dataSource1(Tb1);
          }
        });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const getTransfer = (id, amount, detail) => {
    axios
      .post(xURL + "find_invoice_transfer", {
        cmumis_id: id,
        amount: parseFloat(amount.replace(",", "").replace(",", "")),
        detail: detail.trim().substring(0, 15),
        order: 2
      })
      .then(result => {
        const Tb1 = [];

        let xsum = 0,
          xsum2 = 0;

        result.data.forEach((i, idx) => {
          let xsdate = "";

          xsdate = dayjs(i.date_receive).format("DD/MM/BBBB");
          //_dateTransfer(dayjs(i.date_receive));

          let url_file = "";

          url_file = `${xURL}file4/${i.file_transfer}`;

          if (i.total_receive) {
            Tb1.push({
              key: idx,
              no: idx + 1,
              dr_money: xsdate,
              money_receive: i.money_receive.toLocaleString(undefined, {
                minimumFractionDigits: 2
              }),
              exchange: i.exchange_receive.toLocaleString(undefined, {
                minimumFractionDigits: 4
              }),
              total_receive: i.total_receive.toLocaleString(undefined, {
                minimumFractionDigits: 2
              }),
              linkfile: url_file,
              dateTransfer: dayjs(i.date_receive),
              linkfile2: i.file_transfer
            });

            xsum = xsum + parseFloat(i.money_receive);
            xsum2 = xsum2 + parseFloat(i.total_receive);

            _dataSource2(Tb1);
            _xSumAmount(xsum);
            _xSumAmount2(xsum2);

            _xChkrow(idx + 1);
          }
        });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bClear = () => {
    form1.resetFields();

    _xexpenses();
    _xover70();
    _xover30();
    _xtotal();

    _ListFile([]);

    _xdis(false);

    _xchkfile("");
  };
  const bClear2 = () => {
    form2.resetFields();

    _xtotal2();

    _ListFile2([]);

    _xdis2(false);

    _xchkfile2("");
  };

  const bSave = () => {
    const f = form1.getFieldValue();

    if (!f.sedate) {
      swal(
        "บันทึก Invoice ไม่ได้!",
        "ยังไม่ได้ คีย์ วันเริ่มต้น-วันสิ้นสุด",
        "warning"
      );
    } else if (!f.invoice_no) {
      swal("บันทึก Invoice ไม่ได้!", "ยังไม่ได้ คีย์ Invoice_No", "warning");
    } else if (xCurrencyValue == 0) {
      swal("บันทึก Invoice ไม่ได้!", "ยังไม่ได้ เลือก สกุลเงิน", "warning");
    } else if (!f.exchange) {
      swal("บันทึก Invoice ไม่ได้!", "ยังไม่ได้ คีย์ Exchange Rate", "warning");
    } else if (!f.invoice) {
      swal("บันทึก Invoice ไม่ได้!", "ยังไม่ได้ คีย์ Money Invoice", "warning");
    } else if (f.f_a < 0) {
      swal("บันทึก Invoice ไม่ได้!", "ยังไม่ได้ คีย์ F&A", "warning");
    } else {
      swal({
        title: "ยืนยันบันทึก invoice / ใบเรียกเก็บเงิน",
        text: "รหัส CMU MIS : " + xcmumis_id + "\n งวดที่ : " + xterm,
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          //console.log(willOk);
          insertdata();
        }
      });
    }

    // else if (ListFile == "" || typeof ListFile == "undefined") {
    //   swal(
    //     "บันทึก Invoice ไม่ได้!",
    //     "ยังไม่ได้แนบไฟล์ Invoice/ใบเรียกเก็บเงิน",
    //     "warning"
    //   );
    // }
  };

  const bSave2 = () => {
    const f = form2.getFieldValue();

    if (!f.sdate) {
      swal(
        "บันทึก Transfer ไม่ได้!",
        "ยังไม่ได้ คีย์ Date Receive Money",
        "warning"
      );
    } else if (!f.receive) {
      swal(
        "บันทึก Transfer ไม่ได้!",
        "ยังไม่ได้ คีย์ Money Receive",
        "warning"
      );
    } else if (!f.exchange) {
      swal(
        "บันทึก Transfer ไม่ได้!",
        "ยังไม่ได้ คีย์ Exchange Rate",
        "warning"
      );
    } else {
      swal({
        title: "ยืนยันบันทึก Transfer / รับเงินโอน",
        text: "รหัส CMU MIS : " + xcmumis_id + "\n งวดที่ : " + xterm,
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          //console.log(willOk);
          //console.log(xChkrow);

          if (xChkrow == 0) {
            insertdata2();
          } else {
            insertdata2_1();
          }
        }
      });
    }

    // else if (ListFile2 == "" || typeof ListFile2 == "undefined") {
    //   swal(
    //     "บันทึก Transfer ไม่ได้!",
    //     "ยังไม่ได้แนบไฟล์ Transfer/รับเงินโอน",
    //     "warning"
    //   );
    // }
  };

  const insertdata = () => {
    const f = form1.getFieldValue();

    //console.log(f, ListFile);

    let xsdate = "",
      xedate = "",
      xcurrency = "";

    xsdate = dayjs(f.sedate[0]).format("YYYY-MM-DD");
    xedate = dayjs(f.sedate[1]).format("YYYY-MM-DD");

    if (xCurrencyValue == 1) {
      xcurrency = "Baht";
    } else if (xCurrencyValue == 2) {
      xcurrency = "USD";
    } else if (xCurrencyValue == 3) {
      xcurrency = "JPY";
    }

    /*
    console.log(
      xsdate,
      xedate,
      parseInt(xexpenses.replace(",", "").replace(",", "")),
      f.exchange,
      f.invoice,
      f.f_a,
      parseInt(xover70.replace(",", "").replace(",", "")),
      parseInt(xover30.replace(",", "").replace(",", "")),
      parseInt(xtotal.replace(",", "").replace(",", "")),
      f.f_a * f.exchange,
      xcurrency
    );*/

    const fd = new FormData();
    fd.append("file", ListFile);

    let xfile = "";
    axios.post(xURL + "uploadfile3", fd).then(res => {
      if (ListFile == "" || typeof ListFile == "undefined") {
        xfile = "";
      } else {
        xfile = res.data.filename;
      }

      axios
        .post(xURL + "update", {
          cmumis_id: xcmumis_id,
          f_name: xfile,
          start_date: xsdate,
          end_date: xedate,
          invoice_no: f.invoice_no,
          currency_name: xcurrency,
          expenses: parseFloat(xexpenses.replace(",", "").replace(",", "")),
          exchange_rate: f.exchange,
          total_usd: parseFloat(xtotal.replace(",", "").replace(",", "")),
          money_invoice: f.invoice,
          overhead_usd: f.f_a,
          overhead_bath: parseFloat((f.f_a * f.exchange).toFixed(2)),
          overhead70: parseFloat(xover70.replace(",", "").replace(",", "")),
          overhead30: parseFloat(xover30.replace(",", "").replace(",", "")),
          money_bath: parseFloat(xamount.replace(",", "").replace(",", "")),
          detail: xdetail.trim().substring(0, 15),
          xcheck: 16
        })
        .then(result => {
          bClear();
          getInvoice(xcmumis_id, xamount, xdetail);
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    });
  };

  const insertdata2 = () => {
    const f = form2.getFieldValue();

    let xsdate = "";

    xsdate = dayjs(f.sdate).format("YYYY-MM-DD");

    /*
    console.log(
      xsdate,
      f.receive,
      f.exchange,
      parseInt(xtotal2.replace(",", "").replace(",", ""))
    );*/

    const fd = new FormData();
    fd.append("file", ListFile2);

    let xfile = "";
    axios.post(xURL + "uploadfile4", fd).then(res => {
      if (ListFile2 == "" || typeof ListFile2 == "undefined") {
        xfile = "";
      } else {
        xfile = res.data.filename;
      }

      axios
        .post(xURL + "update", {
          cmumis_id: xcmumis_id,
          f_name: xfile,
          date_receive: xsdate,
          money_receive: f.receive,
          exchange_receive: f.exchange,
          total_receive: parseFloat(xtotal2.replace(",", "").replace(",", "")),
          money_bath: parseFloat(xamount.replace(",", "").replace(",", "")),
          detail: xdetail.trim().substring(0, 15),
          xcheck: 18
        })
        .then(result => {
          bClear2();
          getTransfer(xcmumis_id, xamount, xdetail);
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    });
  };

  const insertdata2_1 = () => {
    const f = form2.getFieldValue();

    let xsdate = "";

    xsdate = dayjs(f.sdate).format("YYYY-MM-DD");

    const fd = new FormData();
    fd.append("file", ListFile2);

    let xfile = "";
    axios.post(xURL + "uploadfile4", fd).then(res => {
      if (ListFile2 == "" || typeof ListFile2 == "undefined") {
        xfile = "";
      } else {
        xfile = res.data.filename;
      }

      axios
        .post(xURL + "insert", {
          cmumis_id: xcmumis_id,
          f_name: xfile,
          date_receive: xsdate,
          money_receive: f.receive,
          exchange_receive: f.exchange,
          total_receive: parseFloat(xtotal2.replace(",", "").replace(",", "")),
          money_bath: parseFloat(xamount.replace(",", "").replace(",", "")),
          detail: xdetail.trim().substring(0, 15),
          order_receive: xterm,
          zorder: 11
        })
        .then(result => {
          bClear2();
          getTransfer(xcmumis_id, xamount, xdetail);
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    });
  };

  const Editinvioce = zdata => {
    let xsdate = zdata.period.split(" - ")[0],
      xedate = zdata.period.split(" - ")[1];

    _xCurrencyValue(0);

    //console.log(zdata.expenses, zdata.exchange, zdata.invoice_us);

    _xexpenses(zdata.expenses);

    form1.setFieldsValue({
      sedate: [sdateInvoice, edateInvoice],
      invoice_no: zdata.no,
      exchange: zdata.exchange,
      invoice: parseFloat(zdata.invoice_us.replace(",", "").replace(",", "")),
      f_a: parseFloat(zdata.fa.replace(",", "").replace(",", "")),
      currency: zdata.current
    });

    _xover70(zdata.over70);
    _xover30(zdata.over30);
    _xtotal(zdata.total);

    if (zdata.current == "Baht") {
      _xCurrencyValue(1);
    } else if (zdata.current == "USD") {
      _xCurrencyValue(2);
    } else if (zdata.current == "JPY") {
      _xCurrencyValue(3);
    }

    _xdis(true);

    _xchkfile(zdata.linkfile2);

    txtExchange.current.focus();
  };

  const Editinvioce2 = zdata => {
    _odate_receive(zdata.dateTransfer);
    _omoney_receive(zdata.money_receive);
    _oexchange_receive(zdata.exchange);
    _ototal_receive(zdata.total_receive);

    form2.setFieldsValue({
      sdate: zdata.dateTransfer,
      receive: parseFloat(
        zdata.money_receive.replace(",", "").replace(",", "")
      ),
      exchange: zdata.exchange
    });

    _xtotal2(zdata.total_receive);

    _xdis2(true);

    _xchkfile2(zdata.linkfile2);

    txtReceive.current.focus();
  };

  const bEdit = () => {
    const f = form1.getFieldValue();

    if (!f.sedate) {
      swal(
        "แก้ไข Invoice ไม่ได้!",
        "ยังไม่ได้ คีย์ วันเริ่มต้น-วันสิ้นสุด",
        "warning"
      );
    } else if (!f.invoice_no) {
      swal("แก้ไข Invoice ไม่ได้!", "ยังไม่ได้ คีย์ Invoice No", "warning");
    } else if (xCurrencyValue == 0) {
      swal("แก้ไข Invoice ไม่ได้!", "ยังไม่ได้ เลือก สกุลเงิน", "warning");
    } else if (!f.exchange) {
      swal("แก้ไข Invoice ไม่ได้!", "ยังไม่ได้ คีย์ Exchange Rate", "warning");
    } else if (!f.invoice) {
      swal("แก้ไข Invoice ไม่ได้!", "ยังไม่ได้ คีย์ Money Invoice", "warning");
    } else if (f.f_a < 0) {
      swal("แก้ไข Invoice ไม่ได้!", "ยังไม่ได้ คีย์ F&A", "warning");
    } else {
      swal({
        title: "ยืนยันแก้ไข invoice / ใบเรียกเก็บเงิน",
        text: "รหัส CMU MIS : " + xcmumis_id + "\n งวดที่ : " + xterm,
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          //console.log(willOk);
          Editdata();
        }
      });
    }
  };

  const bEdit2 = () => {
    const f = form2.getFieldValue();

    if (!f.sdate) {
      swal(
        "แก้ไข Transfer ไม่ได้!",
        "ยังไม่ได้ คีย์ Date Receive Money",
        "warning"
      );
    } else if (!f.receive) {
      swal("แก้ไข Transfer ไม่ได้!", "ยังไม่ได้ คีย์ Money Receive", "warning");
    } else if (!f.exchange) {
      swal("แก้ไข Transfer ไม่ได้!", "ยังไม่ได้ คีย์ Exchange Rate", "warning");
    } else {
      swal({
        title: "ยืนยันแก้ไข Transfer / รับเงินโอน",
        text: "รหัส CMU MIS : " + xcmumis_id + "\n งวดที่ : " + xterm,
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          //console.log(willOk);
          Editdata2();
        }
      });
    }
  };

  const Editdata = () => {
    const f = form1.getFieldValue();

    let xsdate = "",
      xedate = "",
      xcurrency = "";

    xsdate = dayjs(f.sedate[0]).format("YYYY-MM-DD");
    xedate = dayjs(f.sedate[1]).format("YYYY-MM-DD");

    if (xCurrencyValue == 1) {
      xcurrency = "Baht";
    } else if (xCurrencyValue == 2) {
      xcurrency = "USD";
    } else if (xCurrencyValue == 3) {
      xcurrency = "JPY";
    }
    // console.log(
    //   parseFloat(xexpenses.replace(",", "").replace(",", "")),
    //   f.exchange,
    //   parseFloat(xtotal.replace(",", "").replace(",", "")),
    //   f.invoice,
    //   f.f_a,
    //   parseFloat((f.f_a * f.exchange).toFixed(2)),
    //   parseFloat(xover70.replace(",", "").replace(",", "")),
    //   parseFloat(xover30.replace(",", "").replace(",", "")),
    //   parseFloat(xamount.replace(",", "").replace(",", "")),
    //   xdetail.substring(0, 8)
    // );

    const fd = new FormData();
    fd.append("file", ListFile);

    if (xchkfile) {
      if (ListFile == "" || typeof ListFile == "undefined") {
        axios
          .post(xURL + "update", {
            cmumis_id: xcmumis_id,
            f_name: xchkfile,
            start_date: xsdate,
            end_date: xedate,
            currency_name: xcurrency,
            expenses: parseFloat(xexpenses.replace(",", "").replace(",", "")),
            exchange_rate: f.exchange,
            total_usd: parseFloat(xtotal.replace(",", "").replace(",", "")),
            money_invoice: f.invoice,
            overhead_usd: f.f_a,
            overhead_bath: parseFloat((f.f_a * f.exchange).toFixed(2)),
            overhead70: parseFloat(xover70.replace(",", "").replace(",", "")),
            overhead30: parseFloat(xover30.replace(",", "").replace(",", "")),
            money_bath: parseFloat(xamount.replace(",", "").replace(",", "")),
            detail: xdetail.trim().substring(0, 15),
            invoice_no: f.invoice_no,
            xcheck: 19
          })
          .then(result => {
            bClear();
            getInvoice(xcmumis_id, xamount, xdetail);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      } else {
        axios
          .post(xURL + "delete_file", {
            linkfile: xchkfile,
            order: 3
          })
          .then(result => {})
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        axios.post(xURL + "uploadfile3", fd).then(res => {
          axios
            .post(xURL + "update", {
              cmumis_id: xcmumis_id,
              f_name: res.data.filename,
              start_date: xsdate,
              end_date: xedate,
              currency_name: xcurrency,
              expenses: parseFloat(xexpenses.replace(",", "").replace(",", "")),
              exchange_rate: f.exchange,
              total_usd: parseFloat(xtotal.replace(",", "").replace(",", "")),
              money_invoice: f.invoice,
              overhead_usd: f.f_a,
              overhead_bath: parseFloat((f.f_a * f.exchange).toFixed(2)),
              overhead70: parseFloat(xover70.replace(",", "").replace(",", "")),
              overhead30: parseFloat(xover30.replace(",", "").replace(",", "")),
              money_bath: parseFloat(xamount.replace(",", "").replace(",", "")),
              detail: xdetail.trim().substring(0, 15),
              invoice_no: f.invoice_no,
              xcheck: 19
            })
            .then(result => {
              bClear();
              getInvoice(xcmumis_id, xamount, xdetail);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
        });
      }
    } else {
      axios.post(xURL + "uploadfile3", fd).then(res => {
        axios
          .post(xURL + "update", {
            cmumis_id: xcmumis_id,
            f_name: res.data.filename,
            start_date: xsdate,
            end_date: xedate,
            currency_name: xcurrency,
            expenses: parseFloat(xexpenses.replace(",", "").replace(",", "")),
            exchange_rate: f.exchange,
            total_usd: parseFloat(xtotal.replace(",", "").replace(",", "")),
            money_invoice: f.invoice,
            overhead_usd: f.f_a,
            overhead_bath: parseFloat((f.f_a * f.exchange).toFixed(2)),
            overhead70: parseFloat(xover70.replace(",", "").replace(",", "")),
            overhead30: parseFloat(xover30.replace(",", "").replace(",", "")),
            money_bath: parseFloat(xamount.replace(",", "").replace(",", "")),
            detail: xdetail.trim().substring(0, 15),
            invoice_no: f.invoice_no,
            xcheck: 19
          })
          .then(result => {
            bClear();
            getInvoice(xcmumis_id, xamount, xdetail);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      });
    }
  };

  const Editdata2 = () => {
    const f = form2.getFieldValue();

    let xsdate = "";

    xsdate = dayjs(f.sdate).format("YYYY-MM-DD");

    const fd = new FormData();
    fd.append("file", ListFile2);

    /*
    console.log(
      dayjs(odate_receive).format("YYYY-MM-DD"),
      omoney_receive,
      oexchange_receive,
      ototal_receive
    );*/

    //console.log(xchkfile2);

    if (xchkfile2) {
      if (ListFile2 == "" || typeof ListFile2 == "undefined") {
        axios
          .post(xURL + "update", {
            cmumis_id: xcmumis_id,
            f_name: xchkfile2,
            date_receive: xsdate,
            money_receive: f.receive,
            exchange_receive: f.exchange,
            total_receive: parseFloat(
              xtotal2.replace(",", "").replace(",", "")
            ),
            money_bath: parseFloat(xamount.replace(",", "").replace(",", "")),
            detail: xdetail.trim().substring(0, 15),
            odate_receive: dayjs(odate_receive).format("YYYY-MM-DD"),
            omoney_receive: parseFloat(
              omoney_receive.replace(",", "").replace(",", "")
            ),
            oexchange_receive: oexchange_receive,
            ototal_receive: parseFloat(
              ototal_receive.replace(",", "").replace(",", "")
            ),
            xcheck: 20
          })
          .then(result => {
            bClear2();
            getTransfer(xcmumis_id, xamount, xdetail);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      } else {
        axios
          .post(xURL + "delete_file", {
            linkfile: xchkfile2,
            order: 4
          })
          .then(result => {})
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        axios.post(xURL + "uploadfile4", fd).then(res => {
          axios
            .post(xURL + "update", {
              cmumis_id: xcmumis_id,
              f_name: res.data.filename,
              date_receive: xsdate,
              money_receive: f.receive,
              exchange_receive: f.exchange,
              total_receive: parseFloat(
                xtotal2.replace(",", "").replace(",", "")
              ),
              money_bath: parseFloat(xamount.replace(",", "").replace(",", "")),
              detail: xdetail.trim().substring(0, 15),
              odate_receive: dayjs(odate_receive).format("YYYY-MM-DD"),
              omoney_receive: parseFloat(
                omoney_receive.replace(",", "").replace(",", "")
              ),
              oexchange_receive: oexchange_receive,
              ototal_receive: parseFloat(
                ototal_receive.replace(",", "").replace(",", "")
              ),
              xcheck: 20
            })
            .then(result => {
              bClear2();
              getTransfer(xcmumis_id, xamount, xdetail);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
        });
      }
    } else {
      if (ListFile2 == "" || typeof ListFile2 == "undefined") {
        axios
          .post(xURL + "update", {
            cmumis_id: xcmumis_id,
            f_name: "",
            date_receive: xsdate,
            money_receive: f.receive,
            exchange_receive: f.exchange,
            total_receive: parseFloat(
              xtotal2.replace(",", "").replace(",", "")
            ),
            money_bath: parseFloat(xamount.replace(",", "").replace(",", "")),
            detail: xdetail.trim().substring(0, 15),
            odate_receive: dayjs(odate_receive).format("YYYY-MM-DD"),
            omoney_receive: parseFloat(
              omoney_receive.replace(",", "").replace(",", "")
            ),
            oexchange_receive: oexchange_receive,
            ototal_receive: parseFloat(
              ototal_receive.replace(",", "").replace(",", "")
            ),
            xcheck: 20
          })
          .then(result => {
            bClear2();
            getTransfer(xcmumis_id, xamount, xdetail);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      } else {
        axios.post(xURL + "uploadfile4", fd).then(res => {
          axios
            .post(xURL + "update", {
              cmumis_id: xcmumis_id,
              f_name: res.data.filename,
              date_receive: xsdate,
              money_receive: f.receive,
              exchange_receive: f.exchange,
              total_receive: parseFloat(
                xtotal2.replace(",", "").replace(",", "")
              ),
              money_bath: parseFloat(xamount.replace(",", "").replace(",", "")),
              detail: xdetail.trim().substring(0, 15),
              odate_receive: dayjs(odate_receive).format("YYYY-MM-DD"),
              omoney_receive: parseFloat(
                omoney_receive.replace(",", "").replace(",", "")
              ),
              oexchange_receive: oexchange_receive,
              ototal_receive: parseFloat(
                ototal_receive.replace(",", "").replace(",", "")
              ),
              xcheck: 20
            })
            .then(result => {
              bClear2();
              getTransfer(xcmumis_id, xamount, xdetail);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
        });
      }
    }
  };

  const Deleteinvioce = zdata => {
    const f = form2.getFieldValue();

    let xtext = "";

    xtext =
      "Date Receive Money : " +
      zdata.dr_money +
      "\n Money Receive : " +
      zdata.money_receive +
      "\n Exchange Rate : " +
      zdata.exchange +
      "\n Total (Baht) Receive : " +
      zdata.total_receive;

    swal({
      title: "ยืนยันลบ Transfer / รับเงินโอน",
      text:
        "รหัส CMU MIS : " + xcmumis_id + "\n งวดที่ : " + xterm + "\n" + xtext,
      icon: "warning",
      buttons: ["ยกเลิก", "ยืนยัน"]
    }).then(willOk => {
      if (willOk) {
        //console.log(willOk);
        deletedata(zdata);
      }
    });
  };

  const deletedata = zdata => {
    //console.log(zdata.linkfile2)
    //console.log(dataSource2.length);
    if (dataSource2.length > 1) {
      if (zdata.linkfile2) {
        axios
          .post(xURL + "delete_file", {
            linkfile: zdata.linkfile2,
            order: 4
          })
          .then(result => {})
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      }

      axios
        .post(xURL + "delete", {
          cmumis_id: xcmumis_id,
          money_bath: parseFloat(xamount.replace(",", "").replace(",", "")),
          detail: xdetail.trim().substring(0, 15),
          odate_receive: dayjs(zdata.dateTransfer).format("YYYY-MM-DD"),
          omoney_receive: parseFloat(
            zdata.money_receive.replace(",", "").replace(",", "")
          ),
          oexchange_receive: zdata.exchange,
          ototal_receive: parseFloat(
            zdata.total_receive.replace(",", "").replace(",", "")
          ),
          xcheck: 24
        })
        .then(result => {
          //getContract(xgrant, xpo);
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
      swal("แจ้งเตือน!", "ลบ Money Transfer สำเร็จ!", "success").then(() => {
        bClear2();
        getTransfer(xcmumis_id, xamount, xdetail);
      });
    } else {
      //swal("ลบ Transfer ไม่ได้!", "กรุณาทำการแก้ไขข้อมูล", "warning");

      if (zdata.linkfile2) {
        axios
          .post(xURL + "delete_file", {
            linkfile: zdata.linkfile2,
            order: 4
          })
          .then(result => {})
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      }

      axios
        .post(xURL + "update", {
          cmumis_id: xcmumis_id,
          f_name: "",
          date_receive: "",
          money_receive: "",
          exchange_receive: "",
          total_receive: "",
          money_bath: parseFloat(xamount.replace(",", "").replace(",", "")),
          detail: xdetail.trim().substring(0, 15),
          odate_receive: dayjs(zdata.dateTransfer).format("YYYY-MM-DD"),
          omoney_receive: parseFloat(
            zdata.money_receive.replace(",", "").replace(",", "")
          ),
          oexchange_receive: zdata.exchange,
          ototal_receive: parseFloat(
            zdata.total_receive.replace(",", "").replace(",", "")
          ),
          xcheck: 20
        })
        .then(result => {})
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });

      swal("แจ้งเตือน!", "ลบ Money Transfer สำเร็จ!", "success").then(() => {
        window.location.reload(false);
      });
    }
  };

  const columns1 = [
    {
      title: "invoice no",
      dataIndex: "no",
      key: "no",
      align: "center"
    },
    {
      title: "invoice period",
      dataIndex: "period",
      key: "period",
      align: "center"
    },
    {
      title: "Expenses (Baht)",
      dataIndex: "expenses",
      key: "expenses",
      align: "center"
    },
    {
      title: "Exchange Rate",
      dataIndex: "exchange",
      key: "exchange",
      align: "center"
    },
    {
      title: "Money Invoice",
      dataIndex: "invoice_us",
      key: "invoice_us",
      align: "center"
    },
    {
      title: "F&A",
      dataIndex: "fa",
      key: "fa",
      align: "center"
    },
    {
      title: "Overhead 70 %",
      dataIndex: "over70",
      key: "over70",
      align: "center"
    },
    {
      title: "Overhead 30 %",
      dataIndex: "over30",
      key: "over30",
      align: "center"
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "center"
    },
    {
      title: "file",
      dataIndex: "tfile",
      key: "tfile",
      align: "center",
      render(text, record, index) {
        if (record.linkfile2) {
          return {
            children: (
              <a href={record.linkfile} target="_blank">
                <PaperClipOutlined /> ไฟล์แนบ
              </a>
            )
          };
        }
      },
      fixed: "right"
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <Button
              icon={<EditOutlined />}
              size="small"
              onClick={e => {
                Editinvioce(record);
              }}
            />
          )
        };
      },
      fixed: "right"
    }
  ];
  const columns2 = [
    {
      title: "Date Receive Money",
      dataIndex: "dr_money",
      key: "dr_money",
      align: "center",
      width: 140
    },
    {
      title: "Money Receive",
      dataIndex: "money_receive",
      key: "money_receive",
      align: "center",
      render: (text, record) => __TBShowText(record.money_receive, 1, 0)
    },
    {
      title: "Exchange Rate",
      dataIndex: "exchange",
      key: "exchange",
      align: "center",
      width: 130
    },
    {
      title: "Total (Baht) Receive",
      dataIndex: "total_receive",
      key: "total_receive",
      align: "center",
      width: 100,
      render: (text, record) => __TBShowText(record.total_receive, 1, 0)
    },
    {
      title: "file",
      dataIndex: "tfile",
      key: "tfile",
      align: "center",

      render(text, record, index) {
        if (record.linkfile2) {
          return {
            children: (
              <a href={record.linkfile} target="_blank">
                <PaperClipOutlined /> ไฟล์แนบ
              </a>
            )
          };
        }
      },
      fixed: "right"
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <Button
              icon={<EditOutlined />}
              size="small"
              onClick={e => {
                //console.log("corresponding email is :", record.c4);
                Editinvioce2(record);
              }}
            />
          )
        };
      },
      fixed: "right"
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="small"
              onClick={e => {
                //console.log("corresponding email is :", record.c4);
                Deleteinvioce(record);
              }}
            />
          )
        };
      },
      fixed: "right"
    }
  ];

  ///////////////////////////from 1 //////////////////////////

  const onChangeexchange = e => {
    const f = form1.getFieldValue();

    if (f.invoice) {
      if (e) {
        let xsum = 0;

        xsum = e * f.invoice;
        _xexpenses(xsum.toLocaleString());
      }
    }
  };

  const onChangeinvoice = e => {
    const f = form1.getFieldValue();

    // console.log(e, f);

    if (f.exchange) {
      if (e) {
        let xsum = 0;

        xsum = e * f.exchange;
        _xexpenses(xsum.toLocaleString());
      }
    }

    if (f.f_a) {
      if (e) {
        let xsum2 = 0;
        xsum2 = e + f.f_a;
        _xtotal(xsum2.toLocaleString());
      }
    }
  };

  const onChangefa = e => {
    const f = form1.getFieldValue();

    if (f.invoice) {
      if (e >= 0) {
        let xsum = 0,
          xsum2 = 0,
          xsum3 = 0;
        xsum = e + f.invoice;
        _xtotal(xsum.toLocaleString());

        xsum2 = e * 70 / 100;
        _xover70(xsum2.toLocaleString());

        xsum3 = e * 30 / 100;
        _xover30(xsum3.toLocaleString());
      }
    }
  };

  ///////////////////////////from 2 //////////////////////////

  const onChangereceive = e => {
    const f = form2.getFieldValue();

    if (f.exchange) {
      if (e) {
        let xsum = 0;
        xsum = e * f.exchange;
        xsum = xsum.toFixed(2);
        _xtotal2(xsum.toLocaleString());
      }
    }
  };

  const onChangeexchange2 = e => {
    const f = form2.getFieldValue();

    if (f.receive) {
      if (e) {
        let xsum = 0;
        xsum = e * f.receive;
        xsum = xsum.toFixed(2);
        _xtotal2(xsum.toLocaleString());
      }
    }
  };

  ///////////////////////////file //////////////////////////

  const onChangeU = e => {
    _ListFile(e.target.files[0]);
  };

  const onChangeU2 = e => {
    _ListFile2(e.target.files[0]);
  };

  function handleChange(value) {
    _xCurrencyValue(value);
  }

  //formatttable (Rigth or Left)
  function __TBShowText(zData, zRigth, zLeft) {
    if (zRigth === 1 && zLeft === 0) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    } else if (zRigth === 0 && zLeft === 1) {
      return (
        <div style={{ display: "flex" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <StyledForm />
      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col span={23}>
            <PageHeader
              className="site-page-header"
              title={
                <a
                  style={{
                    color: "black"
                  }}
                  onClick={() => {
                    window.close();
                  }}
                >
                  ใบเรียกเก็บเงิน (Invoice) และ รับเงินโอน (Money Transfer)
                </a>
              }
              onBack={() => window.close()}
            />
            <h3>
              รหัส CMU MIS : {xcmumis_id}
            </h3>
            <h3>
              งวดที่ : {xterm} จำนวนเงิน {xamount} บาท
            </h3>
            <h3>
              รายละเอียด : {xdetail}
            </h3>
            <Divider />
          </Col>
        </Row>

        <Row justify="space-around">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={15}
            //style={{ padding: "10px", paddingTop: "24px" }}
          >
            <Row justify="center">
              <Col span={23}>
                <Card style={{ height: "650px" }}>
                  <h2>[ใบเรียกเก็บเงิน Invoice]</h2>

                  <Divider />

                  <Row>
                    <Col span={18}>
                      <Form form={form1} initialValues={{}} {...layout}>
                        <ConfigProvider locale={configuredLocale}>
                          <Form.Item
                            label="วันเริ่มต้น-วันสิ้นสุด"
                            name="sedate"
                            rules={[
                              {
                                required: true
                              }
                            ]}
                          >
                            <DatePicker.RangePicker
                              format="DD/MM/BBBB"
                              style={{ width: "100%" }}
                              onChange={e => {
                                if (e.length == 2) {
                                  txtExchange.current.focus();
                                }
                              }}
                            />
                          </Form.Item>
                        </ConfigProvider>

                        <Form.Item label="Invoice No" name="invoice_no">
                          <Input allowClear style={{ width: "100%" }} />
                        </Form.Item>

                        <Form.Item label="สกุลเงิน" name="currency">
                          <Select
                            defaultValue="0"
                            style={{ width: "50%" }}
                            onChange={handleChange}
                          >
                            <Option value="0">เลือกรายการ</Option>

                            {xCurrency.length > 0
                              ? xCurrency.map((i, idx) => {
                                  return (
                                    <Option value={i.id}>
                                      {i.name}
                                    </Option>
                                  );
                                })
                              : <Option value="0">เลือกรายการ</Option>}
                          </Select>
                        </Form.Item>

                        <Form.Item label="Expenses (Baht)" name="expenses_b">
                          <Row>
                            <Input
                              disabled
                              value={xexpenses}
                              style={{ width: "100%", color: "red" }}
                            />
                          </Row>
                        </Form.Item>

                        <Form.Item
                          label="Exchange Rate"
                          name="exchange"
                          rules={[
                            {
                              required: true
                            }
                          ]}
                        >
                          <InputNumber
                            ref={txtExchange}
                            formatter={value =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            parser={value => value.replace(/\$\s?|(,*)/g, "")}
                            style={{ width: "100%" }}
                            onChange={e => onChangeexchange(e)}
                            onPressEnter={() => {
                              txtMInvoice.current.focus();
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          label="Money Invoice"
                          name="invoice"
                          rules={[
                            {
                              required: true
                            }
                          ]}
                        >
                          <InputNumber
                            ref={txtMInvoice}
                            formatter={value =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            parser={value => value.replace(/\$\s?|(,*)/g, "")}
                            style={{ width: "100%" }}
                            onChange={e => onChangeinvoice(e)}
                            onPressEnter={() => {
                              txtFa.current.focus();
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          label="F&A"
                          name="f_a"
                          rules={[
                            {
                              required: true
                            }
                          ]}
                        >
                          <InputNumber
                            ref={txtFa}
                            formatter={value =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            parser={value => value.replace(/\$\s?|(,*)/g, "")}
                            style={{ width: "100%" }}
                            onChange={e => onChangefa(e)}
                          />
                        </Form.Item>

                        <Form.Item label="Overhead 70 %" name="oh70">
                          <Row>
                            <Input
                              disabled
                              value={xover70}
                              style={{ width: "100%", color: "red" }}
                            />
                          </Row>
                        </Form.Item>

                        <Form.Item label="Overhead 30 %" name="oh30">
                          <Row>
                            <Input
                              disabled
                              value={xover30}
                              style={{ width: "100%", color: "red" }}
                            />
                          </Row>
                        </Form.Item>

                        <Form.Item label="Total" name="total">
                          <Row>
                            <Input
                              disabled
                              value={xtotal}
                              style={{ width: "100%", color: "red" }}
                            />
                          </Row>
                        </Form.Item>

                        <Form.Item
                          label="ไฟล์ Invoice/ใบเรียกเก็บเงิน "
                          name="edfile"
                          rules={[
                            {
                              //required: true
                            }
                          ]}
                        >
                          <Input
                            className="form-control"
                            id="drop-area2"
                            type="file"
                            onChange={e => onChangeU(e)}
                          />
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>

                  <br />

                  <Row justify="center">
                    <Space>
                      {xdis == false
                        ? <Button ghost type="primary" onClick={bSave}>
                            <SaveOutlined /> บันทึก
                          </Button>
                        : <Button ghost type="primary" onClick={bEdit}>
                            <SaveOutlined /> แก้ไข
                          </Button>}

                      <Button ghost type="danger" onClick={bClear}>
                        <ClearOutlined /> เคลียร์
                      </Button>
                    </Space>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={9}>
            <Row justify="center">
              <Col span={23}>
                <Card style={{ height: "650px" }}>
                  <h2>[รับเงินโอน Transfer]</h2>

                  <Divider />

                  <Row>
                    <Col span={19}>
                      <Form form={form2} initialValues={{}} {...layout2}>
                        <ConfigProvider locale={configuredLocale}>
                          <Form.Item
                            label="Date Receive Money"
                            name="sdate"
                            rules={[
                              {
                                required: true,
                                message: "กรุณากรอก Date Receive Money"
                              }
                            ]}
                          >
                            <DatePicker
                              format="DD/MM/BBBB"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </ConfigProvider>

                        <Form.Item
                          label="Money Receive"
                          name="receive"
                          rules={[
                            {
                              required: true
                            }
                          ]}
                        >
                          <InputNumber
                            ref={txtReceive}
                            formatter={value =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            parser={value => value.replace(/\$\s?|(,*)/g, "")}
                            style={{ width: "100%" }}
                            onPressEnter={() => {
                              txtExchange2.current.focus();
                            }}
                            onChange={e => onChangereceive(e)}
                          />
                        </Form.Item>

                        <Form.Item
                          label="Exchange Rate"
                          name="exchange"
                          rules={[
                            {
                              required: true
                            }
                          ]}
                        >
                          <InputNumber
                            ref={txtExchange2}
                            parser={value => value.replace(/\$\s?|(,*)/g, "")}
                            style={{ width: "100%" }}
                            onChange={e => onChangeexchange2(e)}
                          />
                        </Form.Item>

                        <Form.Item label="Total (Baht) Receive" name="total">
                          <Row>
                            <Input
                              disabled
                              value={xtotal2}
                              style={{ width: "100%", color: "red" }}
                            />
                          </Row>
                        </Form.Item>

                        <Form.Item
                          label="ไฟล์ Transfer/รับเงินโอน "
                          name="edfile"
                          rules={[
                            {
                              //required: true
                            }
                          ]}
                        >
                          <Input
                            className="form-control"
                            id="drop-area2"
                            type="file"
                            onChange={e => onChangeU2(e)}
                          />
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>

                  <br />

                  <Row justify="center">
                    <Space>
                      {xdis2 == false
                        ? <Button ghost type="primary" onClick={bSave2}>
                            <SaveOutlined /> บันทึก
                          </Button>
                        : <Button ghost type="primary" onClick={bEdit2}>
                            <SaveOutlined /> แก้ไข
                          </Button>}

                      <Button ghost type="danger" onClick={bClear2}>
                        <ClearOutlined /> เคลียร์
                      </Button>
                    </Space>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <br />

        <Row justify="space-between">
          <Col xs={24} sm={24} md={24} lg={24} xl={15}>
            <Row justify="center" style={{ marginBottom: 10 }}>
              <Col span={23}>
                <Table
                  title={() => "INVOICE"}
                  bordered
                  scroll={{ x: "max-content" }}
                  dataSource={dataSource1}
                  columns={columns1}
                  size="small"
                  pagination={false}
                />
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={9}>
            <Row justify="center">
              <Col span={23}>
                <Table
                  title={() => "Money Transfer"}
                  bordered
                  scroll={{ x: "max-content" }}
                  dataSource={dataSource2}
                  columns={columns2}
                  size="small"
                  pagination={false}
                  summary={() =>
                    <Table.Summary fixed>
                      <Table.Summary.Row
                        style={{
                          textAlign: "right",
                          backgroundColor: "#b5f5ec"
                        }}
                      >
                        <Table.Summary.Cell>
                          รวม Money Receive
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          {xSumAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2
                          })}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          รวม Total Receive
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          {xSumAmount2.toLocaleString(undefined, {
                            minimumFractionDigits: 2
                          })}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                      </Table.Summary.Row>
                    </Table.Summary>}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>

      <br />

      <Footer_last />
    </React.Fragment>
  );
};
export default Research_Transfer;
