import "antd/dist/antd.min.css";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { SaveOutlined, ClearOutlined } from "@ant-design/icons";
import { createGlobalStyle } from "styled-components";

import {
  Row,
  Col,
  Form,
  Layout,
  Card,
  Input,
  Select,
  Space,
  Button,
  Divider,
  PageHeader
} from "antd";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import swal from "sweetalert";

const { Content } = Layout;
const { Option } = Select;

const StyledForm = createGlobalStyle`.ant-form-item {
  margin-bottom: 5px;
}
`;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
};

const Research_input_agency = () => {
  const [form1] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [xAgency1, _xAgency1] = useState("");
  const [xAgency2, _xAgency2] = useState("");
  const [xAgency3, _xAgency3] = useState("");
  const [xAgency4, _xAgency4] = useState("");

  const [xAgency1V, _xAgency1V] = useState("");
  const [xAgency2V, _xAgency2V] = useState("");
  const [xAgency3V, _xAgency3V] = useState("");
  const [xAgency4V, _xAgency4V] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    //console.log(token);

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");
          //ค้นหา type1
          axios
            .get(xURL + "agency_type1")
            .then(result => {
              //console.log(result.data);

              _xAgency1(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหา type2
          axios
            .get(xURL + "agency_type2")
            .then(result => {
              //console.log(result.data);

              _xAgency2(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหา type3
          axios
            .get(xURL + "agency_type3")
            .then(result => {
              //console.log(result.data);

              _xAgency3(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหา type3
          axios
            .get(xURL + "agency_type4")
            .then(result => {
              //console.log(result.data);

              _xAgency4(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const bClearclick = () => {
    form1.resetFields();

    _xAgency1V("0");
    _xAgency2V("0");
    _xAgency3V("0");
    _xAgency4V("0");
  };

  const bSaveclick = () => {
    const f = form1.getFieldValue();

    if (!f.edagency_name) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ ชื่อแหล่งทุน", "warning");
    } else if (xAgency1V == "0") {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้เลือก ประเภทแหล่งทุน1", "warning");
    } else if (xAgency2V == "0") {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้เลือก ประเภทแหล่งทุน2", "warning");
    } else if (xAgency3V == "0") {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้เลือก ประเภทแหล่งทุน3", "warning");
    } else if (xAgency4V == "0") {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้เลือก ประเภทแหล่งทุน4", "warning");
    } else {
      swal({
        title: "ยืนยันเพิ่มโครงการวิจัย",
        text: ":-)",
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          axios
            .post(xURL + "insert", {
              agency_name: f.edagency_name,
              type1: xAgency1V,
              type2: xAgency2V,
              type3: xAgency3V,
              type4: xAgency4V,
              zorder: 9
            })
            .then(result => {
              //console.log(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          bClearclick();
        }
      });
    }
  };

  function handleChange(value) {
    _xAgency1V(value);
  }

  function handleChange2(value) {
    _xAgency2V(value);
  }

  function handleChange3(value) {
    _xAgency3V(value);
  }

  function handleChange4(value) {
    _xAgency4V(value);
  }

  return (
    <React.Fragment>
      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <StyledForm />

      <Content
        className="site-layout"
        style={{
          padding: "0 20px",
          marginTop: 64,
          height: "calc(80vh - 16px)"
        }}
      >
        <Row justify="space-around">
          <Col
            xs={23}
            sm={23}
            md={23}
            lg={18}
            xl={16}
            //justify="space-around"
            //style={{ padding: "10px", paddingTop: "24px" }}
          >
            <PageHeader
              className="site-page-header"
              title={
                <a
                  style={{
                    color: "black"
                  }}
                  onClick={() => {
                    window.close();
                  }}
                >
                  เพิ่มแหล่งทุนใหม่
                </a>
              }
              onBack={() => window.close()}
            />

            <Divider />

            <Card title="">
              <Form
                form={form1}
                initialValues={{
                  edagency_name: "",
                  sltype1: "",
                  sltype2: "",
                  sltype3: "",
                  sltype4: ""
                }}
                {...layout}
              >
                <Form.Item label="ชื่อแหล่งทุน" name="edagency_name">
                  <Input autoFocus allowClear placeholder="ชื่อแหล่งทุน" />
                </Form.Item>

                <Divider />

                <Form.Item label="ประเภทแหล่งทุน1" name="sltype1">
                  <Select onChange={handleChange} style={{ width: "50%" }}>
                    {xAgency1.length > 0
                      ? xAgency1.map((i, idx) => {
                          return (
                            <Option value={i.id}>
                              {i.name}
                            </Option>
                          );
                        })
                      : <Option value="0">
                          {}
                        </Option>}
                  </Select>
                </Form.Item>

                <Form.Item label="ประเภทแหล่งทุน2" name="sltype2">
                  <Select onChange={handleChange2} style={{ width: "50%" }}>
                    {xAgency2.length > 0
                      ? xAgency2.map((i, idx) => {
                          return (
                            <Option value={i.id}>
                              {i.name}
                            </Option>
                          );
                        })
                      : <Option value="0">
                          {}
                        </Option>}
                  </Select>
                </Form.Item>

                <Form.Item label="ประเภทแหล่งทุน3" name="sltype3">
                  <Select onChange={handleChange3} style={{ width: "50%" }}>
                    {xAgency3.length > 0
                      ? xAgency3.map((i, idx) => {
                          return (
                            <Option value={i.id}>
                              {i.name}
                            </Option>
                          );
                        })
                      : <Option value="0">
                          {}
                        </Option>}
                  </Select>
                </Form.Item>

                <Form.Item label="ประเภทแหล่งทุน4" name="sltype4">
                  <Select onChange={handleChange4} style={{ width: "50%" }}>
                    {xAgency4.length > 0
                      ? xAgency4.map((i, idx) => {
                          return (
                            <Option value={i.id}>
                              {i.name}
                            </Option>
                          );
                        })
                      : <Option value="0">
                          {}
                        </Option>}
                  </Select>
                </Form.Item>

                <br />
                <br />

                <Row justify="center">
                  <Space>
                    <Button ghost type="primary" onClick={bSaveclick}>
                      <SaveOutlined />บันทึก
                    </Button>

                    <Button ghost type="danger" onClick={bClearclick}>
                      <ClearOutlined /> เคลียร์ทั้งหมด
                    </Button>
                  </Space>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
        <br />
        <br />
      </Content>

      <Footer_last />
    </React.Fragment>
  );
};
export default Research_input_agency;
