import React, { useEffect, useState } from "react";
import "antd/dist/antd.min.css";
import axios from "axios";
import swal from "sweetalert";

import { createGlobalStyle } from "styled-components";
import {
  SearchOutlined,
  ClearOutlined,
  PaperClipOutlined,
  FileExcelOutlined
} from "@ant-design/icons";

import {
  Layout,
  Row,
  Col,
  PageHeader,
  Divider,
  Card,
  Form,
  ConfigProvider,
  Space,
  Button,
  Table,
  Typography
} from "antd";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import thTH from "antd/lib/locale/th_TH";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

import moment from "moment";
import "moment/locale/th";

import * as xlsx from "xlsx";

dayjs.locale("th");
dayjs.extend(buddhistEra);

const { Content } = Layout;
const { Text } = Typography;

const configuredLocale = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    dateFormat: "DD MM BBBB", // DD MM BBBB
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker.lang,
      dateFormat: "DD MM BBBB", // DD MM BBBB
      dateTimeFormat: "DD MM BBBB HH:mm:ss",
      yearFormat: "BBBB"
    }
  }
};

const StyledForm = createGlobalStyle`.ant-form-item {
    margin-bottom: 5px;
  }
  `;

const Research_report1_1 = () => {
  const [form1] = Form.useForm();
  const DatePicker = generatePicker(dayjsGenerateConfig);

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [dataSource1, _dataSource1] = useState();
  const [dataSourceEx, _dataSourceEx] = useState();

  const [xshow, _xshow] = useState(true);

  const [xsummary, _xsummary] = useState(0);

  const [levelU, _levelU] = useState("");
  const [r_id, _r_id] = useState("");

  const [paginationInfo] = useState({
    current: 1,
    pageSize: 10
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    const xresearch_id = localStorage.getItem("research_id");

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");

          axios
            .post(xURL + "findlevel", { research_id: xresearch_id })
            .then(result => {
              // console.log(result.data.length);
              if (result.data.length > 0) {
                if (result.data[0].user_level_id == 4) {
                  _r_id(xresearch_id);
                  _levelU("research");
                } else {
                  _levelU("");
                }
              } else {
                _levelU("");
              }
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  });

  const bSearch = () => {
    const f = form1.getFieldValue();

    let xid = "";
    if (levelU == "research") {
      xid = r_id;
    } else {
      xid = "";
    }

    if (!f.sedate) {
      swal(
        "แสดงข้อมูล ไม่ได้!",
        "ยังไม่ได้ คีย์ วันเริ่มต้น-วันสิ้นสุด",
        "warning"
      );
    } else {
      let xsdate = "",
        xedate = "";

      xsdate = dayjs(f.sedate[0]).format("YYYY-MM-DD");
      xedate = dayjs(f.sedate[1]).format("YYYY-MM-DD");

      axios
        .post(xURL + "find_report1_1", {
          start_date: xsdate,
          end_date: xedate,
          research_id: xid
        })
        .then(result => {
          if (result.data === "") {
            swal(
              "แสดงข้อมูล ไม่ได้!",
              "ไม่มีข้อมูลตามเงื่อนไขที่เลือก",
              "warning"
            );
          } else {
            _xshow(false);
            Showreport(result.data);
          }
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    }
  };

  const Showreport = zdata => {
    const Tb1 = [],
      Tb2 = [];
    let xsum = 0;
    let xheadname = "";

    zdata.forEach((i, idx) => {
      let xsdate = "";

      xsdate = dayjs(i.date_receive).format("DD/MM/BBBB");

      Tb1.push({
        key: idx,
        no: idx + 1,
        id_name: i.headname,
        date1: xsdate,
        money_b: i.total_receive.toLocaleString(undefined, {
          minimumFractionDigits: 2
        }),
        invoice_detail: i.detail
      });

      if (xheadname !== "") {
        if (xheadname !== i.headname) {
          Tb2.push({
            id_name: i.headname
          });
        }
      } else {
        Tb2.push({
          id_name: i.headname
        });
      }

      Tb2.push({
        date1: xsdate,
        money_b: i.total_receive.toLocaleString(undefined, {
          minimumFractionDigits: 2
        }),
        invoice_detail: i.detail
      });

      xsum = xsum + parseFloat(i.total_receive);
      xheadname = i.headname;
    });

    _dataSource1(Tb1);
    _dataSourceEx(Tb2);

    _xsummary(
      xsum.toLocaleString(undefined, {
        minimumFractionDigits: 2
      })
    );
  };

  const bClear = () => {
    form1.resetFields();

    _dataSource1([]);

    _xshow(true);
  };

  const bExcel = () => {
    const f = form1.getFieldValue();

    let xsdate = "",
      xedate = "";

    let xdata = [],
      xtabtitle = "",
      header = [];

    xtabtitle = "รายงานเงินรับเข้าโครงการวิจัยรายโครงการ";

    header.push("วันที่", "จำนวนเงินโอน (บาท)", "invoice / รายละเอียด");

    xdata.push([xtabtitle]);

    xsdate = dayjs(f.sedate[0]).format("YYYY-MM-DD");
    xedate = dayjs(f.sedate[1]).format("YYYY-MM-DD");

    xdata.push(["ตั้งแต่วันที่ " + xsdate + " ถึงวันที่ " + xedate]);

    xdata.push([""]);
    xdata.push(header);

    for (var row in dataSourceEx) {
      const xx = [];

      for (var col in dataSourceEx[row]) {
        xx.push(dataSourceEx[row][col]);
      }

      xdata.push(xx);
    }

    xdata.push(["รวมทั้งหมด", xsummary]);

    var wb = xlsx.utils.book_new(),
      ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, xtabtitle + ".xlsx");
  };

  const GoPage = cmumis_id => {
    window.open("/Research_main?cmumis_id=" + cmumis_id, "_blank");
  };

  const columns1 = [
    {
      title: "ที่",
      dataIndex: "no",
      key: "no",
      align: "center",
      width: 50
    },
    {
      title: "รหัส MIS:ชื่อย่อโครงการ / ชื่อหัวหน้าโครงการ",
      dataIndex: "id_name",
      key: "id_name",
      width: 300,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                GoPage(text.substring(0, 10));
              }}
            >
              {text}
            </a>
          ),
          props: {}
        };

        if (
          index >= 1 &&
          record.id_name === dataSource1[trueIndex - 1].id_name
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.id_name === dataSource1[trueIndex + i].id_name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "วันที่",
      dataIndex: "date1",
      key: "date1",
      align: "center",
      width: 100
    },
    {
      title: "จำนวนเงินโอน (บาท)",
      dataIndex: "money_b",
      key: "money_b",
      align: "center",
      width: 160,
      render: (text, record) => __TBShowText(record.money_b, 1, 0)
    },
    {
      title: "invoice / รายละเอียด",
      dataIndex: "invoice_detail",
      key: "invoice_detail",
      width: 300
      //   align: "center"
    }
  ];

  //formatttable (Rigth or Left)
  function __TBShowText(zData, zRigth, zLeft) {
    if (zRigth === 1 && zLeft === 0) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    } else if (zRigth === 0 && zLeft === 1) {
      return (
        <div style={{ display: "flex" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <StyledForm />
      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={23}>
            <PageHeader
              className="site-page-header"
              title="รายงานเงินรับเข้าโครงการวิจัยรายโครงการ"
            />
            <Divider />
          </Col>
        </Row>

        <Row justify="center">
          <Col span={23}>
            <Card
              style={{
                borderRadius: "15px",
                overflow: "hidden"
              }}
            >
              <Form form={form1} initialValues={{}}>
                <ConfigProvider locale={configuredLocale}>
                  <Row justify="center">
                    <Col span={8}>
                      <Form.Item
                        label="วันเริ่มต้น-วันสิ้นสุด"
                        name="sedate"
                        rules={[
                          {
                            required: true
                          }
                        ]}
                      >
                        <DatePicker.RangePicker
                          autoFocus
                          format="DD/MM/BBBB"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </ConfigProvider>
              </Form>

              <br />

              <Row justify="center">
                <Space>
                  <Button ghost type="primary" onClick={bSearch}>
                    <SearchOutlined /> แสดงข้อมูล
                  </Button>

                  <Button ghost type="danger" onClick={bClear}>
                    <ClearOutlined /> เคลียร์
                  </Button>

                  <Button
                    ghost
                    type="success"
                    onClick={bExcel}
                    disabled={xshow}
                  >
                    <FileExcelOutlined /> Excel
                  </Button>
                </Space>
              </Row>
            </Card>
          </Col>
        </Row>

        <br />
        <br />

        <Row justify="center" style={{ marginBottom: 10 }}>
          <Col span={23}>
            <Table
              bordered
              scroll={{ x: "max-content" }}
              dataSource={dataSource1}
              columns={columns1}
              size="small"
              pagination={false}
              summary={() =>
                <Table.Summary fixed>
                  <Table.Summary.Row
                    style={{
                      textAlign: "right",
                      backgroundColor: "#b5f5ec"
                    }}
                  >
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                    <Table.Summary.Cell align="center">
                      รวมทั้งหมด
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {xsummary}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell />
                  </Table.Summary.Row>
                </Table.Summary>}
            />
          </Col>
        </Row>
      </Content>

      <div style={{ height: "140px" }} />

      <Footer_last />
    </React.Fragment>
  );
};

export default Research_report1_1;
