import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Research_login from "./pages/Research_login";
import Research_main from "./pages/Research_main";
import Research_input from "./pages/Research_input";
import Research_input_file from "./pages/Research_input_file";
import Research_input_installment from "./pages/Research_input_installment";
import Research_input_contract from "./pages/Research_input_contract";
import Research_register from "./pages/Research_register";
import Research_input_agency from "./pages/Research_input_agency";
import Research_input_researcher from "./pages/Research_input_researcher";
import Research_scopus from "./pages/Research_scopus";
import Research_scopus_list from "./pages/Research_scopus_list";
import Research_scopus_list2 from "./pages/Research_scopus_list2";
import Research_first from "./pages/Research_first";
import Research_Edit from "./pages/Research_Edit";
import Research_staff from "./pages/Research_staff";
import Research_scopus_list2_naut from "./pagenoaut/Research_scopus_list2_naut";
import Research_scopus_naut from "./pagenoaut/Research_scopus_naut";
import Research_Transfer from "./pages/Research_transfer";
import Research_show_transfer from "./pages/Research_show_transfer";
import Research_report from "./pages/Research_report";
import Research_report2 from "./pages/Research_report2";
import Research_report3 from "./pages/Research_report3";
import Research_report4 from "./pages/Research_report4";
import Research_report1_1 from "./pages/Research_report1_1";
import Research_to_cmu from "./pages/Research_to_cmu";
import Research_input_to_cmu from "./pages/Research_input_to_cmu";
import Research_report_to_cmu from "./pages/Research _report_to_cmu";
import Research_pub_type_cmu from "./pages/Research_pub_type_cmu";
import Research_citation_count from "./pages/Research_citation_count";
import Research_academic from "./pages/Research_academic";
import Research_report_patent from "./pages/Research_report_patent";
import Research_input_patent from "./pages/Research_input_patent";

import Research_scopus2 from "./pagenoaut/Research_scopus2";
import Research_scopus2_list from "./pagenoaut/Research_scopus2_list";
import Research_scopus2_list2 from "./pagenoaut/Research_scopus2_list2";

import Reseach_pms from "./pages/Research_pms";

import Research_dashboard from "./pages/Research_dashboard";
import Research_dashboard_income from "./pages/Research_dashboard_income";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Research_login />} />
        <Route path="/Research_main" element={<Research_main />} />
        <Route path="/Research_Edit" element={<Research_Edit />} />
        <Route path="/Research_input" element={<Research_input />} />
        <Route path="/Research_input_file" element={<Research_input_file />} />
        <Route
          path="/Research_input_installment"
          element={<Research_input_installment />}
        />
        <Route
          path="/Research_input_contract"
          element={<Research_input_contract />}
        />
        <Route path="/Research_register" element={<Research_register />} />
        <Route
          path="/Research_input_agency"
          element={<Research_input_agency />}
        />
        <Route
          path="/Research_input_researcher"
          element={<Research_input_researcher />}
        />
        <Route path="/Research_scopus" element={<Research_scopus />} />
        <Route
          path="/Research_scopus_list"
          element={<Research_scopus_list />}
        />
        <Route
          path="/Research_scopus_list2"
          element={<Research_scopus_list2 />}
        />
        <Route path="Research_staff" element={<Research_staff />} />

        <Route path="Research_Transfer" element={<Research_Transfer />} />
        <Route
          path="Research_show_transfer"
          element={<Research_show_transfer />}
        />

        <Route path="Research_report" element={<Research_report />} />
        <Route path="Research_report2" element={<Research_report2 />} />
        <Route path="Research_report3" element={<Research_report3 />} />
        <Route path="Research_report4" element={<Research_report4 />} />
        <Route path="Research_report1_1" element={<Research_report1_1 />} />

        <Route path="Research_to_cmu" element={<Research_to_cmu />} />
        <Route
          path="Research_input_to_cmu"
          element={<Research_input_to_cmu />}
        />
        <Route
          path="Research_report_to_cmu"
          element={<Research_report_to_cmu />}
        />
        <Route
          path="Research_pub_type_cmu"
          element={<Research_pub_type_cmu />}
        />
        <Route
          path="Research_citation_count"
          element={<Research_citation_count />}
        />

        <Route path="Research_academic" element={<Research_academic />} />

        <Route path="Research_dashboard" element={<Research_dashboard />} />
        <Route
          path="Research_dashboard_income"
          element={<Research_dashboard_income />}
        />

        <Route path="Research_pms" element={<Reseach_pms />} />

        <Route
          path="Research_report_patent"
          element={<Research_report_patent />}
        />

        <Route
          path="Research_input_patent"
          element={<Research_input_patent />}
        />

        {/* //// No Aut //// */}
        <Route path="Research_scopus_naut" element={<Research_scopus_naut />} />

        <Route
          path="Research_scopus_list2_naut"
          element={<Research_scopus_list2_naut />}
        />

        <Route path="Research_scopus2" element={<Research_scopus2 />} />
        <Route
          path="Research_scopus2_list"
          element={<Research_scopus2_list />}
        />
        <Route
          path="Research_scopus2_list2"
          element={<Research_scopus2_list2 />}
        />

        <Route path="/1" element={<Research_first />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
