import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import {
  SaveOutlined,
  ClearOutlined,
  UserAddOutlined
} from "@ant-design/icons";
import { createGlobalStyle } from "styled-components";

import {
  Row,
  Col,
  Form,
  Card,
  Input,
  Divider,
  Button,
  Space,
  Typography,
  Select
} from "antd";

import axios from "axios";
import swal from "sweetalert";

import background from "../pics/header.jpg";

import ReCAPTCHA from "react-google-recaptcha";

const { Text } = Typography;
const { Option } = Select;

const StyledForm = createGlobalStyle`.ant-form-item {
  margin-bottom: 5px;
}
`;

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 }
};

function Research_register() {
  const [form1] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;
  let secretKey = "";

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
    secretKey = "6Lcx3xcqAAAAAJq16RqUkH3Tf60h3G6n-rq9GTDx";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
    secretKey = "6Lcl3xcqAAAAAJpzC-Z4HsDvMsiVMnsPCiy5wNg2";
  }

  const [xEmp, _xEmp] = useState("");
  const [xPosition, _xPosition] = useState("");
  const [xProject_Position, _xProject_Position] = useState("");
  const [xCenter, _xCenter] = useState("");

  const [xEmpV, _xEmpV] = useState("");
  const [xPositionV, _xPositionV] = useState("");
  const [xProject_PositionV, _xProject_PositionV] = useState("");
  const [xCenterV, _xCenterV] = useState("");

  const [ReCAPTCHAValue, _ReCAPTCHAValue] = useState("");

  useEffect(() => {
    //ค้นหา emp
    axios
      .get(xURL + "research_emp")
      .then(result => {
        //console.log(result.data);

        _xEmp(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    //ค้นหา position
    axios
      .get(xURL + "research_position")
      .then(result => {
        //console.log(result.data);

        _xPosition(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    //ค้นหา project_position
    axios
      .get(xURL + "research_project_position")
      .then(result => {
        //console.log(result.data);

        _xProject_Position(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    //ค้นหา center
    axios
      .get(xURL + "center")
      .then(result => {
        //console.log(result.data);

        _xCenter(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const bClearclick = () => {
    form1.resetFields();
    _ReCAPTCHAValue("");
  };

  const bSaveclick = () => {
    const f = form1.getFieldsValue();

    if (!f.edusername) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ Username!", "warning");
    } else if (!f.edpassword) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ Password!", "warning");
    } else if (!f.edcfpassword) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ Confirm-Password!", "warning");
    } else if (f.edpassword !== f.edcfpassword) {
      swal(
        "บันทึก ไม่ได้!",
        "คีย์ Confirm-Password ไม่ตรงกับ Password!",
        "warning"
      );
    } else if (ReCAPTCHAValue == "" || ReCAPTCHAValue == null) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้ยืนยัน ReCAPCHA", "warning");
    } else {
      swal({
        title: "ยืนยัน บันทึกผู้ใช้งานระบบ",
        text: ":-)",
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          axios
            .post(xURL + "checkUser", {
              username: f.edusername
            })
            .then(result2 => {
              // console.log(result2.data);
              if (result2.data.length > 0) {
                swal(
                  "บันทึก ไม่ได้!",
                  "๊Username นี้มีอยู่ในระบบแล้ว",
                  "warning"
                );
              } else {
                axios
                  .post(xURL + "checkUser2", {
                    name: f.edfirst_name_th,
                    lname: f.edlast_name_th
                  })
                  .then(result3 => {
                    if (result3.data.length > 0) {
                      axios
                        .post(xURL + "register", {
                          username: f.edusername,
                          password: f.edpassword,
                          fname:
                            f.edfirst_name_th.trim() +
                            " " +
                            f.edlast_name_th.trim(),
                          research_id: result3.data[0].id,
                          position: xPositionV
                        })
                        .then(result => {
                          window.location = "/";
                        })
                        .catch(function(error) {
                          // handle error
                          console.log("problem here", error);
                        });
                    } else {
                      axios
                        .post(xURL + "insert", {
                          title_name: f.edtitle_name,
                          fname_th: f.edfirst_name_th,
                          lname_th: f.edlast_name_th,
                          fname_eng: f.edfirst_name_eng,
                          lname_eng: f.edlast_name_eng,
                          emp: xEmpV,
                          position: xPositionV,
                          project_position: xProject_PositionV,
                          center: xCenterV,
                          email: f.edemail,
                          phone: f.edphone,
                          linetk: f.edline,
                          zorder: 10
                        })
                        .then(result => {
                          //console.log(result.data.insertId);

                          axios
                            .post(xURL + "register", {
                              username: f.edusername,
                              password: f.edpassword,
                              fname: f.edfirst_name_th + " " + f.edlast_name_th,
                              research_id: result.data.insertId,
                              position: xPositionV
                            })
                            .then(result => {
                              window.location = "/";
                            })
                            .catch(function(error) {
                              // handle error
                              console.log("problem here", error);
                            });
                        })
                        .catch(function(error) {
                          // handle error
                          console.log("problem here", error);
                        });
                    }
                  })
                  .catch(function(error) {
                    // handle error
                    console.log("problem here", error);
                  });
              }
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
        }
      });
    }
  };

  function handleChange(value) {
    _xEmpV(value);
  }

  function handleChange2(value) {
    _xPositionV(value);
  }

  function handleChange3(value) {
    _xProject_PositionV(value);
  }

  function handleChange4(value) {
    _xCenterV(value);
  }

  function onChangeRecap(value) {
    // console.log("Captcha value:", value);
    _ReCAPTCHAValue(value);
  }

  return (
    <React.Fragment>
      <div
        id="background-fix"
        style={{
          backgroundImage: `url(${background})`
        }}
      />
      <StyledForm />
      <Row justify="center">
        <Col
          xs={23}
          sm={23}
          md={23}
          lg={14}
          xl={14}
          justify="space-around"
          style={{ padding: "10px", paddingTop: "24px" }}
        >
          <Card
            id="ant-card-body"
            style={{
              borderRadius: "15px",
              overflow: "hidden"
            }}
          >
            <Form
              form={form1}
              initialValues={{
                edusername: "",
                edpassword: "",
                edcfpassword: "",
                edtitle_name: "",
                edfirst_name_th: "",
                edlast_name_th: "",
                edfirst_name_eng: "",
                edlast_name_eng: "",
                slemp: "",
                slposition: "",
                slproject_position: "",
                slcenter: "",
                edemail: "",
                edphone: "",
                edline: ""
              }}
              {...layout}
            >
              <Row justify="space-around">
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: "40px"
                  }}
                >
                  <UserAddOutlined /> Register
                </Text>
              </Row>

              <br />

              <Form.Item
                label="Username"
                name="edusername"
                rules={[
                  {
                    required: true,
                    message: "Please input your Username!"
                  }
                ]}
              >
                <Input
                  autoFocus
                  allowClear
                  placeholder="Username"
                  style={{ width: "50%" }}
                />
              </Form.Item>

              <Form.Item
                label="Password"
                name="edpassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!"
                  }
                ]}
              >
                <Input
                  allowClear
                  type="password"
                  placeholder="Password"
                  style={{ width: "50%" }}
                />
              </Form.Item>

              <Form.Item
                label="Confirm-Password"
                name="edcfpassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your Confirm-Password!"
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("edpassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The Confirm-Password that you entered do not match!"
                      );
                    }
                  })
                ]}
              >
                <Input
                  allowClear
                  type="password"
                  placeholder="Confirm-Password"
                  style={{ width: "50%" }}
                />
              </Form.Item>

              <Divider />

              <Form.Item
                label="ชื่อนำ"
                name="edtitle_name"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอก ชื่อนำ"
                  }
                ]}
              >
                <Input
                  allowClear
                  placeholder="ชื่อนำ"
                  style={{ width: "20%" }}
                />
              </Form.Item>

              <Form.Item
                label="ชื่อ ภาษาไทย"
                name="edfirst_name_th"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอก ชื่อ"
                  },
                  {
                    pattern: new RegExp(/^[ก-๏\s]+$/i),
                    message: "ภาษาไม่ถูกต้อง"
                  }
                ]}
              >
                <Input
                  allowClear
                  placeholder="ชื่อ ภาษาไทย"
                  style={{ width: "50%" }}
                />
              </Form.Item>

              <Form.Item
                label="นามสกุล ภาษาไทย"
                name="edlast_name_th"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอก นามสกุล"
                  },
                  {
                    pattern: new RegExp(/^[ก-๏\s]+$/i),
                    message: "ภาษาไม่ถูกต้อง"
                  }
                ]}
              >
                <Input
                  allowClear
                  placeholder="นามสกุล ภาษาไทย"
                  style={{ width: "50%" }}
                />
              </Form.Item>

              <Form.Item
                label="ชื่อ ภาษาอังกฤษ"
                name="edfirst_name_eng"
                rules={[
                  {
                    pattern: new RegExp(/^[a-zA-Z\s]+$/i),
                    message: "ภาษาไม่ถูกต้อง"
                  }
                ]}
              >
                <Input
                  allowClear
                  placeholder="ชื่อ ภาษาอังกฤษ"
                  style={{ width: "50%" }}
                />
              </Form.Item>

              <Form.Item
                label="นามสกุล ภาษาอังกฤษ"
                name="edlast_name_eng"
                rules={[
                  {
                    pattern: new RegExp(/^[a-zA-Z\s]+$/i),
                    message: "ภาษาไม่ถูกต้อง"
                  }
                ]}
              >
                <Input
                  allowClear
                  placeholder="นามสกุล ภาษาอังกฤษ"
                  style={{ width: "50%" }}
                />
              </Form.Item>

              <Form.Item label="ระดับ" name="slemp">
                <Select onChange={handleChange} style={{ width: "50%" }}>
                  {xEmp.length > 0
                    ? xEmp.map((i, idx) => {
                        return (
                          <Option value={i.id}>
                            {i.name}
                          </Option>
                        );
                      })
                    : <Option value="">
                        {}
                      </Option>}
                </Select>
              </Form.Item>

              <Form.Item label="ตำแหน่ง" name="slposition">
                <Select onChange={handleChange2} style={{ width: "50%" }}>
                  {xPosition.length > 0
                    ? xPosition.map((i, idx) => {
                        return (
                          <Option value={i.id}>
                            {i.name}
                          </Option>
                        );
                      })
                    : <Option value="">
                        {}
                      </Option>}
                </Select>
              </Form.Item>

              <Form.Item label="ตำแหน่งนักวิจัย" name="slproject_position">
                <Select onChange={handleChange3} style={{ width: "50%" }}>
                  {xProject_Position.length > 0
                    ? xProject_Position.map((i, idx) => {
                        return (
                          <Option value={i.id}>
                            {i.name}
                          </Option>
                        );
                      })
                    : <Option value="">
                        {}
                      </Option>}
                </Select>
              </Form.Item>

              <Form.Item label="หน่วยงาน" name="slcenter">
                <Select onChange={handleChange4} style={{ width: "50%" }}>
                  {xCenter.length > 0
                    ? xCenter.map((i, idx) => {
                        return (
                          <Option value={i.id}>
                            {i.name}
                          </Option>
                        );
                      })
                    : <Option value="">
                        {}
                      </Option>}
                </Select>
              </Form.Item>

              <Form.Item
                label="E-mail"
                name="edemail"
                rules={[
                  {
                    type: "email",
                    message: "กรอก E-mail ไม่ถูกต้อง!"
                  }
                ]}
              >
                <Input
                  allowClear
                  placeholder="E-mail"
                  style={{ width: "50%" }}
                />
              </Form.Item>

              <Form.Item label="เบอร์ติดต่อ" name="edphone">
                <Input
                  allowClear
                  placeholder="เบอร์ติดต่อ"
                  style={{ width: "50%" }}
                />
              </Form.Item>

              <Form.Item label="Line-Token" name="edline">
                <Input
                  allowClear
                  placeholder="Line-Token"
                  style={{ width: "50%" }}
                />
              </Form.Item>
            </Form>

            <br />
            <Row justify="center">
              <ReCAPTCHA sitekey={secretKey} onChange={onChangeRecap} />
            </Row>
            <br />

            <Row justify="center">
              <Space>
                <Button ghost type="primary" onClick={bSaveclick}>
                  <SaveOutlined /> บันทึก
                </Button>

                <Button ghost type="danger" onClick={bClearclick}>
                  <ClearOutlined /> เคลียร์
                </Button>
              </Space>
            </Row>

            <br />
            <br />
            <Divider />
            <Row justify="space-around">
              <h4>Copyright &copy; 2022 Research RIHES CMU</h4>
            </Row>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
export default Research_register;
