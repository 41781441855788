import "antd/dist/antd.min.css";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";

import {
  SearchOutlined,
  ClearOutlined,
  DeleteOutlined,
  EditOutlined,
  PaperClipOutlined,
  FileExcelOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { createGlobalStyle } from "styled-components";

import {
  Row,
  Col,
  Form,
  Layout,
  Card,
  Typography,
  Modal,
  Input,
  InputNumber,
  Space,
  Button,
  Table,
  PageHeader,
  Divider
} from "antd";

import moment from "moment";
import "moment/locale/th";

import * as xlsx from "xlsx";
import swal from "sweetalert";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import thTH from "antd/lib/locale/th_TH";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

const { Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;
const { TextArea } = Input;

const StyledForm = createGlobalStyle`.ant-form-item {
    margin-bottom: 5px;
  }
  `;

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 }
};

const configuredLocale = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    dateFormat: "DD MM BBBB", // DD MM BBBB
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker.lang,
      dateFormat: "DD MM BBBB", // DD MM BBBB
      dateTimeFormat: "DD MM BBBB HH:mm:ss",
      yearFormat: "BBBB"
    }
  }
};

dayjs.locale("th");
dayjs.extend(buddhistEra);

const Research_to_cmu = () => {
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [xcmumis_id, _xcmumis_id] = useState("");
  const [xproject_name, _xproject_name] = useState("");
  const [xproject_shortname, _xproject_shortname] = useState("");
  const [xresearch_header, _xresearch_header] = useState("");
  const [xresearch_time, _xresearch_time] = useState("");

  const [dataTocmu, _dataTocmu] = useState();
  const [dataSourceEx, _dataSourceEx] = useState();

  const [xSumAmount, _xSumAmount] = useState(0);
  const [xSumDirect, _xSumDirect] = useState(0);
  const [xSumF_A, _xSumF_A] = useState(0);
  const [xSumOver70, _xSumOver70] = useState(0);
  const [xSumOver30, _xSumOver30] = useState(0);
  const [xSumTo_cmu, _xSumTo_cmu] = useState(0);

  const [selectionType, setSelectionType] = useState("radio");
  const [dataselectradio, _dataselectradio] = useState();

  useEffect(() => {
    const token = localStorage.getItem("token");

    //console.log(token);

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");
          var url = new URL(window.location.href);

          _xcmumis_id(url.searchParams.get("cmumis_id"));

          GetHeader(url.searchParams.get("cmumis_id"));

          GetToCmu(url.searchParams.get("cmumis_id"));
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const GetHeader = cmumis_id => {
    axios
      .post(xURL + "find_header", {
        cmumis_id: cmumis_id
      })
      .then(result => {
        let sdate = "",
          edate = "";

        if (result.data[0].start_date) {
          sdate = dayjs(result.data[0].start_date).format("DD/MM/BBBB");
        }

        if (result.data[0].end_date) {
          edate = dayjs(result.data[0].end_date).format("DD/MM/BBBB");
        }

        _xproject_name(result.data[0].name_thai);
        _xproject_shortname(result.data[0].short_name);
        _xresearch_header(result.data[0].researchname);
        _xresearch_time(sdate + " - " + edate);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetToCmu = cmumis_id => {
    axios
      .post(xURL + "find_to_cmu", {
        cmumis_id: cmumis_id
      })
      .then(result => {
        const Tb1 = [],
          Tb2 = [];

        let xsum = 0,
          xsum1 = 0,
          xsum2 = 0,
          xsum3 = 0,
          xsum4 = 0,
          xsum5 = 0,
          xsum0 = 0;

        result.data.forEach((i, idx) => {
          let xdate_receive = "",
            xtotal_receive = "",
            xdirect = "",
            xf_a = "",
            xoverhead70 = "",
            xoverhead30 = "",
            xdate_to_cmu = "",
            xmoney_to_cmu = "";

          if (i.total_receive != null) {
            xtotal_receive = i.total_receive.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.date_receive && i.date_receive != "0000-00-00") {
            xdate_receive = dayjs(i.date_receive).format("DD/MM/BBBB");
          }

          if (i.direct != null) {
            xdirect = i.direct.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.fa != null) {
            xf_a = i.fa.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.overhead70 != null) {
            xoverhead70 = i.overhead70.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.overhead30 != null) {
            xoverhead30 = i.overhead30.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.date_to_cmu && i.date_to_cmu != "0000-00-00") {
            xdate_to_cmu = dayjs(i.date_to_cmu).format("DD/MM/BBBB");
          }

          if (i.money_to_cmu != null) {
            xmoney_to_cmu = i.money_to_cmu.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          let url_file_to_cmu = "",
            url_file_invoice = "",
            url_file_transfer = "";

          if (i.file_to_cmu) {
            if (i.file_to_cmu !== "undefined") {
              url_file_to_cmu = `${xURL}file5/${i.file_to_cmu}`;
            } else {
              url_file_to_cmu = "";
            }
          }

          if (i.file_invoice) {
            if (i.file_invoice !== "undefined") {
              url_file_invoice = `${xURL}file3/${i.file_invoice}`;
            } else {
              url_file_invoice = "";
            }
          }

          if (i.file_transfer) {
            if (i.file_transfer !== "undefined") {
              url_file_transfer = `${xURL}file4/${i.file_transfer}`;
            } else {
              url_file_transfer = "";
            }
          }

          if (i.total_receive) {
            xsum = xsum + i.total_receive;
          }
          if (i.direct) {
            xsum1 = xsum1 + i.direct;
          }
          if (i.fa) {
            xsum2 = xsum2 + i.fa;
          }
          if (i.overhead70) {
            xsum3 = xsum3 + i.overhead70;
          }
          if (i.overhead30) {
            xsum4 = xsum4 + i.overhead30;
          }
          if (i.money_to_cmu) {
            xsum5 = xsum5 + i.money_to_cmu;
          }
          if (i.total_receive) {
            xsum0 = xsum0 + i.total_receive;
          }

          Tb1.push({
            key: idx,
            num: idx + 1,
            detail: i.detail,
            date_receive: xdate_receive,
            total_receive: xtotal_receive,
            direct: xdirect,
            f_a: xf_a,
            overhead70: xoverhead70,
            overhead30: xoverhead30,
            date_to_cmu: xdate_to_cmu,
            money_to_cmu: xmoney_to_cmu,
            file_to_cmu: url_file_to_cmu,
            fileinvoice: url_file_invoice,
            filetransfer: url_file_transfer
          });

          Tb2.push({
            num: idx + 1,
            detail: i.detail,
            date_receive: xdate_receive,
            total_receive: xtotal_receive,
            direct: xdirect,
            f_a: xf_a,
            overhead70: xoverhead70,
            overhead30: xoverhead30,
            date_to_cmu: xdate_to_cmu,
            money_to_cmu: xmoney_to_cmu
          });
        });

        Tb2.push({
          num: "",
          detail: "",
          date_receive: "รวม",
          total_receive: xsum0,
          direct: xsum1,
          f_a: xsum2,
          overhead70: xsum3,
          overhead30: xsum4,
          date_to_cmu: "",
          money_to_cmu: xsum5
        });

        _dataTocmu(Tb1);
        _dataSourceEx(Tb2);

        _xSumAmount(xsum);
        _xSumDirect(xsum1);
        _xSumF_A(xsum2);
        _xSumOver70(xsum3);
        _xSumOver30(xsum4);
        _xSumTo_cmu(xsum5);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bAddToCmu = () => {
    if (!dataselectradio) {
      swal("เพิ่ม ไม่ได้!", "ยังไม่ได้เลือกรายการในตาราง!", "warning");
    } else {
      // console.log(dataselectradio[0].detail);

      let xdetail = "",
        xdetail2 = "",
        xtype = 1;

      if (dataselectradio[0].detail.split("#")[1]) {
        xdetail = dataselectradio[0].detail.split("#")[0];
        xdetail2 = dataselectradio[0].detail.split("#")[1];
      } else {
        xdetail = dataselectradio[0].detail;
        xdetail2 = "";
      }

      if (
        dataselectradio[0].file_to_cmu !== "" ||
        dataselectradio[0].date_to_cmu !== "" ||
        dataselectradio[0].money_to_cmu !== ""
      ) {
        xtype = 0;
      }

      window.open(
        "/Research_input_to_cmu?cmumis_id=" +
          xcmumis_id +
          "&num=" +
          dataselectradio[0].num +
          "&detail=" +
          xdetail +
          "&detail2=" +
          xdetail2 +
          "&type=" +
          xtype,
        "_self"
      );
    }
  };

  const bExcelTocmu = () => {
    if (dataSourceEx[0].num == "") {
      swal("เปิด Excel ไม่ได้!", "ไม่มีข้อมูล!", "warning");
    } else {
      let xdata = [];
      let header = [
        "ที่",
        "รายละเอียด",
        "วันที่รับเงิน",
        "เงินที่ได้รับ(บาท)",
        "Direct",
        "F&A",
        "Overhead 70%",
        "Overhead 30%",
        "วันที่นำส่งเงินสมทบ มช.",
        "จำนวนเงินที่นำส่ง"
      ];

      xdata.push(["เงินสมทบ"]);
      xdata.push([""]);
      xdata.push(["รหัส CMU MIS : " + xcmumis_id]);
      xdata.push(["ชื่อย่อโครงการ : " + xproject_shortname]);
      xdata.push([""]);
      xdata.push(header);

      for (var row in dataSourceEx) {
        const xx = [];

        for (var col in dataSourceEx[row]) {
          xx.push(dataSourceEx[row][col]);
        }

        xdata.push(xx);
      }

      var wb = xlsx.utils.book_new(),
        ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

      xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
      xlsx.writeFile(wb, "เงินสมทบ.xlsx");
    }
  };

  const cTocmu = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 40
    },
    {
      title: "รายละเอียด",
      dataIndex: "detail",
      key: "detail",
      width: 450
    },
    {
      title: "วันที่รับเงิน",
      dataIndex: "date_receive",
      key: "date_receive",
      align: "center"
    },
    {
      title: "เงินที่ได้รับ(บาท)",
      dataIndex: "total_receive",
      key: "total_receive",
      align: "center",
      render: (text, record) => __TBShowText(record.total_receive, 1, 0)
    },
    {
      title: "Direct",
      dataIndex: "direct",
      key: "direct",
      align: "center",
      render: (text, record) => __TBShowText(record.direct, 1, 0)
    },
    {
      title: "F&A",
      dataIndex: "f_a",
      key: "f_a",
      align: "center",
      render: (text, record) => __TBShowText(record.f_a, 1, 0)
    },
    {
      title: "Overhead 70%",
      dataIndex: "overhead70",
      key: "overhead70",
      align: "center",
      render: (text, record) => __TBShowText(record.overhead70, 1, 0)
    },
    {
      title: "Overhead 30%",
      dataIndex: "overhead30",
      key: "overhead30",
      align: "center",
      render: (text, record) => __TBShowText(record.overhead30, 1, 0)
    },
    {
      title: "วันที่นำส่งเงินสมทบ มช.",
      dataIndex: "date_to_cmu",
      key: "date_to_cmu",
      align: "center"
    },
    {
      title: "จำนวนเงินที่นำส่ง",
      dataIndex: "money_to_cmu",
      key: "money_to_cmu",
      align: "center",
      render: (text, record) => __TBShowText(record.money_to_cmu, 1, 0)
    },
    {
      title: "ไฟล์นำส่ง มช.",
      dataIndex: "file_to_cmu",
      key: "file_to_cmu",
      align: "center",
      width: 130,
      render(text, record, index) {
        if (record.file_to_cmu) {
          return {
            children: (
              <a href={record.file_to_cmu} target="_blank">
                <PaperClipOutlined /> ไฟล์แนบ
              </a>
            )
          };
        }
      }
    },
    {
      title: "ไฟล์ invoice",
      dataIndex: "fileinvoice",
      key: "fileinvoice",
      align: "center",
      width: 130,
      render(text, record, index) {
        if (record.fileinvoice) {
          return {
            children: (
              <a href={record.fileinvoice} target="_blank">
                <PaperClipOutlined /> ไฟล์แนบ
              </a>
            )
          };
        }
      }
    },
    {
      title: "ไฟล์ใบโอน",
      dataIndex: "filetransfer",
      key: "filetransfer",
      align: "center",
      width: 130,
      render(text, record, index) {
        if (record.filetransfer) {
          return {
            children: (
              <a href={record.filetransfer} target="_blank">
                <PaperClipOutlined /> ไฟล์แนบ
              </a>
            )
          };
        }
      }
    }
  ];

  //formatttable (Rigth or Left)
  function __TBShowText(zData, zRigth, zLeft) {
    if (zRigth === 1 && zLeft === 0) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    } else if (zRigth === 0 && zLeft === 1) {
      return (
        <div style={{ display: "flex" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      // console.log(selectedRows);
      _dataselectradio(selectedRows);
    },
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name
    })
  };

  return (
    <React.Fragment>
      <StyledForm />

      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col span={23}>
            <PageHeader
              className="site-page-header"
              title={
                <a
                  style={{
                    color: "black"
                  }}
                  onClick={() => {
                    window.close();
                  }}
                >
                  การส่งเงินสมทบ
                </a>
              }
              onBack={() => window.close()}
            />

            <Divider />

            <Card title="">
              <Form form={form2} {...layout}>
                <Form.Item
                  label={
                    <Text style={{ fontWeight: "bold" }}>รหัส CMU MIS</Text>
                  }
                >
                  {xcmumis_id}
                </Form.Item>

                <Form.Item
                  label={
                    <Text style={{ fontWeight: "bold" }}>ชื่อโครงการ</Text>
                  }
                >
                  {xproject_name}
                </Form.Item>

                <Form.Item
                  label={
                    <Text style={{ fontWeight: "bold" }}>ชื่อย่อโครงการ</Text>
                  }
                >
                  {xproject_shortname}
                </Form.Item>

                <Form.Item
                  label={
                    <Text style={{ fontWeight: "bold" }}>หัวหน้าโครงการ</Text>
                  }
                >
                  {xresearch_header}
                </Form.Item>

                <Form.Item
                  label={<Text style={{ fontWeight: "bold" }}>ระยะเวลา</Text>}
                >
                  {xresearch_time}
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>

        <br />

        <Row justify="center">
          <Col span={23}>
            <Card title="">
              <div style={{ float: "right" }}>
                <Form.Item>
                  <Button ghost type="success" onClick={bExcelTocmu}>
                    <FileExcelOutlined /> Excel
                  </Button>
                </Form.Item>
              </div>

              {dataTocmu != ""
                ? <Table
                    bordered
                    scroll={{ x: "max-content" }}
                    dataSource={dataTocmu}
                    columns={cTocmu}
                    size="small"
                    pagination={false}
                    summary={() =>
                      <Table.Summary fixed>
                        <Table.Summary.Row
                          style={{
                            textAlign: "right",
                            backgroundColor: "#b5f5ec"
                          }}
                        >
                          <Table.Summary.Cell />
                          <Table.Summary.Cell />
                          <Table.Summary.Cell />
                          <Table.Summary.Cell>รวม</Table.Summary.Cell>
                          <Table.Summary.Cell>
                            {xSumAmount.toLocaleString(undefined, {
                              minimumFractionDigits: 2
                            })}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            {xSumDirect.toLocaleString(undefined, {
                              minimumFractionDigits: 2
                            })}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            {xSumF_A.toLocaleString(undefined, {
                              minimumFractionDigits: 2
                            })}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            {xSumOver70.toLocaleString(undefined, {
                              minimumFractionDigits: 2
                            })}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            {xSumOver30.toLocaleString(undefined, {
                              minimumFractionDigits: 2
                            })}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell />
                          <Table.Summary.Cell>
                            {xSumTo_cmu.toLocaleString(undefined, {
                              minimumFractionDigits: 2
                            })}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell />
                          <Table.Summary.Cell />
                          <Table.Summary.Cell />
                        </Table.Summary.Row>
                      </Table.Summary>}
                    rowSelection={{
                      type: selectionType,
                      ...rowSelection
                    }}
                  />
                : <p>---ไม่มีข้อมูล---</p>}

              <br />
              <Row>
                <Space>
                  <Button ghost type="primary" onClick={bAddToCmu}>
                    <PlusOutlined />เพิ่ม/แก้ไข เงินสมทบ
                  </Button>
                </Space>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>

      <br />
      <Footer_last />
    </React.Fragment>
  );
};

export default Research_to_cmu;
