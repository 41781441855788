import React, { useEffect, useState } from "react";
import "antd/dist/antd.min.css";
import axios from "axios";
import swal from "sweetalert";
import { createGlobalStyle } from "styled-components";
import {
  SearchOutlined,
  ClearOutlined,
  PaperClipOutlined,
  FileExcelOutlined
} from "@ant-design/icons";

import {
  Layout,
  Row,
  Col,
  PageHeader,
  Divider,
  Card,
  Form,
  ConfigProvider,
  Space,
  Button,
  Table,
  Typography,
  Select
} from "antd";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import thTH from "antd/lib/locale/th_TH";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

import moment from "moment";
import "moment/locale/th";

import * as xlsx from "xlsx";

dayjs.locale("th");
dayjs.extend(buddhistEra);

const { Content } = Layout;
const { Text } = Typography;
const { Option } = Select;

const configuredLocale = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    dateFormat: "DD MM BBBB", // DD MM BBBB
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker.lang,
      dateFormat: "DD MM BBBB", // DD MM BBBB
      dateTimeFormat: "DD MM BBBB HH:mm:ss",
      yearFormat: "BBBB"
    }
  }
};

const StyledForm = createGlobalStyle`.ant-form-item {
      margin-bottom: 5px;
    }
    `;

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
};

const Research_report_to_cmu = () => {
  const [form1] = Form.useForm();
  const DatePicker = generatePicker(dayjsGenerateConfig);

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const xType = [
    { id: 1, name: "โครงการวิจัย" },
    { id: 2, name: "โครงการวิชาการรับใช้สังคม" },
    { id: 3, name: "เงินรายได้" },
    { id: 4, name: "โครงการของนักศึกษาสำนักวิชาฯ" },
    { id: 9, name: "ไม่ระบุ" }
  ];
  const [xTypeValue, _xTypeValue] = useState("0");

  const [dataSource1, _dataSource1] = useState();
  const [dataSourceEx, _dataSourceEx] = useState();

  const [xshow, _xshow] = useState(true);

  const [xsummary, _xsummary] = useState(0);

  const [levelU, _levelU] = useState("");
  const [r_id, _r_id] = useState("");

  const [paginationInfo] = useState({
    current: 1,
    pageSize: 10
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    const xresearch_id = localStorage.getItem("research_id");

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          axios
            .post(xURL + "findlevel", { research_id: xresearch_id })
            .then(result => {
              // console.log(result.data.length);
              if (result.data.length > 0) {
                if (result.data[0].user_level_id == 4) {
                  _r_id(xresearch_id);
                  _levelU("research");
                } else {
                  _levelU("");
                }
              } else {
                _levelU("");
              }
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  });

  const bClear = () => {
    form1.resetFields();

    _dataSource1([]);
    _dataSourceEx([]);

    _xshow(true);
  };

  const bSearch = () => {
    const f = form1.getFieldValue();

    let xid = "";
    if (levelU == "research") {
      xid = r_id;
    } else {
      xid = "";
    }

    if (!f.sedate) {
      swal(
        "แสดงข้อมูล ไม่ได้!",
        "ยังไม่ได้ คีย์ วันเริ่มต้น-วันสิ้นสุด",
        "warning"
      );
    } else {
      let xsdate = "",
        xedate = "";

      xsdate = dayjs(f.sedate[0]).format("YYYY-MM-DD");
      xedate = dayjs(f.sedate[1]).format("YYYY-MM-DD");

      let xtypesearch = 0,
        xtype = "";

      if (xTypeValue !== "0") {
        xtypesearch = 1;
        xtype = xTypeValue;
      }

      axios
        .post(xURL + "find_report_to_cmu", {
          start_date: xsdate,
          end_date: xedate,
          research_id: xid,
          typesearch: xtypesearch,
          type: xtype
        })
        .then(result => {
          if (result.data === "") {
            swal(
              "แสดงข้อมูล ไม่ได้!",
              "ไม่มีข้อมูลตามเงื่อนไขที่เลือก",
              "warning"
            );
          } else {
            _xshow(false);
            Showreport(result.data);
          }
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    }
  };

  const Showreport = zdata => {
    console.log(zdata);

    const Tb1 = [],
      Tb2 = [];
    let xsum = 0;

    let xnum = 0,
      xcmumis_id = "",
      xmoney_to_cmu = 0;

    zdata.forEach((i, idx) => {
      let xsdate = "";
      xsdate = dayjs(i.date_to_cmu).format("DD/MM/BBBB");

      let url_file = "";
      url_file = `${xURL}file5/${i.file_to_cmu}`;

      if (i.cmumis_id !== xcmumis_id) {
        xnum = xnum + 1;
      }

      Tb1.push({
        key: idx,
        no: xnum,
        datetocmu: xsdate,
        cmumis_id: i.cmumis_id,
        name_P: i.name_thai,
        reseatcher_name: i.researchname,
        money_tocmu: i.money_to_cmu.toLocaleString(undefined, {
          minimumFractionDigits: 2
        }),
        tfile: url_file,
        detail: i.detail,
        short_nameP: i.short_name,
        agency: i.agency_name
      });

      Tb2.push({
        datetocmu: xsdate,
        cmumis_id: i.cmumis_id,
        name_P: i.name_thai,
        reseatcher_name: i.researchname,
        money_tocmu: i.money_to_cmu.toLocaleString(undefined, {
          minimumFractionDigits: 2
        }),
        detail: i.detail,
        short_nameP: i.short_name,
        agency: i.agency_name
      });

      if (i.cmumis_id !== xcmumis_id) {
        xsum = xsum + parseFloat(i.money_to_cmu);
      } else {
        if (parseFloat(i.money_to_cmu) !== xmoney_to_cmu) {
          xsum = xsum + parseFloat(i.money_to_cmu);
        }
      }

      xmoney_to_cmu = parseFloat(i.money_to_cmu);
      xcmumis_id = i.cmumis_id;
    });

    _dataSource1(Tb1);
    _dataSourceEx(Tb2);

    _xsummary(
      xsum.toLocaleString(undefined, {
        minimumFractionDigits: 2
      })
    );
  };

  async function bExcel() {
    const f = form1.getFieldValue();

    let xsdate = "",
      xedate = "";

    let xdata = [],
      xtabtitle = "",
      header = [];

    xtabtitle = "รายงานการส่งเงินสมทบ";

    header.push(
      "วันที่ส่งเงินสมทบ",
      "รหัส CMU MIS",
      "ชื่อโครงการ",
      "หัวหน้าโครงการ",
      "จำนวนเงินสมทบ",
      "รายละเอียด",
      "ชื่อย่อโครงการ",
      "แหล่งทุน"
    );

    xdata.push([xtabtitle]);

    xsdate = dayjs(f.sedate[0]).format("YYYY-MM-DD");
    xedate = dayjs(f.sedate[1]).format("YYYY-MM-DD");

    xdata.push(["ตั้งแต่วันที่ " + xsdate + " ถึงวันที่ " + xedate]);

    xdata.push([""]);
    xdata.push(header);

    let excmumis_id = "",
      exdate = "",
      exname_thai = "",
      exresearchname = "",
      exmoney_tocmu = "",
      exshort_name = "",
      exdetail = "";

    let xchk = 0;

    for (var row in dataSourceEx) {
      const xx = [];
      for (var col in dataSourceEx[row]) {
        if (col == "datetocmu") {
          if (exdate == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (
              dataSourceEx[row]["datetocmu"] == exdate &&
              dataSourceEx[row]["cmumis_id"] == excmumis_id
            ) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exdate = dataSourceEx[row]["datetocmu"];
        } else if (col == "cmumis_id") {
          if (excmumis_id == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (
              dataSourceEx[row]["datetocmu"] == exdate &&
              dataSourceEx[row]["cmumis_id"] == excmumis_id &&
              dataSourceEx[row]["detail"] == exdetail
            ) {
              xx.push("");

              xchk = 1;
            } else {
              xx.push(dataSourceEx[row][col]);

              xchk = 0;
            }
          }

          excmumis_id = dataSourceEx[row]["cmumis_id"];
        } else if (col == "name_P") {
          if (exname_thai == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (
              dataSourceEx[row]["name_P"] == exname_thai &&
              dataSourceEx[row]["cmumis_id"] == excmumis_id &&
              dataSourceEx[row]["detail"] == exdetail
            ) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exname_thai = dataSourceEx[row]["name_P"];
        } else if (col == "reseatcher_name") {
          if (exresearchname == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1 && dataSourceEx[row]["detail"] == exdetail) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exresearchname = dataSourceEx[row]["reseatcher_name"];
        } else if (col == "money_tocmu") {
          if (exmoney_tocmu == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1 && dataSourceEx[row]["detail"] == exdetail) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exmoney_tocmu = dataSourceEx[row]["money_tocmu "];
        } else if (col == "detail") {
          if (exmoney_tocmu == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1 && dataSourceEx[row]["detail"] == exdetail) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exdetail = dataSourceEx[row]["detail"];
        } else if (col == "short_nameP") {
          if (exshort_name == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1 && dataSourceEx[row]["detail"] == exdetail) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exshort_name = dataSourceEx[row]["short_nameP"];
        } else {
          xx.push(dataSourceEx[row][col]);
        }
      }

      xdata.push(xx);
    }

    xdata.push(["", "", "", "รวม", xsummary]);

    var wb = xlsx.utils.book_new(),
      ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, "รายงานการส่งเงินสมทบ.xlsx");
  }

  const GoPage = cmumis_id => {
    window.open("/Research_main?cmumis_id=" + cmumis_id, "_blank");
  };

  const columns1 = [
    {
      title: "ที่",
      dataIndex: "no",
      key: "no",
      align: "center",
      width: 50,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "วันที่ส่งเงินสมทบ",
      dataIndex: "datetocmu",
      key: "datetocmu",
      align: "center",
      width: 100,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "รหัส CMU MIS",
      dataIndex: "cmumis_id",
      key: "cmumis_id",
      align: "center",
      width: 120,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                GoPage(text);
              }}
            >
              {text}
            </a>
          ),
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ชื่อโครงการ",
      dataIndex: "name_P",
      key: "name_P",
      width: 550,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                GoPage(record.cmumis_id);
              }}
            >
              {text}
            </a>
          ),
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "หัวหน้าโครงการ",
      dataIndex: "reseatcher_name",
      key: "reseatcher_name",
      align: "center",
      width: 250,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: __TBShowText(text, 0, 1),
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "จำนวนเงินสมทบ",
      dataIndex: "money_tocmu",
      key: "money_tocmu",
      align: "center",
      width: 160,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: __TBShowText(text, 1, 0),
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id &&
          record.money_to_cmu === dataSource1[trueIndex - 1].money_to_cmu &&
          record.detail === dataSource1[trueIndex - 1].detail
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id &&
            record.money_to_cmu === dataSource1[trueIndex + i].money_to_cmu &&
            record.detail === dataSource1[trueIndex + i].detail;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ไฟล์เอกสารเงินสมทบ",
      dataIndex: "tfile",
      key: "tfile",
      align: "center",
      width: 100,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: (
            <a href={record.tfile} target="_blank">
              <PaperClipOutlined /> ไฟล์แนบ
            </a>
          ),
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id &&
          record.money_tocmu === dataSource1[trueIndex - 1].money_tocmu &&
          record.detail === dataSource1[trueIndex - 1].detail
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id &&
            record.money_tocmu === dataSource1[trueIndex + i].money_tocmu &&
            record.detail === dataSource1[trueIndex + i].detail;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "รายละเอียด",
      dataIndex: "detail",
      key: "detail",
      align: "center",
      width: 250,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: __TBShowText(text, 0, 1),
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id &&
          record.money_to_cmu === dataSource1[trueIndex - 1].money_to_cmu &&
          record.detail === dataSource1[trueIndex - 1].detail
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id &&
            record.money_to_cmu === dataSource1[trueIndex + i].money_to_cmu &&
            record.detail === dataSource1[trueIndex + i].detail;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ชื่อย่อโครงการ",
      dataIndex: "short_nameP",
      key: "short_nameP",
      align: "center",
      width: 250,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: __TBShowText(text, 0, 1),
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "แหล่งทุน",
      dataIndex: "agency",
      key: "agency",
      width: 300
    }
  ];

  function handleChange(value) {
    //console.log(`selected ${value}`);

    _xTypeValue(value);
  }

  //formatttable (Rigth or Left)
  function __TBShowText(zData, zRigth, zLeft) {
    if (zRigth === 1 && zLeft === 0) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    } else if (zRigth === 0 && zLeft === 1) {
      return (
        <div style={{ display: "flex" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <StyledForm />
      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={23}>
            <PageHeader
              className="site-page-header"
              title="รายงานการส่งเงินสมทบ"
            />
            <Divider />
          </Col>
        </Row>

        <Row justify="center">
          <Col span={23}>
            <Card
              style={{
                borderRadius: "15px",
                overflow: "hidden"
              }}
            >
              <Form form={form1} initialValues={{}} {...layout}>
                <ConfigProvider locale={configuredLocale}>
                  <Row justify="center">
                    <Col span={8}>
                      <Form.Item
                        label="วันเริ่มต้น-วันสิ้นสุด"
                        name="sedate"
                        rules={[
                          {
                            required: true
                          }
                        ]}
                      >
                        <DatePicker.RangePicker
                          autoFocus
                          format="DD/MM/BBBB"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </ConfigProvider>

                <Row justify="center">
                  <Col span={8}>
                    <Form.Item label="ประเภทโครงการ" name="sltype">
                      <Select
                        defaultValue="ประเภททั้งหมด"
                        style={{ width: "100%" }}
                        onChange={handleChange}
                      >
                        {xType.length > 0
                          ? xType.map((i, idx) => {
                              return (
                                <Option value={i.id}>
                                  {i.id} : {i.name}
                                </Option>
                              );
                            })
                          : <Option value="0">ประเภททั้งหมด</Option>}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

              <br />

              <Row justify="center">
                <Space>
                  <Button ghost type="primary" onClick={bSearch}>
                    <SearchOutlined /> แสดงข้อมูล
                  </Button>

                  <Button ghost type="danger" onClick={bClear}>
                    <ClearOutlined /> เคลียร์
                  </Button>

                  <Button
                    ghost
                    type="success"
                    onClick={bExcel}
                    disabled={xshow}
                  >
                    <FileExcelOutlined /> Excel
                  </Button>
                </Space>
              </Row>
            </Card>
          </Col>
        </Row>

        <br />
        <br />

        <Row justify="center" style={{ marginBottom: 10 }}>
          <Col span={23}>
            <Table
              bordered
              scroll={{ x: "max-content" }}
              dataSource={dataSource1}
              columns={columns1}
              size="small"
              pagination={false}
              summary={() =>
                <Table.Summary fixed>
                  <Table.Summary.Row
                    style={{
                      textAlign: "right",
                      backgroundColor: "#b5f5ec"
                    }}
                  >
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                    <Table.Summary.Cell align="center">
                      รวมเงินโอน
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {xsummary}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                  </Table.Summary.Row>
                </Table.Summary>}
            />
          </Col>
        </Row>
      </Content>

      <div style={{ height: "80px" }} />

      <Footer_last />
    </React.Fragment>
  );
};

export default Research_report_to_cmu;
