import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import axios from "axios";
import { PaperClipOutlined, EditOutlined } from "@ant-design/icons";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import {
  Layout,
  Row,
  Col,
  Divider,
  PageHeader,
  Table,
  Typography,
  Button
} from "antd";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";

const { Content } = Layout;
const { Text } = Typography;

dayjs.locale("th");
dayjs.extend(buddhistEra);

const Research_show_transfer = () => {
  const [xcmumis_id, _xcmumis_id] = useState();
  const [xterm, _xterm] = useState();
  const [xamount, _xamount] = useState();
  const [xdetail, _xdetail] = useState();
  const [xdetail2, _xdetail2] = useState();

  const [dataSource1, _dataSource1] = useState();
  const [dataSource2, _dataSource2] = useState();

  const [xSumAmount, _xSumAmount] = useState(0);
  const [xSumAmount2, _xSumAmount2] = useState(0);

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");
          var url = new URL(window.location.href);

          _xcmumis_id(url.searchParams.get("cmumis_id"));
          _xterm(url.searchParams.get("term"));
          _xamount(url.searchParams.get("amount"));
          _xdetail(url.searchParams.get("detail"));
          _xdetail2(url.searchParams.get("detail").replace("#", "%23"));

          getInvoice(
            url.searchParams.get("cmumis_id"),
            url.searchParams.get("amount"),
            url.searchParams.get("detail")
          );

          getTransfer(
            url.searchParams.get("cmumis_id"),
            url.searchParams.get("amount"),
            url.searchParams.get("detail")
          );
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const getInvoice = (id, amount, detail) => {
    axios
      .post(xURL + "find_invoice_transfer", {
        cmumis_id: id,
        amount: parseFloat(amount.replace(",", "").replace(",", "")),
        detail: detail.substring(0, 15),
        order: 1
      })
      .then(result => {
        const Tb1 = [];

        result.data.forEach((i, idx) => {
          let xsdate = "",
            xedate = "";

          xsdate = dayjs(i.start_date).format("DD/MM/BBBB");
          xedate = dayjs(i.end_date).format("DD/MM/BBBB");

          let url_file = "";

          url_file = `${xURL}file3/${i.file_invoice}`;

          if (i.expenses) {
            Tb1.push({
              key: idx,
              no: idx + 1,
              period: xsdate + " - " + xedate,
              expenses: i.expenses.toLocaleString(),
              exchange: i.exchange_rate,
              invoice_us: i.money_invoice.toLocaleString(),
              fa: i.overhead_usd.toLocaleString(),
              over70: i.overhead70.toLocaleString(),
              over30: i.overhead30.toLocaleString(),
              total: i.total_usd.toLocaleString(),
              linkfile: url_file
            });

            _dataSource1(Tb1);
          }
        });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const getTransfer = (id, amount, detail) => {
    axios
      .post(xURL + "find_invoice_transfer", {
        cmumis_id: id,
        amount: parseFloat(amount.replace(",", "").replace(",", "")),
        detail: detail.substring(0, 15),
        order: 2
      })
      .then(result => {
        const Tb1 = [];

        let xsum = 0,
          xsum2 = 0;

        result.data.forEach((i, idx) => {
          let xsdate = "";

          xsdate = dayjs(i.date_receive).format("DD/MM/BBBB");

          let url_file = "";

          url_file = `${xURL}file4/${i.file_transfer}`;

          if (i.total_receive) {
            Tb1.push({
              key: idx,
              no: idx + 1,
              dr_money: xsdate,
              money_receive: i.money_receive.toLocaleString(undefined, {
                minimumFractionDigits: 2
              }),
              exchange: i.exchange_receive.toLocaleString(undefined, {
                minimumFractionDigits: 4
              }),
              total_receive: i.total_receive.toLocaleString(undefined, {
                minimumFractionDigits: 2
              }),
              linkfile: url_file
            });

            xsum = xsum + parseFloat(i.money_receive);
            xsum2 = xsum2 + parseFloat(i.total_receive);

            _dataSource2(Tb1);
          }
        });

        _dataSource2(Tb1);
        _xSumAmount(xsum);
        _xSumAmount2(xsum2);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };
  const columns1 = [
    {
      title: "invoice no",
      dataIndex: "no",
      key: "no",
      align: "center"
    },
    {
      title: "invoice period",
      dataIndex: "period",
      key: "period",
      align: "center"
    },
    {
      title: "Expenses (Baht)",
      dataIndex: "expenses",
      key: "expenses",
      align: "center"
    },
    {
      title: "Exchange Rate",
      dataIndex: "exchange",
      key: "exchange",
      align: "center"
    },
    {
      title: "Money Invoice",
      dataIndex: "invoice_us",
      key: "invoice_us",
      align: "center"
    },
    {
      title: "F&A",
      dataIndex: "fa",
      key: "fa",
      align: "center"
    },
    {
      title: "Overhead 70 %",
      dataIndex: "over70",
      key: "over70",
      align: "center"
    },
    {
      title: "Overhead 30 %",
      dataIndex: "over30",
      key: "over30",
      align: "center"
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "center"
    },
    {
      title: "file",
      dataIndex: "tfile",
      key: "tfile",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a href={record.linkfile} target="_blank">
              <PaperClipOutlined /> ไฟล์แนบ
            </a>
          )
        };
      },
      fixed: "right"
    }
  ];
  const columns2 = [
    {
      title: "Date Receive Money",
      dataIndex: "dr_money",
      key: "dr_money",
      align: "center"
    },
    {
      title: "Money Receive",
      dataIndex: "money_receive",
      key: "money_receive",
      align: "center",
      render: (text, record) => __TBShowText(record.money_receive, 1, 0)
    },
    {
      title: "Exchange Rate",
      dataIndex: "exchange",
      key: "exchange",
      align: "center"
    },
    {
      title: "Total (Baht) Receive",
      dataIndex: "total_receive",
      key: "total_receive",
      align: "center",
      render: (text, record) => __TBShowText(record.total_receive, 1, 0)
    },
    {
      title: "file",
      dataIndex: "tfile",
      key: "tfile",
      align: "center",

      render(text, record, index) {
        return {
          children: (
            <a href={record.linkfile} target="_blank">
              <PaperClipOutlined /> ไฟล์แนบ
            </a>
          )
        };
      },
      fixed: "right"
    }
  ];

  //formatttable (Rigth or Left)
  function __TBShowText(zData, zRigth, zLeft) {
    if (zRigth === 1 && zLeft === 0) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    } else if (zRigth === 0 && zLeft === 1) {
      return (
        <div style={{ display: "flex" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col span={23}>
            <PageHeader
              className="site-page-header"
              title={
                <a
                  style={{
                    color: "black"
                  }}
                  onClick={() => {
                    window.close();
                  }}
                >
                  แสดงใบเรียกเก็บเงิน (Invoice) และ รับเงินโอน (Money Transfer)
                </a>
              }
              onBack={() => window.close()}
            />
            <h3>
              รหัส CMU MIS : {xcmumis_id}
            </h3>
            <h3>
              งวดที่ : {xterm} จำนวนเงิน {xamount} บาท
            </h3>
            <h3>
              รายละเอียด : {xdetail2}
            </h3>
            <Divider />
          </Col>
        </Row>

        <Row justify="center">
          <Col span={19}>
            <Row justify="center" style={{ marginBottom: 10, float: "right" }}>
              <Col span={23}>
                <Button
                  type="primary"
                  onClick={() => {
                    window.open(
                      "/Research_transfer?cmumis_id=" +
                        xcmumis_id +
                        "&term=" +
                        xterm +
                        "&amount=" +
                        xamount +
                        "&detail=" +
                        xdetail2,
                      "_blank"
                    );
                  }}
                >
                  <EditOutlined /> แก้ไขข้อมูล
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row justify="center">
          <Col span={20}>
            <Row justify="center" style={{ marginBottom: 10 }}>
              <Col span={23}>
                <Table
                  title={() => "INVOICE"}
                  bordered
                  scroll={{ x: "max-content" }}
                  dataSource={dataSource1}
                  columns={columns1}
                  size="small"
                  pagination={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <br />
        <Row justify="center">
          <Col span={20}>
            <Row justify="center" style={{ marginBottom: 10 }}>
              <Col span={23}>
                <Table
                  title={() => "Money Transfer"}
                  bordered
                  scroll={{ x: "max-content" }}
                  dataSource={dataSource2}
                  columns={columns2}
                  size="small"
                  pagination={false}
                  summary={() =>
                    <Table.Summary fixed>
                      <Table.Summary.Row
                        style={{
                          textAlign: "right",
                          backgroundColor: "#b5f5ec"
                        }}
                      >
                        <Table.Summary.Cell>
                          รวม Money Receive
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          {xSumAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2
                          })}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          รวม Total Receive
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          {xSumAmount2.toLocaleString(undefined, {
                            minimumFractionDigits: 2
                          })}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell />
                      </Table.Summary.Row>
                    </Table.Summary>}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
      <br />

      <Footer_last />
    </React.Fragment>
  );
};
export default Research_show_transfer;
