import "antd/dist/antd.min.css";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { createGlobalStyle } from "styled-components";

import {
  SaveOutlined,
  ClearOutlined,
  PaperClipOutlined,
  DeleteOutlined
} from "@ant-design/icons";

import moment from "moment";
import "moment/locale/th";

import {
  Row,
  Col,
  Form,
  Layout,
  Card,
  Typography,
  Modal,
  Input,
  InputNumber,
  Space,
  Button,
  Table,
  PageHeader,
  Divider,
  ConfigProvider
} from "antd";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import swal from "sweetalert";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import thTH from "antd/lib/locale/th_TH";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

const { Content } = Layout;
const { Text } = Typography;

const StyledForm = createGlobalStyle`.ant-form-item {
    margin-bottom: 5px;
  }
  `;

const configuredLocale = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    dateFormat: "DD MM BBBB", // DD MM BBBB
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker.lang,
      dateFormat: "DD MM BBBB", // DD MM BBBB
      dateTimeFormat: "DD MM BBBB HH:mm:ss",
      yearFormat: "BBBB"
    }
  }
};

dayjs.locale("th");
dayjs.extend(buddhistEra);

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 }
};

const layout2 = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 }
};

const Research_input_to_cmu = () => {
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [xcmumis_id, _xcmumis_id] = useState("");
  const [xnum, _xnum] = useState("");
  const [xdetail, _xdetail] = useState("");
  const [xdetail2, _xdetail2] = useState("");

  const DatePicker = generatePicker(dayjsGenerateConfig);

  const [xMoney, _xMoney] = useState(0);
  const [ListFile, _ListFile] = useState([]);

  const [pageTable, _pageTable] = useState(1);
  const [pageSizeTable, _pageSizeTable] = useState(5);

  const [dataTocmu, _dataTocmu] = useState();

  const [xBtype, _xBtype] = useState(0);
  const [xdt, _xdt] = useState("");
  const [xdt2, _xdt2] = useState("");

  const txtmoney = useRef("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    //console.log(token);

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");
          var url = new URL(window.location.href);

          form1.setFieldsValue({
            date_to_cmu: "",
            edmoneytc: ""
          });

          _xcmumis_id(url.searchParams.get("cmumis_id"));
          _xnum(url.searchParams.get("num"));
          _xBtype(url.searchParams.get("type"));
          _xdt(url.searchParams.get("detail"));
          _xdt2(url.searchParams.get("detail2"));

          if (url.searchParams.get("detail2") == "") {
            _xdetail(url.searchParams.get("detail"));

            GetToCmu(
              url.searchParams.get("cmumis_id"),
              url.searchParams.get("detail")
            );
          } else {
            _xdetail(
              url.searchParams.get("detail") +
                "#" +
                url.searchParams.get("detail2")
            );

            GetToCmu(
              url.searchParams.get("cmumis_id"),
              url.searchParams.get("detail") +
                "#" +
                url.searchParams.get("detail2")
            );
          }

          //   GetTable(url.searchParams.get("cmumis_id"));
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const GetToCmu = (cmumis_id, detail) => {
    axios
      .post(xURL + "find_to_cmu2", {
        cmumis_id: cmumis_id,
        detail: detail
      })
      .then(result => {
        const Tb1 = [];

        let xdate_to_cmu2 = "",
          xmoney_to_cmu2 = 0;

        if (result.data[0].date_to_cmu == null) {
          xdate_to_cmu2 = "";
        } else {
          xdate_to_cmu2 = dayjs(result.data[0].date_to_cmu);
        }

        if (
          result.data[0].date_to_cmu &&
          result.data[0].date_to_cmu != "0000-00-00"
        ) {
          xdate_to_cmu2 = dayjs(result.data[0].date_to_cmu);
        } else {
          xdate_to_cmu2 = "";
        }

        if (result.data[0].money_to_cmu != null) {
          xmoney_to_cmu2 = result.data[0].money_to_cmu;
        } else {
          xmoney_to_cmu2 = null;
        }

        form1.setFieldsValue({
          date_to_cmu: xdate_to_cmu2,
          edmoneytc: xmoney_to_cmu2
        });
        _xMoney(xmoney_to_cmu2);

        result.data.forEach((i, idx) => {
          let xdate_receive = "",
            xtotal_receive = "",
            xdirect = "",
            xdate_to_cmu = "",
            xmoney_to_cmu = "";

          if (i.total_receive != null) {
            xtotal_receive = i.total_receive.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.date_receive && i.date_receive != "0000-00-00") {
            xdate_receive = dayjs(i.date_receive).format("DD/MM/BBBB");
          }

          if (i.direct != null) {
            xdirect = i.direct.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.date_to_cmu && i.date_to_cmu != "0000-00-00") {
            xdate_to_cmu = dayjs(i.date_to_cmu).format("DD/MM/BBBB");
          }

          if (i.money_to_cmu != null) {
            xmoney_to_cmu = i.money_to_cmu.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          let url_file_to_cmu = "",
            url_file_invoice = "",
            url_file_transfer = "";

          if (i.file_to_cmu) {
            if (i.file_to_cmu !== "undefined") {
              url_file_to_cmu = `${xURL}file5/${i.file_to_cmu}`;
            } else {
              url_file_to_cmu = "";
            }
          }

          if (i.file_invoice) {
            if (i.file_invoice !== "undefined") {
              url_file_invoice = `${xURL}file3/${i.file_invoice}`;
            } else {
              url_file_invoice = "";
            }
          }

          if (i.file_transfer) {
            if (i.file_transfer !== "undefined") {
              url_file_transfer = `${xURL}file4/${i.file_transfer}`;
            } else {
              url_file_transfer = "";
            }
          }

          Tb1.push({
            key: idx,
            num: idx + 1,
            detail: i.detail,
            date_receive: xdate_receive,
            total_receive: xtotal_receive,
            direct: xdirect,
            f_a: i.fa,
            overhead70: i.overhead70,
            overhead30: i.overhead30,
            date_to_cmu: xdate_to_cmu,
            money_to_cmu: xmoney_to_cmu,
            file_to_cmu: url_file_to_cmu,
            fileinvoice: url_file_invoice,
            filetransfer: url_file_transfer
          });
        });

        _dataTocmu(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bClearclick = () => {
    form1.resetFields();

    _xMoney(0);
    _ListFile([]);
  };

  const bSaveclick = () => {
    const f = form1.getFieldValue();

    if (!f.date_to_cmu) {
      swal(
        "บันทึก ไม่ได้!",
        "ยังไม่ได้คีย์ วันที่นำส่งเงินสมทบ มช.!",
        "warning"
      );
    } else if (xMoney === 0) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ จำนวนเงินที่นำส่ง!", "warning");
    } else if (ListFile == "" || typeof ListFile == "undefined") {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้เลือก ไฟล์นำส่ง มช.!", "warning");
    } else {
      swal({
        title: "ยืนยันบันทึก เงินสมทบ",
        text: "",
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          //console.log(willOk);
          savedata();
        }
      });
    }
  };

  const bEditclick = () => {
    const f = form1.getFieldValue();

    // if (!f.date_to_cmu) {
    //   swal(
    //     "แก้ไข ไม่ได้!",
    //     "ยังไม่ได้คีย์ วันที่นำส่งเงินสมทบ มช.!",
    //     "warning"
    //   );
    // } else if (xMoney === 0) {
    //   swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ จำนวนเงินที่นำส่ง!", "warning");
    // } else if (ListFile == "" || typeof ListFile == "undefined") {
    //   swal("แก้ไข ไม่ได้!", "ยังไม่ได้เลือก ไฟล์นำส่ง มช.!", "warning");
    // } else {
    swal({
      title: "ยืนยันแก้ไข เงินสมทบ",
      text: "",
      icon: "warning",
      buttons: ["ยกเลิก", "ยืนยัน"]
    }).then(willOk => {
      if (willOk) {
        //console.log(willOk);
        savedata2();
      }
    });
    // }
  };

  const savedata = () => {
    const f = form1.getFieldValue();

    const fd = new FormData();
    fd.append("file", ListFile);

    axios.post(xURL + "uploadfile5", fd).then(res => {
      //Now do what you want with the response;

      axios
        .post(xURL + "updateTocmu", {
          cmumis_id: xcmumis_id,
          f_name: res.data.filename,
          date_to_cmu: dayjs(f.date_to_cmu).format("YYYY-MM-DD"),
          money_to_cmu: xMoney,
          detail: xdetail
        })
        .then(result => {
          // window.location.reload();

          window.open(
            "/Research_input_to_cmu?cmumis_id=" +
              xcmumis_id +
              "&num=" +
              xnum +
              "&detail=" +
              xdt +
              "&detail2=" +
              xdt2 +
              "&type=0",
            "_self"
          );
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    });
  };

  const savedata2 = () => {
    const f = form1.getFieldValue();

    let xMoney2 = xMoney,
      xDate = dayjs(f.date_to_cmu).format("YYYY-MM-DD");

    if (xMoney2 == 0 || xMoney2 == undefined) {
      xMoney2 = null;
    }

    if (!f.date_to_cmu) {
      xDate = null;
    }

    if (ListFile == "" || typeof ListFile == "undefined") {
      axios
        .post(xURL + "updateTocmu", {
          cmumis_id: xcmumis_id,
          f_name: "none_pic",
          date_to_cmu: xDate,
          money_to_cmu: xMoney2,
          detail: xdetail
        })
        .then(result => {
          // window.location.reload();

          window.open(
            "/Research_input_to_cmu?cmumis_id=" +
              xcmumis_id +
              "&num=" +
              xnum +
              "&detail=" +
              xdt +
              "&detail2=" +
              xdt2 +
              "&type=0",
            "_self"
          );
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    } else {
      const fd = new FormData();
      fd.append("file", ListFile);

      axios.post(xURL + "uploadfile5", fd).then(res => {
        //Now do what you want with the response;

        axios
          .post(xURL + "updateTocmu", {
            cmumis_id: xcmumis_id,
            f_name: res.data.filename,
            date_to_cmu: xDate,
            money_to_cmu: xMoney2,
            detail: xdetail
          })
          .then(result => {
            // window.location.reload();

            window.open(
              "/Research_input_to_cmu?cmumis_id=" +
                xcmumis_id +
                "&num=" +
                xnum +
                "&detail=" +
                xdt +
                "&detail2=" +
                xdt2 +
                "&type=0",
              "_self"
            );
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      });
    }
  };

  const deleteFile = zdata => {
    // console.log(zdata);
    swal({
      title: "ยืนยันลบ ไฟล์นำส่ง มช.",
      text: "",
      icon: "warning",
      buttons: ["ยกเลิก", "ยืนยัน"]
    }).then(willOk => {
      if (willOk) {
        //console.log(willOk);

        axios
          .post(xURL + "delete_pic_tocmu", {
            cmumis_id: xcmumis_id,
            f_name: "",
            detail: xdetail
          })
          .then(result => {
            // window.location.reload();

            window.open(
              "/Research_input_to_cmu?cmumis_id=" +
                xcmumis_id +
                "&num=" +
                xnum +
                "&detail=" +
                xdt +
                "&detail2=" +
                xdt2 +
                "&type=0",
              "_self"
            );
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      }
    });
  };

  const cTocmu = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 40
    },
    {
      title: "รายละเอียด",
      dataIndex: "detail",
      key: "detail",
      width: 450
    },
    {
      title: "วันที่รับเงิน",
      dataIndex: "date_receive",
      key: "date_receive",
      align: "center"
    },
    {
      title: "เงินที่ได้รับ(บาท)",
      dataIndex: "total_receive",
      key: "total_receive",
      align: "center",
      render: (text, record) => __TBShowText(record.total_receive, 1, 0)
    },
    {
      title: "Direct",
      dataIndex: "direct",
      key: "direct",
      align: "center",
      render: (text, record) => __TBShowText(record.direct, 1, 0)
    },
    {
      title: "F&A",
      dataIndex: "f_a",
      key: "f_a",
      align: "center",
      render: (text, record) => __TBShowText(record.f_a, 1, 0)
    },
    {
      title: "Overhead 70%",
      dataIndex: "overhead70",
      key: "overhead70",
      align: "center",
      render: (text, record) => __TBShowText(record.overhead70, 1, 0)
    },
    {
      title: "Overhead 30%",
      dataIndex: "overhead30",
      key: "overhead30",
      align: "center",
      render: (text, record) => __TBShowText(record.overhead30, 1, 0)
    },
    {
      title: "วันที่นำส่งเงินสมทบ มช.",
      dataIndex: "date_to_cmu",
      key: "date_to_cmu",
      align: "center"
    },
    {
      title: "จำนวนเงินที่นำส่ง",
      dataIndex: "money_to_cmu",
      key: "money_to_cmu",
      align: "center",
      render: (text, record) => __TBShowText(record.money_to_cmu, 1, 0)
    },
    {
      title: "ไฟล์นำส่ง มช.",
      dataIndex: "file_to_cmu",
      key: "file_to_cmu",
      align: "center",
      width: 150,
      render(text, record, index) {
        if (record.file_to_cmu) {
          return {
            children: (
              <Row>
                <Space>
                  <a href={record.file_to_cmu} target="_blank">
                    <PaperClipOutlined /> ไฟล์แนบ
                  </a>

                  <Button
                    type="danger"
                    icon={<DeleteOutlined />}
                    size="small"
                    onClick={() => deleteFile(record)}
                  />
                </Space>
              </Row>
            )
          };
        }
      }
    },
    {
      title: "ไฟล์ invoice",
      dataIndex: "fileinvoice",
      key: "fileinvoice",
      align: "center",
      width: 130,
      render(text, record, index) {
        if (record.fileinvoice) {
          return {
            children: (
              <a href={record.fileinvoice} target="_blank">
                <PaperClipOutlined /> ไฟล์แนบ
              </a>
            )
          };
        }
      }
    },
    {
      title: "ไฟล์ใบโอน",
      dataIndex: "filetransfer",
      key: "filetransfer",
      align: "center",
      width: 130,
      render(text, record, index) {
        if (record.filetransfer) {
          return {
            children: (
              <a href={record.filetransfer} target="_blank">
                <PaperClipOutlined /> ไฟล์แนบ
              </a>
            )
          };
        }
      }
    }
  ];

  const onChangeedmoneytc = e => {
    if (e == null) {
      _xMoney();
    } else {
      _xMoney(e);
    }
  };

  const onChangeU = e => {
    //console.log(e, e.target.files[0]);

    _ListFile(e.target.files[0]);
  };

  const pageOnChange = (zpage, zpageSize) => {
    _pageTable(zpage);
    _pageSizeTable(zpageSize);
  };

  //formatttable (Rigth or Left)
  function __TBShowText(zData, zRigth, zLeft) {
    if (zRigth === 1 && zLeft === 0) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    } else if (zRigth === 0 && zLeft === 1) {
      return (
        <div style={{ display: "flex" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <StyledForm />

      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col span={23}>
            <PageHeader
              className="site-page-header"
              title={
                <a
                  style={{
                    color: "black"
                  }}
                  onClick={() => {
                    window.open(
                      "/Research_to_cmu?cmumis_id=" + xcmumis_id,
                      "_self"
                    );
                  }}
                >
                  เพิ่ม / แก้ไข การส่งเงินสมทบ
                </a>
              }
              onBack={() => {
                window.open(
                  "/Research_to_cmu?cmumis_id=" + xcmumis_id,
                  "_self"
                );
              }}
            />

            <Divider />

            <Card title="">
              <Form form={form2} {...layout}>
                <Form.Item
                  label={
                    <Text style={{ fontWeight: "bold" }}>รหัส CMU MIS</Text>
                  }
                >
                  {xcmumis_id}
                </Form.Item>

                <Form.Item
                  label={<Text style={{ fontWeight: "bold" }}>รายการที่</Text>}
                >
                  {xnum}
                </Form.Item>

                <Form.Item
                  label={<Text style={{ fontWeight: "bold" }}>รายละเอียด</Text>}
                >
                  {xdetail}
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>

        <br />

        <Row justify="center">
          <Col span={23}>
            <Card title="รายละเอียดการส่งเงินสมทบ">
              <Form
                form={form1}
                {...layout2}
                initialValues={{
                  date_to_cmu: "",
                  edmoneytc: "",
                  edfile_to_cmu: ""
                }}
              >
                <ConfigProvider locale={configuredLocale}>
                  <Form.Item
                    label="วันที่นำส่งเงินสมทบ มช."
                    name="date_to_cmu"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "กรุณากรอก วันที่นำส่งเงินสมทบ มช."
                    //   }
                    // ]}
                  >
                    <DatePicker
                      format="DD/MM/BBBB"
                      style={{ width: "40%" }}
                      onChange={e => {
                        if ((e.length = 10)) {
                          txtmoney.current.focus();
                        }
                      }}
                    />
                  </Form.Item>
                </ConfigProvider>

                <Form.Item label="จำนวนเงินที่นำส่ง" name="edmoneytc">
                  <InputNumber
                    //disabled={Export}
                    // min="0"
                    max="9999999999"
                    maxLength="12"
                    onChange={e => onChangeedmoneytc(e)}
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                    style={{ width: "40%" }}
                    ref={txtmoney}
                    // onPressEnter={() => {
                    //   txtfile.current.focus();
                    // }}
                  />
                </Form.Item>

                <Form.Item
                  label="ไฟล์ นำส่ง มช."
                  name="edfile_to_cmu"
                  // rules={[
                  //   {
                  //     required: true
                  //   }
                  // ]}
                >
                  <Input
                    className="form-control"
                    id="drop-area2"
                    type="file"
                    onChange={e => onChangeU(e)}
                  />
                </Form.Item>

                {xBtype == 1
                  ? <div />
                  : <Form.Item label="*" name="">
                      <p style={{ marginTop: "5px", color: "red" }}>
                        หากเคยบันทึกไฟล์แล้ว ต้องการเปลี่ยนไฟล์ให้เลือกไฟล์ใหม่
                        หากไม่ได้เลือกใหม่ ไฟล์จะไม่เปลี่ยนแปลง
                      </p>
                    </Form.Item>}

                <br />

                <Row justify="center">
                  <Space>
                    {xBtype == 1
                      ? <Button ghost type="primary" onClick={bSaveclick}>
                          <SaveOutlined /> บันทึก
                        </Button>
                      : <Button ghost type="primary" onClick={bEditclick}>
                          <SaveOutlined /> แก้ไข
                        </Button>}

                    <Button ghost type="danger" onClick={bClearclick}>
                      <ClearOutlined /> เคลียร์
                    </Button>
                  </Space>
                </Row>
              </Form>
            </Card>

            <br />
            <Table
              bordered
              scroll={{ x: "max-content" }}
              dataSource={dataTocmu}
              columns={cTocmu}
              size="small"
              pagination={{
                onChange: (zpage, zpageSize) => {
                  pageOnChange(zpage, zpageSize);
                },
                current: pageTable,
                pageSize: pageSizeTable
              }}
            />
          </Col>
        </Row>
      </Content>

      <br />
      <br />
      <br />

      <Footer_last />
    </React.Fragment>
  );
};

export default Research_input_to_cmu;
