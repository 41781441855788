import React, { useEffect, useState } from "react";
import "antd/dist/antd.min.css";
import axios from "axios";
import { createGlobalStyle } from "styled-components";
import {
  SearchOutlined,
  ClearOutlined,
  FileExcelOutlined
} from "@ant-design/icons";

import {
  Layout,
  Row,
  Col,
  PageHeader,
  Divider,
  Card,
  Form,
  Space,
  Button,
  Table,
  Typography,
  Select
} from "antd";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import * as xlsx from "xlsx";

const { Content } = Layout;
const { Text } = Typography;
const { Option } = Select;

const StyledForm = createGlobalStyle`.ant-form-item {
    margin-bottom: 5px;
  }
  `;

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 }
};

const Research_report2 = () => {
  const [form1] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [dataSource1, _dataSource1] = useState();
  const [dataSourceEx1, _dataSourceEx1] = useState();

  const [xAgency, _xAgency] = useState("");
  const [xAgencyValue, _xAgencyValue] = useState("0");
  const [xAgencyName, _xAgencyName] = useState("");

  const [xType1, _xType1] = useState("");
  const [xType1Value, _xType1Value] = useState("0");
  const [xType1Name, _xType1Name] = useState("");

  const [xType2, _xType2] = useState("");
  const [xType2Value, _xType2Value] = useState("0");
  const [xType2Name, _xType2Name] = useState("");

  const [xType3, _xType3] = useState("");
  const [xType3Value, _xType3Value] = useState("0");
  const [xType3Name, _xType3Name] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");

          //ค้นหาแหล่งทุน
          axios
            .get(xURL + "agency")
            .then(result => {
              //console.log(result.data);

              _xAgency(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหาประเภทแหล่งทุน
          axios
            .get(xURL + "agency_type1")
            .then(result => {
              //console.log(result.data);

              _xType1(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหาสังกัด
          axios
            .get(xURL + "agency_type3")
            .then(result => {
              //console.log(result.data);

              _xType2(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหาภายใน/ภายนอก
          axios
            .get(xURL + "agency_type2")
            .then(result => {
              //console.log(result.data);

              _xType3(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          bSearch("0", "0", "0", "0");
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const bSearch = (Agency, Type1, Type2, Type3) => {
    // console.log(xAgencyValue, xType1Value, xType2Value, xType3Value);

    // console.log(1);
    axios
      .post(xURL + "find_report2", {
        agency: Agency,
        type1: Type1,
        type2: Type2,
        type3: Type3
      })
      .then(result => {
        let Tb1 = [],
          Tb2 = [];

        result.data.forEach((i, idx) => {
          Tb1.push({
            key: idx,
            no: parseInt(idx) + 1,
            agency: i.agency,
            type1: i.type1,
            type2: i.type2,
            type3: i.type3,
            agency_id: i.agency_id
          });

          Tb2.push({
            no: parseInt(idx) + 1,
            agency: i.agency,
            type1: i.type1,
            type2: i.type2,
            type3: i.type3
          });
        });

        _dataSource1(Tb1);
        _dataSourceEx1(Tb2);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bClear = () => {
    form1.resetFields();

    _xAgencyValue("0");
    _xType1Value("0");
    _xType2Value("0");
    _xType3Value("0");

    bSearch("0", "0", "0", "0");
  };

  const bExcel = () => {
    let xdata = [],
      xtabtitle = "",
      xtabtitle2 = "",
      header = [];

    xtabtitle = "รายงานแสดงแหล่งทุน";

    header.push(
      "ที่",
      "แหล่งทุน",
      "ประเภทแหล่งทุน (ในประเทศ/นอกประเทศ)",
      "สังกัด (ภาครัฐ/ภาคเอกชน)",
      "ภายใน/ภายนอก มหาวิทยาลัยเชียงใหม่"
    );

    xdata.push([xtabtitle]);

    if (xAgencyValue != "0") {
      xtabtitle2 = "แหล่งทุน " + xAgencyName;
    } else {
      xtabtitle2 = "แหล่งทุนทั้งหมด ";
    }

    if (xType1Value != "0") {
      xtabtitle2 = xtabtitle2 + " ,ประเภทแหล่งทุน " + xType1Name;
    } else {
      xtabtitle2 = xtabtitle2 + " ,ประเภทแหล่งทุนทั้งหมด";
    }

    if (xType2Value != "0") {
      xtabtitle2 = xtabtitle2 + " ,สังกัด " + xType2Name;
    } else {
      xtabtitle2 = xtabtitle2 + " ,สังกัดทั้งหมด";
    }

    if (xType3Value != "0") {
      xtabtitle2 = xtabtitle2 + " ," + xType3Name;
    }

    xdata.push([xtabtitle2]);
    xdata.push([""]);
    xdata.push(header);

    for (var row in dataSourceEx1) {
      const xx = [];
      for (var col in dataSourceEx1[row]) {
        xx.push(dataSourceEx1[row][col]);
      }

      xdata.push(xx);
    }

    var wb = xlsx.utils.book_new(),
      ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, "รายงานแสดงแหล่งทุน.xlsx");
  };

  const columns1 = [
    {
      title: "ที่",
      dataIndex: "no",
      key: "no",
      width: 50,
      align: "center",
      render: (text, record, index) => {
        return index + 1;
      }
    },
    {
      title: "แหล่งทุน",
      dataIndex: "agency",
      key: "agency",
      width: 400,
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                // window.open(
                //   "/Research_main?agency_id=" + record.agency_id,
                //   "_blank"
                // );
                GoPage(record.agency_id);
              }}
            >
              {__TBShowText(text, 0, 1)}
            </a>
          )
        };
      },
      sorter: (a, b) => {
        return a.agency.localeCompare(b.agency);
      }
    },
    {
      title: "ประเภทแหล่งทุน (ในประเทศ/นอกประเทศ)",
      dataIndex: "type1",
      key: "type1",
      width: 200,
      align: "center",
      sorter: (a, b) => {
        return a.type1.localeCompare(b.type1);
      }
    },
    {
      title: "สังกัด (ภาครัฐ/ภาคเอกชน)",
      dataIndex: "type2",
      key: "type2",
      width: 200,
      align: "center",
      sorter: (a, b) => {
        return a.type2.localeCompare(b.type2);
      }
    },
    {
      title: "ภายใน/ภายนอก มหาวิทยาลัยเชียงใหม่",
      dataIndex: "type3",
      key: "type3",
      width: 200,
      align: "center",
      sorter: (a, b) => {
        return a.type3.localeCompare(b.type3);
      }
    },
    {
      title: "",
      dataIndex: "agency_id",
      key: "agency_id",
      hidden: true
    }
  ].filter(item => !item.hidden);

  const GoPage = agency_id => {
    window.open("/Research_main?agency_id=" + agency_id, "_blank");

    // console.log(agency_id);
  };

  function handleChange(value) {
    _xAgencyValue(value);

    xAgency.forEach((i, idx) => {
      if (i.id === value) {
        //console.log(i.name);
        _xAgencyName(i.name);
      }
    });

    bSearch(value, xType1Value, xType2Value, xType3Value);
  }

  function handleChange2(value) {
    _xType1Value(value);

    xType1.forEach((i, idx) => {
      if (i.id === value) {
        //console.log(i.name);
        _xType1Name(i.name);
      }
    });

    bSearch(xAgencyValue, value, xType2Value, xType3Value);
  }

  function handleChange3(value) {
    _xType2Value(value);

    xType2.forEach((i, idx) => {
      if (i.id === value) {
        //console.log(i.name);
        _xType2Name(i.name);
      }
    });

    bSearch(xAgencyValue, xType1Value, value, xType3Value);
  }

  function handleChange4(value) {
    _xType3Value(value);

    xType3.forEach((i, idx) => {
      if (i.id === value) {
        //console.log(i.name);
        _xType3Name(i.name);
      }
    });

    bSearch(xAgencyValue, xType1Value, xType2Value, value);
  }

  //formatttable (Rigth or Left)
  function __TBShowText(zData, zRigth, zLeft) {
    if (zRigth === 1 && zLeft === 0) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {zData}
        </div>
      );
    } else if (zRigth === 0 && zLeft === 1) {
      return (
        <div style={{ display: "flex" }}>
          {zData}
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <StyledForm />
      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={23}>
            <PageHeader
              className="site-page-header"
              title="รายงานแสดงแหล่งทุน"
            />
            <Divider />
          </Col>
        </Row>

        <Row justify="center">
          <Col span={23}>
            <Card
              style={{
                borderRadius: "15px",
                overflow: "hidden"
              }}
            >
              <Form form={form1} initialValues={{}} {...layout}>
                <Form.Item label="แหล่งทุน" name="slagency">
                  <Select
                    showSearch
                    defaultValue="สถานะทั้งหมด"
                    onChange={handleChange}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())}
                    style={{ width: "500px" }}
                  >
                    <Option value="0">สถานะทั้งหมด</Option>
                    {xAgency.length > 0
                      ? xAgency.map((i, idx) => {
                          return (
                            <Option value={i.id}>
                              {i.name}
                            </Option>
                          );
                        })
                      : <Option value="0">สถานะทั้งหมด</Option>}
                  </Select>
                </Form.Item>

                <Form.Item label="ประเภทแหล่งทุน" name="sltype1">
                  <Select
                    defaultValue="ประเภทแหล่งทุนทั้งหมด"
                    style={{ width: "300px" }}
                    onChange={handleChange2}
                  >
                    <Option value="0">ประเภทแหล่งทุนทั้งหมด</Option>
                    {xType1.length > 0
                      ? xType1.map((i, idx) => {
                          return (
                            <Option value={i.id}>
                              {i.name}
                            </Option>
                          );
                        })
                      : <Option value="0">ประเภทแหล่งทุนทั้งหมด</Option>}
                  </Select>
                </Form.Item>

                <Form.Item label="สังกัด" name="sltype2">
                  <Select
                    defaultValue="สังกัดทั้งหมด"
                    style={{ width: "300px" }}
                    onChange={handleChange3}
                  >
                    <Option value="0">สังกัดทั้งหมด</Option>
                    {xType2.length > 0
                      ? xType2.map((i, idx) => {
                          return (
                            <Option value={i.id}>
                              {i.name}
                            </Option>
                          );
                        })
                      : <Option value="0">สังกัดทั้งหมด</Option>}
                  </Select>
                </Form.Item>

                <Form.Item label=" " name="sltype3">
                  <Select
                    defaultValue="ทั้งหมด"
                    style={{ width: "300px" }}
                    onChange={handleChange4}
                  >
                    <Option value="0">ทั้งหมด</Option>
                    {xType3.length > 0
                      ? xType3.map((i, idx) => {
                          return (
                            <Option value={i.id}>
                              {i.name}
                            </Option>
                          );
                        })
                      : <Option value="0">ทั้งหมด</Option>}
                  </Select>
                </Form.Item>
              </Form>

              <br />

              <Row justify="center">
                <Space>
                  <Button
                    ghost
                    type="primary"
                    onClick={() =>
                      bSearch(
                        xAgencyValue,
                        xType1Value,
                        xType2Value,
                        xType3Value
                      )}
                    hidden
                  >
                    <SearchOutlined /> แสดงข้อมูล
                  </Button>

                  <Button ghost type="danger" onClick={bClear}>
                    <ClearOutlined /> เคลียร์
                  </Button>

                  <Button ghost type="success" onClick={bExcel}>
                    <FileExcelOutlined /> Excel
                  </Button>
                </Space>
              </Row>
            </Card>
          </Col>
        </Row>

        <br />
        <br />

        <Row justify="center" style={{ marginBottom: 10 }}>
          <Col span={23}>
            <Table
              bordered
              scroll={{ x: "max-content" }}
              dataSource={dataSource1}
              columns={columns1}
              size="small"
              pagination={false}
            />
          </Col>
        </Row>
      </Content>

      <br />

      <Footer_last />
    </React.Fragment>
  );
};

export default Research_report2;
