import "antd/dist/antd.min.css";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import {
  SaveOutlined,
  ClearOutlined,
  DeleteOutlined,
  EditOutlined,
  PaperClipOutlined,
  CloseOutlined
} from "@ant-design/icons";
import { createGlobalStyle } from "styled-components";

import {
  Row,
  Col,
  Form,
  Layout,
  Card,
  Typography,
  Input,
  InputNumber,
  Select,
  Space,
  Button,
  Table,
  Modal,
  PageHeader,
  Divider,
  ConfigProvider
} from "antd";
import moment from "moment";
import "moment/locale/th";
import locale from "antd/es/date-picker/locale/th_TH";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import swal from "sweetalert";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import thTH from "antd/lib/locale/th_TH";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

const { Content } = Layout;
const { Text } = Typography;
const { Option } = Select;
const { confirm } = Modal;

const StyledForm = createGlobalStyle`.ant-form-item {
  margin-bottom: 5px;
}
`;

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 }
};

const layout2 = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 }
};

const layout3 = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 }
};

const configuredLocale = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    dateFormat: "DD MM BBBB", // DD MM BBBB
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker.lang,
      dateFormat: "DD MM BBBB", // DD MM BBBB
      dateTimeFormat: "DD MM BBBB HH:mm:ss",
      yearFormat: "BBBB"
    }
  }
};

dayjs.locale("th");
dayjs.extend(buddhistEra);

const Research_input_contract = () => {
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [form4] = Form.useForm();

  const [form5] = Form.useForm();
  const [form6] = Form.useForm();
  const [form7] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }
  const [xcmumis_id, _xcmumis_id] = useState("");
  const [xname, _xname] = useState("");
  const [xshortname, _xshortname] = useState("");
  const [xresearch, _xresearch] = useState("");
  const [xdate, _xdate] = useState("");

  const [dataSource1, _dataSource1] = useState([]);
  const [dataSource2, _dataSource2] = useState([]);
  const [dataSource3, _dataSource3] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);

  const [xtitle, _xtitle] = useState("");
  const [xgrant, _xgrant] = useState("");
  const [xpo, _xpo] = useState("");

  const [xAmout, _xAmout] = useState(0);
  const [xAmout2, _xAmout2] = useState(0);
  const [xAmoutE, _xAmoutE] = useState(0);
  const [xAmoutE2, _xAmoutE2] = useState(0);

  const [xCurrency, _xCurrency] = useState("");

  const [xCurrencyValue, _xCurrencyValue] = useState("1");
  const [xCurrencyValue2, _xCurrencyValue2] = useState("1");
  const [xCurrencyEValue, _xCurrencyEValue] = useState("1");
  const [xCurrencyE2Value, _xCurrencyE2Value] = useState("1");

  const [ListFile, _ListFile] = useState([]);
  const [ListFile2, _ListFile2] = useState([]);

  const [xMod, _xMod] = useState("");
  const [xModValue, _xModValue] = useState("0");
  const [xModEValue, _xModEValue] = useState("0");

  const [xtitleE, _xtitleE] = useState("");
  const [xtitleE2, _xtitleE2] = useState("");
  const [xtitleE3, _xtitleE3] = useState("");

  const [show1, _show1] = useState(false);
  const [show2, _show2] = useState(false);
  const [show3, _show3] = useState(false);

  const [ogrant_no, _ogrant_no] = useState("");
  const [opo, _opo] = useState("");
  const [odescription, _odescription] = useState("");

  const [omodification_no, _omodification_no] = useState("");
  const [osdate, _osdate] = useState("");
  const [oedate, _oedate] = useState("");
  const [odescription2, _odescription2] = useState("");
  const [ofile, _ofile] = useState("");

  const [xsummary, _xsummary] = useState(0);
  const [xcrc, _xcrc] = useState("");

  const txtpo = useRef("");
  const txtdescription = useRef("");
  const txtsdate = useRef("");
  const txtedate = useRef("");
  const txtamount = useRef("");
  const txtcurrency = useRef("");
  const txtdescription2 = useRef("");
  const txtsdate2 = useRef("");
  const txtedate2 = useRef("");
  const txtamount2 = useRef("");
  const txtcurrency2 = useRef("");
  const txtdescription3 = useRef("");

  const DatePicker = generatePicker(dayjsGenerateConfig);

  const [xSumAmount, _xSumAmount] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem("token");
    //console.log(token);

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");
          var url = new URL(window.location.href);

          //ค้นหาหน่วย
          axios
            .get(xURL + "find_currency")
            .then(result => {
              _xCurrency(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          _xcmumis_id(url.searchParams.get("cmumis_id"));
          _xname(url.searchParams.get("name"));
          _xshortname(url.searchParams.get("shortname"));
          _xresearch(url.searchParams.get("research"));
          _xdate(url.searchParams.get("date"));

          GetTable(url.searchParams.get("cmumis_id"));
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const GetTable = cmumis_id => {
    axios
      .post(xURL + "find_contract", {
        cmumis_id: cmumis_id
      })
      .then(result => {
        const Tb1 = [];

        let xsum = 0;

        result.data.forEach((i, idx) => {
          let xsdate = "",
            xedate = "",
            xbudget = "";
          //console.log(result.data);

          if (i.start_date != null) {
            /*
            xsdate = moment(
              moment(i.start_date.substr(0, 10)).add(1, "day").format("L"),
              "DD/MM/YYYY"
            )._i;

            xedate = moment(
              moment(i.end_date.substr(0, 10)).add(1, "day").format("L"),
              "DD/MM/YYYY"
            )._i;*/

            xsdate = dayjs(i.start_date).format("DD/MM/BBBB");
            xedate = dayjs(i.end_date).format("DD/MM/BBBB");

            if (i.budget) {
              xbudget = i.budget.toLocaleString(undefined, {
                minimumFractionDigits: 2
              });

              xsum = xsum + i.budget;
            } else {
              if (i.budget2) {
                xbudget = i.budget2.toLocaleString(undefined, {
                  minimumFractionDigits: 2
                });

                xsum = xsum + i.budget2;
              } else {
                if (i.budget3) {
                  xbudget = i.budget3.toLocaleString(undefined, {
                    minimumFractionDigits: 2
                  });

                  xsum = xsum + i.budget3;
                }
              }
            }
          } else {
            xsdate = "";
            xedate = "";
            xbudget = "";
          }

          Tb1.push({
            key: idx,
            num: idx + 1,
            grant_no: i.grant_number,
            po: i.po_number,
            description: i.description,
            cgrant: i.cmod_no,
            sdate: xsdate,
            edate: xedate,
            budget: xbudget,
            currency: i.currency
          });
        });

        _dataSource1(Tb1);
        _xSumAmount(xsum);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bClearclick = () => {
    form1.resetFields();

    _xtitle("");
    _xgrant("");
    _xpo("");

    _xMod("");

    _show1(false);
  };

  const bClearclick2 = () => {
    form3.resetFields();

    _xAmout(0);
    _xCurrencyValue("1");

    _ListFile([]);

    _show2(false);
  };

  const bClearclick3 = () => {
    form4.resetFields();

    _xAmout2(0);
    _xCurrencyValue2("1");
    _xModValue("0");

    _ListFile2([]);
  };

  const bClearEclick1 = () => {
    form5.resetFields();
  };

  const bClearEclick2 = () => {
    form6.resetFields();

    _ListFile2([]);
  };

  const bClearEclick3 = () => {
    form7.resetFields();
  };

  const getContract = (grant_no, po_no) => {
    axios
      .post(xURL + "find_mod1", {
        cmumis_id: xcmumis_id,
        grant_no: grant_no,
        po_no: po_no
      })
      .then(result => {
        //console.log(result);
        const Tb1 = [];
        let xsum = 0,
          xname = "";

        result.data.forEach((i, idx) => {
          /*
          let xsdate = moment(
            moment(i.start_date.substr(0, 10)).add(1, "day").format("L"),
            "DD/MM/YYYY"
          )._i;

          let xedate = moment(
            moment(i.end_date.substr(0, 10)).add(1, "day").format("L"),
            "DD/MM/YYYY"
          )._i;*/

          let xsdate = dayjs(i.start_date).format("DD/MM/BBBB");
          let xedate = dayjs(i.end_date).format("DD/MM/BBBB");

          let url_file = "";

          url_file = `${xURL}file2/${i.file}`;

          let xbudget = "0";

          if (i.budget) {
            xbudget = i.budget.toLocaleString();
          }

          Tb1.push({
            key: idx,
            num: idx + 1,
            grant_no: i.grant_number,
            po: i.po_number,
            Modification: i.mod_no,
            sdate: xsdate,
            edate: xedate,
            budget: xbudget,
            currency: i.currency_name,
            description: i.remark,
            linkfile: url_file,
            linkfile2: i.file
          });

          xsum = xsum + i.budget;
          xname = i.currency_name;
        });

        _xcrc(xname);
        _xsummary(xsum.toLocaleString());

        _dataSource2(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const getInvoice = (grant_no, po_no) => {
    axios
      .post(xURL + "find_invoice", {
        cmumis_id: xcmumis_id,
        grant_no: grant_no,
        po_no: po_no
      })
      .then(result => {
        //console.log(result);
        const Tb1 = [];

        result.data.forEach((i, idx) => {
          /*
          let xsdate = moment(
            moment(i.start_date.substr(0, 10)).add(1, "day").format("L"),
            "DD/MM/YYYY"
          )._i;

          let xedate = moment(
            moment(i.end_date.substr(0, 10)).add(1, "day").format("L"),
            "DD/MM/YYYY"
          )._i;*/

          let xsdate = dayjs(i.start_date).format("DD/MM/BBBB");
          let xedate = dayjs(i.end_date).format("DD/MM/BBBB");

          let url_file = "";

          url_file = `${xURL}file3/${i.file_invoice}`;

          Tb1.push({
            key: idx,
            num: idx + 1,
            grant_no: i.grant_number,
            po: i.po_number,
            Modification: i.mod_no,
            sdate: xsdate,
            edate: xedate,
            budget: i.budget.toLocaleString(),
            currency: i.currency_name,
            description: i.remark,
            linkfile: url_file,
            invoice_no: i.invoice_no
          });
        });

        _dataSource3(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bSaveclick = () => {
    const f = form1.getFieldValue();

    if (!f.edgrant_no) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ เลขที่สัญญา!", "warning");
    } else if (!f.edpo_no) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ PO!", "warning");
    } else {
      swal({
        title: "ยืนยันบันทึกสัญญาโครงการวิจัย",
        text:
          "รหัส CMU MIS " +
          xcmumis_id +
          "\nเลขที่สัญญา : " +
          f.edgrant_no +
          "\nPO : " +
          f.edpo_no,
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          insertdata();
        }
      });
    }
  };

  const bSaveclick2 = () => {
    const f = form3.getFieldValue();

    if (!f.edmodification_no) {
      swal(
        "บันทึก ไม่ได้!",
        "ยังไม่ได้คีย์ ฉบับที่ / Modification No.!",
        "warning"
      );
    } else if (!f.sdate) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ วันที่เริ่มต้น!", "warning");
    } else if (!f.edate) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ วันที่สิ้นสุด!", "warning");
    } else if (ListFile == "" || typeof ListFile == "undefined") {
      /*else if (xAmout === 0) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ งบประมาณ!", "warning");
    } */ swal(
        "บันทึก ไม่ได้!",
        "ยังไม่ได้เลือก ไฟล์สัญญา!",
        "warning"
      );
    } else {
      swal({
        title: "ยืนยัน บันทึกสัญญาย่อย",
        text: "เลขที่สัญญา : " + xgrant + "\nPO : " + xpo,
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          insertdata2();
        }
      });
    }
  };

  const bSaveclick3 = () => {
    const f = form4.getFieldValue();

    if (xModValue == "0") {
      swal(
        "บันทึก ไม่ได้!",
        "ยังไม่ได้เลือก ฉบับที่ / Modification No.!",
        "warning"
      );
    } else if (!f.sdate) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ วันที่เริ่มต้น!", "warning");
    } else if (!f.edate) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ วันที่สิ้นสุด!", "warning");
    } else if (xAmout2 === 0) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ งบประมาณ!", "warning");
    } else if (ListFile2 == "" || typeof ListFile2 == "undefined") {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้เลือก ไฟล์สัญญา!", "warning");
    } else {
      swal({
        title: "ยืนยันบันทึก invoice / ใบเรียกเก็บเงิน",
        text: "เลขที่สัญญา : " + xgrant + "\nPO : " + xpo,
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          //console.log(willOk);
          insertdata3();
        }
      });
    }
  };

  const insertdata = () => {
    const f = form1.getFieldValue();

    axios
      .post(xURL + "insert", {
        cmumis_id: xcmumis_id,
        grant_no: f.edgrant_no,
        po_no: f.edpo_no,
        description: f.eddescription,
        zorder: 6
      })
      .then(result => {
        bClearclick();
        GetTable(xcmumis_id);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const insertdata2 = () => {
    const f = form3.getFieldValue();

    //console.log(ListFile.name.split(".")[1]);

    // console.log(
    //   f.edmodification_no,
    //   dayjs(f.sdate).format("DD/MM/YYYY"),
    //   dayjs(f.edate).format("DD/MM/YYYY"),
    //   xAmout,
    //   xCurrencyValue,
    //   f.eddescription,
    //   ListFile
    // );

    const fd = new FormData();
    fd.append("file", ListFile);

    axios.post(xURL + "uploadfile2", fd).then(res => {
      //Now do what you want with the response;

      axios
        .post(xURL + "insert", {
          cmumis_id: xcmumis_id,
          f_name: res.data.filename,
          mod_no: f.edmodification_no,
          grant_number: xgrant,
          po_number: xpo,
          start_date: dayjs(f.sdate).format("YYYY-MM-DD"),
          end_date: dayjs(f.edate).format("YYYY-MM-DD"),
          budget: xAmout,
          currency: xCurrencyValue,
          remark: f.eddescription,
          zorder: 7
        })
        .then(result => {
          bClearclick2();
          getContract(xgrant, xpo);
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    });
  };

  const insertdata3 = () => {
    const f = form4.getFieldValue();

    const fd = new FormData();
    fd.append("file", ListFile2);

    axios.post(xURL + "uploadfile3", fd).then(res => {
      //Now do what you want with the response;

      axios
        .post(xURL + "insert", {
          cmumis_id: xcmumis_id,
          f_name: res.data.filename,
          mod_no: xModValue,
          grant_number: xgrant,
          po_number: xpo,
          start_date: dayjs(f.sdate).format("YYYY-MM-DD"),
          end_date: dayjs(f.edate).format("YYYY-MM-DD"),
          budget: xAmout2,
          currency: xCurrencyValue2,
          remark: f.eddescription,
          zorder: 8
        })
        .then(result => {
          bClearclick3();
          getInvoice(xgrant, xpo);
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    });
  };

  const bConlist = (grant_no, po) => {
    form3.resetFields();

    _xgrant(grant_no);
    _xpo(po);
    _xtitle("เลขที่สัญญา/Grant No. " + grant_no + "  ,PO/Protocol No. " + po);

    getContract(grant_no, po);

    setIsModalVisible(true);
  };

  const binvoice = (grant_no, po) => {
    form4.resetFields();

    _xModValue("0");
    _xgrant(grant_no);
    _xpo(po);
    _xtitle("เลขที่สัญญา/Grant No. " + grant_no + "  " + po);

    //ค้นหา เลขสัญญา

    axios
      .post(xURL + "find_grant_no", {
        cmumis_id: xcmumis_id,
        grant_number: grant_no,
        po_number: po
      })
      .then(result => {
        _xMod(result.data);
        //getContract(xgrant, xpo);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    getInvoice(grant_no, po);

    setIsModalVisible2(true);
  };

  const bDContractClick = e => {
    swal({
      title: "ยืนยัน ลบสัญญาโครงการวิจัย",
      text:
        "เลขที่สัญญา : " + e.grant_no + "\nจำนวนสัญญา : " + e.cgrant + " สัญญา",
      icon: "warning",
      dangerMode: true,
      buttons: ["ยกเลิก", "ยืนยัน"]
    }).then(willDelete => {
      if (willDelete) {
        axios
          .post(xURL + "sl_d_file", {
            cmumis_id: xcmumis_id,
            grant_no: e.grant_no,
            po_number: e.po,
            order: 2
          })
          .then(result => {
            //console.log(result.data.file);
            result.data.forEach(i => {
              //console.log(i.file_invoice);

              axios
                .post(xURL + "delete_file", {
                  linkfile: i.file_invoice,
                  order: 3
                })
                .then(result => {})
                .catch(function(error) {
                  // handle error
                  console.log("problem here", error);
                });
            });
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        axios
          .post(xURL + "sl_d_file", {
            cmumis_id: xcmumis_id,
            grant_no: e.grant_no,
            po_number: e.po,
            order: 1
          })
          .then(result => {
            //console.log(result.data.file);
            result.data.forEach(i => {
              //console.log(i.file);
              axios
                .post(xURL + "delete_file", {
                  linkfile: i.file,
                  order: 2
                })
                .then(result => {})
                .catch(function(error) {
                  // handle error
                  console.log("problem here", error);
                });
            });
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        ///////////////////////////////////////////////////////

        axios
          .post(xURL + "delete", {
            cmumis_id: xcmumis_id,
            grant_no: e.grant_no,
            po_number: e.po,
            xcheck: 19
          })
          .then(result => {})
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        axios
          .post(xURL + "delete", {
            cmumis_id: xcmumis_id,
            grant_no: e.grant_no,
            po_number: e.po,
            xcheck: 16
          })
          .then(result => {
            //GetTable(xgrant, xpo);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        axios
          .post(xURL + "delete", {
            cmumis_id: xcmumis_id,
            grant_no: e.grant_no,
            po_number: e.po,
            xcheck: 17
          })
          .then(result => {
            //GetTable(xcmumis_id);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        swal("แจ้งเตือน!", "ลบสัญญาโครงการวิจัยสำเร็จ!", "success").then(() => {
          GetTable(xcmumis_id);
        });
      }
    });
  };

  const bDModClick = e => {
    swal({
      title: "ยืนยัน ลบสัญญาย่อย",
      text:
        "เลขที่สัญญา : " +
        e.grant_no +
        "\nPO : " +
        e.po +
        "\nฉบับที่ : " +
        e.Modification,
      icon: "warning",
      dangerMode: true,
      buttons: ["ยกเลิก", "ยืนยัน"]
    }).then(willDelete => {
      if (willDelete) {
        axios
          .post(xURL + "delete_file", {
            linkfile: e.linkfile2,
            order: 2
          })
          .then(result => {})
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        axios
          .post(xURL + "delete", {
            cmumis_id: xcmumis_id,
            grant_no: e.grant_no,
            po_number: e.po,
            mod_no: e.Modification,
            xcheck: 15
          })
          .then(result => {
            //getContract(xgrant, xpo);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        swal("แจ้งเตือน!", "ลบสัญญาย่อยสำเร็จ!", "success").then(() => {
          getContract(xgrant, xpo);
        });
      }
    });
  };

  const bDinvoiceClick = e => {
    swal({
      title: "ยืนยัน ลบ invoice / ใบเรียกเก็บเงิน",
      text:
        "เลขที่สัญญา : " +
        e.grant_no +
        "\nPO : " +
        e.po +
        "\nฉบับที่ : " +
        e.Modification,
      icon: "warning",
      dangerMode: true,
      buttons: ["ยกเลิก", "ยืนยัน"]
    }).then(willDelete => {
      if (willDelete) {
        axios
          .post(xURL + "delete_file", {
            linkfile: e.linkfile.split("/")[5],
            order: 3
          })
          .then(result => {})
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        axios
          .post(xURL + "delete", {
            cmumis_id: xcmumis_id,
            grant_no: e.grant_no,
            po_number: e.po,
            mod_no: e.Modification,
            xcheck: 18
          })
          .then(result => {
            //getInvoice(xgrant, xpo);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        swal("แจ้งเตือน!", "ลบสัญญาย่อยสำเร็จ!", "success").then(() => {
          getInvoice(xgrant, xpo);
        });
      }
    });
  };

  const bEditClick = zdata => {
    _show1(true);

    _xtitleE("แก้ไข - สัญญาโครงการวิจัย");

    _ogrant_no(zdata.grant_no);
    _opo(zdata.po);
    _odescription(zdata.description);

    form5.setFieldsValue({
      edgrant_no: zdata.grant_no,
      edpo_no: zdata.po,
      eddescription: zdata.description
    });
  };

  const [xgrant_no, setxgrant_no] = useState("");

  const bEditClick2 = zdata => {
    _show2(true);

    _xtitleE2("แก้ไข - สัญญาย่อย");

    _xAmoutE(parseInt(zdata.budget.replace(",", "").replace(",", "")));

    if (zdata.currency === "Baht") {
      _xCurrencyEValue(1);
    } else if (zdata.currency === "USD") {
      _xCurrencyEValue(2);
    } else if (zdata.currency === "JPY") {
      _xCurrencyEValue(3);
    }

    setxgrant_no(zdata.grant_no);

    axios
      .post(xURL + "getDate_mod", {
        cmumis_id: xcmumis_id,
        grant_no: zdata.grant_no,
        po_no: zdata.po,
        mod_no: zdata.Modification,
        date1: zdata.sdate,
        date2: zdata.edate
      })
      .then(result => {
        let xsdate = dayjs(result.data[0].start_date);
        let xedate = dayjs(result.data[0].end_date);

        _omodification_no(zdata.Modification);
        _osdate(dayjs(xsdate));
        _oedate(dayjs(xedate));
        _odescription2(zdata.description);
        _ofile(zdata.linkfile2);

        form6.setFieldsValue({
          edmodification_no: zdata.Modification,
          sdate: xsdate,
          edate: xedate,
          edamount: zdata.budget,
          slcurrency: zdata.currency,
          eddescription: zdata.description
        });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bEditClick3 = zdata => {
    _show3(true);

    _xtitleE3("แก้ไข - invoice / ใบเรียกเก็บเงิน");

    let xmod_id = "";
    xMod.forEach(i => {
      if (i.mod_no === zdata.Modification) {
        xmod_id = i.id;
      }
    });

    _xModEValue(xmod_id);

    _xAmoutE2(parseInt(zdata.budget.replace(",", "").replace(",", "")));

    if (zdata.currency === "Baht") {
      _xCurrencyE2Value(0);
    } else if (zdata.currency === "USD") {
      _xCurrencyE2Value(1);
    } else if (zdata.currency === "JPY") {
      _xCurrencyE2Value(2);
    }

    _omodification_no(xmod_id);
    _osdate(dayjs(zdata.sdate, "DD/MM/BBBB"));
    _oedate(dayjs(zdata.edate, "DD/MM/BBBB"));
    _odescription2(zdata.description);

    form7.setFieldsValue({
      slmod_no: zdata.Modification,
      sdate: dayjs(zdata.sdate, "DD/MM/BBBB"),
      edate: dayjs(zdata.edate, "DD/MM/BBBB"),
      edamount: zdata.budget,
      slcurrency: zdata.currency,
      eddescription: zdata.description
    });
  };

  const bclose = () => {
    _show1(false);
  };

  const bclose2 = () => {
    _show2(false);
  };

  const bclose3 = () => {
    _show3(false);
  };

  const bEditSclick = () => {
    const f = form5.getFieldValue();

    if (!f.edgrant_no) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ เลขที่สัญญา!", "warning");
    } else if (!f.edpo_no) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ PO!", "warning");
    } else {
      swal({
        title: "ยืนยัน แก้ไขสัญญาโครงการวิจัย",
        text: "รหัส CMU MIS " + xcmumis_id,
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          axios
            .post(xURL + "update", {
              cmumis_id: xcmumis_id,
              grant_no: f.edgrant_no,
              po_no: f.edpo_no,
              description: f.eddescription,
              ogrant_no: ogrant_no,
              opo_no: opo,
              odescription: odescription,
              xcheck: 14
            })
            .then(result => {
              _show1(false);
              GetTable(xcmumis_id);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
        }
      });
    }
  };

  const bEditSclick2 = () => {
    const f = form6.getFieldValue();

    // console.log(f, xAmoutE, xCurrencyEValue);

    if (!f.edmodification_no) {
      swal(
        "แก้ไข ไม่ได้!",
        "ยังไม่ได้คีย์ ฉบับที่ / Modification No.!",
        "warning"
      );
    } else if (!f.sdate) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ วันที่เริ่มต้น!", "warning");
    } else if (!f.edate) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ วันที่สิ้นสุด!", "warning");
    } else {
      swal({
        title: "ยืนยัน แก้ไขสัญญาย่อย",
        text: "รหัส CMU MIS " + xcmumis_id,
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          //console.log(xCurrencyEValue)

          if (ListFile2 == "" || typeof ListFile2 == "undefined") {
            axios
              .post(xURL + "update", {
                cmumis_id: xcmumis_id,
                mod_no: f.edmodification_no,
                sdate: dayjs(f.sdate).format("YYYY-MM-DD"),
                edate: dayjs(f.edate).format("YYYY-MM-DD"),
                budget: xAmoutE,
                currency: xCurrencyEValue,
                description: f.eddescription,
                omod_no: omodification_no,
                osdate: dayjs(osdate).format("YYYY-MM-DD"),
                oedate: dayjs(oedate).format("YYYY-MM-DD"),
                odescription: odescription2,
                grant_no: xgrant_no,
                xcheck: 15,
                xcheck2: 1
              })
              .then(result => {
                _show2(false);
                getContract(xgrant, xpo);
              })
              .catch(function(error) {
                // handle error
                console.log("problem here", error);
              });
          } else {
            axios
              .post(xURL + "delete_file", {
                linkfile: ofile,
                order: 2
              })
              .then(result => {})
              .catch(function(error) {
                // handle error
                console.log("problem here", error);
              });

            const fd = new FormData();
            fd.append("file", ListFile2);

            axios.post(xURL + "uploadfile2", fd).then(res => {
              axios
                .post(xURL + "update", {
                  cmumis_id: xcmumis_id,
                  mod_no: f.edmodification_no,
                  sdate: dayjs(f.sdate).format("YYYY-MM-DD"),
                  edate: dayjs(f.edate).format("YYYY-MM-DD"),
                  budget: xAmoutE,
                  currency: xCurrencyEValue,
                  description: f.eddescription,
                  omod_no: omodification_no,
                  osdate: dayjs(osdate).format("YYYY-MM-DD"),
                  oedate: dayjs(oedate).format("YYYY-MM-DD"),
                  odescription: odescription2,
                  f_name: res.data.filename,
                  grant_no: xgrant_no,
                  xcheck: 15,
                  xcheck2: 2
                })
                .then(result => {
                  bClearEclick2();
                  _show2(false);

                  getContract(xgrant, xpo);
                })
                .catch(function(error) {
                  // handle error
                  console.log("problem here", error);
                });
            });
          }
        }
      });
    }
  };

  const bEditSclick3 = () => {
    const f = form7.getFieldValue();

    if (!f.sdate) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ วันที่เริ่มต้น!", "warning");
    } else if (!f.edate) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ วันที่สิ้นสุด!", "warning");
    } else if (xAmoutE2 === 0) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ งบประมาณ!", "warning");
    } else {
      swal({
        title: "ยืนยันแก้ไข invoice / ใบเรียกเก็บเงิน",
        text: "รหัส CMU MIS " + xcmumis_id,
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          axios
            .post(xURL + "update", {
              cmumis_id: xcmumis_id,
              mod_no: xModEValue,
              sdate: dayjs(f.sdate).format("YYYY-MM-DD"),
              edate: dayjs(f.edate).format("YYYY-MM-DD"),
              budget: xAmoutE2,
              currency: xCurrencyE2Value,
              description: f.eddescription,
              omod_no: omodification_no,
              osdate: dayjs(osdate).format("YYYY-MM-DD"),
              oedate: dayjs(oedate).format("YYYY-MM-DD"),
              odescription: odescription2,
              xcheck: 16
            })
            .then(result => {
              _show3(false);
              getInvoice(xgrant, xpo);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
        }
      });
    }
  };

  const columns1 = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 40
    },
    {
      title: "เลขที่สัญญา / Grant No.",
      dataIndex: "grant_no",
      key: "grant_no",
      align: "center",
      width: 180
    },
    {
      title: "PO / Protocol No.",
      dataIndex: "po",
      key: "po",
      align: "center",
      width: 150
    },
    {
      title: "จำนวนสัญญา",
      dataIndex: "cgrant",
      key: "cgrant",
      align: "center",
      width: 110
    },
    {
      title: "วันที่เริ่มต้น (สัญญาล่าสุด)",
      dataIndex: "sdate",
      key: "cgrant",
      align: "center",
      width: 120
    },
    {
      title: "วันที่สิ้นสุด (สัญญาล่าสุด)",
      dataIndex: "edate",
      key: "edate",
      align: "center",
      width: 120
    },
    {
      title: "งบประมาณ (รวม)",
      dataIndex: "budget",
      key: "budget",
      align: "center",
      width: 150,
      render: (text, record) => __TBShowText(record.budget, 1, 0)
    },
    {
      title: "หน่วย (สัญญาล่าสุด)",
      dataIndex: "currency",
      key: "currency",
      align: "center",
      width: 100
    },
    {
      title: "คำอธิบาย",
      dataIndex: "description",
      key: "description",
      ellipsis: true
      //align: "center",
      //render: (text, record) => __TBShowText(record.description, 0, 1)
    },
    {
      title: "",
      dataIndex: "addmod",
      key: "addmod",
      align: "center",
      width: 120,
      render(text, record, index) {
        return {
          children: (
            <Button
              ghost
              type="link"
              onClick={e => {
                bConlist(record.grant_no, record.po);
              }}
            >
              เพิ่มสัญญาย่อย
            </Button>
          )
        };
      },
      fixed: "right"
    },

    // {
    //   title: "",
    //   dataIndex: "addinvoice",
    //   key: "addinvoice",
    //   align: "center",
    //   width: 190,
    //   render(text, record, index) {
    //     return {
    //       children: (
    //         <Button
    //           ghost
    //           type="link"
    //           onClick={e => {
    //             //bConlist(record.grant_no,record.po);
    //             binvoice(record.grant_no, record.po);
    //           }}
    //         >
    //           เพิ่ม invoice / ใบเรียกเก็บเงิน
    //         </Button>
    //       )
    //     };
    //   },
    //   fixed: "right"
    // },
    {
      title: "แก้ไข",
      dataIndex: "update",
      key: "update",
      align: "center",
      width: 50,
      render(text, record, index) {
        return {
          children: (
            <Button
              icon={<EditOutlined />}
              size="small"
              onClick={e => {
                bEditClick(record);
              }}
            />
          )
        };
      },
      fixed: "right"
    },
    {
      title: "ลบ",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      width: 50,
      render(text, record, index) {
        return {
          children: (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="small"
              onClick={e => {
                bDContractClick(record);
              }}
            />
          )
        };
      },
      fixed: "right"
    }
  ];

  const columns2 = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 40
    },
    {
      title: "เลขที่สัญญา / Grant No.",
      dataIndex: "grant_no",
      key: "grant_no",
      align: "center",
      width: 130
    },
    {
      title: "PO / Protocol No.",
      dataIndex: "po",
      key: "po",
      align: "center",
      width: 130
    },
    {
      title: "ฉบับที่ / Modification",
      dataIndex: "Modification",
      key: "Modification",
      align: "center",
      width: 110
    },
    {
      title: "วันที่เริ่มต้น",
      dataIndex: "sdate",
      key: "sdate",
      align: "center",
      width: 120
    },
    {
      title: "วันที่สิ้นสุด",
      dataIndex: "edate",
      key: "edate",
      align: "center",
      width: 120
    },
    {
      title: "งบประมาณ",
      dataIndex: "budget",
      key: "budget",
      align: "center",
      width: 150,
      render: (text, record) => __TBShowText(record.budget, 1, 0)
    },
    {
      title: "หน่วย",
      dataIndex: "currency",
      key: "currency",
      align: "center",
      width: 80
    },
    {
      title: "คำอธิบาย",
      dataIndex: "description",
      key: "description",
      ellipsis: true
      //align: "center",
      //render: (text, record) => __TBShowText(record.description, 0, 1)
    },
    {
      title: "ไฟล์สัญญา",
      dataIndex: "tfile",
      key: "tfile",
      align: "center",
      width: 130,
      render(text, record, index) {
        return {
          children: (
            <a href={record.linkfile} target="_blank">
              <PaperClipOutlined /> ไฟล์สัญญา
            </a>
          )
        };
      },
      fixed: "right"
    },

    {
      title: "แก้ไข",
      dataIndex: "update",
      key: "update",
      align: "center",
      width: 50,
      render(text, record, index) {
        return {
          children: (
            <Button
              icon={<EditOutlined />}
              size="small"
              onClick={e => {
                bEditClick2(record);
              }}
            />
          )
        };
      },
      fixed: "right"
    },
    {
      title: "ลบ",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      width: 50,
      render(text, record, index) {
        return {
          children: (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="small"
              onClick={e => {
                bDModClick(record);
              }}
            />
          )
        };
      },
      fixed: "right"
    }
  ];

  const columns3 = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 40
    },
    {
      title: "เลขที่สัญญา / Grant No.",
      dataIndex: "grant_no",
      key: "grant_no",
      align: "center",
      width: 130
    },
    {
      title: "PO / Protocol No.",
      dataIndex: "po",
      key: "po",
      align: "center",
      width: 130
    },
    {
      title: "ฉบับที่ / Modification",
      dataIndex: "Modification",
      key: "Modification",
      align: "center",
      width: 110
    },
    {
      title: "invoice no.",
      dataIndex: "invoice_no",
      key: "invoice_no",
      align: "center",
      width: 120
    },
    {
      title: "วันที่เริ่มต้น",
      dataIndex: "sdate",
      key: "cgrant",
      align: "center",
      width: 120
    },
    {
      title: "วันที่สิ้นสุด",
      dataIndex: "edate",
      key: "edate",
      align: "center",
      width: 120
    },
    {
      title: "งบประมาณ",
      dataIndex: "budget",
      key: "budget",
      align: "center",
      width: 150,
      render: (text, record) => __TBShowText(record.budget, 1, 0)
    },
    {
      title: "หน่วย",
      dataIndex: "currency",
      key: "currency",
      align: "center",
      width: 80
    },
    {
      title: "คำอธิบาย",
      dataIndex: "description",
      key: "description",
      ellipsis: true
      //align: "center",
      //render: (text, record) => __TBShowText(record.description, 0, 1)
    },
    {
      title: "ไฟล์ invoice",
      dataIndex: "tfile",
      key: "tfile",
      align: "center",
      width: 130,
      render(text, record, index) {
        return {
          children: (
            <a href={record.linkfile} target="_blank">
              <PaperClipOutlined /> ไฟล์ invoice
            </a>
          )
        };
      },
      fixed: "right"
    },

    {
      title: "แก้ไข",
      dataIndex: "update",
      key: "update",
      align: "center",
      width: 50,
      render(text, record, index) {
        return {
          children: (
            <Button
              icon={<EditOutlined />}
              size="small"
              onClick={e => {
                bEditClick3(record);
              }}
            />
          )
        };
      },
      fixed: "right"
    },
    {
      title: "ลบ",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      width: 50,
      render(text, record, index) {
        return {
          children: (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="small"
              onClick={e => {
                bDinvoiceClick(record);
              }}
            />
          )
        };
      },
      fixed: "right"
    },
    {
      title: "บันทึกเงินโอน",
      dataIndex: "saveinv",
      key: "saveinv",
      align: "center",
      width: 50,
      render(text, record, index) {
        return {
          children: (
            <Button
              danger
              icon={<SaveOutlined />}
              size="small"
              onClick={e => {}}
            />
          )
        };
      },
      fixed: "right"
    }
  ];

  const onChangeededamount = e => {
    if (e == null) {
      _xAmout();
    } else {
      _xAmout(e);
    }
  };

  const onChangeededamount2 = e => {
    if (e == null) {
      _xAmout2();
    } else {
      _xAmout2(e);
    }
  };

  const onChangeededamountE = e => {
    if (e == null) {
      _xAmoutE();
    } else {
      _xAmoutE(e);
    }
  };

  const onChangeededamountE2 = e => {
    if (e == null) {
      _xAmoutE2();
    } else {
      _xAmoutE2(e);
    }
  };

  function handleChange(value) {
    _xCurrencyValue(value);
    txtdescription2.current.focus();
  }

  function handleChange2(value) {
    _xCurrencyValue2(value);
    txtdescription3.current.focus();
  }

  function handleChange3(value) {
    _xModValue(value);
    txtsdate2.current.focus();
  }

  function handleChangeE(value) {
    _xCurrencyEValue(value);
  }

  function handleChangeE2(value) {
    _xModEValue(value);
  }

  function handleChangeE3(value) {
    _xCurrencyE2Value(value);
  }

  const handleOk = () => {
    setIsModalVisible(false);
    GetTable(xcmumis_id);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    GetTable(xcmumis_id);
  };

  const handleOk2 = () => {
    setIsModalVisible2(false);
    GetTable(xcmumis_id);
  };

  const handleCancel2 = () => {
    setIsModalVisible2(false);
    GetTable(xcmumis_id);
  };

  //formatttable (Rigth or Left)
  function __TBShowText(zData, zRigth, zLeft) {
    if (zRigth === 1 && zLeft === 0) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    } else if (zRigth === 0 && zLeft === 1) {
      return (
        <div style={{ display: "flex" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    }
  }

  const onChangeU = e => {
    //console.log(e, e.target.files[0]);

    _ListFile(e.target.files[0]);
  };

  const onChangeU2 = e => {
    _ListFile2(e.target.files[0]);
  };

  return (
    <React.Fragment>
      <StyledForm />

      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col span={23}>
            <PageHeader
              className="site-page-header"
              title={
                <a
                  style={{
                    color: "black"
                  }}
                  onClick={() => {
                    window.close();
                  }}
                >
                  สัญญาโครงการวิจัย
                </a>
              }
              onBack={() => window.close()}
            />

            <Divider />

            <Card title="">
              <Form form={form2} {...layout2}>
                <Form.Item
                  label={
                    <Text style={{ fontWeight: "bold" }}>รหัส CMU MIS</Text>
                  }
                >
                  {xcmumis_id}
                </Form.Item>

                <Form.Item
                  label={
                    <Text style={{ fontWeight: "bold" }}>ชื่อโครงการ</Text>
                  }
                >
                  {xname}
                </Form.Item>

                <Form.Item
                  label={
                    <Text style={{ fontWeight: "bold" }}>ชื่อย่อโครงการ</Text>
                  }
                >
                  {xshortname}
                </Form.Item>

                <Form.Item
                  label={
                    <Text style={{ fontWeight: "bold" }}>หัวหน้าโครงการ</Text>
                  }
                >
                  {xresearch}
                </Form.Item>

                <Form.Item
                  label={<Text style={{ fontWeight: "bold" }}>ระยะเวลา</Text>}
                >
                  {xdate}
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>

        <br />

        <Row justify="center">
          <Col span={23}>
            <Card title="สัญญาโครงการวิจัย">
              <Form
                form={form1}
                initialValues={{
                  edgrant_no: "",
                  edpo_no: "",
                  eddescription: ""
                }}
                {...layout}
              >
                <Form.Item
                  label="เลขที่สัญญา / Grant No."
                  name="edgrant_no"
                  rules={[
                    {
                      required: true,
                      message: "กรุณากรอก เลขที่สัญญา / Grant No."
                    }
                  ]}
                >
                  <Input
                    autoFocus
                    allowClear
                    //maxLength="11"
                    placeholder="เลขที่สัญญา / Grant No."
                    style={{ width: "50%" }}
                    onPressEnter={() => {
                      txtpo.current.focus();
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="PO / Protocol No."
                  name="edpo_no"
                  rules={[
                    {
                      required: true,
                      message: "กรุณากรอก PO / Protocol No."
                    }
                  ]}
                >
                  <Input
                    allowClear
                    placeholder="PO / Protocol No."
                    //maxLength="10"
                    style={{ width: "50%" }}
                    ref={txtpo}
                    onPressEnter={() => {
                      txtdescription.current.focus();
                    }}
                  />
                </Form.Item>

                <Form.Item label="คำอธิบาย" name="eddescription">
                  <Input
                    allowClear
                    placeholder="คำอธิบาย"
                    ref={txtdescription}
                  />
                </Form.Item>

                <br />

                <Row justify="center">
                  <Space>
                    <Button ghost type="primary" onClick={bSaveclick}>
                      <SaveOutlined /> บันทึก
                    </Button>

                    <Button ghost type="danger" onClick={bClearclick}>
                      <ClearOutlined /> เคลียร์
                    </Button>
                  </Space>
                </Row>

                <br />

                <Table
                  bordered
                  scroll={{ x: "max-content" }}
                  dataSource={dataSource1}
                  columns={columns1}
                  size="small"
                  pagination={false}
                  summary={() =>
                    <Table.Summary fixed>
                      <Table.Summary.Row
                        style={{
                          textAlign: "right",
                          backgroundColor: "#b5f5ec"
                        }}
                      >
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                        <Table.Summary.Cell>รวมงบประมาณ</Table.Summary.Cell>
                        <Table.Summary.Cell>
                          {xSumAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2
                          })}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                      </Table.Summary.Row>
                    </Table.Summary>}
                />
              </Form>

              <br />
              {show1
                ? <Form form={form5} initialValues={{}} {...layout}>
                    <Card
                      title={xtitleE}
                      extra={
                        <Button ghost type="text" onClick={bclose}>
                          <CloseOutlined />
                        </Button>
                      }
                    >
                      <Form.Item
                        label="เลขที่สัญญา / Grant No."
                        name="edgrant_no"
                        rules={[
                          {
                            required: true,
                            message: "กรุณากรอก เลขที่สัญญา / Grant No."
                          }
                        ]}
                      >
                        <Input autoFocus allowClear style={{ width: "50%" }} />
                      </Form.Item>

                      <Form.Item
                        label="PO / Protocol No."
                        name="edpo_no"
                        rules={[
                          {
                            required: true,
                            message: "กรุณากรอก PO / Protocol No."
                          }
                        ]}
                      >
                        <Input allowClear style={{ width: "50%" }} />
                      </Form.Item>

                      <Form.Item label="คำอธิบาย" name="eddescription">
                        <Input allowClear />
                      </Form.Item>

                      <br />
                      <Row justify="center">
                        <Space>
                          <Button ghost type="primary" onClick={bEditSclick}>
                            <SaveOutlined /> แก้ไข
                          </Button>

                          <Button ghost type="danger" onClick={bClearEclick1}>
                            <ClearOutlined /> เคลียร์
                          </Button>
                        </Space>
                      </Row>
                    </Card>
                  </Form>
                : <div />}
            </Card>
            <br />
          </Col>
        </Row>
      </Content>

      <Footer_last />

      <Modal
        title="เพิ่มสัญญาย่อย"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1800}
      >
        <Form
          form={form3}
          initialValues={{
            edmodification_no: "",
            sdate: "",
            edate: "",
            edamount: "",
            slcurrency: "Baht",
            eddescription: "",
            edfile: ""
          }}
          {...layout3}
        >
          <Card title={xtitle}>
            <Form.Item
              label="ฉบับที่ / Modification No."
              name="edmodification_no"
              rules={[
                {
                  required: true,
                  message: "กรุณากรอก ฉบับที่ / Modification No."
                }
              ]}
            >
              <Input
                autoFocus
                allowClear
                //maxLength="11"
                placeholder="ฉบับที่ / Modification No."
                style={{ width: "30%" }}
                onPressEnter={() => {
                  txtsdate.current.focus();
                }}
              />
            </Form.Item>

            <ConfigProvider locale={configuredLocale}>
              <Form.Item
                label="วันที่เริ่มต้น"
                name="sdate"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอก วันที่เริ่มต้น"
                  }
                ]}
              >
                <DatePicker
                  format="DD/MM/BBBB"
                  style={{ width: "30%" }}
                  ref={txtsdate}
                  onChange={e => {
                    if ((e.length = 10)) {
                      txtedate.current.focus();
                    }
                  }}
                />
              </Form.Item>

              <Form.Item
                label="วันที่สิ้นสุด"
                name="edate"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอก วันที่สิ้นสุด"
                  }
                ]}
              >
                <DatePicker
                  format="DD/MM/BBBB"
                  style={{ width: "30%" }}
                  ref={txtedate}
                  onChange={e => {
                    if ((e.length = 10)) {
                      txtamount.current.focus();
                    }
                  }}
                />
              </Form.Item>
            </ConfigProvider>

            <Form.Item label="งบประมาณ" name="edamount">
              <InputNumber
                //disabled={Export}
                // min="0"
                max="9999999999"
                maxLength="12"
                onChange={e => onChangeededamount(e)}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
                style={{ width: "30%" }}
                ref={txtamount}
                onPressEnter={() => {
                  txtcurrency.current.focus();
                }}
              />
            </Form.Item>

            <Form.Item label="หน่วย" name="slcurrency">
              <Select
                defaultValue="0"
                onChange={handleChange}
                style={{ width: "15%" }}
                ref={txtcurrency}
              >
                {xCurrency.length > 0
                  ? xCurrency.map((i, idx) => {
                      return (
                        <Option value={i.id}>
                          {i.name}
                        </Option>
                      );
                    })
                  : <Option value="0">
                      {}
                    </Option>}
              </Select>
            </Form.Item>

            <Form.Item label="คำอธิบาย" name="eddescription">
              <Input
                allowClear
                placeholder="คำอธิบาย"
                style={{ width: "50%" }}
                ref={txtdescription2}
              />
            </Form.Item>

            <Form.Item
              label="ไฟล์สัญญา"
              name="edfile"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input
                className="form-control"
                id="drop-area2"
                type="file"
                value=""
                onChange={e => onChangeU(e)}
              />
            </Form.Item>

            <br />

            <Row justify="center">
              <Space>
                <Button ghost type="primary" onClick={bSaveclick2}>
                  <SaveOutlined /> บันทึก
                </Button>

                <Button ghost type="danger" onClick={bClearclick2}>
                  <ClearOutlined /> เคลียร์
                </Button>
              </Space>
            </Row>

            <br />

            <Table
              bordered
              scroll={{ x: "max-content" }}
              dataSource={dataSource2}
              columns={columns2}
              size="small"
              pagination={false}
              summary={() =>
                <Table.Summary fixed>
                  <Table.Summary.Row
                    style={{
                      textAlign: "right",
                      backgroundColor: "#b5f5ec"
                    }}
                  >
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                    <Table.Summary.Cell align="center">
                      รวมทั้งหมด
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>{xsummary}</Table.Summary.Cell>
                    <Table.Summary.Cell align="center">
                      {/* {xcrc} */}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell /> <Table.Summary.Cell />
                    <Table.Summary.Cell /> <Table.Summary.Cell />
                  </Table.Summary.Row>
                </Table.Summary>}
            />
          </Card>
        </Form>

        <br />
        {show2
          ? <Form form={form6} initialValues={{}} {...layout3}>
              <Card
                title={xtitleE2}
                extra={
                  <Button ghost type="text" onClick={bclose2}>
                    <CloseOutlined />
                  </Button>
                }
              >
                <Form.Item
                  label="ฉบับที่ / Modification No."
                  name="edmodification_no"
                  rules={[
                    {
                      required: true,
                      message: "กรุณากรอก ฉบับที่ / Modification No."
                    }
                  ]}
                >
                  <Input autoFocus allowClear style={{ width: "30%" }} />
                </Form.Item>

                <ConfigProvider locale={configuredLocale}>
                  <Form.Item
                    label="วันที่เริ่มต้น"
                    name="sdate"
                    rules={[
                      {
                        required: true,
                        message: "กรุณากรอก วันที่เริ่มต้น"
                      }
                    ]}
                  >
                    <DatePicker format="DD/MM/BBBB" style={{ width: "30%" }} />
                  </Form.Item>

                  <Form.Item
                    label="วันที่สิ้นสุด"
                    name="edate"
                    rules={[
                      {
                        required: true,
                        message: "กรุณากรอก วันที่สิ้นสุด"
                      }
                    ]}
                  >
                    <DatePicker format="DD/MM/BBBB" style={{ width: "30%" }} />
                  </Form.Item>
                </ConfigProvider>

                <Form.Item label="งบประมาณ" name="edamount">
                  <InputNumber
                    //disabled={Export}
                    // min="0"
                    max="9999999999"
                    maxLength="12"
                    onChange={e => onChangeededamountE(e)}
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                    style={{ width: "30%" }}
                  />
                </Form.Item>

                <Form.Item label="หน่วย" name="slcurrency">
                  <Select
                    defaultValue="0"
                    onChange={handleChangeE}
                    style={{ width: "15%" }}
                  >
                    {xCurrency.length > 0
                      ? xCurrency.map((i, idx) => {
                          return (
                            <Option value={i.id}>
                              {i.name}
                            </Option>
                          );
                        })
                      : <Option value="0">
                          {}
                        </Option>}
                  </Select>
                </Form.Item>

                <Form.Item label="คำอธิบาย" name="eddescription">
                  <Input allowClear style={{ width: "50%" }} />
                </Form.Item>

                <Form.Item label="เปลี่ยน ไฟล์สัญญา" name="edfile">
                  <Input
                    className="form-control"
                    id="drop-area2"
                    type="file"
                    onChange={e => onChangeU2(e)}
                  />
                </Form.Item>

                <br />
                <Row justify="center">
                  <Space>
                    <Button ghost type="primary" onClick={bEditSclick2}>
                      <SaveOutlined /> แก้ไข
                    </Button>

                    <Button ghost type="danger" onClick={bClearEclick2}>
                      <ClearOutlined /> เคลียร์
                    </Button>
                  </Space>
                </Row>
              </Card>
            </Form>
          : <div />}
      </Modal>

      <Modal
        title="เพิ่ม invoice / ใบเรียกเก็บเงิน"
        visible={isModalVisible2}
        onOk={handleOk2}
        onCancel={handleCancel2}
        footer={null}
        width={1800}
      >
        <Form
          form={form4}
          initialValues={{
            edmodification_no: "",
            sdate: "",
            edate: "",
            edamount: "",
            slcurrency: "Baht",
            eddescription: "",
            edfile: "",
            slmod_no: "เลือกรายการ"
          }}
          {...layout3}
        >
          <Card title={xtitle}>
            <Form.Item
              label="ฉบับที่ / Modification No."
              name="slmod_no"
              rules={[
                {
                  required: true,
                  message: "กรุณาเลือก ฉบับที่ / Modification No."
                }
              ]}
            >
              <Select style={{ width: "30%" }} onChange={handleChange3}>
                {xMod.length > 0
                  ? xMod.map((i, idx) => {
                      return (
                        <Option value={i.id}>
                          {i.mod_no}
                        </Option>
                      );
                    })
                  : <Option value="0">เลือกรายการ</Option>}
              </Select>
            </Form.Item>

            <ConfigProvider locale={configuredLocale}>
              <Form.Item
                label="วันที่เริ่มต้น"
                name="sdate"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอก วันที่เริ่มต้น"
                  }
                ]}
              >
                <DatePicker
                  format="DD/MM/BBBB"
                  style={{ width: "30%" }}
                  ref={txtsdate2}
                  onChange={e => {
                    if ((e.length = 10)) {
                      txtedate2.current.focus();
                    }
                  }}
                />
              </Form.Item>

              <Form.Item
                label="วันที่สิ้นสุด"
                name="edate"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอก วันที่สิ้นสุด"
                  }
                ]}
              >
                <DatePicker
                  format="DD/MM/BBBB"
                  style={{ width: "30%" }}
                  ref={txtedate2}
                  onChange={e => {
                    if ((e.length = 10)) {
                      txtamount2.current.focus();
                    }
                  }}
                />
              </Form.Item>
            </ConfigProvider>

            <Form.Item
              label="จำนวนเงิน"
              name="edamount"
              rules={[
                {
                  required: true,
                  message: "กรุณากรอก จำนวนเงิน"
                }
              ]}
            >
              <InputNumber
                //disabled={Export}
                min="0"
                max="9999999999"
                maxLength="12"
                onChange={e => onChangeededamount2(e)}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
                style={{ width: "30%" }}
                ref={txtamount2}
                onPressEnter={() => {
                  txtcurrency2.current.focus();
                }}
              />
            </Form.Item>

            <Form.Item label="หน่วย" name="slcurrency">
              <Select
                defaultValue="0"
                onChange={handleChange2}
                style={{ width: "15%" }}
                ref={txtcurrency2}
              >
                {xCurrency.length > 0
                  ? xCurrency.map((i, idx) => {
                      return (
                        <Option value={i.id}>
                          {i.name}
                        </Option>
                      );
                    })
                  : <Option value="0">
                      {}
                    </Option>}
              </Select>
            </Form.Item>

            <Form.Item label="คำอธิบาย" name="eddescription">
              <Input
                allowClear
                placeholder="คำอธิบาย"
                style={{ width: "50%" }}
                ref={txtdescription3}
              />
            </Form.Item>

            <Form.Item
              label="ไฟล์ invoice/ใบเรียกเก็บเงิน"
              name="edfile"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input
                className="form-control"
                id="drop-area2"
                type="file"
                onChange={e => onChangeU2(e)}
              />
            </Form.Item>

            <br />

            <Row justify="center">
              <Space>
                <Button ghost type="primary" onClick={bSaveclick3}>
                  <SaveOutlined /> บันทึก
                </Button>

                <Button ghost type="danger" onClick={bClearclick3}>
                  <ClearOutlined /> เคลียร์
                </Button>
              </Space>
            </Row>

            <br />

            <Table
              bordered
              scroll={{ x: "max-content" }}
              dataSource={dataSource3}
              columns={columns3}
              size="small"
              pagination={false}
            />
          </Card>
        </Form>

        <br />
        {show3
          ? <Form form={form7} initialValues={{}} {...layout3}>
              <Card
                title={xtitleE3}
                extra={
                  <Button ghost type="text" onClick={bclose3}>
                    <CloseOutlined />
                  </Button>
                }
              >
                <Form.Item
                  label="ฉบับที่ / Modification No."
                  name="slmod_no"
                  rules={[
                    {
                      required: true,
                      message: "กรุณาเลือก ฉบับที่ / Modification No."
                    }
                  ]}
                >
                  <Select style={{ width: "30%" }} onChange={handleChangeE2}>
                    {xMod.length > 0
                      ? xMod.map((i, idx) => {
                          return (
                            <Option value={i.id}>
                              {i.mod_no}
                            </Option>
                          );
                        })
                      : <Option value="0">เลือกรายการ</Option>}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="วันที่เริ่มต้น"
                  name="sdate"
                  rules={[
                    {
                      required: true,
                      message: "กรุณากรอก วันที่เริ่มต้น"
                    }
                  ]}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    locale={locale}
                    style={{ width: "30%" }}
                  />
                </Form.Item>

                <Form.Item
                  label="วันที่สิ้นสุด"
                  name="edate"
                  rules={[
                    {
                      required: true,
                      message: "กรุณากรอก วันที่สิ้นสุด"
                    }
                  ]}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    locale={locale}
                    style={{ width: "30%" }}
                  />
                </Form.Item>

                <Form.Item
                  label="งบประมาณ"
                  name="edamount"
                  rules={[
                    {
                      required: true,
                      message: "กรุณากรอก งบประมาณ"
                    }
                  ]}
                >
                  <InputNumber
                    //disabled={Export}
                    min="0"
                    max="9999999999"
                    maxLength="12"
                    onChange={e => onChangeededamountE2(e)}
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                    style={{ width: "30%" }}
                  />
                </Form.Item>

                <Form.Item label="หน่วย" name="slcurrency">
                  <Select
                    defaultValue="0"
                    onChange={handleChangeE3}
                    style={{ width: "15%" }}
                  >
                    {xCurrency.length > 0
                      ? xCurrency.map((i, idx) => {
                          return (
                            <Option value={i.id}>
                              {i.name}
                            </Option>
                          );
                        })
                      : <Option value="0">
                          {}
                        </Option>}
                  </Select>
                </Form.Item>

                <Form.Item label="คำอธิบาย" name="eddescription">
                  <Input allowClear style={{ width: "50%" }} />
                </Form.Item>

                <br />
                <Row justify="center">
                  <Space>
                    <Button ghost type="primary" onClick={bEditSclick3}>
                      <SaveOutlined /> แก้ไข
                    </Button>

                    <Button ghost type="danger" onClick={bClearEclick3}>
                      <ClearOutlined /> เคลียร์
                    </Button>
                  </Space>
                </Row>
              </Card>
            </Form>
          : <div />}
      </Modal>
    </React.Fragment>
  );
};
export default Research_input_contract;
