import "antd/dist/antd.min.css";
import "antd-button-color/dist/css/style.css";

import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Layout,
  Table,
  Card,
  Divider,
  Tabs,
  Input,
  Space,
  BackTop,
  PageHeader,
  Spin,
  Select
} from "antd";

import Button from "antd-button-color";

import { createGlobalStyle } from "styled-components";

import {
  SearchOutlined,
  FileTextOutlined,
  FileExcelOutlined
} from "@ant-design/icons";

import moment from "moment";
//import "moment/locale/th";

import Header from "./Header";
import Footer2 from "./Footer";

import swal from "sweetalert";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import thTH from "antd/lib/locale/th_TH";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

import * as xlsx from "xlsx";

const { Content } = Layout;

const StyledForm = createGlobalStyle`.ant-form-item {
  margin-bottom: 5px;
}
`;

const Research_scopus_naut = () => {
  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [dataSource2, _dataSource2] = useState([]);

  const [Sum_All, _Sum_All] = useState(0);

  const [loading, _loading] = useState(true);

  const [keyTab, _keyTab] = useState("1");

  const [dataYear, _dataYear] = useState([]);

  useEffect(() => {
    GetTable2();
  }, []);

  const GetTable2 = () => {
    axios
      .get(xURL + "find_scopus2")
      .then(result => {
        //console.log(result.data);
        Show_scopus2(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Show_scopus2 = zdata => {
    const Tb1 = [];

    zdata.forEach((i, idx) => {
      Tb1.push({
        key: idx,
        num: idx + 1,
        research: i.research,
        pp_all: i.C_allpp,
        pp_first: i.C_firstpp,
        q1: i.C_q1pp,
        q2: i.C_q2pp,
        q3: i.C_q3pp,
        q4: i.C_q4pp,
        q_other: i.C_napp
      });
    });

    _loading(false);

    _dataSource2(Tb1);
  };

  const Showlist = (zyear, zhead, zcount) => {
    //console.log(zyear, zhead, zcount);

    if (zcount > 0) {
      window.open(
        "/Research_scopus_list?year=" +
          zyear +
          "&head=" +
          zhead +
          "&count=" +
          zcount,
        "_blank"
      );
    } else {
      swal("แจ้งเตือน!", "ไม่มีข้อมูลตามเงื่อนไขที่เลือก!", "warning");
    }
  };

  const Showlist2 = (zresearch, zhead, zcount) => {
    //console.log(zresearch, zhead, zcount);

    if (zcount > 0) {
      window.open(
        "/Research_scopus_list2_naut?research=" +
          zresearch +
          "&head=" +
          zhead +
          "&count=" +
          zcount,
        "_blank"
      );
    } else {
      swal("แจ้งเตือน!", "ไม่มีข้อมูลตามเงื่อนไขที่เลือก!", "warning");
    }
  };

  const bExcelClick = () => {
    //console.log(keyTab);

    axios
      .post(xURL + "scopusEx", { keys: keyTab, c5: dataYear })
      .then(result => {
        let xdata = [],
          xtabtitle = "",
          header = [],
          header2 = [];

        xtabtitle = "นักวิจัย";

        header.push(
          "ชื่อนักวิจัย",
          "จำนวนผลงานตีพิมพ์",
          "จำนวนผลงานที่เป็น First/Corresponding Author",
          "Q1",
          "Q2",
          "Q3",
          "Q4",
          "ไม่มี Q"
        );

        xdata.push(["SCOPUS (" + xtabtitle + ")"]);
        xdata.push([""]);

        let tb = [],
          xyear = "";

        tb.push("รายการข้อมูล");
        dataYear.forEach((i, idx) => {
          xyear = dayjs("01/01/" + i.year).format("BBBB");

          tb.push(xyear);
        });

        xdata.push(tb);

        for (var row in result.data) {
          const xx = [];

          for (var col in result.data[row]) {
            xx.push(result.data[row][col]);
          }

          xdata.push(xx);
        }

        var wb = xlsx.utils.book_new(),
          ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

        xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
        xlsx.writeFile(wb, "Excel.xlsx");
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const columns2 = [
    {
      title: "No.",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 50,
      sorter: (a, b) => a.num - b.num,
      render: (text, record, index) => {
        return index + 1;
      }
    },
    {
      title: "ชื่อนักวิจัย",
      dataIndex: "research",
      key: "research",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.research, "All", record.pp_all);
              }}
            >
              {text}
            </a>
          )
        };
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              autoFocus
              allowClear
              placeholder="ค้นหา"
              value={selectedKeys[0]}
              onChange={e => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              style={{ marginBottom: 5 }}
            >
              {}
            </Input>

            <Space>
              <Button
                icon={<SearchOutlined />}
                type="primary"
                size="small"
                style={{ width: 90 }}
                onClick={() => {
                  confirm();
                }}
              >
                ค้นหา
              </Button>
              <Button
                type="danger"
                size="small"
                style={{ width: 90 }}
                onClick={() => {
                  clearFilters();
                }}
              >
                เคลียร์
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.research.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.num - b.num
    },
    {
      title: "จำนวนผลงานตีพิมพ์",
      dataIndex: "pp_all",
      key: "pp_all",
      align: "center",
      width: 150,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.research, "All", record.pp_all);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.pp_all - b.pp_all
    },
    {
      title: "จำนวนผลงานที่เป็น First/Corresponding Author",
      dataIndex: "pp_first",
      key: "pp_first",
      align: "center",
      width: 310,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.research, "First", record.pp_first);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.pp_first - b.pp_first
    },
    {
      title: "Q1",
      dataIndex: "q1",
      key: "q1",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.research, "Q1", record.q1);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q1 - b.q1
    },
    {
      title: "Q2",
      dataIndex: "q2",
      key: "q2",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.research, "Q2", record.q2);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q2 - b.q2
    },
    {
      title: "Q3",
      dataIndex: "q3",
      key: "q3",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.research, "Q3", record.q3);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q3 - b.q3
    },
    {
      title: "Q4",
      dataIndex: "q4",
      key: "q4",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.research, "Q4", record.q4);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q4 - b.q4
    },
    {
      title: "ไม่มี Q",
      dataIndex: "q_other",
      key: "q_other",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.research, "N/A", record.q_other);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q_other - b.q_other
    }
  ];

  return (
    <React.Fragment>
      <StyledForm />

      <Header />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={23}>
            <PageHeader className="site-page-header" title="SCOPUS" />
            <Divider />

            <Card
              title={
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "center",
                    color: "white"
                  }}
                >
                  จำนวนผลงานตีพิมพ์ใน SCOPUS
                </div>
              }
              headStyle={{
                backgroundColor: "#00474f"
              }}
              style={{
                //margin: "10px",
                borderRadius: "15px",
                overflow: "hidden"
              }}
            >
              <Button ghost type="success" onClick={bExcelClick}>
                <FileExcelOutlined /> Excel
              </Button>

              <Divider />

              <Spin tip="Loading..." spinning={loading} size="large">
                <Table
                  bordered
                  scroll={{ x: "max-content" }}
                  dataSource={dataSource2}
                  columns={columns2}
                  size="small"
                  pagination={false}
                />
              </Spin>
            </Card>
          </Col>
        </Row>

        <br />
      </Content>

      <Footer2 />
      <BackTop />
    </React.Fragment>
  );
};

export default Research_scopus_naut;
