import "antd/dist/antd.min.css";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import {
  ClearOutlined,
  PlusOutlined,
  SaveOutlined,
  ProfileOutlined,
  BankOutlined,
  UsergroupAddOutlined
} from "@ant-design/icons";
import { createGlobalStyle } from "styled-components";

import {
  Row,
  Col,
  Button,
  Form,
  Table,
  Layout,
  Typography,
  Divider,
  Input,
  Space,
  Card,
  InputNumber,
  Select,
  Tabs,
  PageHeader,
  ConfigProvider
} from "antd";
import moment from "moment";
import "moment/locale/th";
import locale from "antd/es/date-picker/locale/th_TH";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import swal from "sweetalert";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import thTH from "antd/lib/locale/th_TH";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

const { Content } = Layout;
const { Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const StyledForm = createGlobalStyle`.ant-form-item {
  margin-bottom: 5px;
}
`;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 }
};

const configuredLocale = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    dateFormat: "DD MM BBBB", // DD MM BBBB
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker.lang,
      dateFormat: "DD MM BBBB", // DD MM BBBB
      dateTimeFormat: "DD MM BBBB HH:mm:ss",
      yearFormat: "BBBB"
    }
  }
};

dayjs.locale("th");
dayjs.extend(buddhistEra);

const Research_input = () => {
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [form4] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }
  const [dataSource1, _dataSource1] = useState([]);
  const [dataSource2, _dataSource2] = useState([]);

  const [xid, _xid] = useState(1);
  const [xidR, _xidR] = useState(1);

  const [xStatus, _xStatus] = useState("");
  const [xPaper, _xPaper] = useState("");
  const [xAgency, _xAgency] = useState("");
  const [xResearch, _xResearch] = useState("");
  const [xResearchP, _xResearchP] = useState("");
  const [xType, _xType] = useState("");
  const [xReceipt, _xReceipt] = useState("");

  const [xStatusValue, _xStatusValue] = useState("0");
  const [xPaperValue, _xPaperValue] = useState("0");
  const [xAgencyValue, _xAgencyValue] = useState("0");
  const [xResearchValue, _xResearchValue] = useState("0");
  const [xResearchPValue, _xResearchPValue] = useState("0");
  const [xTypeValue, _xTypeValue] = useState("0");
  const [xReceiptValue, _xReceiptValue] = useState("0");

  const [xAgencyName, _xAgencyName] = useState("");
  const [xResearchName, _xResearchName] = useState("");
  const [xResearchPosition_id, _xResearchPosition_id] = useState("");
  const [xRPName, _xRPName] = useState("");
  const [xTypeName, _xTypeName] = useState("");

  const [xBudget, _xBudget] = useState(0);
  const [xSumAmount, _xSumAmount] = useState(0);

  const [activeKeyshow, _activeKeyshow] = useState("1");

  const txtname_th = useRef("");
  const txtname_eng = useRef("");
  const txtshort_name = useRef("");
  const txtppt_cmu = useRef("");
  const txtsdate = useRef("");
  const txtedate = useRef("");
  const txtstatus = useRef("");
  const txttype = useRef("");

  const DatePicker = generatePicker(dayjsGenerateConfig);

  useEffect(() => {
    const token = localStorage.getItem("token");
    //console.log(token);

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");

          //ค้นหาสถานะโครงการ
          axios
            .get(xURL + "Project_status")
            .then(result => {
              //console.log(result.data);

              _xStatus(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหายุทธศาสตร์ของมหาวิทยาลัย
          axios
            .get(xURL + "ppt_cmu")
            .then(result => {
              //console.log(result.data);

              _xPaper(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหาแหล่งทุน
          axios
            .get(xURL + "agency")
            .then(result => {
              //console.log(result.data);

              _xAgency(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหานักวิจัย
          axios
            .get(xURL + "research")
            .then(result => {
              //console.log(result.data);

              _xResearch(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหาตำแหน่งนักวิจัย
          axios
            .get(xURL + "researchP")
            .then(result => {
              //console.log(result.data);

              _xResearchP(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหาประเภทโครงการ
          axios
            .get(xURL + "ptype")
            .then(result => {
              //console.log(result.data);

              _xType(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหาใบเสร็จแหล่งทุน
          axios
            .get(xURL + "Receiptname")
            .then(result => {
              //console.log(result.data);

              _xReceipt(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const bClearclick = () => {
    form1.resetFields();
    form2.resetFields();
    form3.resetFields();
    form4.resetFields();

    _dataSource1([]);
    _dataSource2([]);

    _xStatusValue("0");
    _xPaperValue("0");
    _xAgencyValue("0");
    _xResearchValue("0");
    _xResearchPValue("0");
    _xReceiptValue("0");

    _xAgencyName("");
    _xResearchName("");
    _xRPName("");
    _xResearchPosition_id("");

    _xid(1);
    _xBudget(0);
    _xSumAmount(0);

    _xidR(1);

    _activeKeyshow("1");
  };

  const bClearclick2 = () => {
    form2.resetFields();

    _dataSource1([]);

    _xAgencyValue("0");
    _xAgencyName("");

    _xid(1);
    _xBudget(0);
    _xSumAmount(0);
  };

  const bClearclick4 = () => {
    form3.resetFields();

    _dataSource2([]);

    _xResearchValue("0");
    _xResearchPValue("0");

    _xResearchName("");
    _xRPName("");
    _xResearchPosition_id("");

    _xidR(1);
  };

  const bAddclick = () => {
    if (xAgencyValue == "0") {
      swal("เพิ่ม ไม่ได้!", "ยังไม่ได้คีย์ชื่อแหล่งทุน!", "warning");
    } else if (xBudget === 0) {
      swal("เพิ่ม ไม่ได้!", "ยังไม่ได้คีย์จำนวนเงิน!", "warning");
    } else {
      const Tb1 = [];

      if (xid !== 1) {
        dataSource1.forEach((i, idx) => {
          Tb1.push({
            key: idx,
            id: i.id,
            name: i.name,
            budget: i.budget,
            agency_id: i.agency_id
          });
        });
      }

      Tb1.push({
        key: xid - 1,
        id: xid,
        name: xAgencyName,
        budget: xBudget.toLocaleString(),
        agency_id: xAgencyValue
      });

      //console.log(Tb1);

      _xSumAmount(parseInt(xSumAmount) + xBudget);

      _dataSource1(Tb1);

      _xid(parseInt(xid + 1));

      form2.resetFields();
      _xBudget(0);
    }
  };

  const bAddclick3 = () => {
    if (xResearchValue == "0") {
      swal("เพิ่ม ไม่ได้!", "ยังไม่ได้คีย์ชื่อนักวิจัย!", "warning");
    } else if (xResearchPValue == "0") {
      swal("เพิ่ม ไม่ได้!", "ยังไม่ได้คีย์ตำแหน่งนักวิจัย!", "warning");
    } else {
      const Tb1 = [];

      if (xidR !== 1) {
        dataSource2.forEach((i, idx) => {
          Tb1.push({
            key: idx,
            r_id: i.r_id,
            r_name: i.r_name,
            r_position: i.r_position,
            r_name_id: i.r_name_id,
            r_p_position_id: i.r_p_position_id,
            r_position_id: i.r_position_id
          });
        });
      }

      Tb1.push({
        key: xidR - 1,
        r_id: xidR,
        r_name: xResearchName,
        r_position: xRPName,
        r_name_id: xResearchValue,
        r_p_position_id: xResearchPValue,
        r_position_id: xResearchPosition_id
      });

      _dataSource2(Tb1);

      _xidR(parseInt(xidR + 1));
      form3.resetFields();
    }
  };

  const bSaveclick = () => {
    const f = form1.getFieldsValue();

    //console.log(f.sdate, f.edate); //null = '',''
    //console.log(f.sdate2, f.edate2); //null = undefined ,undefined

    if (!f.edCmumis_id) {
      swal(
        "บันทึก ไม่ได้!",
        "ยังไม่ได้คีย์ รหัส CMU MIS!",
        "warning"
      ).then(() => {
        _activeKeyshow("1");
      });
    } else if (f.edCmumis_id.trim().length !== 10) {
      swal(
        "บันทึก ไม่ได้!",
        "คีย์ รหัส CMU MIS ไม่ถูกต้อง",
        "warning"
      ).then(() => {
        _activeKeyshow("1");
      });
    } else if (!f.edname_th && !f.edname_eng) {
      swal(
        "บันทึก ไม่ได้!",
        "ยังไม่ได้คีย์ ชื่อโครงการ",
        "warning"
      ).then(() => {
        _activeKeyshow("1");
      });
    } else if (xPaperValue == "0") {
      swal(
        "บันทึก ไม่ได้!",
        "ยังไม่ได้เลือก ยุทธศาสตร์ของมหาวิทยาลัย",
        "warning"
      ).then(() => {
        _activeKeyshow("1");
      });
    } else if (!f.sdate) {
      swal(
        "บันทึก ไม่ได้!",
        "ยังไม่ได้คีย์ วันที่เริ่มต้นโครงการ",
        "warning"
      ).then(() => {
        _activeKeyshow("1");
      });
    } else if (!f.edate) {
      swal(
        "บันทึก ไม่ได้!",
        "ยังไม่ได้คีย์ วันที่สิ้นสุดโครงการ",
        "warning"
      ).then(() => {
        _activeKeyshow("1");
      });
    } else if (xStatusValue == "0") {
      swal(
        "บันทึก ไม่ได้!",
        "ยังไม่ได้เลือก สถานะโครงการ",
        "warning"
      ).then(() => {
        _activeKeyshow("1");
      });
    } else if (xTypeValue == "0") {
      swal(
        "บันทึก ไม่ได้!",
        "ยังไม่ได้เลือก ประเภทโครงการ",
        "warning"
      ).then(() => {
        _activeKeyshow("1");
      });
    } else if (xReceiptValue == "0") {
      swal(
        "บันทึก ไม่ได้!",
        "ยังไม่ได้เลือก นำส่งใบเสร็จ",
        "warning"
      ).then(() => {
        _activeKeyshow("1");
      });
    } else {
      swal({
        title: "ยืนยันเพิ่มโครงการวิจัย",
        text: "รหัส CMU MIS " + f.edCmumis_id,
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          swal({
            title: "แจ้งเตือน!",
            text: "เพิ่มโครงการวิจัย สำเร็จ",
            icon: "success",
            buttons: false,
            timer: 2000
          }).then(() => {
            insertdata();
          });
        }
      });
    }
  };

  const insertdata = () => {
    const f = form1.getFieldsValue();

    /*
      console.log(
        moment(f.sdate).format("YYYY-MM-DD"),
        moment(f.edate).format("YYYY-MM-DD"),
        xSumAmount,xPaperValue,xStatusValue
      );*/

    //console.log(dataSource1); //agency

    axios
      .post(xURL + "checkinsert", {
        cmumis_id: f.edCmumis_id
      })
      .then(result => {
        if (result.data.length > 0) {
          swal(
            "บันทึก ไม่ได้!",
            "รหัสโครงการ " + f.edCmumis_id + " ซ้ำ",
            "error"
          ).then(() => {
            _activeKeyshow("1");
          });
        } else {
          axios
            .post(xURL + "insert", {
              cmumis_id: f.edCmumis_id,
              name_thai: f.edname_th,
              name_eng: f.edname_eng,
              short_name: f.edshort_name,
              pub_type_cmu_id: xPaperValue,
              start_date: dayjs(f.sdate).format("YYYY-MM-DD"),
              end_date: dayjs(f.edate).format("YYYY-MM-DD"),
              budget: xSumAmount,
              status_id: xStatusValue,
              type_id: xTypeValue,
              receipt_id: xReceiptValue,
              zorder: 1
            })
            .then(result => {
              //console.log(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          if (dataSource1 != "") {
            dataSource1.forEach(v => {
              axios
                .post(xURL + "insert", {
                  cmumis_id: f.edCmumis_id,
                  agency_id: v.agency_id,
                  budget: parseInt(v.budget.replace(",", "").replace(",", "")),
                  zorder: 2
                })
                .then(result => {
                  //console.log(result.data);
                })
                .catch(function(error) {
                  // handle error
                  console.log("problem here", error);
                });
            });
          }

          if (dataSource2 != "") {
            dataSource2.forEach(v => {
              axios
                .post(xURL + "insert", {
                  cmumis_id: f.edCmumis_id,
                  researcher_staff_id: v.r_name_id,
                  researcher_project_position_id: v.r_p_position_id,
                  researcher_position_id: v.r_position_id,
                  zorder: 3
                })
                .then(result => {
                  //console.log(result.data);
                })
                .catch(function(error) {
                  // handle error
                  console.log("problem here", error);
                });
            });
          }

          bClearclick();
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bAddagency = () => {
    window.location = "/Research_input_agency";
  };

  const bAddresearch = () => {
    window.location = "/Research_input_researcher";
  };

  const columns1 = [
    {
      title: "แหล่งทุนที่",
      dataIndex: "id",
      key: "id",
      align: "center"
    },
    {
      title: "ชื่อแหล่งทุน",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (text, record) => __TBShowText(record.name, 0, 1)
    },
    {
      title: "งบประมาณ",
      dataIndex: "budget",
      key: "budget",
      align: "center",
      render: (text, record) => __TBShowText(record.budget, 1, 0)
    }
  ];

  const columns2 = [
    {
      title: "นักวิจัยที่",
      dataIndex: "r_id",
      key: "r_id",
      align: "center"
    },
    {
      title: "ชื่อนักวิจัย",
      dataIndex: "r_name",
      key: "r_name",
      align: "center",
      render: (text, record) => __TBShowText(record.r_name, 0, 1)
    },
    {
      title: "ตำแหน่งนักวิจัย",
      dataIndex: "r_position",
      key: "r_position",
      align: "center"
    }
  ];

  function handleChange(value) {
    _xPaperValue(value);
    txtsdate.current.focus();
  }

  function handleChange2(value) {
    _xStatusValue(value);
  }

  function handleChange3(value) {
    _xTypeValue(value);

    xType.forEach((i, idx) => {
      if (i.id === value) {
        //console.log(i.name);
        _xTypeName(i.name);
      }
    });
  }

  function handleChangereceipt(value) {
    _xReceiptValue(value);
  }

  // function handleChange3(value) {
  //   _xAgencyValue(value);

  //   xAgency.forEach((i, idx) => {
  //     if (i.id === value) {
  //       //console.log(i.name);
  //       _xAgencyName(i.name);
  //     }
  //   });
  // }

  function handleChange4(value) {
    _xResearchValue(value);

    xResearch.forEach((i, idx) => {
      if (i.id === value) {
        //console.log(i.name);
        //_xResearchName(i.pre + " " + i.name + " " + i.lname);
        _xResearchName(i.name + " " + i.lname);
        _xResearchPosition_id(i.researcher_position_id);
      }
    });
  }

  function handleChange5(value) {
    _xResearchPValue(value);

    xResearchP.forEach((i, idx) => {
      if (i.id === value) {
        //console.log(i.name);
        _xRPName(i.name);
      }
    });
  }

  const onChangeedbudget = e => {
    if (e == null) {
      _xBudget(0);
    } else {
      _xBudget(e);
    }
  };

  function TabClick(key) {
    //console.log(key);
    //*** tab Choose ***
    if (key == "1") {
      _activeKeyshow("1");
    }
    if (key == "2") {
      _activeKeyshow("2");
    }
    if (key == "3") {
      _activeKeyshow("3");
    }
  }

  //formatttable (Rigth or Left)
  function __TBShowText(zData, zRigth, zLeft) {
    if (zRigth === 1 && zLeft === 0) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    } else if (zRigth === 0 && zLeft === 1) {
      return (
        <div style={{ display: "flex" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <StyledForm />
      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />
      <Content
        className="site-layout"
        style={{
          padding: "0 20px",
          marginTop: 64
        }}
      >
        <Row justify="space-around">
          <Col
            xs={23}
            sm={23}
            md={23}
            lg={18}
            xl={16}
            //justify="space-around"
            //style={{ padding: "10px", paddingTop: "24px" }}
          >
            <PageHeader
              className="site-page-header"
              title="เพิ่ม โครงการวิจัย"
            />

            <Divider />

            <Card>
              <Tabs
                defaultActiveKey="1"
                size={"small"}
                activeKey={activeKeyshow}
                onChange={TabClick}
              >
                <TabPane
                  tab={
                    <span>
                      <ProfileOutlined /> * เพิ่มหัวข้อโครงการวิจัย
                    </span>
                  }
                  key="1"
                >
                  <Form
                    form={form1}
                    initialValues={{
                      edCmumis_id: "",
                      edname_th: "",
                      edname_eng: "",
                      edshort_name: "",
                      slppt_cmu: "เลือกรายการ",
                      sdate: "",
                      edate: ""
                    }}
                    {...layout}
                  >
                    <Divider />

                    <Form.Item
                      label="รหัส CMU MIS"
                      name="edCmumis_id"
                      rules={[
                        {
                          required: true,
                          message: "กรุณากรอก รหัส CMU MIS"
                        }
                      ]}
                    >
                      <Input
                        autoFocus
                        allowClear
                        placeholder="รหัส CMU MIS"
                        maxLength="10"
                        onPressEnter={() => {
                          txtname_th.current.focus();
                        }}
                        //style={{ width: 400 }}
                      />
                    </Form.Item>

                    <Form.Item label="ชื่อโครงการ: (ภาษาไทย)" name="edname_th">
                      <Input
                        allowClear
                        placeholder="ชื่อโครงการ: (ภาษาไทย)"
                        ref={txtname_th}
                        onPressEnter={() => {
                          txtname_eng.current.focus();
                        }}
                        //style={{ width: 400 }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="ชื่อโครงการ: (ภาษาอังกฤษ)"
                      name="edname_eng"
                    >
                      <Input
                        allowClear
                        placeholder="ชื่อโครงการ: (ภาษาอังกฤษ)"
                        ref={txtname_eng}
                        onPressEnter={() => {
                          txtshort_name.current.focus();
                        }}
                        //style={{ width: 400 }}
                      />
                    </Form.Item>

                    <Form.Item label="ชื่อย่อโครงการ" name="edshort_name">
                      <Input
                        allowClear
                        placeholder="ชื่อย่อโครงการ"
                        ref={txtshort_name}
                        onPressEnter={() => {
                          txtppt_cmu.current.focus();
                        }}
                        //style={{ width: 400 }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="ยุทธศาสตร์ของมหาวิทยาลัย"
                      name="slppt_cmu"
                      rules={[
                        {
                          required: true
                        }
                      ]}
                    >
                      <Select onChange={handleChange} ref={txtppt_cmu}>
                        {xPaper.length > 0
                          ? xPaper.map((i, idx) => {
                              return (
                                <Option value={i.pub_type_cmu_id}>
                                  {i.pub_type_cmu_name} {i.pub_type_short}
                                </Option>
                              );
                            })
                          : <Option value="0">
                              {}
                            </Option>}
                      </Select>
                    </Form.Item>

                    <ConfigProvider locale={configuredLocale}>
                      <Form.Item
                        label="วันที่เริ่มต้นโครงการ"
                        name="sdate"
                        rules={[
                          {
                            required: true,
                            message: "กรุณากรอก วันที่เริ่มต้นโครงการ"
                          }
                        ]}
                      >
                        <DatePicker
                          format="DD/MM/BBBB"
                          ref={txtsdate}
                          onChange={e => {
                            if ((e.length = 10)) {
                              txtedate.current.focus();
                            }
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        label="วันที่สิ้นสุดโครงการ"
                        name="edate"
                        rules={[
                          {
                            required: true,
                            message: "กรุณากรอก วันที่สิ้นสุดโครงการ"
                          }
                        ]}
                      >
                        <DatePicker
                          format="DD/MM/BBBB"
                          ref={txtedate}
                          onChange={e => {
                            if ((e.length = 10)) {
                              txtstatus.current.focus();
                            }
                          }}
                        />
                      </Form.Item>
                    </ConfigProvider>
                  </Form>

                  <Divider />

                  <Form
                    form={form4}
                    initialValues={{ slstatus: "เลือกรายการ" }}
                    {...layout}
                  >
                    <Form.Item
                      label="สถานะโครงการ"
                      name="slstatus"
                      rules={[
                        {
                          required: true
                        }
                      ]}
                    >
                      <Select onChange={handleChange2} ref={txtstatus}>
                        {xStatus.length > 0
                          ? xStatus.map((i, idx) => {
                              return (
                                <Option value={i.id}>
                                  {i.id} : {i.name}
                                </Option>
                              );
                            })
                          : <Option value="0">
                              {}
                            </Option>}
                      </Select>
                    </Form.Item>
                  </Form>

                  <Divider />

                  <Form
                    form={form4}
                    initialValues={{ sltype: "เลือกรายการ" }}
                    {...layout}
                  >
                    <Form.Item
                      label="ประเภทโครงการ"
                      name="sltype"
                      rules={[
                        {
                          required: true
                        }
                      ]}
                    >
                      <Select onChange={handleChange3} ref={txttype}>
                        {xType.length > 0
                          ? xType.map((i, idx) => {
                              return (
                                <Option value={i.id}>
                                  {i.id} : {i.name}
                                </Option>
                              );
                            })
                          : <Option value="0">
                              {}
                            </Option>}
                      </Select>
                    </Form.Item>
                  </Form>

                  <Divider />

                  <Form
                    form={form4}
                    initialValues={{ slreceipt: "เลือกรายการ" }}
                    {...layout}
                  >
                    <Form.Item label="นำส่งใบเสร็จ" name="slreceipt">
                      <Select onChange={handleChangereceipt}>
                        {xReceipt.length > 0
                          ? xReceipt.map((i, idx) => {
                              return (
                                <Option value={i.id}>
                                  {i.name}
                                </Option>
                              );
                            })
                          : <Option value="0">
                              {}
                            </Option>}
                      </Select>
                    </Form.Item>
                  </Form>
                </TabPane>

                {/* 
                <TabPane
                  tab={
                    <span>
                      <BankOutlined /> เพิ่มแหล่งทุน
                    </span>
                  }
                  key="2"
                >
                  <Form
                    form={form2}
                    initialValues={{ slagency: "เลือกรายการ" }}
                  >
                    <Divider />
                    <Row>
                      <Col span={4}>
                        <Form.Item label="แหล่งทุนที่" name="edbudget_id">
                          <Row>
                            <Input
                              disabled
                              style={{
                                width: 35,
                                color: "red",
                                textAlign: "center"
                              }}
                              value={xid}
                            />
                          </Row>
                        </Form.Item>
                      </Col>

                      <Col span={20}>
                        <Form.Item label="ชื่อแหล่งทุน" name="slagency">
                          <Select onChange={handleChange3}>
                            {xAgency.length > 0
                              ? xAgency.map((i, idx) => {
                                  return (
                                    <Option value={i.id}>
                                      {i.name}
                                    </Option>
                                  );
                                })
                              : <Option value="0">
                                  {}
                                </Option>}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col offset={12} span={12}>
                        <Form.Item label="จำนวนเงิน" name="edbudget">
                          <InputNumber
                            style={{ width: "100%" }}
                            //disabled={Export}
                            min="0"
                            max="9999999999"
                            maxLength="12"
                            onChange={e => onChangeedbudget(e)}
                            formatter={value =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            parser={value => value.replace(/\$\s?|(,*)/g, "")}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Divider />
                    <Row justify="center">
                      <Space>
                        <Button ghost type="primary" onClick={bAddclick}>
                          <PlusOutlined /> เพิ่ม
                        </Button>

                        <Button ghost type="danger" onClick={bClearclick2}>
                          <ClearOutlined /> เคลียร์แหล่งทุน
                        </Button>
                      </Space>
                    </Row>

                    <br />

                    <Table
                      bordered
                      scroll={{ x: "max-content" }}
                      dataSource={dataSource1}
                      columns={columns1}
                      size="small"
                      pagination={false}
                      summary={() =>
                        <Table.Summary fixed>
                          <Table.Summary.Row
                            style={{
                              textAlign: "right",
                              backgroundColor: "#b5f5ec"
                            }}
                          >
                            <Table.Summary.Cell />
                            <Table.Summary.Cell>รวมงบประมาณ</Table.Summary.Cell>

                            <Table.Summary.Cell>
                              {xSumAmount.toLocaleString()}
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        </Table.Summary>}
                    />
                    <br />
                  </Form>
                </TabPane>

                <TabPane
                  tab={
                    <span>
                      <UsergroupAddOutlined /> ผู้ร่วมวิจัย
                    </span>
                  }
                  key="3"
                >
                  <Form
                    form={form3}
                    initialValues={{
                      slresearchname: "เลือกรายการ",
                      slresearchposition: "เลือกรายการ"
                    }}
                  >
                    <Divider />
                    <Row>
                      <Col span={4}>
                        <Form.Item label="นักวิจัยที่" name="edresearch_id">
                          <Row>
                            <Input
                              disabled
                              style={{
                                width: 35,
                                color: "red",
                                textAlign: "center"
                              }}
                              value={xidR}
                            />
                          </Row>
                        </Form.Item>
                      </Col>

                      <Col span={11}>
                        <Form.Item label="ชื่อนักวิจัย" name="slresearchname">
                          <Select onChange={handleChange4}>
                            {xResearch.length > 0
                              ? xResearch.map((i, idx) => {
                                  return (
                                    <Option value={i.id}>
                                      {i.name} {i.lname}
                                    </Option>
                                  );
                                })
                              : <Option value="0">
                                  {}
                                </Option>}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={8} offset={1}>
                        <Form.Item
                          label="ตำแหน่งนักวิจัย"
                          name="slresearchposition"
                        >
                          <Select onChange={handleChange5}>
                            {xResearchP.length > 0
                              ? xResearchP.map((i, idx) => {
                                  return (
                                    <Option value={i.id}>
                                      {i.name}
                                    </Option>
                                  );
                                })
                              : <Option value="0">
                                  {}
                                </Option>}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Divider />
                    <Row justify="center">
                      <Space>
                        <Button ghost type="primary" onClick={bAddclick3}>
                          <PlusOutlined /> เพิ่ม
                        </Button>

                        <Button ghost type="danger" onClick={bClearclick4}>
                          <ClearOutlined /> เคลียร์นักวิจัย
                        </Button>
                      </Space>
                    </Row>

                    <br />

                    <Table
                      bordered
                      scroll={{ x: "max-content" }}
                      dataSource={dataSource2}
                      columns={columns2}
                      size="small"
                      pagination={false}
                    />

                    <br />
                  </Form>
                </TabPane> */}
              </Tabs>
            </Card>

            <br />
            <br />

            <Row justify="center">
              <Space>
                <Button ghost type="primary" onClick={bSaveclick}>
                  <SaveOutlined />บันทึก
                </Button>

                <Button ghost type="danger" onClick={bClearclick}>
                  <ClearOutlined /> เคลียร์ทั้งหมด
                </Button>
              </Space>
            </Row>
          </Col>
        </Row>
      </Content>
      <br />
      <br /> <br />
      <br />
      <br />
      <Footer_last />
    </React.Fragment>
  );
};
export default Research_input;
